export function getTankName(hash) {
    switch(hash) {
        case 'TANK_CL': 
        case 'CL': 
            return 'Chlorine tank';
        case 'TANK_PH':
        case 'PH':
            return 'PH tank';
        case 'TANK_PH-':
        case 'PH -':
            return 'PH- tank';
        case 'TANK_PH+':
        case 'PH +':
            return 'PH+ tank';
        case 'TANK_OXY':
        case 'OXY':
            return 'Oxy tank';
        case 'TANK_ALG':
        case 'ALG':
            return 'Algicid Tank';
        case 'TANK_FLO':
        case 'FLO':
            return 'Flocculant tank';
        case 'TANK_BRO':
        case 'BRO':
            return 'Bromine tank';
        default:
            return hash;
    }
}