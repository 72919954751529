import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  SafeAreaView,
  Modal,
  TouchableOpacity,
} from 'react-native';
import {connect} from 'react-redux';
import {Header, Icon, Button} from 'react-native-elements';
import translate, {translateString} from '../translations/translate';
import PropTypes from 'prop-types';

import {getTankName} from '../Utils/device';
import * as actionsApp from '../Store/App/AppActions';
import DraggableFlatList from 'react-native-draggable-flatlist';
import {fontFamilyBold, fontFamilyRegular} from '../Config/fonts';
import {
  appHeaderHeight,
  msg_code_to_text,
  ShowSnaskMessage,
  setStoreData,
} from '../Config/Config';
import * as actions from '../Store/Systems/SystemsActions';
import ThreeDotsLoader from '../components/ThreeDotsLoader';
import HeaderLeftComponent from '../components/HeaderLeftComponent';

function ItemModal({
  style_id,
  style_name,
  style_type,
  style_field,
  style_number,
  style_is_default,
  lastUpdate,
  currentItem = {},
  UpdateStatusDevices,
}) {
  return (
    <View style={styles.item}>
      <Text
        style={{
          flex: 0.5,
          color: '#fff',
          alignSelf: 'center',
          justifyContent: 'center',
        }}>
        {style_id}.
      </Text>
      <View
        style={{
          flex: 5,
          flexDirection: 'row',
          paddingTop: 15,
          paddingBottom: 15,
        }}>
        <Text style={styles.title}>{style_name}</Text>
        <View style={{flex: 1.5, flexDirection: 'row'}}>
          {style_is_default ? (
            <TouchableOpacity>
              <Icon
                size={30}
                containerStyle={{flex: 1}}
                name="favorite"
                color="#B164C5"
              />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              onPress={() =>
                UpdateStatusDevices('fav_widget', currentItem.fav_id, style_id)
              }>
              <Icon
                size={30}
                containerStyle={{flex: 1}}
                name="favorite-border"
                color="#B164C5"
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  );
}

function Item({
  index,
  drag,
  isActive,
  item,
  SetToFav,
  RemoveFromFav,
  openModal,
}) {
  let hasModelWidget = false;
  if (
    item.hasOwnProperty('device_widget') ||
    item.hasOwnProperty('sensor_widget') ||
    item.hasOwnProperty('strip_widget')
  ) {
    hasModelWidget = true;
  }

  return (
    <TouchableOpacity
      style={{
        backgroundColor: isActive ? 'rgba(255, 255, 255, 0.5)' : 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onLongPress={drag}>
      <View style={styles.item}>
        <View
          style={{
            flex: 5,
            flexDirection: 'row',
            borderBottomColor: '#fff',
            borderBottomWidth: 0.5,
            paddingTop: 15,
            paddingBottom: 15,
          }}>
          <Text style={styles.title}>{getTankName(item.title)}</Text>
          <View style={{flex: 1.5, flexDirection: 'row', paddingRight: 10}}>
            {hasModelWidget ? (
              <TouchableOpacity
                onPress={() => openModal(item)}
                style={{marginRight: 10}}>
                <Icon
                  size={30}
                  containerStyle={{flex: 1}}
                  name="bars"
                  type="font-awesome"
                  color="#B164C5"
                />
              </TouchableOpacity>
            ) : (
              <Text style={{width: 30}} />
            )}

            {item.isfavory ? (
              <TouchableOpacity onPress={() => RemoveFromFav(item)}>
                <Icon
                  size={30}
                  containerStyle={{flex: 1}}
                  style={{marginRight: 10}}
                  name="favorite"
                  color="#B164C5"
                />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity onPress={() => SetToFav(item)}>
                <Icon
                  size={30}
                  containerStyle={{flex: 1}}
                  style={{marginRight: 10}}
                  name="favorite-border"
                  color="#B164C5"
                />
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

class GererMesFavoris extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      isWait: false,
      itemsTitleModal: '',
      itemsIdModal: 0,
      itemsOptionsModal: [],
      sensors: [],
      devices: [],
      listItems: [],
      fav_widget: [],
      favorites: [],
      system: {},
      curentItem: {},
    };
  }

  componentDidMount() {
    this.initComponent();
  }

  componentDidUpdate(nextProps) {
    const {last_update_data_redux} = this.props;

    if (
      nextProps.last_update_data_redux !== last_update_data_redux ||
      nextProps.last_update_data_redux_system !==
        this.props.last_update_data_redux_system
    ) {
      console.log('componentDidUpdate Fav');
      this.initComponent();
    }
  }
  initComponent = () => {
    this.getSensors();
    this.InitFavWidget();
    this.getfavorites();
  };
  getfavorites = async () => {
    if (
      this.props.current_system.hasOwnProperty('favorites') &&
      this.props.current_system.favorites.length > 0
    ) {
      let listFavorites = [];
      let devices = {};
      let sensors = {};
      let strips = {};
      //  console.log("Run getfavorites",this.props.current_system.actions);
      this.props.current_system.actions.map(device => {
        devices[device.device_number] = device;
      });
      this.props.current_system.sensors.map(sensor => {
        sensors[sensor.sensor_number] = sensor;
      });
      this.props.current_system.strips.map(strip => {
        strips[strip.strip_number] = strip;
      });

      for (let i = 0; i < this.props.current_system.favorites.length; i++) {
        const item = this.props.current_system.favorites[i];
        let fav = {
          fav_type: item.fav_type,
          widget_type: item.fav_type,
          fav_hash: item.fav_hash,
          fav_id: item.fav_id,
          id: item.fav_id,
          fav_number: item.fav_number,
          fav_display: item.fav_display,
          fav_widget: item.fav_widget,
          isfavory: item.fav_display === '1' ? true : false,
          fav_order: item.fav_order,
          title: '',
          obj: {},
        };
        if (item.fav_type === 'device') {
          let obj = devices[item.fav_number] ? devices[item.fav_number] : null;
          fav.title = '';
          fav.device_hash = '';
          fav.widget_hash = '';
          fav.device_number = '';
          fav.device_widget = '';
          if (obj) {
            fav.title = obj.device_name;
            fav.device_hash = obj.device_number;
            fav.widget_hash = obj.device_number;
            fav.device_number = obj.device_number;
            fav.device_widget = obj.device_widget || obj.fav_widget;

            console.log('Fav init data ', fav.title, fav.device_widget);
          }
        } else if (item.fav_type === 'sensor') {
          fav.title = '';
          fav.sensor_type = '';
          fav.sensor_hash = '';
          fav.widget_hash = '';
          fav.sensor_number = '';
          fav.sensor_widget = '';
          let obj = sensors[item.fav_number] ? sensors[item.fav_number] : null;

          if (obj) {
            fav.title = obj.sensor_name;
            fav.sensor_type = obj.sensor_type;
            fav.sensor_hash = obj.sensor_hash;
            fav.widget_hash = obj.sensor_hash;
            fav.sensor_widget = obj.sensor_widget;
            fav.sensor_number = obj.sensor_number || obj.fav_widget;

            console.log('Fav init data ', fav.title, fav.sensor_widget);
          }
        } else if (item.fav_type === 'strip') {
          fav.title = '';
          fav.strip_type = '';
          fav.strip_hash = '';
          fav.widget_hash = '';
          fav.strip_number = '';
          fav.strip_widget = '';
          let obj = strips[item.fav_number] ? strips[item.fav_number] : null;

          if (obj) {
            fav.title = obj.strip_name;
            fav.strip_type = obj.strip_type;
            fav.strip_hash = obj.strip_hash;
            fav.widget_hash = obj.strip_hash;
            fav.strip_widget = obj.strip_widget;
            fav.strip_number = obj.strip_number || obj.fav_widget;

            console.log('Fav init data ', fav.title, fav.strip_widget);
          }
        } else {
          fav.title = await translateString(item.fav_hash);
        }

        console.log('Fav init data ', fav.title);
        listFavorites[i] = fav;

        if (this.state.curentItem.fav_id === fav.fav_id) {
          const item = fav;
          let hasmoreOption = [];
          console.log('openModalOption ==> ', item);

          if (item.hasOwnProperty('sensor_widget')) {
            hasmoreOption = [
              {
                style_id: '0',
                style_name: 'Widget Curve',
                style_type: 'sensor',
                style_field: 'sensor_widget',
                style_number: item.sensor_number,
                style_is_default:
                  parseInt(item.sensor_widget) === 0 ? true : false,
              },
              {
                style_id: '1',
                style_name: 'Widget Graph',
                style_type: 'sensor',
                style_field: 'sensor_widget',
                style_number: item.sensor_number,
                style_is_default:
                  parseInt(item.sensor_widget) === 1 ? true : false,
              },
              {
                style_id: '2',
                style_name: 'Widget Round',
                style_type: 'sensor',
                style_field: 'sensor_widget',
                style_number: item.sensor_number,
                style_is_default:
                  parseInt(item.sensor_widget) === 2 ? true : false,
              },
              {
                style_id: '3',
                style_name: 'Widget Simple',
                style_type: 'sensor',
                style_field: 'sensor_widget',
                style_number: item.sensor_number,
                style_is_default:
                  parseInt(item.sensor_widget) === 3 ? true : false,
              },
              {
                style_id: '4',
                style_name: 'Widget Gauge',
                style_type: 'sensor',
                style_field: 'sensor_widget',
                style_number: item.sensor_number,
                style_is_default: parseInt(item.sensor_widget) === 4,
              },
            ];
          } else if (item.hasOwnProperty('strip_widget')) {
            hasmoreOption = [
              {
                style_id: '0',
                style_name: 'Widget Curve',
                style_type: 'strip',
                style_field: 'strip_widget',
                style_number: item.strip_number,
                style_is_default:
                  parseInt(item.strip_widget) === 0 ? true : false,
              },
              {
                style_id: '1',
                style_name: 'Widget Graph',
                style_type: 'strip',
                style_field: 'strip_widget',
                style_number: item.strip_number,
                style_is_default:
                  parseInt(item.strip_widget) === 1 ? true : false,
              },
              {
                style_id: '2',
                style_name: 'Widget Round',
                style_type: 'strip',
                style_field: 'strip_widget',
                style_number: item.strip_number,
                style_is_default:
                  parseInt(item.strip_widget) === 2 ? true : false,
              },
              {
                style_id: '3',
                style_name: 'Widget Simple',
                style_type: 'strip',
                style_field: 'strip_widget',
                style_number: item.strip_number,
                style_is_default:
                  parseInt(item.strip_widget) === 3 ? true : false,
              },
              {
                style_id: '4',
                style_name: 'Widget Gauge',
                style_type: 'strip',
                style_field: 'strip_widget',
                style_number: item.strip_number,
                style_is_default: parseInt(item.strip_widget) === 4,
              },
            ];
          } else {
            hasmoreOption = [
              {
                style_id: '0',
                style_name: 'Widget Aux',
                style_type: 'device',
                style_field: 'device_widget',
                style_number: item.device_number,
                style_is_default:
                  parseInt(item.device_widget) === 0 ? true : false,
              },
              {
                style_id: '1',
                style_name: 'Widget Sensor',
                style_type: 'device',
                style_field: 'device_widget',
                style_number: item.device_number,
                style_is_default:
                  parseInt(item.device_widget) === 1 ? true : false,
              },
              {
                style_id: '2',
                style_name: 'Widget Speed',
                style_type: 'device',
                style_field: 'device_widget',
                style_number: item.device_number,
                style_is_default:
                  parseInt(item.device_widget) === 2 ? true : false,
              },
              {
                style_id: '3',
                style_name: 'Widget Led',
                style_type: 'device',
                style_field: 'device_widget',
                style_number: item.device_number,
                style_is_default:
                  parseInt(item.device_widget) === 3 ? true : false,
              },
            ];
          }

          this.setState({currentItem: fav, itemsOptionsModal: hasmoreOption});
        }
      }

      listFavorites = listFavorites.sort(function(a, b) {
        return parseInt(a.fav_order) - parseInt(b.fav_order);
      });
      /*listFavorites = this.props.current_system.favorites
        .map(item => {
          let fav = {
            fav_type: item.fav_type,
            widget_type: item.fav_type,
            fav_hash: item.fav_hash,
            fav_id: item.fav_id,
            id: item.fav_id,
            fav_number: item.fav_number,
            fav_display: item.fav_display,
            isfavory: item.fav_display === '1' ? true : false,
            fav_order: item.fav_order,
            title: '',
            obj: {},
          };
          if (item.fav_type === 'device') {
            let obj = devices[item.fav_number]
              ? devices[item.fav_number]
              : null;
            fav.title = '';
            fav.device_hash = '';
            fav.widget_hash = '';
            fav.device_number = '';
            fav.device_widget = '';
            if (obj) {
              fav.title = obj.device_name;
              fav.device_hash = obj.device_number;
              fav.widget_hash = obj.device_number;
              fav.device_number = obj.device_number;
              fav.device_widget = obj.device_widget;

              console.log('Fav init data ', fav.title, fav.device_widget);
            }
          } else if (item.fav_type === 'sensor') {
            fav.title = '';
            fav.sensor_type = '';
            fav.sensor_hash = '';
            fav.widget_hash = '';
            fav.sensor_number = '';
            fav.sensor_widget = '';
            let obj = sensors[item.fav_number]
              ? sensors[item.fav_number]
              : null;

            if (obj) {
              fav.title = obj.sensor_name;
              fav.sensor_type = obj.sensor_type;
              fav.sensor_hash = obj.sensor_hash;
              fav.widget_hash = obj.sensor_hash;
              fav.sensor_widget = obj.sensor_widget;
              fav.sensor_number = obj.sensor_number;

              console.log('Fav init data ', fav.title, fav.sensor_widget);
            }
          } else {
            fav.title = translate(item.fav_hash);
          }

          console.log('Fav init data ', fav.title);

          return fav;
        })
        .sort(function(a, b) {
          return parseInt(a.fav_order) - parseInt(b.fav_order);
        });*/
      this.setState({favorites: listFavorites});
    }
  };
  InitFavWidget = () => {
    this.setState({fav_widget: this.props.app_fav_widget});
  };
  getSensors = () => {
    if (
      this.props.current_system.hasOwnProperty('sensors') &&
      this.props.current_system.sensors.length > 0
    ) {
      this.setState(
        {
          sensors: this.props.current_system.sensors,
          devices: this.props.current_system.actions,
          strips: this.props.current_system.strips,
          system: this.props.current_system.system[0],
        },
        () => {
          // this.gestFav()
          //  console.log("this.state.system.sys_appid ",this.state.system.sys_appid);
        },
      );
    } else {
      // this.gestFav()
    }
  };

  SetToFav = item => {
    let newFav = this.state.favorites;
    let index = this.state.favorites.findIndex(_item => {
      return _item.fav_id === item.fav_id;
    });
    this.UpdateStatusDevices('fav_display', item.fav_id, 1);
    newFav[index].isfavory = true;
    this.setState({favorites: newFav}, () => {
      //  this.gestFav();
      // this.RemoveFromFav("__clear_app__");
    });
  };

  RemoveFromFav = item => {
    let newFav = this.state.favorites;
    let index = this.state.favorites.findIndex(_item => {
      return _item.fav_id === item.fav_id;
    });

    newFav[index].isfavory = false;
    /* let newFav = this.state.fav_widget.filter((_item) =>{
             return _item.widget_hash != item.widget_hash;
         });*/

    this.UpdateStatusDevices('fav_display', item.fav_id, 0);
    this.setState({favorites: newFav}, () => {
      //this.gestFav();
      // this.props.updateFav(newFav);
    });
  };

  updatePossitionFav = (data, from, to) => {
    let item = data[to];
    this.UpdateStatusDevices('fav_order', item.fav_id, to + 1);
    this.setState({fav_widget: data}, () => {
      //  this.props.updateFav(data);
    });
  };
  WidgetIsFavorite = sensor_hash => {
    let index = this.state.fav_widget.findIndex(item => {
      return item.widget_hash === sensor_hash;
    });
    if (index >= 0) {
      return true;
    }
    return false;
  };

  /**
   * Todo to removed
   * @returns {boolean}
   */
  gestFav = () => {
    if (this.props.app_fav_widget.length > 5) {
      this.setState({listItems: this.props.app_fav_widget});
      return false;
    }

    let ItemsDefault = [
      {
        id: 1,
        widget_type: 'defaul',
        isfavory: this.WidgetIsFavorite('__Baignade__'),
        title: 'Baignade',
        widget_hash: '__Baignade__',
        hasmoreOption: [],
      },
      {
        id: 2,
        widget_type: 'defaul',
        isfavory: this.WidgetIsFavorite('__faq__'),
        title: 'Faq',
        widget_hash: '__faq__',
        hasmoreOption: [],
      },
      {
        id: 3,
        widget_type: 'defaul',
        isfavory: this.WidgetIsFavorite('__meteo__'),
        title: 'Meteo',
        widget_hash: '__meteo__',
        hasmoreOption: [],
      },
      {
        id: 4,
        widget_type: 'defaul',
        isfavory: this.WidgetIsFavorite('__bidons__'),
        title: 'Bidons',
        widget_hash: '__bidons__',
        hasmoreOption: [],
      },
      {
        id: 5,
        widget_type: 'defaul',
        isfavory: this.WidgetIsFavorite('__raccourcis__'),
        title: 'Raccourcis',
        widget_hash: '__raccourcis__',
        hasmoreOption: [],
      },
    ];

    this.state.sensors.map((item, index) => {
      ItemsDefault.push({
        id: ItemsDefault.length + 1,
        widget_type: 'sensor',
        sensor_type: item.sensor_type,
        isfavory: this.WidgetIsFavorite(item.sensor_hash),
        title: item.sensor_name,
        sensor_hash: item.sensor_hash,
        widget_hash: item.sensor_hash,
        sensor_widget: item.sensor_widget,
        hasmoreOption: [],
        sensor_number: item.sensor_number,
      });
    });

    this.state.strips.map((item, index) => {
      ItemsDefault.push({
        id: ItemsDefault.length + 1,
        widget_type: 'strip',
        sensor_type: item.strip_type,
        isfavory: this.WidgetIsFavorite(item.strip_hash),
        title: item.strip_name,
        sensor_hash: item.strip_hash,
        widget_hash: item.strip_hash,
        sensor_widget: item.strip_widget,
        hasmoreOption: [],
        sensor_number: item.strip_number,
      });
    });

    this.state.devices.map((item, index) => {
      ItemsDefault.push({
        id: ItemsDefault.length + 1,
        device_type: item.device_type,
        widget_type: 'device',
        isfavory: this.WidgetIsFavorite(item.device_hash),
        title: item.device_name,
        device_hash: item.device_hash,
        widget_hash: item.device_hash,
        device_number: item.device_number,
        device_widget: item.device_widget,
        hasmoreOption: [],
      });
    });
    this.setState({listItems: ItemsDefault});
  };

  componentWillUnmount() {}

  closeModal = () => {
    this.setState({modalVisible: false});
    // this.setState({ curentItem: {}});
  };
  OpenModal = i => {
    this.setState({modalVisible: true});
    this.setState({curentItem: i});
  };
  openModalOption = item => {
    let hasmoreOption = [];
    console.log('openModalOption ==> ', item);
    if (item.hasOwnProperty('sensor_widget')) {
      hasmoreOption = [
        {
          style_id: '0',
          style_name: 'Widget Curve',
          style_type: 'sensor',
          style_field: 'sensor_widget',
          style_number: item.sensor_number,
          style_is_default: parseInt(item.sensor_widget) === 0 ? true : false,
        },
        {
          style_id: '1',
          style_name: 'Widget Graph',
          style_type: 'sensor',
          style_field: 'sensor_widget',
          style_number: item.sensor_number,
          style_is_default: parseInt(item.sensor_widget) === 1 ? true : false,
        },
        {
          style_id: '2',
          style_name: 'Widget Round',
          style_type: 'sensor',
          style_field: 'sensor_widget',
          style_number: item.sensor_number,
          style_is_default: parseInt(item.sensor_widget) === 2 ? true : false,
        },
        {
          style_id: '3',
          style_name: 'Widget Simple',
          style_type: 'sensor',
          style_field: 'sensor_widget',
          style_number: item.sensor_number,
          style_is_default: parseInt(item.sensor_widget) === 3 ? true : false,
        },
        {
          style_id: '4',
          style_name: 'Widget Gauge',
          style_type: 'sensor',
          style_field: 'sensor_widget',
          style_number: item.sensor_number,
          style_is_default: parseInt(item.sensor_widget) === 4,
        },
      ];
    } else if (item.hasOwnProperty('strip_widget')) {
      hasmoreOption = [
        {
          style_id: '0',
          style_name: 'Widget Curve',
          style_type: 'strip',
          style_field: 'strip_widget',
          style_number: item.strip_number,
          style_is_default: parseInt(item.strip_widget) === 0 ? true : false,
        },
        {
          style_id: '1',
          style_name: 'Widget Graph',
          style_type: 'strip',
          style_field: 'strip_widget',
          style_number: item.strip_number,
          style_is_default: parseInt(item.strip_widget) === 1 ? true : false,
        },
        {
          style_id: '2',
          style_name: 'Widget Round',
          style_type: 'strip',
          style_field: 'strip_widget',
          style_number: item.strip_number,
          style_is_default: parseInt(item.strip_widget) === 2 ? true : false,
        },
        {
          style_id: '3',
          style_name: 'Widget Simple',
          style_type: 'strip',
          style_field: 'strip_widget',
          style_number: item.strip_number,
          style_is_default: parseInt(item.strip_widget) === 3 ? true : false,
        },
        {
          style_id: '4',
          style_name: 'Widget Gauge',
          style_type: 'strip',
          style_field: 'strip_widget',
          style_number: item.strip_number,
          style_is_default: parseInt(item.strip_widget) === 4,
        },
      ];
    } else {
      hasmoreOption = [
        {
          style_id: '0',
          style_name: 'Widget Aux',
          style_type: 'device',
          style_field: 'device_widget',
          style_number: item.device_number,
          style_is_default: parseInt(item.device_widget) === 0 ? true : false,
        },
        {
          style_id: '1',
          style_name: 'Widget Sensor',
          style_type: 'device',
          style_field: 'device_widget',
          style_number: item.device_number,
          style_is_default: parseInt(item.device_widget) === 1 ? true : false,
        },
        {
          style_id: '2',
          style_name: 'Widget Speed',
          style_type: 'device',
          style_field: 'device_widget',
          style_number: item.device_number,
          style_is_default: parseInt(item.device_widget) === 2 ? true : false,
        },
        {
          style_id: '3',
          style_name: 'Widget Led',
          style_type: 'device',
          style_field: 'device_widget',
          style_number: item.device_number,
          style_is_default: parseInt(item.device_widget) === 3 ? true : false,
        },
      ];
    }
    this.setState({itemsOptionsModal: hasmoreOption});
    this.setState({itemsTitleModal: item.title});
    this.setState({itemsIdModal: item.id});
    this.OpenModal(item);
  };

  goToBack = () => {
    this.props.updateFav(this.state.fav_widget);
    this.props.navigation.goBack();
  };
  render() {
    return (
      <SafeAreaView style={styles.containerfull}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          leftComponent={<HeaderLeftComponent goToBack={this.goToBack} />}
        />
        <View style={styles.triangleCorner} />
        <View style={styles.container}>
          <View style={styles.containerList}>
            <View style={{flexDirection: 'row', paddingBottom: 0}}>
              <Text style={styles.enteteText}>{translate('Mes Favoris')}</Text>
            </View>
            <Button
              icon={
                <Icon size={15} name="heart" type="font-awesome" color="#fff" />
              }
              iconLeft
              buttonStyle={styles.btnButton}
              title={translate('Gerer mes raccourcis')}
              titleStyle={{
                marginLeft: 10,
                color: 'white',
                fontFamily: fontFamilyRegular(),
              }}
              onPress={() => {
                this.props.navigation.navigate('GererMesRaccourcis');
              }}
            />
            <Text
              style={{
                color: '#fff',
                fontSize: 18,
                fontFamily: fontFamilyBold(),
                marginBottom: 20,
              }}>
              {translate('les indispensables')}
            </Text>
            <View>{this.state.isWait ? <ThreeDotsLoader /> : <View />}</View>
            <DraggableFlatList
              data={this.state.favorites}
              renderItem={({item, index, drag, isActive}) => (
                <Item
                  index={index}
                  drag={drag}
                  isActive={isActive}
                  openModal={this.openModalOption}
                  item={item}
                  SetToFav={this.SetToFav}
                  RemoveFromFav={this.RemoveFromFav}
                />
              )}
              keyExtractor={(item, index) => `draggable-item-${item.fav_id}`}
              onDragEnd={({data, from, to}) => {
                console.log('onDragEnd ', from, to);
                this.updatePossitionFav(data, from, to);
                this.setState({favorites: data});
              }}
            />
          </View>
        </View>
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.modalVisible}>
          <View style={styles.Modalcontainer}>
            <View
              style={{
                backgroundColor: '#000000',
                borderRadius: 20,
                padding: 15,
                width: '90%',
              }}>
              <View style={{position: 'absolute', right: 10, top: 10}}>
                <TouchableOpacity
                  onPress={() => this.closeModal()}
                  style={{padding: 3}}>
                  <Icon
                    size={20}
                    containerStyle={{marginLeft: 10}}
                    name="check"
                    type="font-awesome"
                    color="#fff"
                  />
                </TouchableOpacity>
              </View>
              <View style={{marginTop: 10}}>
                <Text
                  style={{
                    color: '#fff',
                    fontFamily: fontFamilyBold(),
                    fontSize: 18,
                    textAlign: 'center',
                  }}>
                  {this.state.itemsTitleModal}
                </Text>
              </View>
              <View style={styles.viewList}>
                <FlatList
                  data={this.state.itemsOptionsModal}
                  renderItem={({item}) => (
                    <ItemModal
                      {...item}
                      currentItem={this.state.curentItem}
                      lastUpdate={this.props.last_update_data_redux}
                      UpdateStatusDevices={this.UpdateStatusDevices}
                    />
                  )}
                  keyExtractor={item => item.id}
                />
              </View>
            </View>
          </View>
        </Modal>
      </SafeAreaView>
    );
  }

  UpdateStatusDevices = (fieldName, fieldNumber, val) => {
    if (this.state.isWait) {
      return true;
    }
    this.setState({isWait: true});
    this.closeModal();

    this.props
      .UpdateDevice(this.state.system.sys_appid, {
        key: fieldName,
        value: val,
        number: fieldNumber,
      })
      .then(data => {
        // this.setState({device_index:val});
        console.log('After Update Fav ', fieldName, val, data);
        this.setState({[fieldName]: val});
        this.setState({isWait: false});

        this.props.getSystem(this.props.current_system.system[0].sys_appid);

        ShowSnaskMessage({
          message: 'Operation Success',
          textColor: '#fff',
          backgroundColor: '#7ED321',
          buttonColor: '#7ED321',
          duration: 2000,
        });

        setTimeout(() => {
          setStoreData('system_current_is_admin', 'true');
          this.props.refreshApp();
        }, 100);
      })
      .catch(error => {
        console.log('Error Update Fav');
        console.log(error);

        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };
}

const styles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
  },
  enteteText: {
    fontSize: 18,
    fontWeight: '300',
    color: '#ffffff',
    fontFamily: fontFamilyBold(),
    marginBottom: 20,
  },
  container: {
    flex: 1,
    backgroundColor: '#000000',
  },
  btnButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#B164C5',
    color: '#fff',
    borderRadius: 50,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 15,
    width: 250,
    fontFamily: fontFamilyBold(),
  },
  containerList: {
    flex: 1,
    width: '90%',
    marginTop: 10,
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  item: {
    alignSelf: 'center',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  title: {
    flex: 5,
    fontSize: 14,
    paddingTop: 5,
    textAlign: 'left',
    color: '#fff',
    fontFamily: fontFamilyBold(),
  },
  triangleCorner: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightColor: 'transparent',
    borderTopColor: '#000000',
    transform: [{rotate: '180deg'}],
  },
  Modalcontainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(154, 152, 152, 0.84)',
    flexDirection: 'column',
  },
});
GererMesFavoris.propTypes = {
  current_system: PropTypes.object,
  app_fav_widget: PropTypes.array,
};
GererMesFavoris.defaultProps = {
  current_system: {},
  app_fav_widget: [],
};
const mapStateToProps = (state, ownProps) => {
  return {
    current_system: state.SystemsRedux.system,
    app_fav_widget: state.AppRedux.app_fav_widget,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    last_update_data_redux_system: state.SystemsRedux.last_update_data_redux,
    user: state.LoginReducer.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateFav: newFav => {
      return dispatch(actionsApp.updateFav(newFav));
    },
    refreshApp: newFav => {
      return dispatch(actionsApp.refreshApp());
    },
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
    getSystem: appid => {
      return dispatch(actions.SystemDetail(appid));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GererMesFavoris);
