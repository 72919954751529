import React from 'react';
import {StyleSheet, View} from 'react-native';
import {connect} from 'react-redux';
// import SplashScreen from 'react-native-splash-screen';
import * as actions from '../Store/Login/LoginActions';
import * as Systemsactions from '../Store/Systems/SystemsActions';
import Loader from '../components/Loader';
class Home extends React.Component {
  componentDidMount() {
    if (this.props.app_is_loadded) {
      this.redirectionScreen();
    }
    // this.props.navigation.navigate('Login');
  }

  redirectionScreen = () => {
    if (this.props.user && this.props.user.appid) {
      console.log('redirectionScreen 1');
      var bits = this.props.user.expiration.split(/\D/);
      var dateexpiration = new Date(
        bits[0],
        --bits[1],
        bits[2],
        bits[3],
        bits[4],
        bits[5],
      );

      if (Date.now() >= dateexpiration.getTime()) {
        console.log('redirectionScreen to logout');
        this.props.Logout();
        // SplashScreen.hide();

        this.props.navigation.replace('Login');
        return false;
      }

      if (
        this.props.current_system &&
        this.props.current_system.hasOwnProperty('system')
      ) {
        console.log('redirectionScreen System');
        this.props
          .getDetailSystem(this.props.current_system.system[0].sys_appid)
          .then(system => {
            this.props.navigation.replace('AcceuilTabs', {
              element: this.props.current_system,
            });
            // SplashScreen.hide();
            this.setState({iswait: false});
          })
          .catch(error => {
            this.setState({iswait: false});
          });
      } else {
        console.log('redirectionScreen Multi System');
        if (this.props.systems.length > 0) {
          this.props.navigation.replace('Systemes');
        } else {
          this.props.navigation.replace('Systemes');
        }
        setTimeout(() => {
          // SplashScreen.hide();
        }, 100);
      }
    } else {
      console.log('redirectionScreen Login');
      this.props.navigation.replace('Login');
      setTimeout(() => {
        // SplashScreen.hide();
      }, 100);
    }
  };

  componentDidUpdate(nextProps) {
    const {app_is_loadded} = this.props;
    if (nextProps.app_is_loadded !== app_is_loadded) {
      this.redirectionScreen();
      // this.setState({modalVisible:isOpen})
    }
  }
  componentWillUnmount() {
    this.redirectionScreen();
  }

  navigate = url => {
    // E
    if (url) {
      const route = url.replace(/.*?:\/\//g, '');
      const routeName = route.split('/');
      if (routeName.length > 2 && routeName[1] === 'mail') {
        const {replace} = this.props.navigation;
        replace('NewPassword', {appidfrommail: routeName[2]});
      }
    }
  };
  handleOpenURL(event) {
    if (event.url) {
      const route = event.url.replace(/.*?:\/\//g, '');
      console.log('ROUTE:' + event.url);
    }

    // do something with the url, in our case navigate(route)
  }
  render() {
    return (
      <View style={styles.container}>
        <Loader isloader={true} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    systems: state.SystemsRedux.systems,
    user: state.LoginReducer.user,
    app_is_loadded: state.AppRedux.app_is_loadded,
    current_system: state.SystemsRedux.system,
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    Logout: () => {
      return dispatch(actions.UserLogout());
    },
    getDetailSystem: appId => {
      return dispatch(Systemsactions.SystemDetail(appId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
