/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
  SafeAreaView,
  StyleSheet,
  ScrollView,
  View,
  Text,
  StatusBar,
  I18nManager,
} from 'react-native';

import "./index.css";

import * as RNLocalize from 'react-native-localize';
import i18n from 'i18n-js';
import {Provider} from 'react-redux';
import rootReducer from './App/Store/Store';
import {onAppStart} from './App/Store/InitApp';
import memoize from 'lodash.memoize';
import AppNavigator from './App/AppNavigator';
// import SplashScreen from 'react-native-splash-screen';
import translate from './App/translations/translate';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import Snackbar from './App/components/snackBar';
import wsMiddleware from './App/middleware/socket/socketMiddleware';
import WebSocketConnection from './App/middleware/socket/WebSocketConnection';
// import messaging from '@react-native-firebase/messaging';

const middleware = [thunk, wsMiddleware];
const store = createStore(rootReducer, applyMiddleware(...middleware));
import {enableScreens} from 'react-native-screens';

enableScreens();
const translationGetters = {
  // lazy requires (metro bundler does not support symlinks)
  en: () => require('./App/translations/en.json'),
  fr: () => require('./App/translations/fr.json'),
};

const setI18nConfig = () => {
  // fallback if no available language fits
  const fallback = {languageTag: 'fr', isRTL: false};

  const {languageTag, isRTL} = fallback;
  //const { languageTag, isRTL } = RNLocalize.findBestAvailableLanguage(Object.keys(translationGetters)) || fallback;

  // clear translation cache
  // translate.cache.clear();
  // update layout direction
  I18nManager.forceRTL(isRTL);
  // set i18n-js config
  i18n.translations = {[languageTag]: translationGetters[languageTag]()};
  i18n.locale = languageTag;
};

onAppStart(store);

import NetworkManager from './App/Utils/NetworkManager';

class App extends React.Component {
  constructor(props) {
    super(props);
    setI18nConfig(); // set initial config
    this.state = {
      ConnsctionIsAvailable: true,
    };

    NetworkManager.RegisterConnectionChangeCallback(
      (IsAvailable, ConnectionType) => {
        this.setState({ConnsctionIsAvailable: IsAvailable});
        console.log('IsAvailable ', IsAvailable);
      },
    );
  }

  componentDidMount() {
    // this.requestUserPermission();
    // do stuff while splash screen is shown
    // After having done stuff (such as async tasks) hide the splash screen

    // SplashScreen.hide();

    RNLocalize.addEventListener('change', this.handleLocalizationChange);
  }

  componentWillUnmount() {
    RNLocalize.removeEventListener('change', this.handleLocalizationChange);
  }

  handleLocalizationChange = () => {
    setI18nConfig();
    this.forceUpdate();
  };

  render() {
    return (
      <>
        <Snackbar id={'root_app'} />
        <Provider store={store}>
          <WebSocketConnection host={'wss://now.swimo.io'}>
            <View style={styles.global}>
              <AppNavigator />
            </View>
          </WebSocketConnection>
        </Provider>
      </>
    );
  }
}

const styles = StyleSheet.create({
  global: {
    fontFamily: "Arial !important",
    height: "100%"
  }
});

export default App;
