import React from 'react';
import styles from './styles.js';
import {
  Keyboard,
  KeyboardAvoidingView,
  Switch,
  TouchableWithoutFeedback,
  View,
  TouchableOpacity,
  Platform,
  Picker,
  StyleSheet,
  SafeAreaView,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import RNPickerSelect from 'react-native-picker-select';

import translate from '../../translations/translate';
import {
  Button,
  Input,
  Text,
  Header,
  Icon,
  ButtonGroup,
} from 'react-native-elements';
import {connect} from 'react-redux';
import * as actions from '../../Store/Registration/RegistrationActions';
import * as systemsActions from '../../Store/Systems/SystemsActions';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  appHeaderHeight,
} from '../../Config/Config';
import {translateString} from '../../translations/translate';

class HeaderRightComponent extends React.Component {
  render() {
    return (
      <TouchableOpacity
        hitSlop={{top: 10, left: 30, right: 30, bottom: 30}}
        onPress={() => this.props.goToBack()}
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}>
        <Icon size={30} name="times" type="font-awesome" color="#fff" />
      </TouchableOpacity>
    );
  }
}

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 12,
    paddingVertical: 5,
    paddingHorizontal: 4,
    color: '#fff',
    textAlign: 'center',
    backgroundColor: 'transparent',
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
});

class Step5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iswait: false,
      treatments: [],
      vbtn: 0,
      Stbtn: 0,
      formData: {
        sys_address: null,
        sys_address2: null,
        sys_zip: null,
        sys_city: null,
        sys_contrat_type: false,
        sys_user_email: false,
        sys_user_push: false,
        sys_number: '',
      },
      errors: {
        sys_address: null,
        sys_address2: null,
        sys_zip: null,
        sys_city: null,
      },
      inside: '',
      outside: '',
      pool: '',
      spa: '',
    };
  }

  async componentDidMount() {
    const system = this.props.step3.system[0];
    const treatments = this.props.step3.treatment;

    const pool = await translateString('Swimming pool');
    const spa = await translateString('Hot tub');
    const inside = await translateString('Indoor');
    const outside = await translateString('Outdoor');

    this.setState({
      pool,
      spa,
      inside,
      outside,
      formData: {
        ...system,
      },
      treatments,
    });
  }
  renderPicker = () => {
    if (Platform.OS === 'ios') {
      return (
        <View style={[styles.ViewPicker, {paddingBottom: 4}]}>
          <RNPickerSelect
            placeholder={{
              label: 'Choisir un element',
              value: null,
              color: '#fff',
            }}
            onValueChange={itemValue =>
              this.onChangeField('sys_number', itemValue)
            }
            value={this.state.formData.sys_number}
            style={{
              ...pickerSelectStyles,
              iconContainer: {
                top: 20,
                right: 10,
              },
              placeholder: {
                color: '#fff',
                fontSize: 12,
                fontWeight: 'bold',
              },
            }}
            items={this.state.treatments.map(t => ({
              label: t.sys_sensor_name,
              value: t.sys_number,
            }))}
          />
        </View>
      );
    }

    return (
      <Picker
        selectedValue={this.state.formData.sys_sensor_name}
        style={styles.Picker}
        onValueChange={itemValue =>
          this.onChangeField('sys_number', itemValue)
        }>
        {this.state.treatments.map(t => (
          <Picker.Item label={t.sys_sensor_name} value={t.sys_number} />
        ))}
      </Picker>
    );
  };

  toggleSwitch = (fieldName, val) => {
    this.onChangeField(fieldName, val);
  };
  onChangeField = (fieldName, val) => {
    let _formData = this.state.formData;
    let _errors = this.state.errors;
    if (val) {
      _errors[fieldName] = '';
    } else {
      _errors[fieldName] = 'Error';
    }
    _formData[fieldName] = val;
    this.setState({formData: _formData, errors: _errors});
  };
  SubmitBtn = () => {
    this.setState({iswait: true});
    if (this.validateFormData()) {
      return;
    }

    const {
      sys_place,
      sys_type,
      sys_ref,
      sys_address,
      sys_address2,
      sys_volume,
      sys_zip,
      sys_city,
      sys_contrat_type,
      sys_user_email,
      sys_user_push,
    } = this.state.formData;
    this.props
      .NextStep(
        {
          sys_place,
          sys_type,
          sys_ref,
          sys_address,
          sys_address2,
          sys_volume,
          sys_zip,
          sys_city,
          sys_contrat_type,
          sys_user_email,
          sys_user_push,
        },
        this.props.userAppId,
      )
      .then(userInfo => {
        this.setState({iswait: false});
        this.props.SystemGetAll(this.props.generalAppId);
        this.props.EndStep();
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });
        let _errors = this.state.errors;
        if (error === '_USERNAME_ALREADY_REGISTERED') {
          _errors.user = 'Already set';
          this.setState({errors: _errors});
        }
        this.setState({iswait: false});
      });
  };
  goToBack = () => {
    this.props.goBack();
  };
  validateFormData = () => {
    let isError = false;
    let _formData = this.state.formData;
    let _errors = this.state.errors;

    if (!_formData.sys_number) {
      isError = true;
      _errors.sys_number = translate('Ce champ est obligatoire');
    }

    this.setState({errors: _errors, iswait: false});
    return isError;
  };
  SkipStep = () => {
    this.props.ToProfile();
  };
  SelectedChoicTypee = selectedIndex => {
    this.onChangeField('sys_type', selectedIndex);
    this.setState({vbtn: selectedIndex});
  };
  SelectedSituaTypee = selectedIndex => {
    if (selectedIndex > 0) {
      this.onChangeField('sys_place', '_EXT');
    } else {
      this.onChangeField('sys_place', '_INT');
    }
    this.setState({Stbtn: selectedIndex});
  };
  render() {
    return (
      <SafeAreaView contentContainerStyle={{flexGrow: 1}}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          rightComponent={<HeaderRightComponent goToBack={this.goToBack} />}
        />
        <KeyboardAwareScrollView contentContainerStyle={{flexGrow: 1}}>
          <KeyboardAvoidingView style={styles.containerView} behavior="padding">
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
              <View style={styles.RegisterFormView}>
                <Text style={styles.subenteteText}>{translate('2/2')} </Text>
                <Input
                  selectionColor={'white'}
                  onChangeText={val => this.onChangeField('sys_ref', val)}
                  label={translate(
                    'Votre refrence',
                    styles.FormInputlabelStyle,
                  )}
                  labelStyle={styles.FormInputlabelStyle}
                  containerStyle={styles.FormInputcontainerStyleStep2}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={styles.FormTextInput}
                  placeholder=""
                  errorStyle={styles.FormInputErrorStyle}
                  errorMessage={this.state.errors.ref}
                />
                <View>
                  <Text style={styles.inputTextLabel}>
                    {translate('Type de traitement')}{' '}
                  </Text>
                  {this.renderPicker()}
                  <Text style={styles.FormInputErrorStyle}>
                    {this.state._errors?.sys_sensor_name}
                  </Text>
                </View>
                <View>
                  <Text style={styles.inputTextLabel}>
                    {translate('Type de bassin :')}{' '}
                  </Text>
                  <ButtonGroup
                    onPress={this.SelectedChoicTypee}
                    buttonStyle={styles.BtnChoise}
                    innerBorderStyle={{width: 0}}
                    selectedButtonStyle={[
                      styles.BtnChoise,
                      styles.BtnChoiseSelected,
                    ]}
                    textStyle={[styles.BtnChoisetextStyle]}
                    selectedTextStyle={[styles.BtnChoiseSelected]}
                    containerStyle={{
                      backgroundColor: 'transparent',
                      borderWidth: 0,
                    }}
                    selectedIndex={this.state.vbtn}
                    buttons={[this.state.pool, this.state.spa]}
                  />
                </View>

                <Input
                  onChangeText={val => this.onChangeField('sys_volume', val)}
                  label={translate(
                    'Volume du bassin :',
                    styles.FormInputlabelStyle,
                  )}
                  labelStyle={styles.FormInputlabelStyle}
                  containerStyle={styles.FormInputcontainerStyleStep2}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={styles.FormTextInput}
                  placeholder=""
                  errorStyle={styles.FormInputErrorStyle}
                  errorMessage={this.state.errors.Vtype}
                />

                <View>
                  <Text style={styles.inputTextLabel}>
                    {translate('Situation')}{' '}
                  </Text>
                  <ButtonGroup
                    onPress={this.SelectedSituaTypee}
                    buttonStyle={styles.BtnChoise}
                    innerBorderStyle={{width: 0}}
                    selectedButtonStyle={[
                      styles.BtnChoise,
                      styles.BtnChoiseSelected,
                    ]}
                    textStyle={[styles.BtnChoisetextStyle]}
                    selectedTextStyle={[styles.BtnChoiseSelected]}
                    containerStyle={{
                      backgroundColor: 'transparent',
                      borderWidth: 0,
                    }}
                    selectedIndex={this.state.Stbtn}
                    buttons={[this.state.inside, this.state.outside]}
                  />
                </View>

                <Text style={styles.enteteStepText}>
                  {translate('Meteo de mon bassin')}{' '}
                </Text>

                <Input
                  onChangeText={val => this.onChangeField('sys_address', val)}
                  ////label={translate('Adresse du bassin')}
                  label={translate(
                    'Adresse du bassin',
                    styles.FormInputlabelStyle,
                  )}
                  labelStyle={styles.FormInputlabelStyle}
                  containerStyle={[styles.FormInputcontainerStyleLast]}
                  inputContainerStyle={
                    styles.FormInputcontainerNoLabelStyleLast
                  }
                  inputStyle={styles.FormTextInput}
                  placeholder=""
                  errorStyle={styles.FormInputErrorStyle}
                  errorMessage=""
                />

                <Input
                  onChangeText={val => this.onChangeField('sys_address2', val)}
                  containerStyle={[styles.FormInputcontainerNoLabelStyleLast]}
                  inputStyle={styles.FormTextInput}
                  inputContainerStyle={
                    styles.FormInputcontainerNoLabelStyleLast
                  }
                  placeholder=""
                  errorStyle={styles.FormInputErrorStyle}
                  errorMessage=""
                />
                <View style={{flex: 1, flexDirection: 'row', marginTop: 20}}>
                  <Input
                    onChangeText={val => this.onChangeField('sys_zip', val)}
                    ///label={translate('Code Postal')}
                    label={translate('Code Postal', styles.FormInputlabelStyle)}
                    labelStyle={styles.FormInputlabelStyle}
                    containerStyle={[styles.FormInputContainerStyle, {flex: 3}]}
                    inputContainerStyle={
                      styles.FormInputcontainerNoLabelStyleLast
                    }
                    inputStyle={styles.FormTextInput}
                    placeholder=""
                    errorStyle={styles.FormInputErrorStyle}
                    errorMessage=""
                  />
                  <Input
                    onChangeText={val => this.onChangeField('sys_city', val)}
                    ////label={translate('Ville')}
                    label={translate('Ville', styles.FormInputlabelStyle)}
                    labelStyle={styles.FormInputlabelStyle}
                    containerStyle={[
                      styles.FormInputcontainerStyleLast,
                      {flex: 4},
                    ]}
                    inputContainerStyle={
                      styles.FormInputcontainerNoLabelStyleLast
                    }
                    inputStyle={styles.FormTextInput}
                    placeholder=""
                    errorStyle={styles.FormInputErrorStyle}
                    errorMessage=""
                  />
                </View>
                <View style={styles.zoneViewSwitch}>
                  <Text style={{flex: 4, color: '#fff'}}>
                    {translate('Service Genius')}
                  </Text>
                  <Switch
                    accessibilityRole="button"
                    style={{flex: 1, marginTop: 0}}
                    onValueChange={() => {
                      this.toggleSwitch(
                        'sys_contrat_type',
                        this.state.formData.sys_contrat_type === '0'
                          ? true
                          : !this.state.formData.sys_contrat_type,
                      );
                    }}
                    value={this.state.formData.sys_contrat_type}
                  />
                </View>

                <View style={styles.zoneViewSwitch}>
                  <Text style={{flex: 4, color: '#fff'}}>
                    {translate('Notification email')}
                  </Text>
                  <Switch
                    accessibilityRole="button"
                    style={{flex: 1, marginTop: 0}}
                    onValueChange={() =>
                      this.toggleSwitch(
                        'sys_user_email',
                        this.state.formData.sys_user_email === '0'
                          ? true
                          : !this.state.formData.sys_user_email,
                      )
                    }
                    value={this.state.formData.sys_user_email}
                  />
                </View>
                <View style={styles.zoneViewSwitch}>
                  <Text style={{flex: 4, color: '#fff'}}>
                    {translate('Notification push')}
                  </Text>
                  <Switch
                    accessibilityRole="button"
                    style={{flex: 1, marginTop: 0}}
                    onValueChange={() =>
                      this.toggleSwitch(
                        'sys_user_push',
                        this.state.formData.sys_user_push === '0'
                          ? true
                          : !this.state.formData.sys_user_push,
                      )
                    }
                    value={this.state.formData.sys_user_push}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'flex-end',
                    marginTop: 30,
                    marginBottom: 30,
                  }}>
                  <View style={{alignSelf: 'center'}}>
                    <Button
                      onPress={this.SubmitBtn}
                      buttonStyle={styles.BtnButton}
                      title={translate('Je finalise mon inscription')}
                    />
                    <Text
                      style={{
                        textAlign: 'center',
                        color: '#fff',
                        marginTop: 10,
                      }}>
                      {translate('Ou')}
                    </Text>
                    <Text
                      onPress={this.SkipStep}
                      style={{
                        textAlign: 'center',
                        color: '#fff',
                        marginTop: 10,
                        textDecorationLine: 'underline',
                      }}>
                      {translate('Continuer plus tard')}
                    </Text>
                  </View>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let appid = state.LoginReducer.appId;

  if (state.RegistrationRedux.step3) {
    appid = state.RegistrationRedux.step3.system[0].sys_appid;
  }

  return {
    userInfo: state.RegistrationRedux.step1,
    step3: state.RegistrationRedux.step3,
    userAppId: appid,
    generalAppId: state.LoginReducer.appId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    NextStep: (data, appid) => {
      return dispatch(actions.RegisterStep4(data, appid));
    },
    SystemGetAll: (appId, params) => {
      return dispatch(systemsActions.SystemGetAll(appId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Step5);
