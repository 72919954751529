import React from 'react';
import {
  View,
  ScrollView,
  ImageBackground,
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
} from 'react-native';
import {connect} from 'react-redux';
import {
  Button,
  CheckBox,
  Header,
  Icon,
  Input,
  Text,
} from 'react-native-elements';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

import translate from '../../translations/translate';
import * as actions from '../../Store/Login/LoginActions';
import {msg_code_to_text, ShowSnaskMessage} from '../../Config/Config';
import PropTypes from 'prop-types';
import {fontFamilyBold, fontFamilyRegular} from '../../Config/fonts';
import HeaderLeftComponent from '../../components/HeaderLeftComponent';

class NewPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iswait: false,
      formData: {
        email: null,
        pwd: null,
        re_pwd: null,
        key_pwd:
          (props &&
            props.route &&
            props.route.params &&
            props.route.params.params &&
            props.route.params.params.code) ||
          null,
      },
      errors: {
        email: null,
        pwd: null,
        re_pwd: null,
        key_pwd: null,
      },
    };
  }

  componentDidUpdate() {
    const newCode =
      this.props &&
      this.props.route &&
      this.props.route.params &&
      this.props.route.params.params &&
      this.props.route.params.params.code;

    const oldCode =
      this.state && this.state.formData && this.state.formData.key_pwd;

    if (!this.state.customCode && oldCode !== newCode) {
      this.setState(prev => {
        prev.formData.key_pwd = newCode;

        return prev;
      });
    }
  }

  ValidateFormData = () => {
    let isError = false;
    let _formData = this.state.formData;
    let _errors = this.state.errors;
    if (!_formData.email) {
      isError = true;
      _errors.email = translate('Ce champ est obligatoire');
    }
    if (!_formData.pwd) {
      isError = true;
      _errors.pwd = translate('Ce champ est obligatoire');
    }

    if (!_formData.key_pwd) {
      isError = true;
      _errors.key_pwd = translate('Ce champ est obligatoire');
    }
    if (_formData.pwd && _formData.pwd != _formData.re_pwd) {
      isError = true;
      _errors.re_pwd = translate('Merci de verifier votre mots de passe');
    }
    this.setState({errors: _errors});
    return isError;
  };
  onChangeUserText = (fieldName, val) => {
    let _formData = this.state.formData;
    let _errors = this.state.errors;
    if (val) {
      _errors[fieldName] = '';
    } else {
      _errors[fieldName] = translate('Ce champ est obligatoire');
    }
    _formData[fieldName] = val;

    const newState = {formData: _formData, errors: _errors};

    if (fieldName === 'key_pwd' && val) {
      newState.customCode = true;
    }
    this.setState(newState);
  };
  SubmitBtn = () => {
    if (!this.ValidateFormData()) {
      this.setState({iswait: true});
      this.props
        .RenewPassword(
          this.state.formData.email,
          this.state.formData.pwd,
          this.state.formData.key_pwd,
        )
        .then(() => {
          this.setState({iswait: false});
          this.props.navigation.navigate('ResetPasswordThx');
        })
        .catch(error => {
          this.setState({iswait: false});
          let _errors = this.state.errors;
          let errormsg = msg_code_to_text(error);

          ShowSnaskMessage({
            message: errormsg,
            textColor: '#fff',
            backgroundColor: 'red',
            buttonColor: 'red',
            duration: 2000,
          });
          switch (error) {
            case '_UNVAILABLE_USER':
              _errors.email = translate(errormsg);
              break;
          }
          this.setState({errors: _errors});
        });
    }
  };

  componentDidMount() {
    const {route} = this.props;

    let appidfrommail = route.params.hasOwnProperty('appidfrommail')
      ? route.params.appidfrommail
      : null;

    if (appidfrommail) {
      this.onChangeUserText('key_pwd', appidfrommail);
    }
  }
  render() {
    return (
      <ImageBackground
        style={styles.imgBackground}
        resizeMode="cover"
        source={require('../../assets/bg.png')}>
        <Header
          containerStyle={{
            backgroundColor: 'transparent',
            height: 50,
            paddingTop: 0,
            margin: 0,
            borderBottomColor: 'transparent',
          }}
          leftComponent={
            <HeaderLeftComponent
              navigation={this.props.navigation}
              page="Login"
            />
          }
        />
        <KeyboardAwareScrollView contentContainerStyle={{flexGrow: 1}}>
          <View style={styles.container}>
            <KeyboardAvoidingView style={styles.containerView}>
              <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                <View style={styles.ResetFormView}>
                  <Text style={styles.logoText}>
                    {translate('Modifier mon mot de passe')}{' '}
                  </Text>
                  <Input
                    label={translate('Email', styles.FormLabelInput)}
                    onChangeText={val => this.onChangeUserText('email', val)}
                    labelStyle={styles.FormLabelInput}
                    containerStyle={{marginBottom: 0}}
                    inputContainerStyle={{
                      borderBottomWidth: 0,
                      marginBottom: 5,
                    }}
                    inputStyle={[
                      styles.FormTextInput,
                      this.state.errors.email ? styles.FormTextInputError : {},
                    ]}
                    placeholder=""
                    errorStyle={{color: 'red'}}
                    errorMessage={this.state.errors.email}
                  />
                  <Input
                    label={translate(
                      'Nouveau mot de passe',
                      styles.FormLabelInput,
                    )}
                    onChangeText={val => this.onChangeUserText('pwd', val)}
                    labelStyle={styles.FormLabelInput}
                    containerStyle={{marginBottom: 0}}
                    inputContainerStyle={{
                      borderBottomWidth: 0,
                      marginBottom: 5,
                    }}
                    inputStyle={[
                      styles.FormTextInput,
                      this.state.errors.pwd ? styles.FormTextInputError : {},
                    ]}
                    placeholder=""
                    secureTextEntry={true}
                    errorStyle={{color: 'red'}}
                    errorMessage={this.state.errors.pwd}
                  />

                  <Input
                    label={translate(
                      'Retaper votre nouveau mot de passe',
                      styles.FormLabelInput,
                    )}
                    onChangeText={val => this.onChangeUserText('re_pwd', val)}
                    labelStyle={styles.FormLabelInput}
                    containerStyle={{marginBottom: 0}}
                    inputContainerStyle={{
                      borderBottomWidth: 0,
                      marginBottom: 5,
                    }}
                    inputStyle={[
                      styles.FormTextInput,
                      this.state.errors.re_pwd ? styles.FormTextInputError : {},
                    ]}
                    placeholder=""
                    secureTextEntry={true}
                    errorStyle={{color: 'red'}}
                    errorMessage={this.state.errors.re_pwd}
                  />

                  <Input
                    label={translate(
                      'Copier/coller la clef provisoire',
                      styles.FormLabelInput,
                    )}
                    onChangeText={val => this.onChangeUserText('key_pwd', val)}
                    labelStyle={styles.FormLabelInput}
                    containerStyle={{marginBottom: 0}}
                    inputContainerStyle={{
                      borderBottomWidth: 0,
                      marginBottom: 5,
                    }}
                    value={this.state.formData.key_pwd}
                    inputStyle={[
                      styles.FormTextInput,
                      this.state.errors.key_pwd
                        ? styles.FormTextInputError
                        : {},
                    ]}
                    placeholder=""
                    errorStyle={{color: 'red'}}
                    errorMessage={this.state.errors.key_pwd}
                  />

                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'flex-end',
                      marginTop: 50,
                    }}>
                    <View style={{alignSelf: 'center'}}>
                      <Button
                        onPress={this.SubmitBtn}
                        buttonStyle={styles.SubmitButton}
                        title={translate(
                          'Mettre a jour mon mot de passe',
                          styles.SubmitButton,
                        )}
                        loading={this.state.iswait}
                      />
                    </View>
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </KeyboardAvoidingView>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  imgBackground: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    width: '90%',
  },
  ResetFormView: {
    marginLeft: 10,
    marginRight: 10,
  },
  logoText: {
    fontSize: 18,
    marginBottom: 50,
    textAlign: 'center',
    color: '#ffffff',
    fontFamily: fontFamilyBold(),
  },
  FormTextInput: {
    height: 43,
    fontSize: 14,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#eaeaea',
    color: '#fff',
    paddingLeft: 10,
    marginTop: 5,
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
  FormLabelInput: {
    fontSize: 12,
    color: 'white',
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
  SubmitButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#ffffff',
    borderRadius: 50,
    paddingTop: 5,
    paddingBottom: 10,
    width: '100%',
    marginBottom: 50,
  },
  FormTextInputError: {
    borderColor: '#D80000',
    backgroundColor: 'rgba(255, 0, 0, 0.22)',
  },
});

NewPassword.propTypes = {
  appidfrommail: PropTypes.string,
};
NewPassword.defaultProps = {
  appidfrommail: '',
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    RenewPassword: (user, code, appid) => {
      return dispatch(actions.RenewPassword(user, code, appid));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewPassword);
