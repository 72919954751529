import React from 'react';
import {StyleSheet, Text, SafeAreaView, ImageBackground} from 'react-native';
import {connect} from 'react-redux';
import {Icon} from 'react-native-elements';
import translate from '../../translations/translate';
class ResetPasswordThx extends React.Component {
  componentDidMount() {
    setTimeout(() => this.props.navigation.replace('Login'), 500);
  }

  render() {
    return (
      <SafeAreaView style={styles.container}>
        <ImageBackground
          style={styles.imgBackground}
          resizeMode="cover"
          source={require('../../assets/bg.png')}>
          <Icon name="check" type="font-awesome" color="#10CC10" size={100} />
          <Text style={{color: '#fff', fontSize: 20, textAlign: 'center'}}>
            {translate('Votre mot de passe a jour')}
          </Text>
        </ImageBackground>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  imgBackground: {
    width: '100%',
    height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.LoginReducer.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordThx);
