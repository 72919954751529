import React from 'react';
import styles from './styles.js';
import {
  Keyboard,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  View,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

import translate, {translateString} from '../../translations/translate';
import {Button, Input, Text, Header, Icon} from 'react-native-elements';
import {connect} from 'react-redux';
import * as actions from '../../Store/Registration/RegistrationActions';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  appHeaderHeight,
} from '../../Config/Config';

class HeaderRightComponent extends React.Component {
  render() {
    return (
      <TouchableOpacity
        hitSlop={{top: 10, left: 30, right: 30, bottom: 30}}
        onPress={() => this.props.goToBack()}
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}>
        <Icon size={30} name="times" type="font-awesome" color="#fff" />
      </TouchableOpacity>
    );
  }
}

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iswait: false,
      formData: {
        tel: null,
        adress1: null,
        adress2: null,
        zipcode: null,
        ville: null,
      },
      errors: {
        tel: null,
        adress1: null,
        adress2: null,
        zipcode: null,
        ville: null,
      },
    };
  }

  onChangeField = (fieldName, val) => {
    let _formData = this.state.formData;
    let _errors = this.state.errors;
    if (val) {
      _errors[fieldName] = '';
    } else {
      _errors[fieldName] = 'Error';
    }
    _formData[fieldName] = val;
    this.setState({formData: _formData, errors: _errors});
  };
  ValidateFormData = async () => {
    let isError = false;
    let _formData = this.state.formData;
    let _errors = this.state.errors;
    if (!_formData.tel) {
      isError = true;
      _errors.tel = await translateString('Ce champ est obligatoire');
    }
    if (!_formData.adress1) {
      isError = true;
      _errors.adress1 = await translateString('Ce champ est obligatoire');
    }
    if (!_formData.zipcode) {
      isError = true;
      _errors.zipcode = await translateString('Ce champ est obligatoire');
    }

    if (!_formData.ville) {
      isError = true;
      _errors.ville = await translateString('Ce champ est obligatoire');
    }

    this.setState({errors: _errors});
    return isError;
  };
  SubmitBtn = () => {
    if (!this.ValidateFormData()) {
      this.setState({iswait: true});
      let req = {
        user_phone: this.state.formData.tel,
        user_address:
          this.state.formData.adress1 + '/' + this.state.formData.adress2,
        user_zip: this.state.formData.zipcode,
        user_city: this.state.formData.ville,
      };
      this.props
        .NextStep(req, this.props.userAppId)
        .then(userInfo => {
          this.setState({iswait: false});
          this.props.nextStep(4);
        })
        .catch(async error => {
          let errormsg = msg_code_to_text(error);
          ShowSnaskMessage({
            message: errormsg,
            textColor: '#fff',
            backgroundColor: 'red',
            buttonColor: 'red',
            duration: 2000,
          });

          let _errors = this.state.errors;
          if (error === '_USERNAME_ALREADY_REGISTERED') {
            _errors.user = await translateString('Already set');
            this.setState({errors: _errors});
          }
          this.setState({iswait: false});
        });
    }
  };
  SkipStep = () => {
    this.props.nextStep(4);
  };
  goToBack = () => {
    this.props.goBack();
  };
  render() {
    return (
      <SafeAreaView contentContainerStyle={{flexGrow: 1}}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          rightComponent={<HeaderRightComponent goToBack={this.goToBack} />}
        />
        <KeyboardAwareScrollView contentContainerStyle={{flexGrow: 1}}>
          <KeyboardAvoidingView style={styles.containerView}>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
              <View style={styles.RegisterFormView}>
                <Text style={styles.subenteteText}>{translate('3/3')} </Text>
                <Text style={styles.enteteStepText}>
                  {translate('Mes Information')}{' '}
                </Text>
                <Input
                  selectionColor={'white'}
                  onChangeText={val => this.onChangeField('tel', val)}
                  ///label={translate('N° de téléphone')}
                  label={translate(
                    'N° de telephone',
                    styles.FormInputlabelStyle,
                  )}
                  labelStyle={styles.FormInputlabelStyle}
                  containerStyle={styles.FormInputcontainerStyle}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[
                    styles.FormTextInput,
                    this.state.errors.tel ? styles.FormTextInputError : {},
                  ]}
                  placeholder=""
                  errorStyle={styles.FormInputErrorStyle}
                  errorMessage={this.state.errors.tel}
                />

                <Input
                  selectionColor={'white'}
                  onChangeText={val => this.onChangeField('adress1', val)}
                  ////label={translate('Mon adresse postale')}
                  label={translate(
                    'Mon adresse postale',
                    styles.FormInputlabelStyle,
                  )}
                  labelStyle={styles.FormInputlabelStyle}
                  containerStyle={styles.FormInputcontainerStyle}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[
                    styles.FormTextInput,
                    this.state.errors.adress1 ? styles.FormTextInputError : {},
                  ]}
                  placeholder=""
                  errorStyle={styles.FormInputErrorStyle}
                  errorMessage={this.state.errors.adress1}
                />
                <Input
                  selectionColor={'white'}
                  onChangeText={val => this.onChangeField('adress2', val)}
                  labelStyle={styles.FormInputlabelStyle}
                  containerStyle={styles.FormInputcontainerStyle}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[
                    styles.FormTextInput,
                    this.state.errors.adress2 ? styles.FormTextInputError : {},
                  ]}
                  placeholder=""
                  errorStyle={styles.FormInputErrorStyle}
                  errorMessage={this.state.errors.adress2}
                />
                <View style={{flex: 1, flexDirection: 'row', marginTop: 20}}>
                  <Input
                    selectionColor={'white'}
                    onChangeText={val => this.onChangeField('zipcode', val)}
                    //// label={translate('Code Postal')}
                    label={translate('Code Postal', styles.FormInputlabelStyle)}
                    labelStyle={styles.FormInputlabelStyle}
                    containerStyle={[styles.FormInputContainerStyle, {flex: 3}]}
                    inputContainerStyle={
                      styles.FormInputcontainerNoLabelStyleLast
                    }
                    inputStyle={[
                      styles.FormTextInput,
                      this.state.errors.zipcode
                        ? styles.FormTextInputError
                        : {},
                    ]}
                    placeholder=""
                    errorStyle={styles.FormInputErrorStyle}
                    errorMessage={this.state.errors.zipcode}
                  />
                  <Input
                    selectionColor={'white'}
                    onChangeText={val => this.onChangeField('ville', val)}
                    //// label={translate('Ville')}
                    label={translate('Ville', styles.FormInputlabelStyle)}
                    labelStyle={styles.FormInputlabelStyle}
                    containerStyle={[
                      styles.FormInputcontainerStyleLast,
                      {flex: 4},
                    ]}
                    inputContainerStyle={
                      styles.FormInputcontainerNoLabelStyleLast
                    }
                    inputStyle={[
                      styles.FormTextInput,
                      this.state.errors.ville ? styles.FormTextInputError : {},
                    ]}
                    placeholder=""
                    errorStyle={styles.FormInputErrorStyle}
                    errorMessage={this.state.errors.ville}
                  />
                </View>

                <View style={{flex: 1, marginBottom: 20, marginTop: 20}}>
                  <View style={{alignSelf: 'center'}}>
                    <Button
                      onPress={this.SubmitBtn}
                      buttonStyle={styles.BtnButton}
                      title={translate('Terminer')}
                      loading={this.state.iswait}
                    />
                  </View>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let appid = state.RegistrationRedux.step1
    ? state.RegistrationRedux.step1.token[0].appid
    : null;

  return {
    userInfo: state.RegistrationRedux.step1,
    userAppId: appid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    NextStep: (data, appid) => {
      return dispatch(actions.RegisterStep2(data, appid));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Step3);
