import React from 'react';
import styles from './styles.js';
import {
  Keyboard,
  KeyboardAvoidingView,
  ScrollView,
  TouchableWithoutFeedback,
  View,
  Switch,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';
import translate, {translateString} from '../../translations/translate';
import {Button, Input, Text, Icon, Header} from 'react-native-elements';
import {connect} from 'react-redux';
import * as actions from '../../Store/Registration/RegistrationActions';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  appHeaderHeight,
} from '../../Config/Config';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

class HeaderRightComponent extends React.Component {
  render() {
    return (
      <TouchableOpacity
        hitSlop={{top: 10, left: 30, right: 30, bottom: 30}}
        onPress={() => this.props.goToBack()}
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}>
        <Icon size={30} name="times" type="font-awesome" color="#fff" />
      </TouchableOpacity>
    );
  }
}

class Step4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scanService: false,
      iswait: false,
      formData: {
        ns: null,
        sys_ref: null,
        sys_type: 0,
        sys_volume: null,
        sys_place: '_INT',
      },
      vbtn: 0,
      Stbtn: 0,
      errors: {
        ns: null,
        sys_ref: null,
        sys_type: null,
        sys_volume: null,
        sys_place: null,
      },
      pool: '',
      spa: '',
      inside: '',
      outside: '',
    };
  }

  componentDidMount = async () => {
    const pool = await translateString('Swimming Pool');
    const spa = await translateString('Hot Tub');
    const inside = await translateString('Indoor');
    const outside = await translateString('Outdoor');

    this.setState({pool, spa, inside, outside});
  };

  onChangeField = (fieldName, val) => {
    let _formData = this.state.formData;
    let _errors = this.state.errors;
    if (val) {
      _errors[fieldName] = '';
    } else {
      _errors[fieldName] = translate('Ce champ est obligatoire');
    }
    _formData[fieldName] = val;
    this.setState({formData: _formData, errors: _errors});
  };
  toggleSwitch = (fieldName, val) => {
    this.onChangeField(fieldName, val);
  };
  toggleScanService = () => {
    this.setState({
      scanService: true,
    });
  };
  ValidateFormData = () => {
    let isError = false;
    // let _formData = this.state.formData;
    let _errors = this.state.errors;
    /*if (!_formData.ns) {
      isError = true;
      _errors.ns = translate('Ce champ est obligatoire');
    }*/

    this.setState({errors: _errors});
    return isError;
  };
  SubmitBtn = () => {
    if (!this.ValidateFormData()) {
      this.setState({iswait: true});
      let req = {
        sys_id: !this.state.scanService ? this.state.formData.ns : '999999',
        sys_ref: this.state.formData.sys_ref,
        sys_type: this.state.formData.sys_type,
        sys_volume: this.state.formData.sys_volume,
        sys_place: this.state.formData.sys_place,
      };

      this.props
        .NextStep(req, this.props.userAppId)
        .then(userInfo => {
          this.setState({iswait: false});
          this.props.nextStep(5);
        })
        .catch(error => {
          let _errors = this.state.errors;
          let errormsg = msg_code_to_text(error);

          ShowSnaskMessage({
            message: errormsg,
            textColor: '#fff',
            backgroundColor: 'red',
            buttonColor: 'red',
            duration: 2000,
          });
          switch (error) {
            case '_INVALID_NEW_SERIAL':
              _errors.ns = translate(errormsg);
              break;
          }

          this.setState({iswait: false});
        });
    }
  };

  SelectedChoicTypee = selectedIndex => {
    this.onChangeField('sys_type', selectedIndex);
    this.setState({vbtn: selectedIndex});
  };
  SelectedSituaTypee = selectedIndex => {
    if (selectedIndex > 0) {
      this.onChangeField('sys_place', '_EXT');
    } else {
      this.onChangeField('sys_place', '_INT');
    }
    this.setState({Stbtn: selectedIndex});
  };
  SkipStep = () => {
    this.props.ToProfile();
  };
  goToBack = () => {
    this.props.goBack();
  };
  render() {
    return (
      <SafeAreaView contentContainerStyle={{flexGrow: 1}}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          rightComponent={<HeaderRightComponent goToBack={this.goToBack} />}
        />
        <KeyboardAwareScrollView contentContainerStyle={{flexGrow: 1}}>
          <KeyboardAvoidingView style={styles.containerView}>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
              <ScrollView
                contentContainerStyle={{height: '100%', paddingBottom: 40}}>
                <Text style={styles.subenteteText}>{translate('1/2')} </Text>
                <Text style={styles.enteteStepText}>
                  {translate('Ajout Bassin')}{' '}
                </Text>

                <Input
                  selectionColor={'white'}
                  onChangeText={val => this.onChangeField('ns', val)}
                  label={translate(
                    'Numero de serie',
                    styles.FormInputlabelStyle,
                  )}
                  disabled={this.state.scanService}
                  labelStyle={styles.FormInputlabelStyle}
                  containerStyle={styles.FormInputcontainerStyleStep2}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[
                    styles.FormTextInput,
                    this.state.errors.ns ? styles.FormTextInputError : {},
                  ]}
                  placeholder=""
                  errorStyle={styles.FormInputErrorStyle}
                  errorMessage={this.state.errors.ns}
                />
                {translate(
                  "I don't have a serial number, I want to use the band scan only",
                  {color: '#fff', marginTop: 50, marginBottom: 20},
                )}
                <View style={styles.zoneViewSwitch}>
                  <Text style={{flex: 4, color: '#fff'}}>
                    {translate('Service scan only')}
                  </Text>
                  <Switch
                    style={{flex: 1, marginTop: 0}}
                    accessibilityRole="button"
                    onValueChange={this.toggleScanService}
                    value={this.state.scanService}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 30,
                    marginBottom: 30,
                  }}>
                  <View style={{alignSelf: 'center'}}>
                    <Button
                      loading={this.state.iswait}
                      onPress={this.SubmitBtn}
                      buttonStyle={styles.BtnButton}
                      title={translate('continuer')}
                    />
                  </View>
                </View>
              </ScrollView>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let appid = state.RegistrationRedux.step1
    ? state.RegistrationRedux.step1.token[0].appid
    : state.LoginReducer.appId;

  return {
    userInfo: state.RegistrationRedux.step1,
    userAppId: appid,
    appId: state.LoginReducer.appId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    NextStep: (data, appid) => {
      return dispatch(actions.RegisterStep3(data, appid));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Step4);
