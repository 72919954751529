import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableHighlight,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import {connect} from 'react-redux';
import {Header, Icon} from 'react-native-elements';
import MachineUnlock from '../../components/widget/MachineUnlock';
import Loader from '../../components/Loader';
import {
  appHeaderHeight,
  appHeaderTop,
  getStoreDataPromise,
  setStoreData,
  APP_BAR_ICON_SIZE,
  LEFT_HIT_SLOP,
} from '../../Config/Config';
import PropTypes from 'prop-types';
import translate from '../../translations/translate';

class HeaderLeftComponent extends React.Component {
  render() {
    return (
      <>
        <TouchableOpacity
          onPress={() => this.props.navigation.navigate('Alertes')}
          hitSlop={LEFT_HIT_SLOP}>
          <Icon
            name="exclamation-triangle"
            type="font-awesome"
            color={
              this.props.current_system.alarm &&
              this.props.current_system.alarm.filter(
                i => i.alarm_status === '0',
              ).length > 0
                ? 'red'
                : '#fff'
            }
            size={APP_BAR_ICON_SIZE}
          />
        </TouchableOpacity>
      </>
    );
  }
}

class HeaderrightComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalUnlock: false,
    };
  }
  unlock = () => {
    if (!this.props.isAdmin) {
      this.setState({modalUnlock: true});
    } else {
      this.props.CloseApp();
    }
  };
  closeModal = () => {
    this.setState({modalUnlock: false});
  };
  callBackModal = () => {
    this.setState({modalUnlock: false});
    this.props.ModalUnlock();
  };
  render() {
    return (
      <SafeAreaView
        style={{flexDirection: 'row', paddingBottom: 0, paddingRight: 10}}>
        <TouchableOpacity onPress={this.unlock}>
          <Icon
            containerStyle={{paddingRight: 30}}
            name={this.props.isAdmin ? 'unlock-alt' : 'lock'}
            type="font-awesome"
            color="#fff"
            size={APP_BAR_ICON_SIZE}
          />
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => this.props.navigation.navigate('Profil')}>
          <Icon
            name="user"
            type="font-awesome"
            color="#fff"
            size={APP_BAR_ICON_SIZE}
          />
        </TouchableOpacity>

        <MachineUnlock
          isOpen={this.state.modalUnlock}
          closeModal={this.closeModal}
          callback={this.callBackModal}
        />
      </SafeAreaView>
    );
  }
}
import NouveauDevices from './NouveauDevices';
import {fontFamilyBold, fontFamilyRegular} from '../../Config/fonts';
import ThreeDotsLoader from '../../components/ThreeDotsLoader';
import WidgetDevice from '../../components/WidgetDevice';
import * as actionsApp from '../../Store/App/AppActions';
class Devices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      system: null,
      system_name: '',
      devices: [],
      device_list: [],
      isAdmin: false,
      isLoader: false,
      OpenModal: false,
    };
  }

  componentDidMount() {
    this.initScreens();
  }

  componentDidUpdate(nextProps) {
    const {last_update_data_redux} = this.props;
    if (nextProps.last_update_data_redux !== last_update_data_redux) {
      //console.log("Load Update System ");
      this.initScreens();
    }
  }
  componentWillUnmount() {}
  initScreens = () => {
    this.getSystem();
    this.LoadDefaultDevices();
    this.IsAdminSystem();
    this.LoadSlots();
  };

  LoadSlots = () => {
    if (
      this.props.current_system.hasOwnProperty('slots') &&
      this.props.current_system.slots.length > 0
    ) {
      let listSlots = {};
      this.props.current_system.slots.map(item => {
        listSlots[item.action_number] = item;
      });

      this.setState({
        system: this.props.current_system.system[0],
        system_name: this.props.current_system.system[0].sys_ref,
        devices: this.props.current_system.actions,
        slots: listSlots,
      });
    }
  };
  LoadDefaultDevices = () => {
    this.setState({device_list: this.props.base_list.device_list});
  };
  getSystem = () => {
    if (
      this.props.current_system.hasOwnProperty('system') &&
      this.props.current_system.system.length > 0
    ) {
      this.setState({
        system: this.props.current_system.system[0],
        system_name: this.props.current_system.system[0].sys_ref,
        devices: this.props.current_system.actions,
        //slots:this.props.current_system.slots,
      });
    }
  };

  IsAdminSystem = () => {
    getStoreDataPromise('system_current_is_admin').then(_isAdmin => {
      if (_isAdmin === 'true') {
        this.setState({isAdmin: true});
      } else {
        this.setState({isAdmin: false});
      }
    });
  };

  ModalUnlock = () => {
    this.setState({isAdmin: true});
    setStoreData('system_current_is_admin', 'true');
  };

  renderElement = device => {
    return (
      <WidgetDevice
        device={device}
        device_list={this.state.device_list}
        slots={this.state.slots}
        navigation={this.props.navigation}
      />
    );
  };
  renderElements = () => {
    if (this.state.devices.length > 0) {
      return this.state.devices.map(device => {
        return this.renderElement(device);
      });
    } else {
      return this.renderEmpty();
    }
  };

  renderEmpty = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Text style={{color: '#fff', textAlign: 'center'}}>
          {translate('Aucun devices installer')}
        </Text>
      </View>
    );
  };

  closeModal = () => {
    this.setState({OpenModal: false});
  };
  OpenModal = () => {
    this.setState({OpenModal: true});
  };

  CloseApp = () => {
    this.setState({isAdmin: false});
    setStoreData('system_current_is_admin', 'false');
    this.props.refreshApp();
  };
  render() {
    return (
      <SafeAreaView style={styles.containerfull}>
        <Loader isloader={this.state.isLoader} />
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            marginTop: appHeaderTop(),
            borderBottomColor: '#289EFF',
          }}
          leftComponent={
            <HeaderLeftComponent
              navigation={this.props.navigation}
              page={translate('Alertes')}
              current_system={this.props.current_system}
            />
          }
          rightComponent={
            <HeaderrightComponent
              navigation={this.props.navigation}
              isAdmin={this.state.isAdmin}
              ModalUnlock={this.ModalUnlock}
              CloseApp={this.CloseApp}
            />
          }
        />

        <ScrollView contentContainerStyle={{flexGrow: 1}}>
          <View style={styles.container}>
            <View style={{flexDirection: 'row', paddingBottom: 0}}>
              <View
                style={{
                  flex: 1,
                  borderColor: '#fff',
                  borderTopWidth: 4,
                  marginTop: 10,
                }}
              />
              <Text style={styles.enteteText}>{translate('equipements')}</Text>
              <View
                style={{
                  flex: 5,
                  borderColor: '#fff',
                  borderBottomWidth: 4,
                  marginBottom: 10,
                }}
              />
            </View>
            <View style={styles.containerFixed}>
              <TouchableHighlight
                style={{padding: 3}}
                onPress={() => this.OpenModal()}>
                <Text style={styles.btnadd}>+</Text>
              </TouchableHighlight>
            </View>
            {/*}
                        <View style={{alignSelf: 'flex-end',paddingBottom:20,paddingRight:20}}>
                            <View style={styles.CircleShapeView}>
                                <TouchableHighlight
                                    onPress={() => this.OpenModal()}
                                >
                                    <Image
                                        source={require('../../assets/plus.png')}
                                        style={{ width:50, height: 50}}

                                    />
                                </TouchableHighlight>

                            </View>
                        </View>
                        {*/}
            {this.renderElements()}
          </View>
          <NouveauDevices
            isOpen={this.state.OpenModal}
            title={translate('equipements')}
            closeModal={this.closeModal}
            sys_id={this.state.system ? this.state.system.sys_appid : null}
          />
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  containerFixed: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    zIndex: 200,
  },
  btnadd: {
    right: 0,
    fontSize: 30,
    marginRight: 15,
    marginTop: 10,
    color: '#289EFF',
    fontFamily: fontFamilyBold(),
    textAlign: 'right',
  },
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
  },
  container: {
    flex: 1,
    backgroundColor: '#000004',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  enteteText: {
    fontSize: 45,
    fontWeight: '300',
    padding: 10,
    textAlign: 'left',
    color: '#ffffff',
    fontFamily: fontFamilyRegular(),
  },
  CircleShapeView: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    borderRadius: 50 / 2,
    borderWidth: 1,
    borderColor: '#1E1E1E',
    backgroundColor: '#289EFF',
  },
});

Devices.propTypes = {
  current_system: PropTypes.object,
};

Devices.defaultProps = {
  current_system: {},
};
const mapStateToProps = (state, ownProps) => {
  return {
    current_system: state.SystemsRedux.system,
    base_list: state.AppRedux.base_list,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshApp: () => {
      return dispatch(actionsApp.refreshApp());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Devices);
