import React from 'react';
import {StyleSheet, Text, View, Modal, Platform} from 'react-native';
import {connect} from 'react-redux';
import DateTimePicker from '@react-native-community/datetimepicker';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import PropTypes from 'prop-types';
import {Button} from 'react-native-elements';
import {fontFamilyRegular} from '../Config/fonts';
class ModalDateTimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedtime: '',
    };
  }

  componentDidMount() {
    this.setState({selectedtime: this.props.defaulTime});
  }

  componentWillUnmount() {}

  renderIos = () => {
    return (
      <DateTimePickerModal
        timeZoneOffsetInMinutes={0}
        isVisible={this.props.isshow}
        value={this.state.selectedtime}
        mode={this.props.mode}
        is24Hour={true}
        display="default"
        textColor="#000"
        neutralButtonLabel="clear"
        onCancel={this.props.close}
        onConfirm={date => {
          this.setState({selectedtime: date});
          this.props.close(this.state.selectedtime);
        }}
      />
    );
  };

  renderAndroid = () => {
    if (this.props.isshow) {
      return (
        <DateTimePicker
          timeZoneOffsetInMinutes={0}
          value={this.state.selectedtime}
          mode={this.props.mode}
          is24Hour={true}
          display="default"
          textColor="#fff"
          neutralButtonLabel="clear"
          onChange={(event, date) => {
            if (date) {
              this.props.close(date);
              this.setState({selectedtime: date});
            }
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  render() {
    if (Platform.OS === 'ios') {
      return this.renderIos();
    } else {
      return this.renderAndroid();
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Modalcontainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(154, 152, 152, 0.84)',
    flexDirection: 'column',
  },
  BtnButton: {
    backgroundColor: '#242424',
    fontFamily: fontFamilyRegular(),
    fontSize: 12,
    borderRadius: 50,
    width: '100%',
  },
});

ModalDateTimePicker.propTypes = {
  isshow: PropTypes.bool,
  mode: PropTypes.string,
  defaulTime: PropTypes.object,
  close: PropTypes.func,
};

ModalDateTimePicker.defaultProps = {
  isshow: false,
  mode: 'time',
  defaulTime: new Date(),
  close: val => {},
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalDateTimePicker);
