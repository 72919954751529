import Model from './Model';
import {get_api_host} from '../Config/Config';

let currentSystemAppId = '';

export const setCurrentSystemAppId = appId => {
  currentSystemAppId = appId;
};

export default class Systems extends Model {
  constructor() {
    let data = {};

    super(data, '');
  }

  getAll(_appid) {
    let headers = {};
    if (_appid) {
      headers.appid = _appid;
    }

    this.setUrl(get_api_host() + '/get_all');
    this.setHeaders(headers);
    return this.fetch({}).then(response => {
      return new Promise((resolve, reject) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      });
    });
  }

  getDevice(_appid, device_number) {
    let headers = {};
    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }

    this.setUrl(get_api_host() + '/get_all?device_number=' + device_number);
    this.setHeaders(headers);
    return this.fetch({}).then(response => {
      return new Promise((resolve, reject) => {
        if (response.status === 200) {
          if (
            response.data.hasOwnProperty('actions') &&
            response.data.actions.length > 0
          ) {
            resolve(response.data.actions[0]);
          } else {
            resolve(null);
          }
        } else {
          reject(response.data.error);
        }
      });
    });
  }

  getSensor(_appid, sensor_number) {
    let headers = {};
    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }

    this.setUrl(get_api_host() + '/get_all?sensor_number=' + sensor_number);
    this.setHeaders(headers);
    return this.fetch({}).then(response => {
      return new Promise((resolve, reject) => {
        if (response.status === 200) {
          if (
            response.data.hasOwnProperty('sensor') &&
            response.data.sensor.length > 0
          ) {
            resolve(response.data.sensor[0]);
          } else {
            resolve(null);
          }
        } else {
          reject(response.data.error);
        }
      });
    });
  }

  getSystem(_appid) {
    let headers = {};
    if (_appid) {
      headers.appid = _appid;
    }

    //  console.log("_appid getSystem",_appid)

    this.setUrl(get_api_host() + '/get_all');
    this.setHeaders(headers);
    return this.fetch({}).then(response => {
      return new Promise((resolve, reject) => {
        if (response.status === 200 && !response.data.error) {
          resolve(response.data);
        } else {
          //console.log("response.data.error ",response.data.error)
          reject(response.data.error);
        }
      });
    });
  }

  get_base_list(_appid) {
    let headers = {};
    if (_appid) {
      headers.appid = _appid;
    }

    this.setUrl(get_api_host() + '/get_base_list');
    this.setHeaders(headers);
    //console.log("get_base_list");
    return this.fetch({}).then(response => {
      return new Promise((resolve, reject) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(null);
        }
      });
    });
  }

  getSenssorHistorique(_appid, _sensor_number, _history) {
    let headers = {};
    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }

    this.setUrl(get_api_host() + '/get_all');

    this.setHeaders(headers);

    return this.fetch({
      sensor_number: _sensor_number,
      history: _history,
    }).then(response => {
      return new Promise((resolve, reject) => {
        // console.log("getSenssorHistorique ==> ",response.data,typeof response.data);
        if (response.status === 200 && !response.data.error) {
          if (typeof response.data !== 'string') {
            resolve(response.data);
          } else {
            reject('ERROR_SYSTEM');
          }
        } else {
          reject(response.data.error);
        }
      });
    });
  }

  getStripHistorique(_appid, _strip_number, _history) {
    let headers = {};
    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }

    this.setUrl(get_api_host() + '/get_all');

    this.setHeaders(headers);

    return this.fetch({
      strip_number: _strip_number,
      history: _history,
    }).then(response => {
      return new Promise((resolve, reject) => {
        // console.log("getSenssorHistorique ==> ",response.data,typeof response.data);
        if (response.status === 200 && !response.data.error) {
          if (typeof response.data !== 'string') {
            resolve(response.data);
          } else {
            reject('ERROR_SYSTEM');
          }
        } else {
          reject(response.data.error);
        }
      });
    });
  }

  SetController(_user, code) {}

  UpdateDevice(_appid, params) {
    let headers = {};

    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }
    this.setUrl(get_api_host() + '/update_all');

    this.setHeaders(headers);
    // console.log("params ===> ",_appid,params);
    return this.fetch(params).then(response => {
      console.log('response ', response.data);
      return new Promise((resolve, reject) => {
        if (
          response.status === 200 &&
          response.data.hasOwnProperty('request')
        ) {
          if (response.data.request) {
            resolve(response.data);
          } else {
            reject('ERROR_SYSTEM_OFFLINE');
          }

          // console.log(response.data)
        } else {
          if (response.data.hasOwnProperty('error')) {
            try {
              let _error = JSON.parse(response.data.error);
              if (_error.hasOwnProperty('code') && _error.code === '401') {
                reject('ERROR_PERMISSION_USER_AUTH');
              } else {
                reject(response.data.error);
              }
            } catch (e) {
              reject(response.data.error);
            }
          } else {
            reject('ERROR_PERMISSION_TO_UPDATE');
          }
        }
      });
    });
  }

  UpdateUser(_appid, params) {
    let headers = {};

    if (_appid) {
      headers.appid = _appid;
    }
    this.setUrl(get_api_host() + '/update_all');

    this.setHeaders(headers);
    // console.log("params ===> ",_appid,params);
    return this.fetch(params).then(response => {
      console.log('response ', response.data);

      return new Promise((resolve, reject) => {
        if (
          response.status === 200 &&
          response.data.hasOwnProperty('request')
        ) {
          if (response.data.request) {
            resolve(response.data);
          } else {
            reject('ERROR_SYSTEM_OFFLINE');
          }

          // console.log(response.data)
        } else {
          if (response.data.hasOwnProperty('error')) {
            try {
              let _error = JSON.parse(response.data.error);
              if (_error.hasOwnProperty('code') && _error.code === '401') {
                reject('ERROR_PERMISSION_USER_AUTH');
              } else {
                reject(response.data.error);
              }
            } catch (e) {
              reject(response.data.error);
            }
          } else {
            reject('ERROR_PERMISSION_TO_UPDATE');
          }
        }
      });
    });
  }

  addSlot(_appid, params) {
    let headers = {};
    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }

    this.setUrl(get_api_host() + '/add_all');

    this.setHeaders(headers);

    return this.fetch(params).then(response => {
      //console.log("response addSlot ",response.data)
      return new Promise((resolve, reject) => {
        if (
          response.status === 200 &&
          response.data.hasOwnProperty('request')
        ) {
          if (response.data.request === 'true') {
            resolve(response.data);
          } else {
            reject(response.data.request);
          }

          // console.log(response.data)
        } else {
          if (response.data.hasOwnProperty('error')) {
            try {
              let _error = JSON.parse(response.data.error);
              if (_error.hasOwnProperty('code') && _error.code === '401') {
                reject('ERROR_PERMISSION_USER_AUTH');
              } else {
                reject(response.data.error);
              }
            } catch (e) {
              reject(response.data.error);
            }
          } else {
            reject('ERROR_UNREACHEABLE');
          }
        }
      });
    });
  }

  RemoveSlot(_appid, params) {
    let headers = {};
    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }

    this.setUrl(get_api_host() + '/del_all');

    this.setHeaders(headers);

    return this.fetch(params).then(response => {
      // console.log("response del_all re",response.data)
      return new Promise((resolve, reject) => {
        if (
          response.status === 200 &&
          response.data.hasOwnProperty('request')
        ) {
          if (response.data.request === 'true') {
            resolve(response.data);
          } else {
            reject(response.data.request);
          }
        } else {
          if (response.data.hasOwnProperty('error')) {
            try {
              let _error = JSON.parse(response.data.error);
              if (_error.hasOwnProperty('code') && _error.code === '401') {
                reject('ERROR_PERMISSION_USER_AUTH');
              } else {
                reject(response.data.error);
              }
            } catch (e) {
              reject(response.data.error);
            }
          } else {
            reject('ERROR_UNREACHEABLE');
          }
        }
      });
    });
  }

  RemoveUser(_appid, params) {
    let headers = {};
    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }

    this.setUrl(get_api_host() + '/del_all');

    this.setHeaders(headers);

    return this.fetch({
      ...params,
      type: 'user',
    }).then(response => {
      return new Promise((resolve, reject) => {
        if (
          response.status === 200 &&
          response.data.hasOwnProperty('request')
        ) {
          if (response.data.request === 'true') {
            resolve(response.data);
          } else {
            reject(response.data.request);
          }
        } else {
          if (response.data.hasOwnProperty('error')) {
            try {
              let _error = JSON.parse(response.data.error);
              if (_error.hasOwnProperty('code') && _error.code === '401') {
                reject('ERROR_PERMISSION_USER_AUTH');
              } else {
                reject(response.data.error);
              }
            } catch (e) {
              reject(response.data.error);
            }
          } else {
            reject('ERROR_REMOVE_USER');
          }
        }
      });
    });
  }

  addNewEquipment(_appid, params) {
    let headers = {};
    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }

    this.setUrl(get_api_host() + '/add_all');

    this.setHeaders(headers);

    return this.fetch(params).then(response => {
      console.log('response addNewEquipment ', response.data);
      return new Promise((resolve, reject) => {
        if (
          response.status === 200 &&
          response.data.hasOwnProperty('request')
        ) {
          if (response.data.request === 'true') {
            resolve(response.data);
          } else {
            reject(response.data.request);
          }

          // console.log(response.data)
        } else {
          if (response.data.hasOwnProperty('error')) {
            try {
              let _error = JSON.parse(response.data.error);
              if (_error.hasOwnProperty('code') && _error.code === '401') {
                reject('ERROR_PERMISSION_USER_AUTH');
              } else {
                reject(response.data.error);
              }
            } catch (e) {
              reject(response.data.error);
            }
          } else {
            reject('ERROR_UNREACHEABLE');
          }
        }
      });
    });
  }

  RemoveDevice(_appid, params) {
    let headers = {};
    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }

    this.setUrl(get_api_host() + '/del_all');

    this.setHeaders(headers);

    return this.fetch(params).then(response => {
      // console.log("response del_all re",response.data)
      return new Promise((resolve, reject) => {
        if (
          response.status === 200 &&
          response.data.hasOwnProperty('request')
        ) {
          if (response.data.request === 'true') {
            resolve(response.data);
          } else {
            reject(response.data.request);
          }
        } else {
          if (response.data.hasOwnProperty('error')) {
            try {
              let _error = JSON.parse(response.data.error);
              if (_error.hasOwnProperty('code') && _error.code === '401') {
                reject('ERROR_PERMISSION_USER_AUTH');
              } else {
                reject(response.data.error);
              }
            } catch (e) {
              reject(response.data.error);
            }
          } else {
            reject('ERROR_UNREACHEABLE');
          }
        }
      });
    });
  }
  RemoveSensor(_appid, params) {
    let headers = {};
    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }

    this.setUrl(get_api_host() + '/del_all');

    this.setHeaders(headers);

    return this.fetch(params).then(response => {
      return new Promise((resolve, reject) => {
        if (
          response.status === 200 &&
          response.data.hasOwnProperty('request')
        ) {
          if (response.data.request === 'true') {
            resolve(response.data);
          } else {
            reject(response.data.request);
          }
        } else {
          if (response.data.hasOwnProperty('error')) {
            try {
              let _error = JSON.parse(response.data.error);
              if (_error.hasOwnProperty('code') && _error.code === '401') {
                reject('ERROR_PERMISSION_USER_AUTH');
              } else {
                reject(response.data.error);
              }
            } catch (e) {
              reject(response.data.error);
            }
          } else {
            reject('ERROR_UNREACHEABLE');
          }
        }
      });
    });
  }
  sendFCMToken(_appid, token) {
    let headers = {};

    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }

    this.setUrl(get_api_host() + '/fcm_token');
    this.setHeaders(headers);

    return this.post({
      token,
    })
      .then(response => {
        return new Promise((resolve, reject) => {
          if (response.status === 200 && !response.data.error) {
            resolve(response.data);
          } else {
            //console.log("response.data.error ",response.data.error)
            reject(response.data.error);
          }
        });
      })
      .catch(err => console.log(err));
  }
  requestAccess(_appid, systemId) {
    let headers = {};
    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }

    this.setUrl(get_api_host() + '/request_code');
    this.setHeaders(headers);

    return this.post({
      systemId,
    })
      .then(response => {
        return new Promise((resolve, reject) => {
          if (response.status === 200 && !response.data.error) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        });
      })
      .catch(err => console.log(err));
  }

  setPseudo(_appid, pseudo) {
    let headers = {};
    if (_appid) {
      headers.appid = _appid;
    }

    this.setUrl(get_api_host() + '/set_pseudo');

    this.setHeaders(headers);

    return this.fetch({
      user: pseudo,
    }).then(response => {
      return new Promise((resolve, reject) => {
        if (response.status === 200 && !response.data.error) {
          if (typeof response.data !== 'string') {
            resolve(response.data);
          } else {
            reject('ERROR_SYSTEM');
          }
        } else {
          reject(response.data.error);
        }
      });
    });
  }

  addBand(_appid, params) {
    let headers = {};

    if (currentSystemAppId) {
      headers.appid = currentSystemAppId;
    }
    this.setUrl(get_api_host() + '/send_sensors_calibrate');

    this.setHeaders(headers);
    // console.log("params ===> ",_appid,params);

    return this.fetch(params).then(response => {
      console.log('response ', response.data);
      return new Promise((resolve, reject) => {
        if (
          response.status === 200 &&
          response.data.hasOwnProperty('request')
        ) {
          if (response.data.request) {
            resolve(response.data);
          } else {
            reject('ERROR_SYSTEM_OFFLINE');
          }

          // console.log(response.data)
        } else {
          if (response.data.hasOwnProperty('error')) {
            try {
              let _error = JSON.parse(response.data.error);
              if (_error.hasOwnProperty('code') && _error.code === '401') {
                reject('ERROR_PERMISSION_USER_AUTH');
              } else {
                reject(response.data.error);
              }
            } catch (e) {
              reject(response.data.error);
            }
          } else {
            reject('ERROR_PERMISSION_TO_UPDATE');
          }
        }
      });
    });
  }
  sendCalibration(_appid, params) {
    // update_all?sensorNumber=1&point1=rawValue
    let headers = {};

    if (_appid) {
      headers.appid = _appid;
    }
    this.setUrl(get_api_host() + '/update_all');

    this.setHeaders(headers);
    // console.log("params ===> ",_appid,params);
    return this.fetch(params).then(response => {
      console.log('response ', response.data);

      return new Promise((resolve, reject) => {
        if (
          response.status === 200 &&
          response.data.hasOwnProperty('request')
        ) {
          if (response.data.request) {
            resolve(response.data);
          } else {
            reject('ERROR_SYSTEM_OFFLINE');
          }

          // console.log(response.data)
        } else {
          if (response.data.hasOwnProperty('error')) {
            try {
              let _error = JSON.parse(response.data.error);
              if (_error.hasOwnProperty('code') && _error.code === '401') {
                reject('ERROR_PERMISSION_USER_AUTH');
              } else {
                reject(response.data.error);
              }
            } catch (e) {
              reject(response.data.error);
            }
          } else {
            reject('ERROR_PERMISSION_TO_UPDATE');
          }
        }
      });
    });
  }

  startCalibration(appid, sensorNumber, point, value, time) {
    return fetch('https://sock.swimo.io/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        appid,
        type: 'START_CALIBRATION',
        point,
        sensorNumber,
        value,
        time,
      }),
    });
  }

  cancelCalibration(appid, sensorNumber) {
    return fetch('https://sock.swimo.io/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        appid,
        type: 'CANCEL_CALIBRATION',
        sensorNumber,
      }),
    });
  }
}
