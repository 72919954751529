import React from 'react';
import {
  StyleSheet,
  View,
  ScrollView,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  Switch,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import {connect} from 'react-redux';

import {ButtonGroup, Header, Icon, Input} from 'react-native-elements';
import MachineUnlock from '../../components/widget/MachineUnlock';
import ThreeDotsLoader from '../../components/ThreeDotsLoader';
import {
  appHeaderHeight,
  appHeaderTop,
  getStoreDataPromise,
  msg_code_to_text,
  setStoreData,
  ShowSnaskMessage,
  APP_BAR_ICON_SIZE,
  LEFT_HIT_SLOP,
} from '../../Config/Config';
import translate, {translateString} from '../../translations/translate';
import styles from './Bassin/styles.js';
import * as actions from '../../Store/Systems/SystemsActions';
import {fontFamilyRegular} from '../../Config/fonts';
import * as actionsApp from '../../Store/App/AppActions';

class HeaderLeftComponent extends React.Component {
  render() {
    return (
      <>
        <TouchableOpacity
          onPress={() => this.props.navigation.navigate('Alertes')}
          hitSlop={LEFT_HIT_SLOP}>
          <Icon
            name="exclamation-triangle"
            type="font-awesome"
            color={
              this.props.current_system &&
              this.props.current_system.alarm &&
              this.props.current_system.alarm.filter(
                i => i.alarm_status === '0',
              ).length > 0
                ? 'red'
                : '#fff'
            }
            size={APP_BAR_ICON_SIZE}
          />
        </TouchableOpacity>
      </>
    );
  }
}

class HeaderrightComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalUnlock: false,
    };
  }
  unlock = () => {
    if (!this.props.isAdmin) {
      this.setState({modalUnlock: true});
    } else {
      this.props.CloseApp();
    }
  };
  closeModal = () => {
    this.setState({modalUnlock: false});
  };
  callBackModal = () => {
    this.setState({modalUnlock: false});
    this.props.ModalUnlock();
  };
  render() {
    return (
      <>
        <View
          style={{flexDirection: 'row', paddingBottom: 0, paddingRight: 10}}>
          <TouchableOpacity onPress={this.unlock}>
            <Icon
              containerStyle={{paddingRight: 30}}
              name={this.props.isAdmin ? 'unlock-alt' : 'lock'}
              type="font-awesome"
              color="#fff"
              size={30}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.props.navigation.navigate('Profil')}>
            <Icon name="user" type="font-awesome" color="#fff" size={30} />
          </TouchableOpacity>

          <MachineUnlock
            isOpen={this.state.modalUnlock}
            closeModal={this.closeModal}
            callback={this.callBackModal}
          />
        </View>
      </>
    );
  }
}

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      system: null,
      system_name: '',
      isAdmin: false,
      isLoader: false,
      iswait: false,
      sys_contrat_type: 0,
      sys_user_email: 0,
      sys_user_push: 0,
      sys_winter: 0,
      vbtn: 0,
      Stbtn: 0,
      errors: {},
      isWait: {},
      inside: '',
      outside: '',
      pool: '',
      spa: '',
    };
  }

  componentDidMount() {
    this.initScreens();
  }

  componentWillUnmount() {}
  componentDidUpdate(nextProps) {
    const {current_system} = this.props;
    if (nextProps.current_system !== current_system) {
      this.initScreens();
    }
  }

  initScreens = async () => {
    this.getSystem();
    this.IsAdminSystem();
    const pool = await translateString('swimming pool');
    const spa = await translateString('hot tub');
    const inside = await translateString('indoor');
    const outside = await translateString('outdoor');

    this.setState({pool, spa, inside, outside});
  };

  getSystem = () => {
    if (
      this.props.current_system.hasOwnProperty('system') &&
      this.props.current_system.system.length > 0
    ) {
      this.setState({system: this.props.current_system.system[0]});
      this.setState({
        sys_type: parseInt(this.props.current_system.system[0].sys_type),
      });
      this.setState({
        sys_place:
          this.props.current_system.system[0].sys_place === '_EXT' ? 1 : 0,
      });
      this.setState({
        sys_contrat_type:
          parseInt(this.props.current_system.system[0].sys_contrat_type) > 0
            ? true
            : false,
      });
      this.setState({
        sys_user_email:
          parseInt(this.props.current_system.system[0].sys_user_email) > 0
            ? true
            : false,
      });
      this.setState({
        sys_user_push:
          parseInt(this.props.current_system.system[0].sys_user_push) > 0
            ? true
            : false,
      });
      this.setState({
        sys_winter:
          parseInt(this.props.current_system.system[0].sys_winter) > 0
            ? true
            : false,
      });

      let IswaitObj = {};
      Object.keys(this.props.current_system.system[0]).map(key => {
        IswaitObj[key] = false;
      });
      this.setState({isWait: IswaitObj});
    }
  };

  IsAdminSystem = () => {
    getStoreDataPromise('system_current_is_admin').then(_isAdmin => {
      if (_isAdmin === 'true') {
        this.setState({isAdmin: true});
      } else {
        this.setState({isAdmin: false});
      }
    });
  };

  onChangeField = (fieldName, val) => {
    let _system = this.state.system;
    _system[fieldName] = val;
    this.setState({system: _system});
  };

  SelectedChoicTypee = selectedIndex => {
    this.onChangeField('sys_type', selectedIndex);
    this.setState({sys_type: selectedIndex});
    this.onBlurField('sys_type');
  };

  SelectedSituaTypee = selectedIndex => {
    if (selectedIndex > 0) {
      this.onChangeField('sys_place', '_EXT');
    } else {
      this.onChangeField('sys_place', '_INT');
    }
    this.setState({sys_place: selectedIndex});
    this.onBlurField('sys_place');
  };
  toggleSwitch = (fieldName, val) => {
    this.onChangeField(fieldName, val);
    this.setState({[fieldName]: val});

    this.onBlurField(fieldName);
  };

  UpdateStatusDevices = (param, val) => {
    let objWait = this.state.isWait;
    if (objWait[param]) {
      return true;
    }

    objWait[param] = true;
    this.setState({isWait: objWait});
    // this.setState({isWait:true});
    this.props
      .UpdateDevice(this.state.system.sys_appid, {
        key: param,
        value: val,
      })
      .then(data => {
        objWait[param] = false;
        this.setState({isWait: objWait});
        ShowSnaskMessage({
          message: 'Operation Success',
          textColor: '#fff',
          backgroundColor: '#7ED321',
          buttonColor: '#7ED321',
          duration: 2000,
        });
      })
      .catch(error => {
        //console.log(error);
        objWait[param] = false;
        this.setState({isWait: objWait});
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });
      });
  };

  onBlurField = fieldName => {
    let _system = this.state.system;
    //console.log("OnBlur => ",fieldName,_system[fieldName]);
    this.UpdateStatusDevices(fieldName, _system[fieldName]);
  };
  CloseApp = () => {
    this.setState({isAdmin: false});
    setStoreData('system_current_is_admin', 'false');
    this.props.refreshApp();
  };
  render() {
    return (
      <SafeAreaView style={Settingstyles.containerfull}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            marginTop: appHeaderTop(),
            borderBottomColor: '#289EFF',
          }}
          leftComponent={
            <HeaderLeftComponent
              navigation={this.props.navigation}
              page="Alertes"
              current_system={this.props.current_system}
            />
          }
          rightComponent={
            <HeaderrightComponent
              navigation={this.props.navigation}
              isAdmin={this.state.isAdmin}
              ModalUnlock={this.ModalUnlock}
              CloseApp={this.CloseApp}
            />
          }
        />
        <ScrollView contentContainerStyle={{flexGrow: 1}}>
          <KeyboardAvoidingView style={styles.containerView}>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
              <View style={styles.container}>
                <View style={styles.RegisterFormView}>
                  {translate('Mon Bassin', styles.enteteStepText)}

                  <Input
                    editable={false}
                    value={this.state.system ? this.state.system.sys_id : ''}
                    onChangeText={val => this.onChangeField('sys_id', val)}
                    label={translate(
                      'Numero de serie',
                      styles.FormInputlabelStyle,
                    )}
                    labelStyle={styles.FormInputlabelStyle}
                    containerStyle={styles.FormInputcontainerStyleStep2}
                    inputContainerStyle={Settingstyles.FormInputContainerStyle}
                    inputStyle={[Settingstyles.FormTextInput]}
                    placeholder=""
                    errorStyle={styles.FormInputErrorStyle}
                    errorMessage={this.state.errors.ns}
                    rightIcon={
                      this.state.isWait.sys_id ? <ThreeDotsLoader /> : null
                    }
                  />
                  <Input
                    onBlur={() => this.onBlurField('sys_ref')}
                    onChangeText={val => this.onChangeField('sys_ref', val)}
                    value={this.state.system ? this.state.system.sys_ref : ''}
                    label={translate(
                      'Votre refrence',
                      styles.FormInputlabelStyle,
                    )}
                    labelStyle={styles.FormInputlabelStyle}
                    containerStyle={styles.FormInputcontainerStyleStep2}
                    inputContainerStyle={Settingstyles.FormInputContainerStyle}
                    inputStyle={[Settingstyles.FormTextInput]}
                    placeholder=""
                    errorStyle={styles.FormInputErrorStyle}
                    errorMessage={this.state.errors.ref}
                    rightIcon={
                      this.state.isWait.sys_ref ? <ThreeDotsLoader /> : null
                    }
                  />
                  <View>
                    {translate('Type de bassin :', styles.inputTextLabel)}
                    <ButtonGroup
                      onPress={this.SelectedChoicTypee}
                      buttonStyle={styles.BtnChoise}
                      innerBorderStyle={{width: 0}}
                      selectedButtonStyle={[
                        styles.BtnChoise,
                        styles.BtnChoiseSelected,
                      ]}
                      textStyle={[styles.BtnChoisetextStyle]}
                      selectedTextStyle={[styles.BtnChoiseSelected]}
                      containerStyle={{
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                      }}
                      selectedIndex={this.state.sys_type}
                      //buttons={['Piscine', 'SPA']}
                      buttons={[this.state.pool, this.state.spa]}
                    />
                    {this.state.isWait.sys_type ? <ThreeDotsLoader /> : null}
                  </View>
                  <Input
                    onBlur={() => this.onBlurField('sys_volume')}
                    value={
                      this.state.system ? this.state.system.sys_volume : ''
                    }
                    onChangeText={val => this.onChangeField('sys_volume', val)}
                    keyboardType={'numeric'}
                    label={translate(
                      'Volume du bassin :',
                      styles.FormInputlabelStyle,
                    )}
                    labelStyle={styles.FormInputlabelStyle}
                    containerStyle={styles.FormInputcontainerStyleStep2}
                    inputContainerStyle={Settingstyles.FormInputContainerStyle}
                    inputStyle={[Settingstyles.FormTextInput]}
                    placeholder=""
                    errorStyle={styles.FormInputErrorStyle}
                    errorMessage={this.state.errors.Vtype}
                    rightIcon={
                      this.state.isWait.sys_volume ? <ThreeDotsLoader /> : null
                    }
                  />
                  <View>
                    {translate('Situation ', styles.inputTextLabel)}
                    <ButtonGroup
                      onPress={this.SelectedSituaTypee}
                      buttonStyle={styles.BtnChoise}
                      innerBorderStyle={{width: 0}}
                      selectedButtonStyle={[
                        styles.BtnChoise,
                        styles.BtnChoiseSelected,
                      ]}
                      textStyle={[styles.BtnChoisetextStyle]}
                      selectedTextStyle={[styles.BtnChoiseSelected]}
                      containerStyle={{
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                      }}
                      selectedIndex={this.state.sys_place}
                      //buttons={['Int�rieur', 'Ext�rieur']}
                      buttons={[this.state.inside, this.state.outside]}
                    />
                    {this.state.isWait.sys_place ? <ThreeDotsLoader /> : null}
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'flex-end',
                      marginTop: 30,
                      marginBottom: 30,
                    }}>
                    <View style={{alignSelf: 'center'}} />
                  </View>
                </View>
                <View style={styles.RegisterFormView}>
                  {translate('Meteo de mon bassin', styles.enteteStepText)}
                  <Input
                    onBlur={() => this.onBlurField('sys_address')}
                    onChangeText={val => this.onChangeField('sys_address', val)}
                    value={
                      this.state.system ? this.state.system.sys_address : ''
                    }
                    label={translate(
                      'Adresse du bassin',
                      styles.FormInputlabelStyle,
                    )}
                    labelStyle={styles.FormInputlabelStyle}
                    containerStyle={[styles.FormInputcontainerStyleLast]}
                    inputContainerStyle={Settingstyles.FormInputContainerStyle}
                    inputStyle={[Settingstyles.FormTextInput]}
                    placeholder=""
                    errorStyle={styles.FormInputErrorStyle}
                    errorMessage=""
                    rightIcon={
                      this.state.isWait.sys_address ? <ThreeDotsLoader /> : null
                    }
                  />
                  <Input
                    onBlur={() => this.onBlurField('sys_address2')}
                    onChangeText={val =>
                      this.onChangeField('sys_address2', val)
                    }
                    containerStyle={[styles.FormInputcontainerNoLabelStyleLast]}
                    inputContainerStyle={Settingstyles.FormInputContainerStyle}
                    inputStyle={[Settingstyles.FormTextInput]}
                    placeholder=""
                    errorStyle={styles.FormInputErrorStyle}
                    errorMessage=""
                  />
                  <View style={{flex: 1, flexDirection: 'row', marginTop: 20}}>
                    <Input
                      keyboardType={'numeric'}
                      onBlur={() => this.onBlurField('sys_zip')}
                      onChangeText={val => this.onChangeField('sys_zip', val)}
                      value={this.state.system ? this.state.system.sys_zip : ''}
                      label={translate(
                        'Code Postal',
                        styles.FormInputlabelStyle,
                      )}
                      labelStyle={styles.FormInputlabelStyle}
                      containerStyle={[
                        styles.FormInputContainerStyle,
                        {flex: 3},
                      ]}
                      inputContainerStyle={
                        Settingstyles.FormInputContainerStyle
                      }
                      inputStyle={[Settingstyles.FormTextInput]}
                      placeholder=""
                      errorStyle={styles.FormInputErrorStyle}
                      errorMessage=""
                      rightIcon={
                        this.state.isWait.sys_zip ? <ThreeDotsLoader /> : null
                      }
                    />
                    <Input
                      onBlur={() => this.onBlurField('sys_city')}
                      onChangeText={val => this.onChangeField('sys_city', val)}
                      value={
                        this.state.system ? this.state.system.sys_city : ''
                      }
                      label={translate('Ville', styles.FormInputlabelStyle)}
                      labelStyle={styles.FormInputlabelStyle}
                      containerStyle={[
                        styles.FormInputcontainerStyleLast,
                        {flex: 4},
                      ]}
                      inputContainerStyle={
                        Settingstyles.FormInputContainerStyle
                      }
                      inputStyle={[Settingstyles.FormTextInput]}
                      placeholder=""
                      errorStyle={styles.FormInputErrorStyle}
                      errorMessage=""
                      rightIcon={
                        this.state.isWait.sys_city ? <ThreeDotsLoader /> : null
                      }
                    />
                  </View>
                  <View style={styles.zoneViewSwitch}>
                    {translate('Service Genius', {flex: 4, color: '#fff'})}
                    {this.state.isWait.sys_contrat_type ? (
                      <ThreeDotsLoader />
                    ) : null}
                    <Switch
                      style={{flex: 1, marginTop: 0}}
                      onValueChange={() =>
                        this.toggleSwitch(
                          'sys_contrat_type',
                          !this.state.sys_contrat_type,
                        )
                      }
                      value={this.state.sys_contrat_type}
                    />
                  </View>
                  <View style={styles.zoneViewSwitch}>
                    {translate('Notification email', {flex: 4, color: '#fff'})}
                    {this.state.isWait.sys_user_email ? (
                      <ThreeDotsLoader />
                    ) : null}
                    <Switch
                      style={{flex: 1, marginTop: 0}}
                      onValueChange={() =>
                        this.toggleSwitch(
                          'sys_user_email',
                          !this.state.sys_user_email,
                        )
                      }
                      accessibilityRole="button"
                      value={this.state.sys_user_email}
                    />
                  </View>
                  <View style={styles.zoneViewSwitch}>
                    {translate('Notification push', {flex: 4, color: '#fff'})}
                    {this.state.isWait.sys_user_push ? (
                      <ThreeDotsLoader />
                    ) : null}
                    <Switch
                      style={{flex: 1, marginTop: 0}}
                      onValueChange={() =>
                        this.toggleSwitch(
                          'sys_user_push',
                          !this.state.sys_user_push,
                        )
                      }
                      accessibilityRole="button"
                      value={this.state.sys_user_push}
                    />
                  </View>
                  <View style={styles.zoneViewSwitch}>
                    {translate('Winter mode', {flex: 4, color: '#fff'})}
                    {this.state.isWait.sys_user_push ? (
                      <ThreeDotsLoader />
                    ) : null}
                    <Switch
                      style={{flex: 1, marginTop: 0}}
                      onValueChange={() =>
                        this.toggleSwitch('sys_winter', !this.state.sys_winter)
                      }
                      value={this.state.sys_winter}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'flex-end',
                      marginTop: 30,
                      marginBottom: 30,
                    }}>
                    <View style={{alignSelf: 'center'}} />
                  </View>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const Settingstyles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
  },
  imgBackground: {
    width: '100%',
    height: '100%',
  },
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  FormView: {
    flex: 1,
  },
  FormTextInput: {
    height: 43,
    fontSize: 14,
    color: '#fff',
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
  FormInputContainerStyle: {
    marginBottom: 5,
    height: 43,
    fontSize: 14,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#eaeaea',
    color: '#fff',
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    current_system: state.SystemsRedux.system,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    userAppId: state.LoginReducer.appId,
    appId: state.LoginReducer.appId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
    refreshApp: () => {
      return dispatch(actionsApp.refreshApp());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);
