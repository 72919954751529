import React from 'react';
import {ImageBackground, StyleSheet, Platform} from 'react-native';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
export default class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: Platform.OS === 'ios' ? 2 : 2,
      serialNumber: '', // Need in IOS only
    };
  }
  SetNextStep = stepNb => {
    this.setState({step: stepNb});
  };
  EndStep = () => {
    this.props.navigation.navigate('Systemes');
  };
  ToProfile = () => {
    this.props.navigation.navigate('Profil');
  };
  goBack = () => {
    this.props.navigation.navigate('Systemes');
  };
  setSerialNumber = serialNumber => {
    this.setState({
      serialNumber,
    });
  };
  SwitchStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <Step1
            nextStep={this.SetNextStep}
            ToProfile={this.ToProfile}
            goBack={this.goBack}
            navigation={this.props.navigation}
            setSerialNumber={this.setSerialNumber}
          />
        );
      case 2:
        return (
          <Step2
            nextStep={this.SetNextStep}
            ToProfile={this.ToProfile}
            EndStep={this.EndStep}
            goBack={this.goBack}
            navigation={this.props.navigation}
            serialNumber={this.state.serialNumber}
          />
        );
      case 3:
        return <Step3 navigation={this.props.navigation} />;
    }
  };
  render() {
    return (
      <ImageBackground
        style={styles.imgBackground}
        resizeMode="cover"
        source={require('../../assets/bg.png')}>
        {this.SwitchStep()}
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  imgBackground: {
    width: '100%',
    height: '100%',
  },
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  FormView: {
    flex: 1,
  },
});
