import Systems from '../../Models/Systems';
import {setStoreData} from '../../Config/Config';

export const ACTION_APP_LOADDED = 'APP_LOADDED';
export const ACTION_APP_FAV = 'APP_FAV';
export const ACTION_APP_RACC = 'APP_RACC';
export const ACTION_APP_BASE_LIST = 'APP_BASE_LIST';
export const ACTION_APP_REFRESH = 'ACTION_APP_REFRESH';

export function updateFav(newFav) {
  return function(dispatch, getState) {
    //  console.log("updateFav ==> ",newFav)
    setStoreData('app_fav', newFav);
    dispatch({type: ACTION_APP_FAV, fav: newFav});
  };
}
export function refreshApp(newFav) {
  return function(dispatch, getState) {
    dispatch({type: ACTION_APP_REFRESH});
  };
}

export function updateRacc(newFav) {
  return function(dispatch, getState) {
    setStoreData('app_racc', newFav);
    dispatch({type: ACTION_APP_RACC, racc: newFav});
  };
}

export function get_base_list(appId) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    return MSystems.get_base_list(appId)
      .then(data => {
        dispatch({type: ACTION_APP_BASE_LIST, base_list: data});
        setStoreData('app_base_list', data);
        return new Promise((resolve, reject) => {
          resolve(data);
        });
      })
      .catch(er => {});
  };
}
