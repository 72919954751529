import React from 'react';
import {StyleSheet, Text, View, Platform} from 'react-native';
import {connect} from 'react-redux';
import {Input} from 'react-native-elements';
import {fontFamilyBold, fontFamilyRegular} from '../../Config/fonts';
import * as actions from '../../Store/Systems/SystemsActions';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  showSnaskMessageOperationSuccess,
} from '../../Config/Config';
import translate from '../../translations/translate';

import ModalDateTimePicker from '../ModalDateTimePicker';

class Consommation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWait: false,
      isOpenModelDateTime: false,
      isShowPicker: true,
      device_consumption: '',
      defaultTime: new Date(1598051730000),
      device_number: '',
      string_time: '12 H : 37 M : 18 S',
      selected_time: '',
      sys_appid: '',
    };
  }
  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.device_consumption !== this.props.device_consumption ||
      prevProps.device_number !== this.props.device_number
    ) {
      this.initData();
    }
  }

  componentWillUnmount() {}

  initData = () => {
    this.setState({
      device_consumption: this.props.device_consumption,
      sys_appid: this.props.sys_appid,
      device_number: this.props.device_number,
    });
  };

  UpdateStatusDevices = val => {
    if (this.state.isWait) {
      return true;
    }
    this.setState({isWait: true});
    //console.log(this.props.sys_appid)
    this.props
      .UpdateDevice(this.props.sys_appid, {
        key: 'device_consumption',
        value: this.state.device_consumption,
        number: this.state.device_number,
      })
      .then(data => {
        this.setState({SelectedBtn: val});
        this.setState({isWait: false});
        this.props.refeshDevice();
        showSnaskMessageOperationSuccess();
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };

  formatTime = date => {
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();
    var secondes = date.getUTCSeconds();

    minutes = minutes < 10 ? '0' + minutes : minutes;
    hours = hours < 10 ? '0' + hours : hours;
    secondes = secondes < 10 ? '0' + secondes : secondes;
    var strTime = hours + ':' + minutes + ':' + secondes;
    return strTime;
  };

  formatStringTime = date => {
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();
    var secondes = date.getUTCSeconds();

    minutes = minutes < 10 ? '0' + minutes : minutes;
    hours = hours < 10 ? '0' + hours : hours;
    secondes = secondes < 10 ? '0' + secondes : secondes;
    var strTime = hours + ' H : ' + minutes + ' M : ' + secondes + ' S';
    return strTime;
  };
  closeModelDateTime = val => {
    if (Platform.OS !== 'ios') {
      this.setState({isOpenModelDateTime: false});
    }
    if (!val) {
      return null;
    }

    this.setState({
      string_time: this.formatStringTime(val),
      selected_time: this.formatTime(val),
      isOpenModelDateTime: false,
    });
  };

  handleInputFocus = () => {
    this.setState({isOpenModelDateTime: true});
  };

  render() {
    //  console.log("device_consumption  composent ",this.props.device_consumption,this.state.device_consumption);
    return (
      <View style={styles.container}>
        <Text
          style={{
            color: '#fff',
            fontSize: 18,
            fontFamily: fontFamilyBold(),
            marginBottom: 10,
          }}>
          {translate('consommation')} :
        </Text>
        <Input
          containerStyle={styles.FormInputcontainerStyle}
          inputContainerStyle={styles.FormInputContainerStyle}
          inputStyle={[styles.FormTextInput]}
          editable={false}
          value={this.state.device_consumption}
        />
        <ModalDateTimePicker
          isshow={this.state.isOpenModelDateTime}
          close={this.closeModelDateTime}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#121212',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
  },
  FormInputcontainerStyle: {
    borderBottomWidth: 0,
    marginBottom: 0,
  },
  FormInputContainerStyle: {
    borderBottomWidth: 0,
    marginBottom: 5,
    borderWidth: 1,
    borderColor: '#242424',
    backgroundColor: '#242424',
    borderRadius: 50,
  },
  FormTextInput: {
    height: 43,
    fontSize: 14,
    color: '#fff',
    paddingLeft: 10,
    marginTop: 5,
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
    textAlign: 'center',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Consommation);
