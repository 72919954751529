import React from 'react';
import {ImageBackground, StyleSheet} from 'react-native';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
export default class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
    };
  }
  SetNextStep = stepNb => {
    this.setState({step: stepNb});
  };
  EndStep = () => {
    this.props.navigation.navigate('Systemes');
  };
  ToProfile = () => {
    this.props.navigation.navigate('Profil');
  };
  goBack = () => {
    this.props.navigation.navigate('Login');
  };
  SwitchStep = () => {
    switch (this.state.step) {
      case 1:
        return <Step1 nextStep={this.SetNextStep} goBack={this.goBack} />;
      case 2:
        return <Step2 nextStep={this.SetNextStep} goBack={this.goBack} />;
      case 3:
        return <Step3 nextStep={this.SetNextStep} goBack={this.goBack} />;
      case 4:
        return (
          <Step4
            nextStep={this.SetNextStep}
            ToProfile={this.ToProfile}
            goBack={this.goBack}
          />
        );
      case 5:
        return (
          <Step5
            nextStep={this.SetNextStep}
            ToProfile={this.ToProfile}
            EndStep={this.EndStep}
            goBack={this.goBack}
          />
        );
    }
  };
  render() {
    return (
      <ImageBackground
        style={styles.imgBackground}
        resizeMode="cover"
        source={require('../../assets/bg.png')}>
        {this.SwitchStep()}
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  imgBackground: {
    width: '100%',
    height: '100%',
  },
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  FormView: {
    flex: 1,
  },
});
