import React, {Component} from 'react';
import {
  StyleSheet,
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
// import RNFS from 'react-native-fs';
import {Header, Icon, Button} from 'react-native-elements';
import HeaderLeftComponent from '../components/HeaderLeftComponent';
import ImageResizer from 'react-native-image-resizer';
import {removeStoreData} from '../Config/Config';

import {
  appHeaderHeight,
  APP_BAR_ICON_SIZE,
  LEFT_HIT_SLOP,
} from '../Config/Config';

class HelloWorldSceneAR extends Component {
  constructor(props) {
    super(props);
    // Set initial state here
    this.state = {
      anchor: false,
      text: 'Initializing AR...',
      position: [0, 0, -1],
      scale: [0.5, 0.5, 0.5],
      rotation: [0, 0, 0],
    };
    // bind 'this' to functions
    this._onInitialized = this._onInitialized.bind(this);
  }

  componentDidMount() {
    // this.props.sceneNavigator.viroAppProps.setScene(this.scene);
  }

  _onCameraTransformUpdate = data => {
    this.setState({
      currentPosition: data.forward,
      currentRotation: data.rotation,
    });
  };

  render() {
    return (
      <div />
    );
  }
}

var styles = StyleSheet.create({
  helloWorldTextStyle: {
    fontFamily: 'Arial',
    fontSize: 30,
    color: '#ffffff',
    textAlignVertical: 'center',
    textAlign: 'center',
  },
});

export default class AR extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  setScene = scene => {
    this.currentScene = scene;
  };

  render() {
    return (
      <SafeAreaView style={{flex: 1}}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            zIndex: 1000,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          leftComponent={
            <HeaderLeftComponent
              navigation={this.props.navigation}
              goToBack={this.props.navigation.goBack}
            />
          }
          rightComponent={
            <TouchableOpacity
              hitSlop={LEFT_HIT_SLOP}
              onPress={() => this.props.navigation.navigate('BandResults')}
              style={{paddingLeft: 5, paddingRight: 5}}>
              <Icon
                name="chevron-right"
                type="font-awesome"
                color="#fff"
                size={APP_BAR_ICON_SIZE}
              />
            </TouchableOpacity>
          }
        />
        <View
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 77,
            alignItems: 'center',
          }}>
          {this.state.loading && (
            <Text style={{color: 'white'}}>Loading...</Text>
          )}
          {this.state.takingScreenshot && (
            <Text style={{color: 'white'}}>Scanning please don't move</Text>
          )}
          {this.state.error && (
            <Text style={{color: 'red'}}>{this.state.error}</Text>
          )}
          {!this.state.takingScreenshot && !this.state.loading && (
            <Button
              buttonStyle={{
                backgroundColor: 'transparent',
                borderWidth: 1,
                fontFamily: 'arial',
                fontSize: 12,
                borderColor: '#ffffff',
                borderRadius: 50,
                paddingTop: 5,
                paddingLeft: 15,
                paddingRight: 15,
              }}
              title="Scan"
              onPress={this.onClick}
            />
          )}
        </View>
      </SafeAreaView>
    );
  }
}
