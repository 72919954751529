import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Modal,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import {connect} from 'react-redux';
import {fontFamilyBold} from '../../Config/fonts';
import translate, {translateString} from '../../translations/translate';
class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      faq: null,
    };
  }

  setModalVisible = async (stateModal, _faq) => {
    this.setState({modalVisible: stateModal, faq: await translateString(_faq)});
  };
  render() {
    const current_system = this.props.current_system;
    return (
      <View style={styles.container}>
        <Text style={styles.titre}>{translate('Des questions')} ?</Text>
        <View style={{marginTop: 10}}>
          {current_system &&
            current_system.advice.map((item, index) => {
              let st = styles.itemblack;
              if (index % 2) {
                st = styles.itemgrenn;
              }

              return (
                <View style={st}>
                  <Text
                    style={styles.itemquestion}
                    onPress={() => {
                      this.setModalVisible(true, item.adv_text);
                    }}>
                    {translate(item.adv_title)}
                  </Text>
                </View>
              );
            })}
        </View>
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.modalVisible}>
          <TouchableOpacity
            style={styles.Modalcontainer}
            onPress={() => {
              this.setModalVisible(!this.state.modalVisible, null);
            }}>
            <View
              style={{
                justifyContent: 'center',
                backgroundColor: '#fff',
                borderRadius: 20,
                padding: 15,
                width: '90%',
                height: '50%',
              }}>
              <ScrollView contentContainerStyle={{flexGrow: 1}}>
                <Text>{translate(this.state.faq)}</Text>
              </ScrollView>
            </View>
          </TouchableOpacity>
        </Modal>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderLeftColor: '#5ECDE7',
    borderLeftWidth: 3,
  },
  titre: {
    color: '#5ECDE7',
    fontSize: 16,
    fontFamily: fontFamilyBold(),
    paddingLeft: 10,
  },
  itemgrenn: {
    backgroundColor: '#1E1E1E',
    paddingLeft: 30,
    paddingRight: 30,
  },
  itemblack: {
    backgroundColor: '#121212',
    paddingLeft: 30,
    paddingRight: 30,
  },
  itemquestion: {
    color: '#ADADAD',
    paddingTop: 10,
    paddingBottom: 10,
    borderTopColor: '#D8D8D8',
    borderTopWidth: 1,
  },
  lastitemquestion: {
    borderBottomColor: '#D8D8D8',
    borderBottomWidth: 1,
  },
  Modalcontainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(154, 152, 152, 0.84)',
    flexDirection: 'column',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    current_system: state.SystemsRedux.system,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Question);
