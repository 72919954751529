import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import {Header, Icon, Image, Button} from 'react-native-elements';
import translate from '../translations/translate';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  appHeaderHeight,
  getStoreDataPromise,
  setStoreData,
} from '../Config/Config';
import ImagePicker from 'react-native-image-picker';
import * as actions from '../Store/Login/LoginActions';
import * as actionsSystems from '../Store/Systems/SystemsActions';

import HeaderLeftComponent from '../components/HeaderLeftComponent';
import UserList from './Profil/UserList';
import ButtonCustom from '../components/Button';

class Profil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      system: {},
      user: {},
      sensors: [],
      devices: [],
      avatarSource: {},
    };
  }

  componentDidMount() {
    this.getSystem();
    this.getUser();
    this.InitAvatar();
  }

  componentDidUpdate(prevProps) {
    // last_update_data_redux: state.AppRedux.last_update_data_redux,
    // last_update_data_redux_system: state.SystemsRedux.last_update_data_redux,
    if (
      prevProps.last_update_data_redux !== this.props.last_update_data_redux ||
      prevProps.last_update_data_redux_system !==
        this.props.last_update_data_redux_system
    ) {
      this.getSystem();
      this.getUser();
      this.InitAvatar();
    }
  }

  logout = () => {
    try {
      this.props.Logout().then(() => {
        setTimeout(() => {
          this.props.navigation.replace('Login');
        }, 400);
      });
    } catch (err) {}
  };

  getSystem = () => {
    if (
      this.props.current_system.hasOwnProperty('system') &&
      this.props.current_system.system.length > 0
    ) {
      this.setState({
        system:
          this.props.current_system.system &&
          this.props.current_system.system[0],
        sensors: this.props.current_system.sensors,
        devices: this.props.current_system.actions,
      });
    }
  };
  getUser = () => {
    if (
      this.props.current_system.hasOwnProperty('user') &&
      this.props.current_system.user.length > 0
    ) {
      this.setState({
        user: this.props.current_system && this.props.current_system.user[0],
      });
    }
  };
  SelectImage = () => {
    const options = {
      title: 'Select Avatar',
      storageOptions: {
        skipBackup: true,
        path: 'images',
      },
    };

    ImagePicker.showImagePicker(options, response => {
      // console.log('Response = ', response);

      if (response.didCancel) {
        console.log('User cancelled image picker');
      } else if (response.error) {
        console.log('ImagePicker Error: ', response.error);
      } else {
        const source = {uri: response.uri};

        console.log(response.uri);
        this.setState({
          avatarSource: source,
        });

        setStoreData('system_current_avatar', response.uri);
      }
    });
  };

  InitAvatar = () => {
    getStoreDataPromise('system_current_avatar').then(_uri => {
      if (_uri) {
        console.log('system_current_avatar ', _uri);

        this.setState({
          avatarSource: {uri: _uri},
        });
      } else {
        const source = {uri: 'https://via.placeholder.com/150'};
        this.setState({
          avatarSource: source,
        });
      }
    });
  };
  render() {
    const currentUser =
      this.props.current_system.user && this.props.current_system.user[0];

    return (
      <SafeAreaView style={styles.containerfull}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          leftComponent={
            <HeaderLeftComponent
              navigation={this.props.navigation}
              page="AcceuilTabs"
            />
          }
        />
        <ScrollView
          style={{marginTop: 0, padding: 0}}
          contentContainerStyle={{flexGrow: 1}}>
          <View style={styles.ViewLogo}>
            <TouchableOpacity onPress={() => this.SelectImage()}>
              <Image
                source={this.state.avatarSource}
                style={{width: 100, height: 100}}
              />
            </TouchableOpacity>
          </View>
          <Text style={styles.userPsd}>
            {this.state.user.user_pseudo ? this.state.user.user_pseudo : '-'}
          </Text>
          <View style={{paddingLeft: 15, paddingRight: 15, marginTop: 25}}>
            <ButtonCustom
              text="Changer / Ajouter un bassin"
              style={styles.btn}
              onPress={() => {
                this.props.CurrentSystemReset();
                setTimeout(() => {
                  this.props.navigation.reset({
                    index: 0,
                    routes: [{name: 'Systemes'}],
                  });
                }, 400);
              }}
              icon={
                <Icon
                  name="chevron-right"
                  type="font-awesome"
                  color="#fff"
                  size={15}
                />
              }
            />
            {currentUser?.user_type === '0' && (
              <ButtonCustom
                text="Ajouter un utilisateur"
                onPress={() => {
                  this.props.navigation.navigate('NewUser');
                }}
                style={styles.btn}
                icon={
                  <Icon
                    name="plus"
                    type="font-awesome"
                    color="#fff"
                    size={15}
                  />
                }
              />
            )}
            <ButtonCustom
              text="Se deconnecter"
              style={styles.btn}
              onPress={this.logout}
              icon={
                <Icon name="times" type="font-awesome" color="#fff" size={15} />
              }
            />
          </View>
          <View style={styles.containerProfile}>
            <View style={styles.SectionRow}>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <Text style={styles.SectionTitle}>
                  {translate('mes_infos')} :
                </Text>
                <TouchableOpacity
                  hitSlop={{top: 10, left: 30, right: 30, bottom: 30}}
                  onPress={() =>
                    this.props.navigation.navigate('ProfilInfoEdit')
                  }>
                  <Icon
                    reverse
                    color="#121212"
                    activeOpacity={1}
                    containerStyle={{width: 60, height: 60}}
                    iconStyle={{marginTop: 0, marginLeft: 0}}
                    name={'pencil'}
                    type="font-awesome"
                    size={20}
                    underlayColor={'#fff'}
                  />
                </TouchableOpacity>
              </View>
              <View style={{flex: 2}}>
                <Text style={styles.SectionText}>
                  {this.state.user.user_firstname
                    ? this.state.user.user_firstname + ' '
                    : '-'}
                  {this.state.user.user_name ? this.state.user.user_name : '-'}
                </Text>
                <Text style={styles.SectionText}>
                  {' '}
                  {this.state.user.user_address
                    ? this.state.user.user_address
                    : '-'}
                </Text>
                <Text style={styles.SectionText}>
                  {this.state.user.user_zip
                    ? this.state.user.user_zip + ' '
                    : '-'}
                  {this.state.user.user_city ? this.state.user.user_city : '-'}
                </Text>
                <Text style={styles.SectionText}>
                  {this.state.user.sys_country
                    ? this.state.user.sys_country
                    : '-'}
                </Text>
              </View>
            </View>
            <UserList onAdd={() => this.props.navigation.navigate('NewUser')} />
            <View style={styles.SectionRow}>
              <View style={{flex: 1}}>
                <Text style={styles.SectionTitle}>
                  {translate('mon_bassin')} :
                </Text>
              </View>
              <View style={{flex: 2}}>
                <View style={{flex: 1, flexDirection: 'row'}}>
                  <View style={{flex: 4}}>
                    <Text style={styles.SectionTextNum}>
                      {this.state.system.sys_id
                        ? this.state.system.sys_id
                        : '-'}
                    </Text>
                  </View>
                </View>
                <Text style={styles.SectionText}>
                  {this.state.system.sys_top ? this.state.system.sys_top : '-'}
                </Text>
                <Text style={styles.SectionText}>
                  {this.state.system.sys_address
                    ? this.state.system.sys_address
                    : '-'}
                </Text>
                <Text style={styles.SectionText}>
                  {this.state.system.sys_zip
                    ? this.state.system.sys_zip + ' '
                    : '-'}{' '}
                  {this.state.system.sys_city
                    ? this.state.system.sys_city
                    : '-'}
                </Text>
                <Text style={styles.SectionText}>FRANCE</Text>
              </View>
            </View>
            <View style={styles.SectionRow}>
              <View style={{flex: 1}}>
                <Text style={styles.SectionTitle}>{translate('support')}:</Text>
              </View>
              <View style={{flex: 2}}>
                <View style={{flex: 1, flexDirection: 'row'}}>
                  <View style={{flex: 4}}>
                    <Text style={styles.SectionTextNum}>
                      {this.props.current_system &&
                      this.props.current_system.pro &&
                      this.props.current_system.pro[0].pro_company
                        ? this.props.current_system.pro[0].pro_company
                        : '-'}
                    </Text>
                  </View>
                </View>
                <Text style={styles.SectionText}>
                  {this.props.current_system &&
                  this.props.current_system.pro &&
                  this.props.current_system.pro[0].pro_support_phone
                    ? this.props.current_system.pro[0].pro_support_phone
                    : '-'}
                </Text>
                <Text style={styles.SectionText}>
                  {this.props.current_system &&
                  this.props.current_system.pro &&
                  this.props.current_system.pro[0].pro_support_email
                    ? this.props.current_system.pro[0].pro_support_email
                    : '-'}
                </Text>
              </View>
            </View>
            <View style={styles.SectionRowChiffer}>
              <View style={{flex: 1, flexDirection: 'row'}}>
                <View style={{flex: 1}}>
                  <Image
                    source={require('../assets/icon-tension.png')}
                    style={{width: 30, height: 37}}
                  />
                </View>
                <View
                  style={{
                    flex: 2,
                    alignSelf: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 20,
                  }}>
                  <Text style={styles.textRound}>
                    {this.state.devices.length}
                  </Text>
                  <Text style={styles.SectionText}>
                    {translate('equipements')}
                  </Text>
                </View>
              </View>
              <View style={{flex: 1, flexDirection: 'row'}}>
                <View style={{flex: 1}}>
                  <Image
                    source={require('../assets/icon.sondes.png')}
                    style={{width: 40, height: 37}}
                  />
                </View>
                <View
                  style={{
                    flex: 2,
                    alignSelf: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 20,
                  }}>
                  <Text style={styles.textRound}>
                    {this.state.sensors.length}
                  </Text>
                  <Text style={styles.SectionText}>{translate('Sondes')}</Text>
                </View>
              </View>
            </View>
            <View style={styles.containerBaseInfoSetting}>
              <View
                style={{
                  flexDirection: 'row',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Text style={{color: '#000', flex: 0}}>
                  {translate('controller')}
                </Text>
                <View style={{flex: 1}}>
                  <Button
                    buttonStyle={styles.btninfo}
                    titleStyle={{color: 'black', fontSize: 18}}
                    title={
                      this.state.system.sys_version
                        ? this.state.system.sys_version
                        : '-'
                    }
                  />
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 10,
                  alignSelf: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Text style={{color: '#000', flex: 0}}>
                  {translate('Base_donnes')}
                </Text>
                <View style={{flex: 1}}>
                  <Button
                    buttonStyle={styles.btninfo}
                    titleStyle={{color: 'black', fontSize: 18}}
                    title={
                      this.state.system.base_version
                        ? this.state.system.base_version
                        : '-'
                    }
                  />
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
  },
  containerProfile: {
    flex: 1,
    backgroundColor: '#000004',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
    marginTop: 10,
  },
  btn: {
    marginBottom: 14,
  },
  ViewLogo: {
    width: '80%',
    padding: 10,
    backgroundColor: '#000004',
    borderRadius: 10,
    marginTop: 50,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#fff',
    borderWidth: 1,
  },
  userPsd: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 18,
  },
  SectionTitle: {
    paddingLeft: 10,
    paddingBottom: 10,
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
  ChangeEquipment: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 18,
    marginTop: 20,
  },
  SectionTextNum: {
    paddingLeft: 10,
    color: '#fff',
    fontSize: 18,
  },
  SectionText: {
    paddingLeft: 10,
    color: '#fff',
    fontSize: 14,
  },
  SectionRow: {
    flexDirection: 'column',
    paddingBottom: 10,
    paddingTop: 10,
    backgroundColor: '#121212',
    borderRadius: 10,
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  SectionRowChiffer: {
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 10,
    backgroundColor: '#121212',
    borderRadius: 10,
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  textRound: {
    paddingTop: 3,
    fontSize: 30,
    textAlign: 'center',
    color: '#fff',
    height: 50,
    width: 50,
    borderRadius: 100,
    backgroundColor: '#B164C5',
  },
  containerBaseInfoSetting: {
    flex: 1,
    marginTop: 10,
    backgroundColor: '#B164C5',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
    paddingTop: 30,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 10,
  },
  btninfo: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    borderWidth: 0.5,
    borderColor: '#000',
    borderRadius: 5,
    backgroundColor: '#B164C5',
    color: 'black',
  },
});

Profil.propTypes = {
  user: PropTypes.object,
  clientInfo: PropTypes.object,
};
Profil.defaultProps = {
  user: {},
  clientInfo: {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.LoginReducer.user || {},
    current_system: state.SystemsRedux.system || {},
    clientInfo: state.SystemsRedux.clientInfo || {},
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    last_update_data_redux_system: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    Logout: () => {
      return dispatch(actions.UserLogout());
    },
    CurrentSystemReset: () => {
      return dispatch(actionsSystems.ResetSystem());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profil);
