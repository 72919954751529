import {BarChart} from 'react-native-chart-kit';
import {View} from 'react-native';
import {G, Rect, Svg, LinearGradient, Defs, Stop} from 'react-native-svg';
import React from 'react';

export default class AppBarChart extends BarChart {
  renderBars = config => {
    const {data, width, height, paddingTop, paddingRight, barRadius} = config;
    const baseHeight = this.calcBaseHeight(data, height);
    return data.map((x, i) => {
      const barHeight = this.calcHeight(x, data, height);
      const barWidth = 32 * this.getBarPercentage();
      const math = Math.random();

      return (
        <>
          <Defs>
            <LinearGradient id={`grad${i}${math}`} x1="0" y1="0" x2="0" y2="1">
              <Stop
                offset="1"
                stopColor={this.props.chartConfig.colorBar && this.props.chartConfig.colorBar(x)[0]}
                stopOpacity="1"
              />
              <Stop
                offset="0"
                stopColor={
                  this.props.chartConfig.colorBar && this.props.chartConfig.colorBar(x)[1] ||
                  this.props.chartConfig.colorBar && this.props.chartConfig.colorBar(x)[0]
                }
                stopOpacity="1"
              />
            </LinearGradient>
          </Defs>
          <Rect
            key={Math.random()}
            x={
              paddingRight +
              (i * (width - paddingRight)) / data.length +
              barWidth / 2
            }
            y={
              ((barHeight > 0 ? baseHeight - barHeight : baseHeight) / 4) * 3 +
              paddingTop
            }
            rx={barRadius}
            width={barWidth}
            height={(Math.abs(barHeight) / 4) * 3}
            fill={`url(#grad${i}${math})`}
          />
        </>
      );
    });
  };
  render() {
    const {
      width,
      height,
      data,
      style = {},
      withHorizontalLabels = true,
      withVerticalLabels = true,
      verticalLabelRotation = 0,
      horizontalLabelRotation = 0,
      withInnerLines = true,
      showBarTops = true,
      segments = 4,
    } = this.props;
    const {borderRadius = 0, paddingTop = 9, paddingRight = -5} = style;
    const config = {
      width,
      height,
      verticalLabelRotation,
      horizontalLabelRotation,
      barRadius:
        (this.props.chartConfig && this.props.chartConfig.barRadius) || 0,
      formatYLabel:
        (this.props.chartConfig && this.props.chartConfig.formatYLabel) ||
        function(label) {
          return label;
        },
      formatXLabel:
        (this.props.chartConfig && this.props.chartConfig.formatXLabel) ||
        function(label) {
          return label;
        },
    };
    return (
      <View style={style}>
        <Svg height={height} width={width}>
          {this.renderDefs({
            ...config,
            ...this.props.chartConfig,
          })}
          <Rect
            width="100%"
            height={height}
            rx={borderRadius}
            ry={borderRadius}
            fill="url(#backgroundGradient)"
          />
          <G>
            {withInnerLines
              ? this.renderHorizontalLines({
                  ...config,
                  count: segments,
                  paddingTop,
                })
              : null}
          </G>
          <G>
            {withHorizontalLabels
              ? this.renderHorizontalLabels({
                  ...config,
                  count: segments,
                  data: data.datasets[0].data,
                  paddingTop,
                  paddingRight,
                })
              : null}
          </G>

          <G>
            {this.renderBars({
              ...config,
              data: data.datasets[0].data,
              paddingTop,
              paddingRight,
            })}
          </G>
        </Svg>
      </View>
    );
  }
}
