import React from 'react';
import {StyleSheet, View, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Svg, {
  Use,
  Image,
} from 'react-native-svg';
import * as actions from '../../Store/Systems/SystemsActions';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  showSnaskMessageOperationSuccess,
} from '../../Config/Config';
import ThreeDotsLoader from '../ThreeDotsLoader';

class Raccourcie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      last_update_data_redux: false,
      isWait: false,
      devices: props.devices,
      system: props.current_system.system[0],
    };
  }

  // TODO fix that
  componentDidUpdate(nextProps) {
    const {devices, last_update_data_redux} = this.props;
    if (
      nextProps.devices !== devices ||
      nextProps.last_update_data_redux !== last_update_data_redux
    ) {
      this.setState({
        devices: this.props.devices,
      });
    }
  }
  componentWillUnmount() {}

  renderElement = element => {
    if (!element.assets || !element.device) {
      return null;
    }

    let assetsWidget = element.assets;
    //  console.log("img assetsWidget",assetsWidget,element);
    let img = '';
    switch (parseInt(element.device.device_status)) {
      case 0:
        img = assetsWidget.device_icon_status_off + '?img=_nocache_2';
        break;
      case 1:
        img = assetsWidget.device_icon_status_on + '?img=_nocache_2';
        break;
    }
    // console.log("img ",img,element.device.device_status,element.device.device_index);

    if (img) {
      return (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <View style={styles.CircleShapeView}>
            <TouchableOpacity
              onPress={() => {
                this.UpdateStatusDevices(
                  'device_index',
                  element.device.device_number,
                  element.device.device_index === '1' ? 0 : 1,
                );
              }}>
                <Svg width={50} height={50}>
                  <Image href={img} width={50} height={50}/>
                </Svg>
            </TouchableOpacity>
          </View>
        </View>
      );
    }
  };
  renderElements = () => {
    let index = 0;
    return this.state.devices.map(element => {
      if (index < 4) {
        let resObj = this.renderElement(element);
        if (resObj) {
          index++;
          return resObj;
        }
      }

      //  return null;
    });
  };
  render() {
    return (
      <View>
        <View>{this.state.isWait ? <ThreeDotsLoader /> : <View />}</View>
        <View style={{flexDirection: 'row'}}>{this.renderElements()}</View>
      </View>
    );
  }

  UpdateStatusDevices = (fieldName, fieldNumber, val) => {
    if (this.state.isWait) {
      return true;
    }
    this.setState({isWait: true});

    this.props
      .UpdateDevice(this.state.system.sys_appid, {
        key: fieldName,
        value: val,
        number: fieldNumber,
      })
      .then(data => {
        this.setState({device_index: val});
        this.setState({isWait: false});

        showSnaskMessageOperationSuccess();
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };
}

const styles = StyleSheet.create({
  CircleShapeView: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
    height: 80,
    borderRadius: 80 / 2,
    borderWidth: 1,
    borderColor: '#1E1E1E',
  },
  Modalcontainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(154, 152, 152, 0.84)',
    flexDirection: 'column',
  },
  viewList: {},
});

Raccourcie.propTypes = {
  app_racc: PropTypes.array,
  devices: PropTypes.array,
  current_system: PropTypes.any,
};
Raccourcie.defaultProps = {
  app_racc: [],
  devices: [],
  current_system: {},
};
const mapStateToProps = (state, ownProps) => {
  return {
    app_racc: state.AppRedux.app_racc,
    current_system: state.SystemsRedux.system,
    base_list: state.AppRedux.base_list,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Raccourcie);
