import * as LoginActions from './LoginActions';
import * as RegisterActions from '../Registration/RegistrationActions';
import {setStoreData} from '../../Config/Config';

const initialState = {
  user: null,
  appId: null,
};

export default function LoginReducer(state = initialState, action) {
  switch (action.type) {
    case RegisterActions.ACTION_REGISTER_USER_STEP2:
      setStoreData('user_logged', action.step2.token[0]);
      return {
        ...state,
        user: action.step2.token[0],
        appId: action.step2.token[0].appid,
      };
    case LoginActions.ACTION_USER_LOGIN:
      return {...state, user: action.user, appId: action.user.appid};
    case LoginActions.ACTION_USER_SET_APPID:
      setStoreData('user_logged', {
        ...state.user,
        appid: action.appId,
      });
      return {
        ...state,
        appId: action.appId,
        user: {
          ...state.user,
          appid: action.appId,
        },
      };
    case LoginActions.ACTION_USER_LOGOUT:
      return {...state, user: null, appId: null};
    default:
      return state;
  }
}
