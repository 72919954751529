import React from 'react';
import {StyleSheet, Text, View, Dimensions} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import Svg, {
  Use,
  Image,
} from 'react-native-svg';

import {
  fontFamilyArialBlack,
  fontFamilyBold,
  fontFamilyRegular,
} from '../../Config/fonts';
import {isColor, getColumnNumber} from '../../Config/Config';
import translate from '../../translations/translate';

import imgbaignade from '../../assets/imgbaignade1.svg';
import imgbaignadeorange from '../../assets/imgbaignadeorange2.svg';
import imgbaignaderouge from '../../assets/imgbaignaderouge3.svg';

class Baignade extends React.Component {
  componentDidMount() {}

  componentWillUnmount() {}
  renderText = () => {
    let colorbaignade = '#00FFC2';
    switch (parseInt(this.props.sys_status)) {
      case 0:
        colorbaignade = '#00FFC2';
        return (
          <View style={{marginTop: 20, paddingLeft: 10, flex: 2}}>
            <Text
              style={{
                color: '#fff',
                fontSize: 16,
                fontFamily: fontFamilyRegular(),
              }}>
              {translate('water_quality')} {translate('is')}
            </Text>
            <Text
              style={{
                color: colorbaignade,
                fontSize: 16,
                fontFamily: fontFamilyRegular(),
              }}>
              {translate('good')}
            </Text>
            <Text
              style={{
                color: '#fff',
                fontSize: 16,
                fontFamily: fontFamilyRegular(),
              }}>
              {translate('good_bath')} !
            </Text>
          </View>
        );
      case 1:
        colorbaignade = '#D06711';
        return (
          <View style={{marginTop: 20, paddingLeft: 10, flex: 2}}>
            <Text
              style={{
                color: '#fff',
                fontSize: 16,
                fontFamily: fontFamilyRegular(),
              }}>
              {translate('water_quality')}{' '}
            </Text>
            <Text
              style={{
                color: '#fff',
                fontSize: 16,
                fontFamily: fontFamilyRegular(),
              }}>
              {translate('maybe_not_clean')}.
            </Text>
            <Text
              style={{
                color: colorbaignade,
                fontSize: 16,
                fontFamily: fontFamilyRegular(),
              }}>
              {translate('discouraged')}
            </Text>
          </View>
        );
      case 2:
        colorbaignade = '#F50000';
        return (
          <View style={{marginTop: 20, paddingLeft: 10, flex: 2}}>
            <Text
              style={{
                color: '#fff',
                fontSize: 16,
                fontFamily: fontFamilyRegular(),
              }}>
              {translate('water_quality')} {translate('is')}
            </Text>
            <Text
              style={{
                color: '#fff',
                fontSize: 16,
                fontFamily: fontFamilyRegular(),
              }}>
              {translate('over_exposing_to_chimical_products')}
            </Text>
            <Text
              style={{
                color: colorbaignade,
                fontSize: 16,
                fontFamily: fontFamilyRegular(),
              }}>
              {translate('strongly_discouraged')}
            </Text>
          </View>
        );
    }
  };

  renderImg = () => {
    switch (parseInt(this.props.sys_status)) {
      case 0:
        return <Svg style={{paddingBottom: 5}} width={Dimensions.get('window').width / (getColumnNumber()) - 20}>
        <Image width={Dimensions.get('window').width / (getColumnNumber()) - 20} href={imgbaignade} />
      </Svg>;
      case 1:
        return (
          <Svg style={{paddingBottom: 5}} width={Dimensions.get('window').width / (getColumnNumber()) - 20} height={90}>
            <Image width={Dimensions.get('window').width / (getColumnNumber()) - 20} href={imgbaignadeorange} />
          </Svg>
        );
      case 2:
        return <Svg style={{paddingBottom: 5}} width={Dimensions.get('window').width / (getColumnNumber()) - 20}>
        <Image width={Dimensions.get('window').width / (getColumnNumber()) - 20} href={imgbaignaderouge} />
      </Svg>;
    }
  };
  render() {
    let imgbaignade = '';
    let colorbaignade = '';
    switch (parseInt(this.props.sys_status)) {
      case 0:
        imgbaignade = require('../../assets/imgbaignade1.svg');
        colorbaignade = '#00FFC2';
        break;
      case 1:
        imgbaignade = require('../../assets/imgbaignadeorange2.svg');
        colorbaignade = '#D06711';
        break;
      case 2:
        imgbaignade = require('../../assets/imgbaignaderouge3.svg');
        colorbaignade = '#F50000';
        break;
    }

    return (
      <LinearGradient
        colors={['#000000', isColor(colorbaignade)]}
        style={styles.containerLinearGradient}>
        <View style={styles.container}>
          <View style={styles.containerFixed}>
            <View
              style={{width: 50, backgroundColor: colorbaignade, height: 2}}
            />
            <Text style={styles.titrewidget}>{this.props.sensor_hash}</Text>
          </View>
          <View style={styles.bottomView}>{this.renderImg()}</View>
          <View style={styles.topViewtitle}>
            <Text
              style={{
                color: '#fff',
                borderTopColor: colorbaignade,
                borderTopWidth: 3,
                fontSize: 18,
                fontFamily: fontFamilyBold(),
                paddingTop: 15,
              }}>
              {translate('baignade')}
            </Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            {this.renderText()}
            <View style={{flex: 1.5, flexDirection: 'row'}}>
              <Text style={styles.s25}>
                {this.props[this.props.fav_source_field]}
              </Text>
              <Text style={styles.s10}>{this.props.sensor_unit}</Text>
            </View>
          </View>
        </View>
      </LinearGradient>
    );
  }
}

Baignade.propTypes = {
  sensor_alarm_max: PropTypes.string,
  sensor_alarm_min: PropTypes.string,
  sensor_calibration_date: PropTypes.string,
  sensor_calibration_point: PropTypes.string,
  sensor_date: PropTypes.string,
  sensor_hash: PropTypes.string,
  sensor_high_color: PropTypes.string,
  sensor_last_value: PropTypes.string,
  sensor_low_color: PropTypes.string,
  sensor_max: PropTypes.string,
  sensor_min: PropTypes.string,
  sensor_name: PropTypes.string,
  sensor_number: PropTypes.string,
  sensor_offset: PropTypes.string,
  sensor_security: PropTypes.string,
  sensor_status: PropTypes.string,
  sensor_type: PropTypes.string,
  sensor_unit: PropTypes.string,
  sensor_values: PropTypes.string,
  sensor_widget: PropTypes.string,
  sys_id: PropTypes.string,
  fav_source_field: PropTypes.string,
  sys_status: PropTypes.number,
};

Baignade.defaultProps = {
  sensor_alarm_max: '',
  sensor_alarm_min: '',
  sensor_calibration_date: '',
  sensor_calibration_point: '',
  sensor_date: '',
  sensor_hash: '',
  sensor_high_color: '',
  sensor_last_value: '',
  sensor_low_color: '',
  sensor_max: '',
  sensor_min: '',
  sensor_name: '',
  sensor_number: '',
  sensor_offset: '',
  sensor_security: '',
  sensor_status: '',
  sensor_type: '',
  sensor_unit: '',
  sensor_values: '',
  sensor_widget: '',
  fav_source_field: 'sensor_last_value',
  sys_id: '',
  sys_status: 0,
};
const styles = StyleSheet.create({
  containerLinearGradient: {
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
    padding: 1,
  },
  container: {
    flex: 1,
    borderWidth: 0,
    borderColor: '#fff',
    backgroundColor: '#121212',
    borderRadius: 10,
    height: 155,
  },
  bottomView: {
    width: '100%',
    position: 'absolute', //Here is the trick
    bottom: -2, //Here is the trick
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: 'hidden',
    marginLeft: -2,
  },
  topViewtitle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  s25: {
    fontSize: 40,
    color: '#fff',
    fontFamily: fontFamilyBold(),
  },
  s10: {
    fontSize: 20,
    color: '#fff',
    fontFamily: fontFamilyBold(),
  },
  containerFixed: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titrewidget: {
    textAlign: 'center',
    width: '100%',
    fontSize: 40,
    color: '#1E1E1E',
    fontFamily: fontFamilyArialBlack(),
    textTransform: 'uppercase',
    marginTop: 5,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Baignade);
