import React from 'react';
import {StyleSheet, Text, View, Dimensions} from 'react-native';
import {connect} from 'react-redux';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import {fontFamilyArialBlack, fontFamilyBold} from '../../Config/fonts';
import {isColor, getColumnNumber} from '../../Config/Config';
import translate from '../../translations/translate';
import BottomExtraValues from './BottomExtraValues';

class SimpleSensor extends React.Component {
  constructor(props) {
    super(props);

    let statusLabel = translate('too_high');

    if (
      props.sensor_min < props.sensor_last_value &&
      props.sensor_last_value < props.sensor_max
    ) {
      statusLabel = translate('good');
    } else if (props.sensor_min >= props.sensor_last_value) {
      statusLabel = translate('too_low');
    }

    let dataGraph = [];
    try {
      dataGraph = JSON.parse(props.sensor_values);
    } catch (e) {
      //console.log(e,this.props.sensor_values);
    }

    this.state = {
      dataGraph,
      statusLabel,
    };
  }

  resize = () => {
    this.setState({
      innerWidth: window.innerWidth
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  render() {
    let cerclepos = -5;

    if (
      parseFloat(this.props.sensor_min) <
        parseFloat(this.props.sensor_last_value) &&
      parseFloat(this.props.sensor_last_value) <
        parseFloat(this.props.sensor_max)
    ) {
      cerclepos =
        (parseFloat(this.props.sensor_last_value) /
          parseFloat(this.props.sensor_max)) *
        (Dimensions.get('window').width / 2 - 20);
      //   console.log("cerclepos 1 ",cerclepos)
      if (parseFloat(this.props.sensor_last_value) < 0) {
        let sensor_last_value = parseFloat(this.props.sensor_last_value);
        let sensor_max = parseFloat(this.props.sensor_max);
        let val = (sensor_max - sensor_last_value) / sensor_last_value;
        val = Math.abs(val);
        cerclepos = val * ((Dimensions.get('window').width / getColumnNumber()) - 45);
      }
    } else if (this.props.sensor_min >= this.props.sensor_last_value) {
      cerclepos = -5;
    } else {
      cerclepos = (Dimensions.get('window').width / getColumnNumber()) - 45;
    }
    let bgtitlecolor = '#3A57FB';

    return (
      <LinearGradient
        colors={['#121212', isColor(this.props.sensor_high_color)]}
        style={styles.containerLinearGradient}>
        <View style={styles.container}>
          <View style={styles.containerFixed}>
            <View
              style={{width: 50, backgroundColor: bgtitlecolor, height: 2}}
            />
            <Text style={styles.titrewidget}>{this.props.sensor_hash}</Text>
          </View>
          <View style={styles.topViewtitle}>
            <Text
              style={{
                color: this.props.sensor_high_color,
                borderTopColor: this.props.sensor_high_color,
                borderTopWidth: 3,
                fontSize: 16,
                fontFamily: fontFamilyBold(),
                paddingTop: 10,
              }}>
              {this.props.sensor_name}
            </Text>
          </View>
          <View style={{flexDirection: 'row', marginTop: 10, marginRight: 10}}>
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 10,
                paddingRight: 10,
                height: 110,
              }}>
              <Text
                style={{
                  color: '#fff',
                  fontSize: 12,
                  fontFamily: fontFamilyBold(),
                }}>
                {translate(this.props.sensor_text)}
              </Text>
              <Text
                style={{
                  color: '#fff',
                  fontSize: 12,
                  fontFamily: fontFamilyBold(),
                }}>
                {this.props.sensor_last_value}
              </Text>
              <View style={{flex: 1, flexDirection: 'row', width: '100%'}}>
                <View style={{flex: 1}}>
                  <Text
                    style={{color: '#fff', fontSize: 10, textAlign: 'left'}}>
                    {this.props.sensor_min}
                  </Text>
                </View>
                <View style={{flex: 1}}>
                  <Text
                    style={{color: '#fff', fontSize: 10, textAlign: 'right'}}>
                    {this.props.sensor_max}
                  </Text>
                </View>
              </View>
              <LinearGradient
                start={{x: 0.0, y: 0.0}}
                end={{x: 1, y: 1.0}}
                colors={['#FFFFFF', '#3A57FB']}
                style={{marginLeft: 10, height: 2, width: (Dimensions.get('window').width / getColumnNumber()) - 40}}
              />
              <View style={{flex: 1, width: '100%'}}>
                <View style={{position: 'absolute', left: cerclepos, top: -5}}>
                  <View
                    style={[
                      styles.CircleShapeView,
                      {
                        borderColor: this.props.sensor_high_color,
                        backgroundColor: this.props.sensor_high_color,
                      },
                    ]}
                  />
                </View>
              </View>
              <View style={{flex: 1}}>
                <Text style={{color: '#9C9C9C', fontSize: 14, marginTop: -10}}>
                  {this.props.sensor_unit}
                </Text>
              </View>
            </View>
          </View>
          <BottomExtraValues
            name2={this.props.sensor_name_2}
            value2={this.props.sensor_live_2}
            unit2={this.props.sensor_unit_2}
            name3={this.props.sensor_name_3}
            value3={this.props.sensor_live_3}
            unit3={this.props.sensor_unit_3}
          />
        </View>
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  topViewtitle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerFixed: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titrewidget: {
    textAlign: 'center',
    fontSize: 40,
    color: '#1E1E1E',
    fontFamily: fontFamilyArialBlack(),
    textTransform: 'uppercase',
    marginTop: 10,
  },
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,
  },
  containerLinearGradient: {
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
    padding: 1,
  },
  CircleShapeView: {
    width: 10,
    height: 10,
    borderRadius: 10 / 2,
    borderWidth: 1,
    borderColor: '#BD10E0',
    backgroundColor: '#BD10E0',
  },
});

SimpleSensor.propTypes = {
  sensor_alarm_max: PropTypes.string,
  sensor_alarm_min: PropTypes.string,
  sensor_calibration_date: PropTypes.string,
  sensor_calibration_point: PropTypes.string,
  sensor_date: PropTypes.string,
  sensor_high_color: PropTypes.string,
  sensor_last_value: PropTypes.string,
  sensor_low_color: PropTypes.string,
  sensor_max: PropTypes.number,
  sensor_min: PropTypes.number,
  sensor_name: PropTypes.string,
  sensor_number: PropTypes.string,
  sensor_offset: PropTypes.string,
  sensor_security: PropTypes.string,
  sensor_status: PropTypes.string,
  sensor_type: PropTypes.string,
  sensor_unit: PropTypes.string,
  sensor_values: PropTypes.string,
  sensor_widget: PropTypes.string,
  sensor_text: PropTypes.string,
  sensor_unit_3: PropTypes.string,
  sensor_live_3: PropTypes.string,
  sensor_name_3: PropTypes.string,
  sensor_unit_2: PropTypes.string,
  sensor_live_2: PropTypes.string,
  sensor_name_2: PropTypes.string,
};
SimpleSensor.defaultProps = {
  sensor_alarm_max: '',
  sensor_alarm_min: '',
  sensor_calibration_date: '',
  sensor_calibration_point: '',
  sensor_date: '',
  sensor_high_color: '#BD10E0',
  sensor_last_value: '0',
  sensor_low_color: '#BD10E0',
  sensor_max: '0',
  sensor_min: '0',
  sensor_name: '',
  sensor_number: '',
  sensor_offset: '',
  sensor_security: '',
  sensor_status: '',
  sensor_type: '',
  sensor_unit: 'L/h',
  sensor_values: '',
  sensor_widget: '',
  sensor_text: '',
};
const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SimpleSensor);
