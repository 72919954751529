import * as RegistrationActions from './RegistrationActions';
import * as LoginActions from '../Login/LoginActions';

const initialState = {
  step1: null,
  step2: null,
  step3: null,
  step4: null,
};

export default function RegistrationReducer(state = initialState, action) {
  switch (action.type) {
    case LoginActions.ACTION_USER_LOGOUT:
      return initialState;
    case RegistrationActions.ACTION_REGISTER_USER_STEP1:
      return {...state, step1: action.step1};
    case RegistrationActions.ACTION_REGISTER_USER_STEP2:
      return {...state, step2: action.step2};
    case RegistrationActions.ACTION_REGISTER_USER_STEP3:
      return {...state, step3: action.step3};
    case RegistrationActions.ACTION_REGISTER_USER_STEP4:
      return {
        step4: action.step4,
        step1: null,
        step2: null,
        step3: null,
      };
    default:
      return state;
  }
}
