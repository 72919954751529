import React, {useState, useEffect} from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Modal,
  SafeAreaView,
  Alert,
} from 'react-native';
import {connect} from 'react-redux';
import {Header, Icon, Input, Button} from 'react-native-elements';

import ProgressCircle from '../components/ProgressCircle';
import translate, {translateString} from '../translations/translate';
import {fontFamilyBold, fontFamilyRegular} from '../Config/fonts';

import {appHeaderHeight} from '../Config/Config';
import * as actions from '../Store/Calibration';
import ThreeDotsLoader from '../components/ThreeDotsLoader';
import HeaderLeftComponent from '../components/HeaderLeftComponent';
import Loader from '../components/Loader';

class Calibration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sensor: {},
      error: '',
      value: 0,
      currentPoint: 1,
      totalTimer: 90,
      currentTimer: 90,
      startTimer: false,
      timerModalOpen: false,
      longPressProgressVal: {
        ispressed: false,
        Interval: null,
      },
    };
  }
  async componentDidMount() {
    let params = this.props.route.params;

    let _sensor = params.sensorDetail;
    this.setState({
      sensor: _sensor,
      sensor_name: _sensor.sensor_name,
      sensor_number: _sensor.sensor_number,
      sensor_status: _sensor.sensor_status,
    });
  }

  componentDidUpdate(oldProps) {
    if (
      this.props.result &&
      oldProps.result !== this.props.result &&
      this.props.status === 'started'
    ) {
      // We started a calibration and we just got a result
      this.setState({
        result: this.props.result,
      });
    }
  }

  componentWillUnmount() {
    let wasInterval = false;
    if (this.interval) {
      wasInterval = true;
      clearInterval(this.interval);
    }

    if (
      this.props.status === 'finished' &&
      this.props.result &&
      this.state.currentPoint === 2
    ) {
      return;
    }

    const {sys_appid} = this.props;
    this.props.cancelCalibration(
      sys_appid,
      this.props.route.params.sensorNumber,
    );
  }

  goToBack = async () => {
    Alert.alert(
      await translateString('Calibration'),
      await translateString('Voulez vous annuler la calibration ?'),
      [
        {
          text: await translateString('Non'),
          onPress: () => {},
          style: 'cancel',
        },
        {
          text: await translateString('Oui'),
          onPress: this.props.navigation.goBack,
        },
      ],
      {cancelable: false},
    );
  };

  startCalibration = async () => {
    if (this.state.startTimer || this.interval) {
      return false;
    }

    this.setState({
      error: '',
    });

    //Call the Socket action
    const {sys_appid} = this.props;
    const {value, totalTimer} = this.state;

    try {
      const res = await this.props.startCalibration(
        sys_appid,
        this.props.route.params.sensorNumber,
        this.state.currentPoint,
        value,
        totalTimer,
      );
      if (!res.ok) {
        const error = await translateString('Impossible to contact hardware');
        this.setState({
          error,
        });

        return;
      }
    } catch (err) {
      console.log(err);
      this.setState({
        error: err,
      });

      return;
    }

    this.setState({
      error: '',
      startTimer: Date.now(),
    });

    this.interval = setInterval(() => {
      const currentTimer = this.computeCurrentTimer();

      this.setState({
        currentTimer,
      });

      if (currentTimer <= 0) {
        clearInterval(this.interval);
        this.interval = null;
        this.setState({
          startTimer: false,
        });
        // Wait for the result to come from the websocket
      }
    }, 1000);
  };

  continueCalibration = () => {
    this.setState({
      value: 0,
      currentPoint: 2,
    });

    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }

    this.props.resetCalibration(this.props.route.params.sensorNumber);
  };

  resetCalibration = () => {
    this.setState({
      error: '',
      value: 0,
      currentPoint: 1,
      totalTimer: 90,
      currentTimer: 90,
    });

    this.props.resetCalibration(this.props.route.params.sensorNumber);
    const {sys_appid} = this.props;
    this.props.cancelCalibration(
      sys_appid,
      this.props.route.params.sensorNumber,
    );
  };

  end = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    this.props.navigation.reset({
      index: 0,
      routes: [{name: 'Home'}],
    });
  };

  computeCurrentTimer = () => {
    const diff = Date.now() - this.state.startTimer;
    const timeLeft = this.state.totalTimer - Math.round(diff / 1000);

    return Math.max(timeLeft, 0);
  };

  UpdateprogressVal = operation => {
    let val = parseInt(this.state.totalTimer);

    let step = 1;
    if (val >= 100) {
      step = 10;
    } else if (val >= 10 && val < 100) {
      step = 1;
    }

    if (operation === '-') {
      if (val - step > 0) {
        val = val - step;
      } else {
        val = 0;
      }
    } else {
      val = val + step;
    }

    this.setState({totalTimer: val, currentTimer: val});
  };

  closeModal = () => {
    this.setState({
      timerModalOpen: false,
    });
  };

  EndUpdateonLongPressprogressVal = op => {
    if (this.state.longPressProgressVal.ispressed) {
      clearInterval(this.state.longPressProgressVal.Interval);
      this.setState({
        longPressProgressVal: {
          ispressed: false,
          Interval: null,
        },
      });
    }
  };

  UpdateonLongPressprogressVal = op => {
    if (!this.state.longPressProgressVal.ispressed) {
      var myVar = setInterval(() => {
        this.UpdateprogressVal(op);
      }, 100);
      this.setState({
        longPressProgressVal: {
          ispressed: true,
          Interval: myVar,
        },
      });
    }
  };

  RenderModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.timerModalOpen}
        onRequestClose={() => {}}>
        <View style={styles.Modalcontainer}>
          <View
            style={{
              justifyContent: 'center',
              backgroundColor: '#000000',
              borderRadius: 20,
              padding: 15,
              width: '90%',
            }}>
            <View
              style={{position: 'absolute', right: 10, top: 10, zIndex: 20}}>
              <TouchableOpacity onPress={() => this.closeModal()}>
                <Icon
                  size={20}
                  containerStyle={{marginLeft: 10}}
                  name="check"
                  type="font-awesome"
                  color="#fff"
                />
              </TouchableOpacity>
            </View>
            <View>
              <View style={[styles.ViewRow, {marginTop: 30, marginBottom: 30}]}>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <TouchableOpacity
                    onPress={() => this.UpdateprogressVal('-')}
                    delayLongPress={1000}
                    onPressIn={() => {
                      //console.log('TouchableOpacity onPressIn');
                      // console.log(Date.now())
                    }}
                    onPressOut={() => {
                      // console.log('TouchableOpacity onPressOut');
                      //console.log(Date.now())
                      this.EndUpdateonLongPressprogressVal('-');
                    }}
                    onLongPress={e => {
                      //console.log(' onLongPress');
                      //console.log(Date.now());

                      this.UpdateonLongPressprogressVal('-');
                    }}>
                    <View style={styles.CircleShapeView}>
                      <Icon
                        iconStyle={{marginTop: 0, marginLeft: 0}}
                        name="minus"
                        type="font-awesome"
                        size={20}
                        underlayColor={'#000'}
                        color={'#000'}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <View style={{flexDirection: 'row'}}>
                    <Text
                      style={{
                        fontSize: 30,
                        fontFamily: fontFamilyBold(),
                        color: '#fff',
                      }}>
                      {this.state.totalTimer}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <TouchableOpacity
                    delayLongPress={1000}
                    onPress={() => {
                      this.UpdateprogressVal('+');
                    }}
                    onPressIn={() => {
                      //console.log('TouchableOpacity onPressIn');
                      // console.log(Date.now())
                    }}
                    onPressOut={() => {
                      // console.log('TouchableOpacity onPressOut');
                      //console.log(Date.now())
                      this.EndUpdateonLongPressprogressVal('+');
                    }}
                    onLongPress={e => {
                      //console.log(' onLongPress');
                      //console.log(Date.now());

                      this.UpdateonLongPressprogressVal('+');
                    }}>
                    <View style={styles.CircleShapeView}>
                      <Icon
                        iconStyle={{marginTop: 0, marginLeft: 0}}
                        name="plus"
                        type="font-awesome"
                        size={20}
                        underlayColor={'#000'}
                        color={'#000'}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    );
  };

  render() {
    const currentTimer = this.state.currentTimer;

    return (
      <SafeAreaView style={styles.containerfull}>
        {this.RenderModal()}
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          leftComponent={<HeaderLeftComponent goToBack={this.goToBack} />}
        />
        <ScrollView contentContainerStyle={{flexGrow: 1}}>
          <View>
            <Text style={styles.enteteText}>{this.state.sensor_name}</Text>
          </View>

          <View style={styles.container}>
            <View>
              {this.state.isWaitToRemove ? <ThreeDotsLoader /> : <View />}
            </View>
            <TouchableOpacity
              onPress={() => this.setState({timerModalOpen: true})}>
              <View
                style={{
                  paddingBottom: 20,
                  paddingTop: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <ProgressCircle
                  percent={
                    currentTimer
                      ? (currentTimer / this.state.totalTimer) * 100
                      : 100
                  }
                  radius={80}
                  borderWidth={5}
                  color={this.state.sensor.sensor_low_color}
                  shadowColor="#000000"
                  bgColor="#000000">
                  <View style={{flexDirection: 'row'}}>
                    <Text
                      style={{
                        fontSize: 30,
                        fontFamily: fontFamilyRegular(),
                        fontWeight: '500',
                        color: '#fff',
                      }}>
                      {this.state.startTimer
                        ? currentTimer
                        : this.state.totalTimer}
                    </Text>
                  </View>
                </ProgressCircle>
              </View>
            </TouchableOpacity>
            <View>
              <View style={{flex: 1, paddingLeft: 10}}>
                <Text
                  style={{
                    fontSize: 18,
                    color: '#fff',
                    fontFamily: fontFamilyBold(),
                  }}>
                  {translate('Bath n°')} {this.state.currentPoint}
                </Text>
              </View>
              <View style={{flex: 1, width: '100%', paddingBottom: 15}}>
                <Input
                  keyboardType={'numeric'}
                  onChangeText={val =>
                    this.setState({
                      value: val,
                    })
                  }
                  value={this.state.value}
                  containerStyle={styles.FormInputcontainerStyle}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[styles.FormTextInput]}
                  placeholder=""
                />
              </View>
            </View>
            {!!this.props.result && this.props.status === 'finished' && (
              <Text style={{color: 'white'}}>
                {translate('Raw sensor value is')} : {this.props.rawValue}
              </Text>
            )}
            {!this.props.result &&
              this.props.connected &&
              this.props.status !== 'started' && (
                <Button
                  buttonStyle={styles.calibrationBtn}
                  title={translate('Start calibration')}
                  onPress={() => this.startCalibration()}
                />
              )}
            {!this.props.result && this.props.status === 'finished' && (
              <View
                style={{marginTop: 20, display: 'flex', alignItems: 'center'}}>
                <Text
                  style={{
                    color: 'red',
                  }}>
                  {translate('Error: Canceling calibration')}
                  <Button
                    buttonStyle={styles.calibrationBtn}
                    title={translate('Reset')}
                    onPress={() => this.resetCalibration()}
                  />
                </Text>
              </View>
            )}
            {!this.props.result && this.props.status === 'started' && (
              <Loader />
            )}
            {!!this.props.result &&
              this.state.currentPoint === 1 &&
              this.props.connected &&
              this.props.status === 'finished' && (
                <Button
                  buttonStyle={styles.calibrationBtn}
                  title={translate('Continue calibration')}
                  onPress={() => this.continueCalibration()}
                />
              )}
            {!!this.props.result &&
              this.state.currentPoint === 2 &&
              this.props.connected &&
              this.props.status === 'finished' && (
                <Button
                  buttonStyle={styles.calibrationBtn}
                  title={translate('Finish')}
                  onPress={() => this.end()}
                />
              )}
            {this.state.error ? (
              <Text style={styles.Error}>{this.state.error}</Text>
            ) : null}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
  },
  FormInputcontainerStyleName: {
    borderBottomWidth: 0,
    marginBottom: 0,
  },
  ViewRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Error: {
    textAlign: 'center',
    color: 'red',
    marginTop: 5,
  },
  FormInputContainerStyleName: {
    marginBottom: 10,
    height: 50,
    borderRadius: 50,
    borderBottomWidth: 1,
    borderBottomColor: '#eaeaea',
    color: '#fff',
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
  enteteText: {
    fontSize: 30,
    fontWeight: '300',
    padding: 10,
    textAlign: 'center',
    color: '#ffffff',
    fontFamily: fontFamilyRegular(),
  },
  container: {
    flex: 1,
    backgroundColor: '#000004',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  widgetView: {
    backgroundColor: '#121212',
    borderRadius: 10,
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  FormTextInputName: {
    height: 50,
    fontSize: 30,
    color: '#fff',
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
    textAlign: 'center',
  },
  FormTextInput: {
    height: 43,
    fontSize: 14,
    width: '100%',
    textAlign: 'center',
    color: '#fff',
    fontFamily: fontFamilyBold(),
    fontWeight: 'normal',
  },
  FormInputcontainerStyle: {
    borderBottomWidth: 0,
    width: '100%',
  },
  FormInputContainerStyle: {
    height: 43,
    fontSize: 14,
    borderRadius: 50,
    borderWidth: 1,
    backgroundColor: '#242424',
    borderColor: '#242424',
    textAlign: 'center',
    color: '#fff',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    fontFamily: fontFamilyBold(),
    fontWeight: 'normal',
  },
  Picker: {
    fontSize: 12,
    color: '#fff',
    height: 40,
    fontFamily: 'arial',
  },
  ViewPicker: {
    fontSize: 14,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#242424',
    backgroundColor: '#242424',
    color: '#fff',
    paddingLeft: 10,
    marginTop: 5,
    fontFamily: 'arial',
  },
  Modalcontainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(154, 152, 152, 0.84)',
    flexDirection: 'column',
  },
  CircleShapeView: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    borderRadius: 80 / 2,
    borderWidth: 1,
    borderColor: '#fff',
    backgroundColor: '#fff',
  },
  calibrationBtn: {
    backgroundColor: '#FF7900',
    borderRadius: 20,
    marginTop: 20,
    marginLeft: 31,
    marginRight: 31,
  },
});

const mapStateToProps = (state, ownProps) => {
  const sensorNumber = ownProps.route.params.sensorNumber;
  return {
    sys_appid:
      state.SystemsRedux.system &&
      state.SystemsRedux.system.system[0].sys_appid,
    point: state.Calibration[sensorNumber]?.point,
    status: state.Calibration[sensorNumber]?.status,
    result: state.Calibration[sensorNumber]?.result,
    rawValue: state.Calibration[sensorNumber]?.rawValue,
    connected: state.SocketReducer.connected,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startCalibration: (appId, sensorNumber, point, value, time) => {
      return dispatch(
        actions.startCalibration(appId, sensorNumber, point, value, time),
      );
    },
    cancelCalibration: (appId, sensorNumber) => {
      return dispatch(actions.cancelCalibration(appId, sensorNumber));
    },
    resetCalibration: sensorNumber => {
      return dispatch(actions.resetCalibration(sensorNumber));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Calibration);
