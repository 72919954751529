import React from 'react';
import {StyleSheet, Text, View, Modal, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Icon, Image} from 'react-native-elements';
import {fontFamilyBold, fontFamilyRegular} from '../../Config/fonts';
import LottieView from 'lottie-react-native';
import translate from '../../translations/translate';

class Meteo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  componentWillUnmount() {}
  closeModal = () => {
    this.setState({modalVisible: false});
  };
  OpenModal = () => {
    console.log('OPEN MODAL??');
    this.setState({modalVisible: true});

    return true;
  };

  renderMeteoAnnimation = () => {
    let isDay = this.props.type === 'd';

    if (isDay) {
      return this.renderMeteoAnnimationDay();
    } else {
      return this.renderMeteoAnnimationNuit();
    }
  };
  renderMeteoAnnimationNuit = () => {
    let weather = this.props.sys_icon.split(this.props.type)[0];

    console.log('weather ', weather, this.props.type);

    let ViewAnimation = [];

    if (weather === '01' || weather === '02' || weather === '10') {
      ViewAnimation.push(
        <LottieView
          style={{
            width: 80,
            height: 100,
            position: 'absolute',
          }}
          source={require('../../assets/nuit_lune.json')}
          autoPlay
          loop
          speed={3}
        />,
      );
    }

    if (weather === '09' || weather === '10' || weather === '13') {
      ViewAnimation.push(
        <LottieView
          style={{
            width: 30,
            height: 30,
            position: 'absolute',
            left: '20%',
          }}
          source={require('../../assets/cloud_.json')}
          autoPlay
          loop
          speed={1}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 80,
            height: 80,
            position: 'absolute',
            left: '50%',
            top: 15,
          }}
          source={require('../../assets/cloud_.json')}
          autoPlay
          loop
          speed={1}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 100,
            height: 100,
            position: 'absolute',
            left: '0%',
            top: 10,
          }}
          source={require('../../assets/pluie.json')}
          autoPlay
          loop
          speed={3}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 100,
            height: 100,
            position: 'absolute',
            left: '50%',
          }}
          source={require('../../assets/pluie.json')}
          autoPlay
          loop
          speed={3}
        />,
      );
    }

    if (weather === '11') {
      ViewAnimation.push(
        <LottieView
          style={{
            width: 30,
            height: 30,
            position: 'absolute',
            left: '20%',
          }}
          source={require('../../assets/cloud_.json')}
          autoPlay
          loop
          speed={1}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 80,
            height: 80,
            position: 'absolute',
            left: '50%',
            top: 15,
          }}
          source={require('../../assets/pluie_avec_eclair.json')}
          autoPlay
          loop
          speed={3}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 100,
            height: 100,
            position: 'absolute',
            left: '0%',
            top: 10,
          }}
          source={require('../../assets/pluie_avec_eclair.json')}
          autoPlay
          loop
          speed={3}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 100,
            height: 100,
            position: 'absolute',
            left: '50%',
          }}
          source={require('../../assets/pluie_avec_eclair.json')}
          autoPlay
          loop
          speed={3}
        />,
      );
    }

    if (
      weather === '02' ||
      weather === '03' ||
      weather === '04' ||
      weather === '50'
    ) {
      ViewAnimation.push(
        <LottieView
          style={{
            width: 30,
            height: 30,
            position: 'absolute',
            left: '20%',
          }}
          source={require('../../assets/nuage_g.json')}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 80,
            height: 80,
            position: 'absolute',
            left: '50%',
            top: 15,
          }}
          source={require('../../assets/nuage_g.json')}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 100,
            height: 100,
            position: 'absolute',
            left: '5%',
            top: 10,
          }}
          source={require('../../assets/nuage_g.json')}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 100,
            height: 100,
            position: 'absolute',
            left: '50%',
          }}
          source={require('../../assets/nuage_g.json')}
        />,
      );
    }

    return ViewAnimation;
  };

  renderMeteoAnnimationDay = () => {
    let weather = this.props.sys_icon.split(this.props.type)[0];
    // weather='09';
    console.log('weather ', weather, this.props.type);
    let ViewAnimation = [];
    if (weather === '01' || weather === '02' || weather === '10') {
      ViewAnimation.push(
        <LottieView
          style={{
            width: 80,
            height: 100,
            position: 'absolute',
          }}
          source={require('../../assets/sol.json')}
          autoPlay
          loop
          speed={3}
        />,
      );
    }

    if (weather === '09' || weather === '10' || weather === '13') {
      ViewAnimation.push(
        <LottieView
          style={{
            width: 30,
            height: 30,
            position: 'absolute',
            left: '20%',
          }}
          source={require('../../assets/cloud_.json')}
          autoPlay
          loop
          speed={1}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 80,
            height: 80,
            position: 'absolute',
            left: '50%',
            top: 15,
          }}
          source={require('../../assets/cloud_.json')}
          autoPlay
          loop
          speed={1}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 100,
            height: 100,
            position: 'absolute',
            left: '0%',
            top: 10,
          }}
          source={require('../../assets/pluie.json')}
          autoPlay
          loop
          speed={3}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 100,
            height: 100,
            position: 'absolute',
            left: '50%',
          }}
          source={require('../../assets/pluie.json')}
          autoPlay
          loop
          speed={3}
        />,
      );
    }

    if (weather === '11') {
      ViewAnimation.push(
        <LottieView
          style={{
            width: 30,
            height: 30,
            position: 'absolute',
            left: '20%',
          }}
          source={require('../../assets/cloud_.json')}
          autoPlay
          loop
          speed={1}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 80,
            height: 80,
            position: 'absolute',
            left: '50%',
            top: 15,
          }}
          source={require('../../assets/pluie_avec_eclair.json')}
          autoPlay
          loop
          speed={3}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 100,
            height: 100,
            position: 'absolute',
            left: '0%',
            top: 10,
          }}
          source={require('../../assets/pluie_avec_eclair.json')}
          autoPlay
          loop
          speed={3}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 100,
            height: 100,
            position: 'absolute',
            left: '50%',
          }}
          source={require('../../assets/pluie_avec_eclair.json')}
          autoPlay
          loop
          speed={3}
        />,
      );
    }

    if (
      weather === '02' ||
      weather === '03' ||
      weather === '04' ||
      weather === '50'
    ) {
      ViewAnimation.push(
        <LottieView
          style={{
            width: 30,
            height: 30,
            position: 'absolute',
            left: '20%',
          }}
          source={require('../../assets/nuage_g.json')}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 80,
            height: 80,
            position: 'absolute',
            left: '50%',
            top: 15,
          }}
          source={require('../../assets/nuage_g.json')}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 100,
            height: 100,
            position: 'absolute',
            left: '5%',
            top: 10,
          }}
          source={require('../../assets/nuage_g.json')}
        />,
      );

      ViewAnimation.push(
        <LottieView
          style={{
            width: 100,
            height: 100,
            position: 'absolute',
            left: '50%',
          }}
          source={require('../../assets/nuage_g.json')}
        />,
      );
    }

    return ViewAnimation;
  };

  render() {
    let imgsource =
      this.props.type === 'd'
        ? require('../../assets/meteo-jour.png')
        : require('../../assets/meteo-nuit.png');

    let stylebg = [];
    stylebg.push(styles.imgBackground);
    if (this.props.time === 'd') {
      stylebg.push(styles.imgBackgroundJour);
    } else {
      stylebg.push(styles.imgBackgroundNuit);
    }

    return (
      <View
        style={stylebg}
        imageStyle={{borderRadius: 10}}
        resizeMode="cover"
        source={imgsource}
        onPress={() => this.OpenModal()}>
        {this.renderMeteoAnnimation()}

        <View style={styles.container}>
          <View style={styles.containerFixed}>
            <Text style={styles.subtitle}>{this.props.sys_weather}</Text>
            <View style={{justifyContent: 'center', alignItems: 'center'}}>
              <View style={{flexDirection: 'row'}}>
                <Text style={styles.val}>{this.props.sys_temperature}</Text>
                <View style={{position: 'absolute', left: 100}}>
                  {this.props.unit === 'metric' ? (
                    <Text style={styles.degre}>°C</Text>
                  ) : (
                    <Text style={styles.degre}>°F</Text>
                  )}
                </View>
              </View>
              <Text
                style={{
                  fontFamily: fontFamilyBold(),
                  color: '#fff',
                  textAlign: 'center',
                  marginBottom: 5,
                }}>
                {this.props.sys_sky}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: '#fff',
              marginLeft: 20,
              marginRight: 20,
              flexDirection: 'row',
            }}>
            <View style={{flex: 2}}>
              <Text style={{textAlign: 'center', color: '#fff'}} />
              <View
                style={{
                  borderRightWidth: 1,
                  borderRightColor: '#fff',
                  paddingLeft: 10,
                }}>
                <Text style={{color: '#fff', padding: 5}}>
                  {translate('pression')} :
                </Text>
                <Text style={{color: '#fff', padding: 5}}>
                  {translate('max')} :
                </Text>
                <Text style={{color: '#fff', padding: 5}}>
                  {translate('vent')} :
                </Text>
                <Text style={{color: '#fff', padding: 5}}>
                  {translate('humidity')} :
                </Text>
                <Text style={{color: '#fff', padding: 5}}>
                  {translate('nuages')} :
                </Text>
              </View>
            </View>

            {this.props.meteoFutur.map((meteo, index) => {
              return (
                <View style={{flex: 1}}>
                  <Text style={{textAlign: 'center', color: '#fff'}}>
                    {meteo.hours}:00
                  </Text>
                  <View
                    style={{
                      borderRightWidth: 1,
                      borderRightColor: index == 0 ? '#fff' : 'none',
                      paddingLeft: 10,
                      marginBottom: 5,
                    }}>
                    <Text style={{color: '#fff', padding: 5}}>
                      {meteo.weather.sys_pressure}
                    </Text>
                    <Text style={{color: '#fff', padding: 5}}>
                      {meteo.weather.sys_max_temp}
                    </Text>
                    <Text style={{color: '#fff', padding: 5}}>
                      {meteo.weather.sys_wind}
                    </Text>
                    <Text style={{color: '#fff', padding: 5}}>
                      {meteo.weather.sys_humidity}
                    </Text>
                    <Text style={{color: '#fff', padding: 5}}>
                      {meteo.weather.sys_clouds}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  imgBackground: {
    flex: 1,

    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingBottom: 10,
  },
  imgBackgroundJour: {
    backgroundColor: 'rgba(41, 159, 255, 0.8)',
  },
  imgBackgroundNuit: {
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
  },
  titrewidget: {
    textAlign: 'center',
    fontSize: 40,
    color: '#289EFF',
    fontFamily: fontFamilyBold(),
    textTransform: 'uppercase',
    marginTop: 10,
  },
  subtitle: {
    fontSize: 18,
    marginTop: -10,
    color: '#fff',
    fontFamily: fontFamilyBold(),
    textAlign: 'center',
  },
  btnadd: {
    fontSize: 30,
    color: '#fff',
    fontFamily: fontFamilyBold(),
    textAlign: 'right',
  },
  val: {
    fontSize: 40,
    color: '#fff',
    fontFamily: fontFamilyBold(),
    textShadowColor: 'rgba(0, 0, 0, 0.75)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 10,
  },
  s40: {
    fontSize: 40,
    color: '#fff',
    fontFamily: fontFamilyRegular(),
  },
  s16: {
    fontSize: 16,
    color: '#fff',
    fontFamily: fontFamilyRegular(),
  },
  s25: {
    fontSize: 25,
    color: '#595959',
    fontFamily: fontFamilyRegular(),
  },
  s10: {
    fontSize: 10,
    color: '#595959',
    fontFamily: fontFamilyRegular(),
  },
  degre: {
    fontSize: 20,
    color: '#fff',
    fontFamily: fontFamilyRegular(),
  },
  Modalcontainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(154, 152, 152, 0.84)',
    flexDirection: 'column',
  },
  Modalval: {
    fontSize: 20,
    color: '#fff',
    fontFamily: fontFamilyRegular(),
  },
  Modaltitle: {
    fontSize: 16,
    color: '#fff',
    fontFamily: fontFamilyRegular(),
  },
  Modaldegre: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#fff',
    fontFamily: fontFamilyRegular(),
  },
  containerFixed: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 35,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

Meteo.propTypes = {
  sys_clouds: PropTypes.string,
  sys_forecast_date: PropTypes.string,
  sys_humidity: PropTypes.string,
  sys_icon: PropTypes.string,
  sys_icon_link: PropTypes.string,
  sys_max_temp: PropTypes.string,
  sys_pressure: PropTypes.string,
  sys_sky: PropTypes.string,
  sys_temp_min: PropTypes.string,
  sys_temperature: PropTypes.string,
  sys_weather: PropTypes.string,
  sys_wind: PropTypes.string,
  meteoFutur: PropTypes.array,
  time: PropTypes.string,
  unit: PropTypes.string,
};
Meteo.defaultProps = {
  sys_clouds: '',
  sys_forecast_date: '',
  sys_humidity: '',
  sys_icon: '',
  sys_icon_link: '',
  sys_max_temp: '',
  sys_pressure: '',
  sys_sky: '',
  sys_temp_min: '',
  sys_temperature: '',
  sys_weather: '',
  sys_wind: '',
  time: '',
  unit: '',
  meteoFutur: [],
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Meteo);
