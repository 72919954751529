import React, {isValidElement} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Colors,
  ScrollView,
  TouchableHighlight,
  TouchableOpacity,
  RefreshControl,
  SafeAreaView,
  Dimensions,
} from 'react-native';
import {connect} from 'react-redux';
import {Header, Icon, Image} from 'react-native-elements';
// import SplashScreen from 'react-native-splash-screen';

import Meteo from '../../components/widget/Meteo';
import Raccourcie from '../../components/widget/Raccourcie';
import Baignade from '../../components/widget/Baignade';
import Question from '../../components/widget/Question';
import Pompe from '../../components/widget/Pompe';
import MachineUnlock from '../../components/widget/MachineUnlock';
import FlowSwitch from '../../components/widget/FlowSwitch';
import GraphSensor from '../../components/widget/GraphSensor';
import Temperature from '../../components/widget/Temperature';
import GaugeChart from '../../components/GaugeChart';
import PropTypes from 'prop-types';
import moment from 'moment';
// import {FlatGrid} from 'react-native-super-grid';
import {
  setStoreData,
  getStoreDataBool,
  getStoreDataPromise,
  appHeaderHeight,
  appHeaderTop,
  msg_code_to_text,
  ShowSnaskMessage,
  APP_BAR_ICON_SIZE,
  LEFT_HIT_SLOP,
} from '../../Config/Config';
import Loader from '../../components/Loader';
import * as actions from '../../Store/Systems/SystemsActions';
import * as actionsApp from '../../Store/App/AppActions';
import {fontFamilyBold, fontFamilyRegular} from '../../Config/fonts';
import RemoteControlle from '../../components/widget/RemoteControlle';
import WidgetDevice from '../../components/WidgetDevice';
import WidgetSensor from '../../components/WidgetSensor';
import WidgetStrip from '../../components/WidgetStrip';
import GaugeSensor from '../../components/widget/GaugeSensor';
import { getColumnNumber } from '../../Config/Config.web';

class HeaderLeftComponent extends React.Component {
  render() {
    return (
      <>
        <TouchableOpacity
          onPress={() => this.props.navigation.navigate('Alertes')}
          hitSlop={LEFT_HIT_SLOP}>
          <Icon
            name="exclamation-triangle"
            type="font-awesome"
            color={
              this.props.current_system.alarm &&
              this.props.current_system.alarm.filter(
                i => i.alarm_status === '0',
              ).length > 0
                ? 'red'
                : '#fff'
            }
            size={APP_BAR_ICON_SIZE}
          />
        </TouchableOpacity>
      </>
    );
  }
}
class HeaderrightComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalUnlock: false,
    };
  }
  unlock = () => {
    if (!this.props.isAdmin) {
      this.setState({modalUnlock: true});
    } else {
      this.props.CloseApp();
    }
  };
  closeModal = () => {
    this.setState({modalUnlock: false});
  };
  callBackModal = () => {
    this.setState({modalUnlock: false});
    this.props.ModalUnlock();
  };
  render() {
    const userType = this.props.userType;
    return (
      <>
        <View
          style={{flexDirection: 'row', paddingBottom: 0, paddingRight: 10}}>
          <TouchableOpacity
            onPress={this.unlock}
            hitSlop={{top: 10, left: 30, right: 2, bottom: 30}}>
            <Icon
              containerStyle={{paddingRight: 30}}
              name={this.props.isAdmin ? 'unlock-alt' : 'lock'}
              type="font-awesome"
              color="#fff"
              size={APP_BAR_ICON_SIZE}
            />
          </TouchableOpacity>

          <TouchableOpacity
            hitSlop={{top: 10, left: 5, right: 30, bottom: 30}}
            onPress={() => this.props.navigation.navigate('Profil')}>
            <Icon
              name="user"
              type="font-awesome"
              color="#fff"
              size={APP_BAR_ICON_SIZE}
            />
          </TouchableOpacity>

          <MachineUnlock
            isOpen={this.state.modalUnlock}
            closeModal={this.closeModal}
            callback={this.callBackModal}
          />
        </View>
      </>
    );
  }
}

class Accueil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meteo: null,
      system: null,
      system_name: '',
      sensor: null,
      devices: [],
      isAdmin: false,
      isLoader: false,
      fav_widget: [],
      favorites: [],
      fav_gadget: [],
      base_list: [],
      device_list: [],
      slots: [],
      listWidgetToShow: [],
      isRefreshing: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.initScreens();
  }


  resize = () => {
    this.setState({
      innerWidth: window.innerWidth
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  initScreens = async () => {
    this.getSystem();
    this.getMeteo();
    this.getsensors();
    this.getdevices();
    this.IsAdminSystem();
    this.getFavWidget();
    this.LoadDefaultDevices();
    this.LoadSlots();
    // this.InitlistWidgetToShow();
    this.getfavorites();
    // SplashScreen.hide();
  };

  InitlistWidgetToShow = () => {
    let listdevices = [];
    let listsenssor = {};

    if (
      this.props.current_system.hasOwnProperty('sensors') &&
      this.props.current_system.sensors.length > 0
    ) {
      this.props.current_system.sensors.map(s => {
        listsenssor[s.sensor_number] = s;
      });
    }

    if (
      this.props.current_system.hasOwnProperty('actions') &&
      this.props.current_system.actions.length > 0
    ) {
      this.props.current_system.actions.map(d => {
        listdevices[d.device_number] = d;
      });
    }

    let _listWidgetToShow = this.props.app_fav_widget.map(item => {
      if (item.widget_type === 'device') {
        let el = listdevices[item.device_number];
        //el["widget_type"] =  "device";
        return el;
      } else if (item.widget_type === 'sensor') {
        let el = listsenssor[item.sensor_number];
        // el["widget_type"] =  "sensor";
        return el;
      } else {
        // console.log(item);

        return item;
      }
    });
    //console.log("_listWidgetToShow ==> ",_listWidgetToShow)
    this.setState({listWidgetToShow: _listWidgetToShow});
  };

  LoadDefaultDevices = () => {
    this.setState({device_list: this.props.base_list.device_list});
  };

  LoadSlots = () => {
    if (
      this.props.current_system.hasOwnProperty('slots') &&
      this.props.current_system.slots.length > 0
    ) {
      let listSlots = {};
      this.props.current_system.slots.map(item => {
        listSlots[item.action_number] = item;
      });

      this.setState({
        slots: listSlots,
      });
    }
  };
  getFavWidget = () => {
    this.setState({fav_widget: this.props.app_fav_widget});
  };
  componentDidUpdate(nextProps) {
    const {current_system, base_list, last_update_data_redux} = this.props;

    if (
      nextProps.current_system !== current_system ||
      nextProps.base_list !== base_list ||
      nextProps.last_update_data_redux !== last_update_data_redux ||
      nextProps.last_update_data_redux_system !==
        this.props.last_update_data_redux_system
    ) {
      this.initScreens();
    }

    if (nextProps.app_fav_widget !== this.props.app_fav_widget) {
      this.getFavWidget();
    }
  }
  IsAdminSystem = () => {
    getStoreDataPromise('system_current_is_admin').then(_isAdmin => {
      if (_isAdmin === 'true') {
        this.setState({isAdmin: true});
      } else {
        this.setState({isAdmin: false});
      }
    });
  };

  ModalUnlock = () => {
    this.setState({isAdmin: true});
    setStoreData('system_current_is_admin', 'true');
    this.props.refreshApp();
  };

  CloseApp = () => {
    this.setState({isAdmin: false});
    setStoreData('system_current_is_admin', 'false');
    this.props.refreshApp();
  };

  getdevices = () => {
    if (
      this.props.current_system.hasOwnProperty('actions') &&
      this.props.current_system.actions.length > 0
    ) {
      this.setState({devices: this.props.current_system.actions});
    }
  };
  getsensors = () => {
    if (
      this.props.current_system.hasOwnProperty('sensors') &&
      this.props.current_system.sensors.length > 0
    ) {
      this.setState({sensor: this.props.current_system.sensors[0]});
    }
  };
  getfavorites = () => {
    if (
      this.props.current_system.hasOwnProperty('favorites') &&
      this.props.current_system.favorites.length > 0
    ) {
      // console.log("this.props.current_system.favorites ==> ",this.props.current_system.favorites)
      let devices = {};
      let sensors = {};
      let strips = {};
      this.props.current_system.actions &&
        this.props.current_system.actions.map(device => {
          devices[device.device_number] = device;
        });
      this.props.current_system.sensors &&
        this.props.current_system.sensors.map(sensor => {
          sensors[sensor.sensor_number] = sensor;
        });
      this.props.current_system.strips &&
        this.props.current_system.strips.map(strip => {
          strips[strip.strip_number] = strip;
        });

      let _fav_gadget = this.props.current_system.favorites
        .sort(function(a, b) {
          return parseInt(a.fav_order) - parseInt(b.fav_order);
        })
        .filter(item => {
          return item.fav_display === '1';
        })
        .map(item => {
          let gadget = {
            type: '',
            fav_order: item.fav_order,
            hash: item.fav_hash,
            obj: {},
            fav_source_data: '',
            fav_shorcut: item.fav_shorcut,
            fav_source_field: '',
            'fav_source-number': '',
          };
          if (item.fav_type === 'device') {
            //les devices
            gadget.type = 'device';
            gadget.obj = devices[item.fav_number]
              ? devices[item.fav_number]
              : null;
            //console.log("les devices ",item.fav_target);
          } else if (item.fav_type === 'sensor') {
            //  les sensors
            gadget.type = 'sensor';
            gadget.obj = sensors[item.fav_number]
              ? sensors[item.fav_number]
              : null;
            // console.log("les sensors ",item.fav_target);
          } else if (item.fav_type === 'strip') {
            //  les strips
            gadget.type = 'strip';
            gadget.obj = strips[item.fav_number]
              ? strips[item.fav_number]
              : null;
            // console.log("les sensors ",item.fav_target);
          } else {
            //les gadgets internes

            gadget.type = 'default';
            gadget.obj = {};
            gadget.fav_source_data = item.fav_source_data;
            if (item.fav_source_data === 'sensor') {
              gadget.obj = sensors[item['fav_source-number']]
                ? sensors[item['fav_source-number']]
                : null;
              // console.log("item renderBidons ",gadget.obj.device_hash,gadget.obj.device_level)
            } else {
              gadget.obj = devices[item['fav_source-number']]
                ? devices[item['fav_source-number']]
                : null;
            }

            gadget.fav_source_field = item.fav_source_field;
            gadget['fav_source-number'] = item['fav_source-number'];

            // console.log("les gadgets internes ",item.fav_target);
          }
          return gadget;
        });

      //console.log("_fav_gadget ==> ",_fav_gadget)
      this.setState({
        favorites: this.props.current_system.favorites,
        fav_gadget: _fav_gadget,
      });
    }
  };
  getSystem = () => {
    if (
      this.props.current_system.hasOwnProperty('system') &&
      this.props.current_system.system.length > 0
    ) {
      this.setState({system: this.props.current_system.system[0]});
      this.setState({system_name: this.props.current_system.system[0].sys_ref});
    }
  };
  getMeteo = () => {
    if (
      this.props.current_system.hasOwnProperty('weather') &&
      this.props.current_system.weather.length > 0
    ) {
      console.log('Current system');
      console.log(this.props.current_system);
      let meteo = this.props.current_system.weather;

      let meteoToday = meteo[0];
      if (!meteoToday.hasOwnProperty('sys_icon')) {
        this.setState({meteo: null});
        return null;
      }
      let meteoFutur = [];
      let _unit = this.props.current_system.user[0].user_unit;
      let _time = meteoToday.sys_icon.charAt(meteoToday.sys_icon.length - 1);
      let meteoObj = meteoToday;
      meteoObj.time = _time;
      meteoObj.type = _time;
      meteoObj.unit = _unit;
      for (let i = 0; i < meteo.length; i++) {
        meteoFutur.push({
          weather: meteo[i],
          day: moment(meteo[i].sys_forecast_date).format('ddd'),
          hours: moment(meteo[i].sys_forecast_date).format('HH'),
        });
      }

      meteoObj.meteoFutur = meteoFutur;

      this.setState({meteo: meteoObj});
    }
  };
  OpenGererMesFavoris = () => {
    this.props.navigation.navigate('GererMesFavoris');
  };
  WidgetIsFavorite = sensor_hash => {
    if (!Array.isArray(this.state.fav_widget)) {
      return false;
    }
    let index = this.state.fav_widget.findIndex(item => {
      return item.sensor_hash === sensor_hash;
    });
    if (index >= 0) {
      return true;
    }
    return false;
  };

  renderRaccourcie = () => {
    // console.log("renderRaccourcie ==> ")
    if (
      Array.isArray(this.state.fav_gadget) &&
      this.state.fav_gadget.length > 0
    ) {
      let devices = {};
      let sensors = {};
      this.props.current_system.actions &&
        this.props.current_system.actions.map(device => {
          devices[device.device_number] = device;
        });
      this.props.current_system.actions &&
        this.props.current_system.sensors.map(sensor => {
          sensors[sensor.sensor_number] = sensor;
        });
      devices =
        this.props.current_system &&
        this.props.current_system.favorites &&
        this.props.current_system.favorites
          .map(item => {
            let gadget = {
              type: '',
              fav_order: item.fav_order,
              hash: item.fav_hash,
              obj: {},
              fav_source_data: '',
              fav_number: item.fav_number,
              fav_shorcut: item.fav_shorcut,
              fav_source_field: '',
              'fav_source-number': '',
            };
            if (item.fav_type === 'device') {
              //les devices
              gadget.type = 'device';
              gadget.obj = devices[item.fav_number]
                ? devices[item.fav_number]
                : null;
              //console.log("les devices ",item.fav_target);
            } else if (item.fav_type === 'sensor') {
              //  les sensors
              gadget.type = 'sensor';
              // console.log("les sensors ",item.fav_target);
            } else {
              //les gadgets internes

              gadget.type = 'default';
              gadget.obj = {};
              gadget.fav_source_data = item.fav_source_data;
              if (item.fav_source_data === 'sensor') {
                gadget.obj = sensors[item['fav_source-number']]
                  ? sensors[item['fav_source-number']]
                  : null;
                // console.log("item renderBidons ",gadget.obj.device_hash,gadget.obj.device_level)
              } else {
                gadget.obj = devices[item['fav_source-number']]
                  ? devices[item['fav_source-number']]
                  : null;
              }

              gadget.fav_source_field = item.fav_source_field;
              gadget['fav_source-number'] = item['fav_source-number'];

              // console.log("les gadgets internes ",item.fav_target);
            }
            return gadget;
          })
          .filter(item => {
            if (
              item.hasOwnProperty('type') &&
              item.type === 'device' &&
              item.fav_shorcut === '1'
            ) {
              return true;
            }
            return false;
          })
          .map(device => {
            let index = this.state.device_list.findIndex(_item => {
              if (device.obj) {
                return _item.device_type === device.obj.device_type;
              }
              return false;
            });
            let assetsWidget = this.state.device_list[index];
            let _device = {
              device: device.obj,
              assets: assetsWidget,
            };

            return _device;
          });

      //console.log("widgetsToShow ===> ",devices);
      if (devices && devices.length) {
        return (
          <View key="shortcut-widget" style={{paddingBottom: 20}}>
            <Raccourcie devices={devices} />
          </View>
        );
      }
    }
  };
  renderFaq = () => {
    return (
      <View key="faq-widget" style={{paddingLeft: 10, paddingBottom: 20}}>
        <Question />
      </View>
    );
  };
  renderMeteo = () => {
    if (!this.state.meteo) {
      return null;
    }

    return (
      <View key="meteo-widget" style={{paddingBottom: 20}}>
        <Meteo {...this.state.meteo} />
      </View>
    );
  };

  renderTemperature = sensor_hash => {
    if (
      !this.props.current_system.sensors ||
      !Array.isArray(this.props.current_system.sensors)
    ) {
      return null;
    }
    let index = this.props.current_system.sensors.findIndex(item => {
      return item.sensor_hash === sensor_hash;
    });
    if (index >= 0) {
      let sensor = this.props.current_system.sensors[index];
      return (
        <TouchableOpacity
          style={{paddingBottom: 20}}
          onPress={() => {
            this.OpenDetailSenssor(sensor);
          }}>
          <Temperature {...sensor} />
        </TouchableOpacity>
      );
    }
  };

  OpenDetailSenssor = sensor => {
    this.setState({isLoader: true});

    this.props
      .getSenssorHistorique(
        this.state.system.sys_appid,
        sensor.sensor_number,
        30,
      )
      .then(response => {
        this.setState({isLoader: false});
        this.props.navigation.navigate({
          name: 'SenssorDetail',
          params: {
            senssorDetail: response,
          },
          key: this.state.system.sys_appid + '_' + sensor.sensor_number + '_30',
        });
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);

        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });
        this.setState({isLoader: false});
      });
  };

  OpenDetailStrip = strip => {
    this.setState({isLoader: true});

    this.props
      .getStripHistorique(this.state.system.sys_appid, strip.strip_number, 30)
      .then(response => {
        this.setState({isLoader: false});
        this.props.navigation.navigate({
          name: 'StripDetail',
          params: {
            stripDetail: response,
          },
          key: this.state.system.sys_appid + '_' + strip.strip_number + '_30',
        });
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);

        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });
        this.setState({isLoader: false});
      });
  };

  renderBaignade = item => {
    return (
      <View style={{paddingBottom: 20}}>
        <Baignade
          {...item.obj}
          sys_status={this.state.system.sys_quality}
          fav_source_field={item.fav_source_field}
        />
      </View>
    );
  };
  renderFlowSwitch = sensor_hash => {
    if (
      !this.props.current_system.sensors ||
      !Array.isArray(this.props.current_system.sensors)
    ) {
      return null;
    }

    let index = this.props.current_system.sensors.findIndex(item => {
      return item.sensor_hash === sensor_hash;
    });
    if (index >= 0) {
      let sensor = this.props.current_system.sensors[index];
      return (
        <TouchableOpacity
          style={{paddingBottom: 20}}
          onPress={() => {
            this.OpenDetailSenssor(sensor);
          }}>
          <FlowSwitch {...sensor} />
        </TouchableOpacity>
      );
    }
  };

  renderGraphSensor = sensor_hash => {
    if (
      !this.props.current_system.sensors ||
      !Array.isArray(this.props.current_system.sensors)
    ) {
      return null;
    }

    let index = this.props.current_system.sensors.findIndex(item => {
      return item.sensor_hash === sensor_hash;
    });
    if (index >= 0) {
      let sensor = this.props.current_system.sensors[index];
      return (
        <TouchableOpacity
          style={{paddingBottom: 20}}
          onPress={() => {
            this.OpenDetailSenssor(sensor);
          }}>
          <GraphSensor {...sensor} />
        </TouchableOpacity>
      );
    }
  };

  renderGaugeSensor = sensor_hash => {
    if (
      !this.props.current_system.sensors ||
      !Array.isArray(this.props.current_system.sensors)
    ) {
      return null;
    }

    let index = this.props.current_system.sensors.findIndex(item => {
      return item.sensor_hash === sensor_hash;
    });
    if (index >= 0) {
      let sensor = this.props.current_system.sensors[index];
      return (
        <TouchableOpacity
          style={{paddingBottom: 20}}
          onPress={() => {
            this.OpenDetailSenssor(sensor);
          }}>
          <GaugeSensor {...sensor} />
        </TouchableOpacity>
      );
    }
  };

  refreshUpdateDevice = params => {
    this.setState({
      device_consumption: params.device_consumption,
      device_date: params.device_date,
      device_hash: params.device_hash,
      device_index: params.device_index,
      device_level: params.device_level,
      device_level_ref: params.device_level_ref,
      device_level_unit: params.device_level_unit,
      device_mode_available: params.device_mode_available,
      device_mode_display: params.device_mode_display,
      device_name: params.device_name,
      device_number: params.device_number,
      device_power: params.device_power,
      device_security: params.device_security,
      device_sensor: params.device_sensor,
      device_sequence: params.device_sequence,
      device_sequence_available: params.device_sequence_available,
      device_sequence_display: params.device_sequence_display,
      device_setpoint: params.device_setpoint,
      device_speed: params.device_speed,
      device_speed_min: params.device_speed_min,
      device_speed_max: params.device_speed_max,
      device_speed_unit: params.device_speed_unit,
      device_speed_gap: params.device_speed_gap,
      device_standard: params.device_standard,
      device_status: params.device_status,
      device_type: params.device_type,
      device_unit_power: params.device_unit_power,
      device_unit_setpoint: params.device_unit_setpoint,
      device_widget: params.device_widget,
      // sys_appid: params.current_system.system[0].sys_appid,
      //  sensors: params.current_system.sensors,
      // assetsWidget:params.assetsWidget,
    });

    this.getfavorites();
  };

  refreshDevice = deviceNumber => {
    this.props.getDevice(this.state.system.sys_appid, deviceNumber);
  };

  renderBidons = item => {
    //  console.log("renderBidons item ",item)
    return (
      <Pompe
        {...item.obj}
        fav_source_field={item.fav_source_field}
        refreshDevice={this.refreshDevice}
      />
    );
  };

  RenderWidget = item => {
    if (!item || !item.obj) {
      return null;
    }

    if (item.hasOwnProperty('type') && item.type === 'sensor') {
      return (
        <WidgetSensor
          OpenDetailSenssor={this.OpenDetailSenssor}
          sensor={item.obj}
          date={this.props.last_update_data_redux}
          date2={this.props.last_update_data_redux_system}
          device_list={this.state.device_list}
          slots={this.state.slots}
          navigation={this.props.navigation}
        />
      );
    } else if (item.hasOwnProperty('type') && item.type === 'strip') {
      return (
        <WidgetStrip
          OpenDetailStrip={this.OpenDetailStrip}
          strip={item.obj}
          date={this.props.last_update_data_redux}
          date2={this.props.last_update_data_redux_system}
          device_list={this.state.device_list}
          slots={this.state.slots}
          navigation={this.props.navigation}
        />
      );
    } else {
      const device =
        this.props.current_system &&
        this.props.current_system.actions &&
        this.props.current_system.actions.find(
          a => a.device_number === item.obj.device_number,
        );

      return (
        <WidgetDevice
          device={{...item.obj, ...device}}
          date={this.props.last_update_data_redux_system}
          device_list={this.state.device_list}
          slots={this.state.slots}
          navigation={this.props.navigation}
        />
      );
    }
  };

  RenderWidgetDefault = (type, item) => {
    switch (type) {
      //case "__raccourcis__":return this.renderRaccourcie();break;
      case 'FAQ':
      case 'ADVICES':
        return this.renderFaq();
      case 'WEATHER':
      case 'METEO':
        return this.renderMeteo();
      case 'SWIMMING':
      case 'BATHING':
        return this.renderBaignade(item);
      case 'SWIM':
        return this.renderBaignade();
      case 'TANK':
        return this.renderBidons(item);
      case '_PH':
        return this.renderBidons(item);
      case 'PH -':
        return this.renderBidons(item);
      case 'TANK PH -':
        return this.renderBidons(item);
      case 'TANK CHLORINE':
        return this.renderBidons(item);
      case 'TANK CL':
        return this.renderBidons(item);
      case 'TANK_PH':
        return this.renderBidons(item);
      case 'TANK_CL':
        return this.renderBidons(item);
      case 'TANK_OX':
        return this.renderBidons(item);
    }
  };

  RenderWidgetold = (type, item) => {
    switch (type) {
      case '__raccourcis__':
        return this.renderRaccourcie();
      case '__faq__':
        return this.renderFaq();
      case '__meteo__':
        return this.renderMeteo();
      case '__Baignade__':
        return this.renderBaignade();
      case '__bidons__':
        return this.renderBidons();
      case '_FLOW_SWITCH':
        return this.renderFlowSwitch('_FLOW_SWITCH');
      case '_FLOW_PRES':
        return this.renderFlowSwitch('_FLOW_PRES');
      case '_PH_SIGNAL_1':
        return this.renderGraphSensor('_PH_SIGNAL_1');
      case '_ORP_SIGNAL_2':
        return this.renderGraphSensor('_ORP_SIGNAL_2');
      case '_TEMP_PT100':
        return this.renderTemperature('_TEMP_PT100');
      default:
        return this.renderDefault(type, item);
    }
  };
  renderDefault = (type, item) => {
    // console.log(item);
    if (
      item &&
      item.hasOwnProperty('widget_type') &&
      item.widget_type === 'device'
    ) {
      let indexD = this.props.current_system.actions.findIndex(item => {
        return item.device_hash === type;
      });

      if (indexD >= 0) {
        //  console.log("device ",indexD)
        let device = this.props.current_system.actions[indexD];
        if (!Array.isArray(this.state.device_list)) {
          return null;
        }

        let index = this.state.device_list.findIndex(_item => {
          return _item.device_hash === device.device_hash;
        });
        let assetsWidget = this.state.device_list[index];
        let Slot = [];
        if (this.state.slots[device.device_number]) {
          Slot = this.state.slots[device.device_number];
        }

        return (
          <View style={{paddingBottom: 20}}>
            <RemoteControlle
              typeComposent={'image'}
              {...device}
              assetsWidget={assetsWidget}
              slot={Slot}
              navigation={this.props.navigation}
            />
          </View>
        );
      }
    }
  };
  RenderMultiWidget = () => {
    if (
      Array.isArray(this.state.fav_gadget) &&
      this.state.fav_gadget.length > 0
    ) {
      let widgetsToShow = this.state.fav_gadget.map(item => {
        // console.log("item listWidgetToShow ",item);
        if (item.hasOwnProperty('type') && item.type === 'default') {
          return this.RenderWidgetDefault(item.hash, item);
        } else {
          return this.RenderWidget(item);
        }
      });
      let dataWidgetDyna = [];
      // let pompfindindex = null;

      for (let i = 0; i < widgetsToShow.length; i++) {
        //   console.log("widgetsToShow[i].type ",typeof widgetsToShow[i].type,widgetsToShow[i].type)
        /*if (
          widgetsToShow[i] &&
          typeof widgetsToShow[i].type === 'object' &&
          widgetsToShow[i].type.displayName === 'Connect(Pompe)'
        ) {
          if (!pompfindindex) {
            pompfindindex = i;
          }
          if (
            typeof widgetsToShow[i].type === 'object' &&
            widgetsToShow[i + 1] &&
            widgetsToShow[i + 1].type.displayName === 'Connect(Pompe)'
          ) {
          } else {
            if (pompfindindex !== i) {
              dataWidgetDyna.push(
                this.PompoGroupeToShow(widgetsToShow, pompfindindex, i),
              );
              pompfindindex = null;
            } else {
              pompfindindex = null;
              dataWidgetDyna.push(widgetsToShow[i]);
            }
          }
        } else {*/
        dataWidgetDyna.push(widgetsToShow[i]);
        //}

        //console.log("widgetsToShow[i] => ", widgetsToShow[i].type.displayName === "Connect(Pompe)");
      }

      return widgetsToShow;
    }
  };

  PompoGroupeToShow = (widgetsToShow, start, end) => {
    let subpomp = [];
    for (let i = start; i <= end; i++) {
      subpomp.push(widgetsToShow[i]);
    }

    return (
      <View style={{paddingBottom: 20}}>
       {/* <FlatGrid
          itemDimension={140}
          items={subpomp}
          renderItem={({item, index}) => item}
        />*/}
      </View>
    );
  };

  RenderMultiWidgetold = () => {
    //return this.renderTemperature();
    if (this.state.system) {
      return this.props.app_fav_widget.map(item => {
        if (item.isfavory) {
          return this.RenderWidget(item.widget_hash, item);
        }

        return false;
      });
    }
  };

  onRefresh = () => {
    this.setState({isRefreshing: true});

    this.props
      .getSystem(this.props.current_system.system[0].sys_appid)
      .then(d => {
        this.props.refreshApp();
        this.initScreens();
        this.setState({isRefreshing: false});
      });
  };

  render() {
    const userType = this.props.user?.user_type;
    return (
      <SafeAreaView style={styles.containerfull}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          leftComponent={
            <HeaderLeftComponent
              navigation={this.props.navigation}
              current_system={this.props.current_system}
            />
          }
          rightComponent={
            <HeaderrightComponent
              navigation={this.props.navigation}
              isAdmin={this.state.isAdmin}
              ModalUnlock={this.ModalUnlock}
              CloseApp={this.CloseApp}
              userType={userType}
            />
          }
        />
        <Loader isloader={this.state.isLoader} />
        <ScrollView
          contentContainerStyle={{flexGrow: 1}}
          style={styles.container}
          refreshControl={
            <RefreshControl
              refreshing={this.state.isRefreshing}
              onRefresh={this.onRefresh}
              tintColor="white"
            />
          }>
          <View style={styles.container}>
            <View style={{flexDirection: 'row', paddingBottom: 0}}>
              <View
                style={{
                  flex: 1,
                  borderColor: '#fff',
                  borderTopWidth: 4,
                  marginTop: 10,
                }}
              />
              <Text style={styles.enteteText}>{this.state.system_name}</Text>
              <View
                style={{
                  flex: 5,
                  borderColor: '#fff',
                  borderBottomWidth: 4,
                  marginBottom: 10,
                }}
              />
            </View>
            {this.state.isAdmin === true && (
              <View style={styles.containerFixed}>
                <TouchableHighlight
                  onPress={() => this.OpenGererMesFavoris()}
                  style={{padding: 3}}>
                  <Text style={styles.btnadd}>+</Text>
                </TouchableHighlight>
              </View>
            )}

            {this.renderRaccourcie()}
            <View style={{display: 'grid',
            gridTemplateColumns: `repeat(${getColumnNumber()}, minmax(100px, 1fr))`,
            gridGap: '10px',
            gridAutoRows: 'minmax(100px, auto)'}}>
              {this.RenderMultiWidget()}
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
  },
  container: {
    flex: 1,
    backgroundColor: '#000004',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  enteteText: {
    fontSize: 45,
    fontWeight: '300',
    padding: 10,
    textAlign: 'left',
    color: '#ffffff',
    fontFamily: fontFamilyRegular(),
  },
  containerFixed: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    zIndex: 200,
  },
  btnadd: {
    right: 0,
    fontSize: 30,
    marginRight: 15,
    marginTop: 10,
    color: '#289EFF',
    fontFamily: fontFamilyBold(),
    textAlign: 'right',
  },
});

Accueil.propTypes = {
  current_system: PropTypes.object,
  app_fav_widget: PropTypes.array,
  app_racc: PropTypes.array,
};

Accueil.defaultProps = {
  current_system: {},
  app_fav_widget: [],
  app_racc: [],
};
const mapStateToProps = (state, ownProps) => {
  return {
    current_system: state.SystemsRedux.system,
    last_update_data_redux_system: state.SystemsRedux.last_update_data_redux,
    app_fav_widget: state.AppRedux.app_fav_widget,
    user: state.SystemsRedux.clientInfo?.user,
    app_racc: state.AppRedux.app_racc,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    base_list: state.AppRedux.base_list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStripHistorique: (appId, strip_number, history) => {
      return dispatch(actions.getStripHistorique(appId, strip_number, history));
    },
    getSenssorHistorique: (appId, sensor_number, history) => {
      return dispatch(
        actions.getSenssorHistorique(appId, sensor_number, history),
      );
    },
    refreshApp: () => {
      return dispatch(actionsApp.refreshApp());
    },
    getDevice: (appId, device_number) => {
      return dispatch(actions.getDevice(appId, device_number));
    },
    getSystem: appid => {
      return dispatch(actions.SystemDetail(appid));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Accueil);
