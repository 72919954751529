import React from 'react';
import {StyleSheet, Text, View, Button, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Svg, {
  Use,
  Image,
} from 'react-native-svg';

import * as actions from '../../Store/Systems/SystemsActions';
import LinearGradient from 'react-native-linear-gradient';
import {fontFamilyArialBlack, fontFamilyArialBold} from '../../Config/fonts';
import {Icon} from 'react-native-elements';
import iconDetailDevice from '../../assets/detail-devices.svg';
import ThreeDotsLoader from '../ThreeDotsLoader';
// import {FlatGrid} from 'react-native-super-grid';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  showSnaskMessageOperationSuccess,
} from '../../Config/Config';
import translate from '../../translations/translate';
import BottomExtraValues from './BottomExtraValues';

const ItemVal = props => {
  const {children, selected, onSelected, item} = props;
  return (
    <TouchableOpacity
      style={[styles.itemStyle, selected && styles.itemSelectedStyle]}
      onPress={() => {
        onSelected(item);
      }}>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 15,
          color: '#fff',
          fontWeight: 'normal',
        }}>
        {children}
      </Text>
    </TouchableOpacity>
  );
};

class RemoteControlleVitesse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWait: false,
      system: null,
      device_index: null,
      device_status: null,
      btns: {
        on: null,
        off: null,
        auto: null,
      },
      listBtns: [],
      speed_btns: [],
      speed_defaul: 3,
    };
  }
  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(prevProps) {
    const {last_update_data_redux_system, last_update_data_redux} = this.props;

    if (
      prevProps.last_update_data_redux_system !==
        last_update_data_redux_system ||
      prevProps.last_update_data_redux !== last_update_data_redux
    ) {
      this.initData();
    }
  }

  initData = () => {
    let _listBtn = this.props.device_mode_display.split('/');
    let listIndex = [];
    for (
      let i = this.props.device_speed_min;
      i <= this.props.device_speed_max;
      i++
    ) {
      listIndex.push(i);
    }
    let _btns = {
      on: _listBtn.length > 0 ? _listBtn[0] : null,
      off: _listBtn.length > 1 ? _listBtn[1] : null,
      auto: _listBtn.length > 2 ? _listBtn[2] : null,
    };

    this.setState({
      device_index: parseInt(this.props.device_index),
      device_standard: parseFloat(this.props.device_standard),
      progressVal: parseFloat(this.props.device_setpoint),
      unit: this.props.device_unit_setpoint,
      device_status: parseInt(this.props.device_status),
      btns: _btns,
      listBtns: _listBtn,
      speed_btns: listIndex,
      speed_defaul: parseInt(this.props.device_speed),
    });
    // console.log("this.props.device_speed ",this.props.device_number,this.props.device_speed)
    this.getSystem();
  };

  getSystem = () => {
    if (
      this.props.current_system.hasOwnProperty('system') &&
      this.props.current_system.system.length > 0
    ) {
      this.setState({
        system: this.props.current_system.system[0],
      });
    }
  };
  componentWillUnmount() {}

  RenderStatus = () => {
    return this.state.listBtns.map((btn, index) => {
      if (this.state.device_index === index) {
        let colorIcon = '';
        switch (this.state.device_status) {
          case 0:
            colorIcon = '#FF0C00';
            break;
          case 1:
            colorIcon = '#7ED321';
            break;
          case 2:
            colorIcon = '#ccc';
            break;
        }
        return (
          <Icon
            iconStyle={{marginTop: 10, marginLeft: 10}}
            name="circle"
            type="font-awesome"
            size={10}
            underlayColor={colorIcon}
            color={colorIcon}
          />
        );
      }
      return null;
    });
  };

  UpdateStatusDevices = (field, val) => {
    if (this.state.isWait) {
      return true;
    }
    this.setState({isWait: true});
    this.props
      .UpdateDevice(this.state.system.sys_appid, {
        key: field,
        value: val,
        number: this.props.device_number,
      })
      .then(data => {
        this.setState({isWait: false});

        showSnaskMessageOperationSuccess();
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };

  render() {
    if (parseInt(this.props.device_speed_min) < 0) {
      return null;
    }

    const userType = this.props.user?.user_type;

    const {borderTitle} = this.props;
    return (
      <LinearGradient
        colors={['#1E1E1E', '#3A57FB']}
        style={styles.LinearGradientcontainer}>
        <View style={styles.container}>
          <View style={styles.containerFixed}>
            <View style={{width: 50, backgroundColor: '#3A57FB', height: 2}} />
            <Text style={styles.titrewidget}>{this.props.device_hash}</Text>
          </View>
          <View style={styles.topViewtitle}>
            <Text
              style={{
                color: borderTitle,
                borderTopColor: borderTitle,
                borderTopWidth: 3,
                fontSize: 16,
                marginTop: -2,
                fontWeight: 'bold',
                fontFamily: fontFamilyArialBold(),
                paddingTop: 10,
              }}>
              {this.props.device_name}
            </Text>

            {this.RenderStatus()}
          </View>
          {userType === '0' && (
            <TouchableOpacity
              style={{flex: 1, paddingLeft: 10, marginTop: -20}}
              onPress={() =>
                this.props.navigation.navigate({
                  name: 'DevicesDetail',
                  params: this.props,
                  key:
                    this.state.system.sys_appid +
                    '_' +
                    this.props.device_number +
                    '_30',
                })
              }>
              <Svg width={20} height={20}>
                <Image href={iconDetailDevice} />
              </Svg>
            </TouchableOpacity>
          )}

          <View>
            {this.state.isWait ? <ThreeDotsLoader /> : <View />}
          </View>
          {this.props.device_text && (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {this.props.device_text
                ? translate(this.props.device_text, styles.deviceText)
                : false}
            </View>
          )}
          <View style={{display: "flex", flexDirection: "row", justifyContent: "space-around", minHeight: 137, alignItems: "center"}}>
            {this.state.speed_btns.map(item => {
                return (
                  <ItemVal
                    selected={this.state.speed_defaul === item ? true : false}
                    onSelected={this.onSelected}
                    item={item}>
                    {item}
                  </ItemVal>
                );
              })}
          </View>
          <BottomExtraValues
            name2={this.props.sensor_name_2}
            value2={this.props.sensor_live_2}
            unit2={this.props.sensor_unit_2}
            name3={this.props.sensor_name_3}
            value3={this.props.sensor_live_3}
            unit3={this.props.sensor_unit_3}
          />
        </View>
      </LinearGradient>
    );
  }

  onSelected = item => {
    this.setState({speed_defaul: item});
    this.UpdateStatusDevices('device_speed', item);
  };
}

const styles = StyleSheet.create({
  LinearGradientcontainer: {
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
    padding: 1,
    marginBottom: 20,
  },
  titrewidget: {
    textAlign: 'right',
    width: '100%',
    fontSize: 40,
    color: '#1E1E1E',
    fontFamily: fontFamilyArialBlack(),
    textTransform: 'uppercase',
    marginTop: 10,
  },
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,
    paddingBottom: 10,
  },
  containerFixed: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topViewtitle: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  gridView: {
    marginLeft: 20,
    marginRight: 20,
    flex: 1,
  },
  itemStyle: {
    width: 40,
    height: 40,
    paddingTop: 9,
    borderWidth: 1,
    borderColor: '#242424',
    borderRadius: 25,
    backgroundColor: '#242424',
  },
  itemSelectedStyle: {
    borderWidth: 2,
    borderColor: '#2D7BD6',
    backgroundColor: '#2D7BD6',
  },
  deviceText: {
    color: 'white',
  },
});

RemoteControlleVitesse.propTypes = {
  typeComposent: PropTypes.string,
  ddevice_consumption: PropTypes.string,
  device_date: PropTypes.string,
  device_hash: PropTypes.string,
  device_index: PropTypes.string,
  device_level: PropTypes.string,
  device_level_ref: PropTypes.string,
  device_level_unit: PropTypes.string,
  device_mode_available: PropTypes.string,
  device_mode_display: PropTypes.string,
  device_name: PropTypes.string,
  device_number: PropTypes.string,
  device_power: PropTypes.string,
  device_security: PropTypes.string,
  device_sensor: PropTypes.string,
  device_sequence: PropTypes.string,
  device_sequence_available: PropTypes.string,
  device_sequence_display: PropTypes.string,
  device_setpoint: PropTypes.string,
  device_speed: PropTypes.number,
  device_speed_unit: PropTypes.string,
  device_speed_min: PropTypes.number,
  device_speed_max: PropTypes.number,
  device_speed_gap: PropTypes.number,
  device_standard: PropTypes.string,
  device_status: PropTypes.string,
  device_type: PropTypes.string,
  device_unit_power: PropTypes.string,
  device_unit_setpoint: PropTypes.string,
  device_color_setpoint: PropTypes.string,
  device_min_setpoint: PropTypes.string,
  device_max_setpoint: PropTypes.string,
  device_widget: PropTypes.string,
  sys_appid: PropTypes.string,
  borderTitle: PropTypes.string,
  assetsWidget: PropTypes.object,
  slot: PropTypes.object,
  sensor_unit_3: PropTypes.string,
  sensor_live_3: PropTypes.string,
  sensor_name_3: PropTypes.string,
  sensor_unit_2: PropTypes.string,
  sensor_live_2: PropTypes.string,
  sensor_name_2: PropTypes.string,
};

RemoteControlleVitesse.defaultProps = {
  typeComposent: 'image',
  device_consumption: '',
  device_date: '',
  device_hash: '',
  device_index: '',
  device_level: '',
  device_level_ref: '',
  device_level_unit: '',
  device_mode_available: '',
  device_mode_display: '',
  device_name: '',
  device_number: '',
  device_power: '',
  device_security: '',
  device_sensor: '',
  device_sequence: '',
  device_sequence_available: '',
  device_sequence_display: '',
  device_setpoint: '',
  device_speed: '',
  device_speed_unit: '',
  device_speed_min: 0,
  device_speed_max: '',
  device_speed_gap: '',
  device_standard: '',
  device_status: '',
  device_type: '',
  device_unit_power: '',
  device_unit_setpoint: '',
  device_widget: '',
  device_color_setpoint: '',
  device_min_setpoint: '',
  device_max_setpoint: '',
  sys_appid: '',
  borderTitle: '#3A57FB',
  assetsWidget: {},
  slot: {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    sys_appid: state.SystemsRedux.system && state.SystemsRedux.system.sys_appid,
    current_system: state.SystemsRedux.system,
    user: state.SystemsRedux.clientInfo?.user,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    last_update_data_redux_system:
      state.SystemsRedux.last_update_data_redux_system,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoteControlleVitesse);
