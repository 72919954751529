import React from 'react';
import {View, StyleSheet, Text} from 'react-native';

const BottomExtraValues = ({name2, value2, unit2, name3, value3, unit3}) => {
  if (!name2 && !name3) {
    return false;
  }

  const containerStyle = {...styles.container};
  if (name2 && name3) {
    containerStyle.justifyContent = 'space-between';
  }
  return (
    <View style={{...containerStyle}}>
      {!!name2 && (
        <View style={styles.valView}>
          <Text style={styles.valTextName}>{name2}:</Text>
          <Text style={styles.valTextValue}>{value2}</Text>
          <Text style={styles.valTextUnit}>{unit2}</Text>
        </View>
      )}
      {!!name3 && (
        <View style={styles.valView}>
          <Text style={styles.valTextName}>{name3}:</Text>
          <Text style={styles.valTextValue}>{value3}</Text>
          <Text style={styles.valTextUnit}>{unit3}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingTop: 10,
  },
  valView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  valTextName: {
    paddingRight: 5,
    color: '#3A57FB',
    fontWeight: 'bold',
  },
  valTextValue: {
    color: 'white',
    paddingRight: 2,
    fontWeight: 'bold',
  },
  valTextUnit: {
    color: 'white',
  },
});

export default BottomExtraValues;
