import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {connect} from 'react-redux';
import {Input} from 'react-native-elements';
import {fontFamilyBold, fontFamilyRegular} from '../../Config/fonts';
import * as actions from '../../Store/Systems/SystemsActions';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  showSnaskMessageOperationSuccess,
} from '../../Config/Config';
import ThreeDotsLoader from '../ThreeDotsLoader';
import translate from '../../translations/translate';

class Volume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWait: false,
      device_level_ref: '',
      device_level_unit: '',
      device_number: '',
      device_level: '',
      sys_appid: '',
    };
  }

  initData = () => {
    this.setState({
      device_level_ref: this.props.device_level_ref,
      device_level_unit: this.props.device_level_unit,
      device_level: this.props.device_level,
      sys_appid: this.props.sys_appid,
      device_number: this.props.device_number,
    });
  };

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(nextProps) {
    const {device_number} = this.props;
    if (nextProps.device_number !== device_number) {
      this.initData();
    }
  }

  componentWillUnmount() {}

  UpdateStatusDevices = val => {
    if (this.state.isWait) {
      return true;
    }
    this.setState({isWait: true});
    //console.log(this.props.sys_appid)
    this.props
      .UpdateDevice(this.props.sys_appid, {
        key: 'device_level',
        value: this.state.device_level,
        number: this.state.device_number,
      })
      .then(data => {
        this.setState({SelectedBtn: val});
        this.setState({isWait: false});
        this.props.refeshDevice();
        showSnaskMessageOperationSuccess();
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };

  onBlurField = fieldName => {
    let device_level = this.state.device_level;
    this.UpdateStatusDevices(device_level);
  };

  render() {
    return (
      <View style={styles.container}>
        <Text
          style={{
            color: '#fff',
            fontSize: 18,
            fontFamily: fontFamilyBold(),
            marginBottom: 10,
          }}>
          {translate('volume')}
        </Text>
        <Input
          onBlur={() => this.onBlurField('device_level')}
          onChangeText={val => this.setState({device_level: val})}
          containerStyle={styles.FormInputcontainerStyle}
          inputContainerStyle={styles.FormInputContainerStyle}
          inputStyle={[styles.FormTextInput]}
          keyboardType={'numeric'}
          value={this.state.device_level}
          rightIcon={this.state.isWait ? <ThreeDotsLoader /> : null}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#121212',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
  },
  FormInputcontainerStyle: {
    borderBottomWidth: 0,
    marginBottom: 0,
  },
  FormInputContainerStyle: {
    marginBottom: 5,
    height: 43,
    borderRadius: 50,
    borderWidth: 1,
    backgroundColor: '#242424',
    borderColor: '#242424',
    color: '#fff',
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
  FormTextInput: {
    height: 43,
    fontSize: 14,
    color: '#fff',
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
    textAlign: 'center',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    current_system: state.SystemsRedux.system,
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Volume);
