import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Switch,
  SafeAreaView,
} from 'react-native';
import {Header, Image, Button} from 'react-native-elements';
import translate from '../../translations/translate';
import {appHeaderHeight, appHeaderTop} from '../../Config/Config';

export default class ProfilInfo extends React.Component {
  state = {switchValue: false};
  toggleSwitch = value => {
    this.setState({switchValue: value});
  };
  render() {
    return (
      <SafeAreaView style={styles.containerfull}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          leftComponent={{icon: 'chevron-left', color: '#fff'}}
        />
        <ScrollView style={{marginTop: -1, padding: 0}}>
          <View style={styles.container}>
            <View
              style={{
                flexDirection: 'row',
                paddingBottom: 0,
                marginBottom: 30,
                marginTop: 10,
              }}>
              <Text
                style={{
                  flex: 1,
                  borderColor: '#fff',
                  borderTopWidth: 4,
                  marginTop: 10,
                }}
              />
              <Text style={styles.enteteText}>{translate('Mes infos')}</Text>
              <Text
                style={{
                  flex: 5,
                  borderColor: '#fff',
                  borderBottomWidth: 4,
                  marginBottom: 10,
                }}
              />
            </View>
            <View style={styles.containerBaseInfo}>
              <View
                style={{
                  flexDirection: 'row',
                  paddingBottom: 0,
                  marginBottom: 20,
                }}>
                <View
                  style={{
                    flex: 1,
                    alignSelf: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Image
                    source={{uri: 'https://via.placeholder.com/150'}}
                    style={{width: 100, height: 100}}
                  />
                </View>
                <View style={{flex: 1}}>
                  <Text style={styles.fullName}>DUPONT</Text>
                  <Text style={styles.fullName}>FRANÇOIS</Text>
                  <Text style={styles.Adress}>
                    20 rue des paquerettes 75092 PARIS FRANCE{' '}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 30,
                  marginRight: 10,
                }}>
                <Text style={{flex: 0.75}} />
                <Button
                  buttonStyle={styles.BtnButton}
                  title={translate('Modifier')}
                />
              </View>
              <View style={styles.containerBaseInfoSetting}>
                <Text style={styles.TextTitleSection}>Notifications :</Text>
                <View style={styles.zoneViewSwitch}>
                  <Text style={{flex: 4, color: '#fff'}}>
                    {translate('Afficher Genius')}
                  </Text>
                  <Switch
                    style={{flex: 1, marginTop: 0}}
                    onValueChange={this.toggleSwitch}
                    value={this.state.switchValue}
                  />
                </View>

                <View style={styles.zoneViewSwitch}>
                  <Text style={{flex: 4, color: '#fff'}}>
                    {translate('Notification email')}
                  </Text>
                  <Switch
                    style={{flex: 1, marginTop: 0}}
                    onValueChange={this.toggleSwitch}
                    value={this.state.switchValue}
                  />
                </View>
                <View style={styles.zoneViewSwitch}>
                  <Text style={{flex: 4, color: '#fff'}}>
                    {translate('Notification push')}
                  </Text>
                  <Switch
                    style={{flex: 1, marginTop: 0}}
                    onValueChange={this.toggleSwitch}
                    value={this.state.switchValue}
                  />
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
  },
  container: {
    flex: 1,
    backgroundColor: '#000004',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  containerBaseInfo: {
    flex: 1,
    backgroundColor: '#121212',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
    paddingTop: 60,
  },
  enteteText: {
    fontSize: 18,
    fontFamily: 'arial-black',
    fontWeight: '200',
    paddingBottom: 10,
    paddingTop: 10,
    textAlign: 'left',
    color: '#ffffff',
  },
  TextTitleSection: {
    fontSize: 18,
    fontFamily: 'arial-black',
    fontWeight: '200',

    textAlign: 'left',
    color: '#000',
  },
  fullName: {
    color: '#ffffff',
    fontSize: 14,
    fontFamily: 'arial-black',
    fontWeight: '200',
  },
  Adress: {
    color: '#ffffff',
    fontSize: 12,
    fontFamily: 'arial',
    marginTop: 20,
  },
  BtnButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    fontFamily: 'arial',
    fontSize: 12,
    borderColor: '#ffffff',
    borderRadius: 50,
    paddingTop: 5,
    paddingLeft: 30,
    paddingRight: 30,
    flex: 1,
  },
  containerBaseInfoSetting: {
    flex: 1,
    backgroundColor: '#B164C5',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
    paddingTop: 30,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 10,
  },
  zoneViewSwitch: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 30,
  },
});
