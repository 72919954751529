import * as React from 'react';

import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import * as RNLocalize from 'react-native-localize';
import moment from 'moment';

import ChartJs from './ChartJs/ChartJs';
import Home from './Screens/Home';
import AcceuilTabs from './Screens/Accueil/AcceuilTabs';
import Login from './Screens/Login';
import Registration from './Screens/Registration/Registration';
import Systemes from './Screens/Systemes';
import Alertes from './Screens/Alertes';
import Profil from './Screens/Profil';
import GererMesFavoris from './Screens/GererMesFavoris';
import GererMesRaccourcis from './Screens/GererMesRaccourcis';
import NouveauBassin from './Screens/Profil/NouveauBassin';
import NewUser from './Screens/Profil/NewUser';
import ProfilInfo from './Screens/Profil/Info';
import ResetPassword from './Screens/ResetPassword/ResetPassword';
import NewPassword from './Screens/ResetPassword/NewPassword';
import ResetPasswordThx from './Screens/ResetPassword/ResetPasswordThx';
import AppareilVerouillee from './Screens/AppareilVerouillee';
import DevicesDetail from './Screens/DevicesDetail';
import SenssorDetail from './Screens/SenssorDetail';
import StripDetail from './Screens/StripDetail';
import ProfilInfoEdit from './Screens/Profil/Edit';
import Scan from './Screens/Scan';
import AR from './Screens/AR';
import BandResults from './Screens/BandResults';
import NeedLogin from './Screens/NeedLogin';
import WifiSetup from './Screens/Wifi/Setup';
import Calibration from './Screens/Calibration';

const Stack = createStackNavigator();

const local = RNLocalize.getLocales()[0].languageCode;
moment.locale(local);

function AppNavigator() {
  const linking = {
    prefixes: ['swimoapp://swimo'],
    config: {
      screens: {
        NewPassword: {
          path: 'reset-password/:code',
          parse: {
            code: String,
          },
        },
      },
    },
  };

  return (
    <NavigationContainer
      theme={{
        colors: {
          background: 'black',
        },
      }}
      linking={linking}>
      <Stack.Navigator
        initialRouteName="Home"
        screenOptions={{
          cardStyle: {backgroundColor: '#000'},
          headerShown: false,
        }}>
        <Stack.Screen name="Home" component={Home} />
        <Stack.Screen name="ChartJs" component={ChartJs} />
        <Stack.Screen name="SenssorDetail" component={SenssorDetail} />
        <Stack.Screen name="StripDetail" component={StripDetail} />
        <Stack.Screen name="Calibration" component={Calibration} />
        <Stack.Screen name="AcceuilTabs" component={AcceuilTabs} />
        <Stack.Screen name="GererMesFavoris" component={GererMesFavoris} />
        <Stack.Screen
          name="GererMesRaccourcis"
          component={GererMesRaccourcis}
        />
        <Stack.Screen name="Scan" component={Scan} />
        <Stack.Screen name="WifiSetup" component={WifiSetup} />
        <Stack.Screen name="ScanAR" component={AR} />
        <Stack.Screen name="BandResults" component={BandResults} />
        <Stack.Screen name="NeedLogin" component={NeedLogin} />
        <Stack.Screen name="Systemes" component={Systemes} />
        <Stack.Screen name="Login" component={Login} />
        <Stack.Screen name="Registration" component={Registration} />
        <Stack.Screen name="Alertes" component={Alertes} />
        <Stack.Screen name="Profil" component={Profil} />
        <Stack.Screen name="ProfilInfo" component={ProfilInfo} />
        <Stack.Screen name="ProfilInfoEdit" component={ProfilInfoEdit} />
        <Stack.Screen name="ResetPassword" component={ResetPassword} />
        <Stack.Screen name="ResetPasswordThx" component={ResetPasswordThx} />
        <Stack.Screen name="NewPassword" component={NewPassword} />
        <Stack.Screen name="NouveauBassin" component={NouveauBassin} />
        <Stack.Screen name="NewUser" component={NewUser} />
        <Stack.Screen
          name="AppareilVerouillee"
          component={AppareilVerouillee}
        />
        <Stack.Screen name="DevicesDetail" component={DevicesDetail} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default AppNavigator;
