import React, {useEffect, useState} from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  ScrollView,
  TouchableWithoutFeedback,
  View,
  SafeAreaView,
} from 'react-native';

import styles from './styles.js';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {Button, Input, Text, Header, CheckBox} from 'react-native-elements';

import HeaderLeftComponent from '../../components/HeaderLeftComponent';
import translate, {translateString} from '../../translations/translate';
import {appHeaderHeight} from '../../Config/Config';

const Step1 = ({nextStep, navigation, setSerialNumber}) => {
  const [checkboxText, setCheckboxText] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const load = async () => {
      const text = await translateString(
        'You have to be connected to the controller to be able to access it locally',
      );
      setCheckboxText(text);
    };

    load();
  }, []);

  return (
    <SafeAreaView contentContainerStyle={{flexGrow: 1}}>
      <Header
        containerStyle={{
          backgroundColor: '#289EFF',
          height: appHeaderHeight(),
          paddingTop: 0,
          margin: 0,
          borderBottomColor: '#289EFF',
        }}
        leftComponent={
          <HeaderLeftComponent
            navigation={navigation}
            goToBack={navigation.goBack}
          />
        }
      />
      <KeyboardAwareScrollView contentContainerStyle={{flexGrow: 1}}>
        <KeyboardAvoidingView style={styles.containerView}>
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <ScrollView
              contentContainerStyle={{height: '100%', paddingBottom: 40}}>
              <Text style={styles.enteteStepText}>
                {translate('Wifi setup')}{' '}
              </Text>

              <Input
                selectionColor={'white'}
                onChangeText={val => setSerialNumber(val)}
                label={translate('Numero de serie', styles.FormInputlabelStyle)}
                labelStyle={styles.FormInputlabelStyle}
                containerStyle={styles.FormInputcontainerStyleStep2}
                inputContainerStyle={styles.FormInputContainerStyle}
                inputStyle={[
                  styles.FormTextInput,
                  false ? styles.FormTextInputError : {},
                ]}
                placeholder=""
                errorStyle={styles.FormInputErrorStyle}
                errorMessage={errors.ns}
              />
              <CheckBox title={<Text>{checkboxText}</Text>} />
              <View
                style={{
                  flex: 1,
                  marginTop: 30,
                  marginBottom: 30,
                }}>
                <View style={{alignSelf: 'center'}}>
                  <Button
                    onPress={() => nextStep(2)}
                    buttonStyle={styles.BtnButton}
                    title={translate('continuer')}
                  />
                </View>
              </View>
            </ScrollView>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

export default Step1;
