import React from 'react';
import {StyleSheet, View} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import RemoteControlle from './widget/RemoteControlle';
import RemoteControlleVitesse from './widget/RemoteControlleVitesse';

class WidgetDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  renderByType = () => {
    const {device_widget, device_number, device_type} = this.props.device;

    let index = this.props.device_list.findIndex(_item => {
      return _item.device_type === device_type;
    });
    //  console.log("this.props.device_list ",device_hash,device_number,this.props.device,index)
    let assetsWidget = this.props.device_list[index];
    let Slot = [];
    if (this.props.slots[device_number]) {
      Slot = this.props.slots[device_number];
    }

    switch (parseInt(device_widget)) {
      case 0:
        return (
          <RemoteControlle
            typeComposent={'image'}
            {...this.props.device}
            assetsWidget={assetsWidget}
            slot={Slot}
            navigation={this.props.navigation}
          />
        );
      case 1:
        return (
          <RemoteControlle
            typeComposent={'progress'}
            {...this.props.device}
            assetsWidget={assetsWidget}
            slot={Slot}
            navigation={this.props.navigation}
          />
        );
      case 2:
        return (
          <RemoteControlle
            typeComposent={'speed'}
            {...this.props.device}
            assetsWidget={assetsWidget}
            slot={Slot}
            navigation={this.props.navigation}
          />
        );
      case 3:
        // console.log("RemoteControlleVitesse");
        return (
          <RemoteControlleVitesse
            {...this.props.device}
            assetsWidget={assetsWidget}
            slot={Slot}
            navigation={this.props.navigation}
          />
        );
    }
  };

  render() {
    return <View style={styles.container}>{this.renderByType()}</View>;
  }
}

const styles = StyleSheet.create({
  container: {},
});

WidgetDevice.propTypes = {
  device: PropTypes.object,
  current_system: PropTypes.object,
  base_list: PropTypes.array,
  device_list: PropTypes.array,
  slots: PropTypes.array,
  navigation: PropTypes.any,
};

WidgetDevice.defaultProps = {
  device: {},
  navigation: {},
  current_system: {},
  device_list: [],
  slots: [],
};

const mapStateToProps = (state, ownProps) => {
  return {
    base_list: state.AppRedux.base_list,
    current_system: state.SystemsRedux.system,
    date: state.SystemsRedux.date,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    last_update_data_redux_system: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetDevice);
