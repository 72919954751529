import Model from './Model';
import {get_api_host} from '../Config/Config';
export default class Auth extends Model {
  constructor() {
    let data = {};

    super(data, '');
  }

  Login(_user, code) {
    console.log('Login App');
    this.setUrl(get_api_host() + '/get_token');
    this.setHeaders({user: _user, code: code});
    return this.fetch({}).then(response => {
      console.log('Login ', response.data);
      return new Promise((resolve, reject) => {
        if (response.status === 200 && response.data.hasOwnProperty('appid')) {
          resolve(response.data);
        } else {
          if (typeof response.data !== 'string') {
            reject(response.data);
          } else {
            reject({error: 'ERROR_SYSTEM'});
          }
        }
      });
    });
  }

  ResetPassword(_user) {
    this.setUrl(get_api_host() + '/set_pass?key=0');
    this.setHeaders({user: _user});

    return this.fetch({}).then(response => {
      return new Promise((resolve, reject) => {
        if (response.status === 200 && !response.data.hasOwnProperty('error')) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      });
    });
  }

  RenewPassword(_user, _pwd, _appid) {
    this.setUrl(get_api_host() + '/set_pass?key=1');
    this.setHeaders({user: _user, code: _pwd, appid: _appid});
    return this.fetch({}).then(response => {
      return new Promise((resolve, reject) => {
        if (response.status === 200 && !response.data.hasOwnProperty('error')) {
          if (
            response.data.hasOwnProperty('answer') &&
            response.data.answer === '_WRONG_TOKEN'
          ) {
            reject('_WRONG_TOKEN');
          } else {
            resolve(response.data);
          }
        } else {
          reject(response.data.error);
        }
      });
    });
  }
}
