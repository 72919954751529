import React from 'react';
import {
  SafeAreaView,
  View,
  Text,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import {connect} from 'react-redux';

import {Header, Input, Button} from 'react-native-elements';

import {fontFamilyBold, fontFamilyRegular} from '../Config/fonts';
import {
  appHeaderHeight,
  setStoreData,
  getStoreData,
} from '../Config/Config';

import HeaderLeftComponent from '../components/HeaderLeftComponent';
import WidgetCard from '../components/widget/Card';
import Loader from '../components/Loader';
import translate from '../translations/translate';
import LinearGradient from 'react-native-linear-gradient';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

import {addNewBand} from '../Store/Systems/SystemsActions';

const FIELDS = [
  {unit: 'ppm', min: 250, max: 580, label: 'Hardness', id: 'd1'},
  {unit: 'ppm', min: 1, max: 3, label: 'Total chlorine', id: 'd2'},
  {unit: 'ppm', min: 1, max: 3, label: 'Free Chlorine', id: 'd3'},
  {unit: 'pt', min: 7.1, max: 7.3, label: 'pH', id: 'd4'},
  {unit: 'ppm', min: 80, max: 120, label: 'Alcalinity', id: 'd5'},
  {unit: 'ppm', min: 0, max: 0, label: 'Cyanuric Acid', id: 'd6'},
  {unit: '', label: 'Temperature', hideScale: true, id: 'd7'},
];

const Content = ({
  id,
  label,
  unit,
  value,
  isWait = false,
  min,
  max,
  onChangeText,
  hideScale = false,
}) => {
  return (
    <View
      style={{
        display: 'flex',
        color: 'white',
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      {label}
      <View style={{flexDirection: 'column'}}>
        <View style={styles.formContainer}>
          <Input
            onChangeText={val => onChangeText(val, id)}
            containerStyle={styles.FormInputcontainerStyle}
            inputContainerStyle={styles.FormInputContainerStyle}
            inputStyle={[styles.FormTextInput]}
            keyboardType={'numeric'}
            value={value}
            rightIcon={isWait}
          />
          <Text style={{color: 'white'}}>{unit}</Text>
        </View>
        {!hideScale && (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              position: 'absolute',
              top: 65,
              left: -10,
            }}>
            <Text style={{color: 'white', width: 30, textAlign: 'right'}}>
              {min}
            </Text>
            <LinearGradient
              useAngle
              angle={20}
              style={{height: 2, width: 150, marginLeft: 10, marginRight: 10}}
              colors={['#ffffff', '#3A57FB']}
            />
            <Text style={{color: 'white', width: 30}}>{max}</Text>
          </View>
        )}
      </View>
    </View>
  );
};

class BandResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      measurements: {},
      isLoading: false,
    };
  }

  async componentDidMount() {
    const {results, id} = this.props.route.params || {};

    let cachedResult = {
      d1: results[0] !== undefined && results[0].toString(),
      d2: results[1] !== undefined && results[1].toString(),
      d3: results[2] !== undefined && results[2].toString(),
      d4: results[3] !== undefined && results[3].toString(),
      d5: results[4] !== undefined && results[4].toString(),
      d6: results[5] !== undefined && results[5].toString(),
    };

    if (!results) {
      try {
        cachedResult = await getStoreData('BandResults');
      } catch (err) {}
    }

    this.setState({
      measurements: cachedResult || {},
      id,
    });
  }

  save = async () => {
    if (!this.props.appId) {
      // If not logged in, cache the values
      setStoreData('BandResults', this.state.measurements);
      // Set a flag so we now we need to restore?
      this.props.navigation.navigate('NeedLogin');
      return;
    }
    this.setState({isLoading: true});
    await this.props.addBand(this.props.appId, {
      id: this.state.id,
      ...this.state.measurements,
    });
    this.setState({isLoading: false});
    // TODO: navigate to the home screen?
    this.props.navigation.navigate({
      name: 'AcceuilTabs',
      params: {
        goToTab: 'strips',
      },
    });
  };

  onChange = (val, id) => {
    if (!id) {
      return false;
    }
    this.setState(s => {
      s.measurements[id] = val;

      return s;
    });
  };

  render() {
    return (
      <SafeAreaView style={styles.containerfull}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          leftComponent={
            <HeaderLeftComponent
              navigation={this.props.navigation}
              goToBack={this.props.navigation.goBack}
            />
          }
        />
        <KeyboardAwareScrollView
          contentContainerStyle={{flexGrow: 1}}
          style={styles.container}>
          <View style={styles.container}>
            <View style={{flexDirection: 'row', paddingBottom: 0}}>
              <View
                style={{
                  flex: 1,
                  borderColor: '#fff',
                  borderTopWidth: 4,
                  marginTop: 10,
                }}
              />
              {translate('Band', styles.enteteText)}
              <View
                style={{
                  flex: 5,
                  borderColor: '#fff',
                  borderBottomWidth: 4,
                  marginBottom: 10,
                }}
              />
            </View>
            {FIELDS.map(f => (
              <WidgetCard>
                <Content
                  id={f.id}
                  label={translate(f.label, {
                    color: 'white',
                    width: 100,
                    maxWidth: 100,
                    fontFamily: fontFamilyBold(),
                  })}
                  onChangeText={this.onChange}
                  value={this.state.measurements[f.id]}
                  unit={f.unit}
                  min={f.min}
                  max={f.max}
                  hideScale={f.hideScale}
                />
              </WidgetCard>
            ))}
            <View
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                width: '100%',
                height: 50,
                marginTop: 20,
                marginBottom: 20,
              }}>
              {this.state.isLoading ? (
                <ActivityIndicator size={100} color="#ffffff" />
              ) : (
                <Button
                  buttonStyle={styles.BtnButton}
                  title={translate('Save')}
                  onPress={this.save}
                />
              )}
            </View>
          </View>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
  },
  container: {
    flex: 1,
    backgroundColor: '#000004',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  enteteText: {
    fontSize: 45,
    fontWeight: '300',
    padding: 10,
    textAlign: 'left',
    color: '#ffffff',
    fontFamily: fontFamilyRegular(),
  },
  containerFixed: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    zIndex: 200,
  },
  btnadd: {
    right: 0,
    fontSize: 30,
    marginRight: 15,
    marginTop: 10,
    color: '#289EFF',
    fontFamily: fontFamilyBold(),
    textAlign: 'right',
  },
  formContainer: {
    flex: 1,
    width: 200,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#121212',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#121212',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
  },
  FormInputcontainerStyle: {
    borderBottomWidth: 0,
    marginBottom: 0,
  },
  FormInputContainerStyle: {
    marginBottom: 5,
    height: 43,
    borderRadius: 50,
    borderWidth: 1,
    backgroundColor: '#242424',
    borderColor: '#242424',
    color: '#fff',
    marginTop: 5,
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
  FormTextInput: {
    height: 43,
    fontSize: 14,
    color: '#fff',
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
    textAlign: 'center',
  },
  BtnButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    fontFamily: 'arial',
    fontSize: 12,
    borderColor: '#ffffff',
    borderRadius: 50,
    paddingTop: 5,
    paddingLeft: 15,
    paddingRight: 15,
    flex: 1,
    marginRight: 20,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    appId:
      state.SystemsRedux?.system?.system &&
      state.SystemsRedux?.system?.system[0]?.sys_appid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addBand: (appId, data) => {
      return dispatch(addNewBand(appId, data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BandResults);
