import * as React from 'react';
import {Path} from 'react-native-svg';

const {PI, cos, sin} = Math;
// const A = PI + PI * 0.4;
const startAngle = PI + PI * 0.2;
const endAngle = 2 * PI - PI * 0.2;

export default ({
  cx,
  cy,
  r,
  stroke,
  strokeWidth,
  fill,
  strokeDasharray,
  strokeDashoffset,
  strokeLinecap,
}) => {
  // A rx ry x-axis-rotation large-arc-flag sweep-flag x y
  const x1 = cx - r * cos(startAngle);
  const y1 = -r * sin(startAngle) + cy;
  const x2 = cx - r * cos(endAngle);
  const y2 = -r * sin(endAngle) + cy;
  const d = `M ${x1} ${y1} A ${r} ${r} 0 1 0 ${x2} ${y2}`;

  return (
    <Path
      stroke={stroke}
      fill={fill}
      strokeDasharray={strokeDasharray}
      strokeDashoffset={strokeDashoffset}
      strokeLinecap={strokeLinecap}
      {...{d, strokeWidth}}
    />
  );
};
