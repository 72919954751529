import React from 'react';
import {StyleSheet, Text, View, Modal, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import {Icon, Input} from 'react-native-elements';
import {fontFamilyBold, fontFamilyRegular} from '../../Config/fonts';
import * as actions from '../../Store/Systems/SystemsActions';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  showSnaskMessageOperationSuccess,
} from '../../Config/Config';
import ThreeDotsLoader from '../ThreeDotsLoader';
import translate from '../../translations/translate';

class Consigne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWait: false,
      isshow: false,
      device_setpoint: 0,
      device_unit_setpoint: '',
      device_number: '',
      sys_appid: '',
      longPressProgressVal: {
        ispressed: false,
        Interval: null,
      },
    };
  }

  initData = () => {
    this.setState({
      device_setpoint: this.props.device_setpoint || 0,
      device_unit_setpoint: this.props.device_unit_setpoint,
      sys_appid: this.props.sys_appid,
      device_number: this.props.device_number,
    });
  };
  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(nextProps) {
    const {device_number} = this.props;
    if (nextProps.device_number !== device_number) {
      this.initData();
    }
  }
  componentWillUnmount() {}

  UpdateStatusDevices = val => {
    if (this.state.isWait) {
      return true;
    }
    this.setState({isWait: true});
    //console.log(this.props.sys_appid)
    this.props
      .UpdateDevice(this.props.sys_appid, {
        key: 'device_setpoint',
        value: this.state.device_setpoint,
        number: this.state.device_number,
      })
      .then(data => {
        this.setState({SelectedBtn: val});
        this.setState({isWait: false});
        this.props.refeshDevice();
        showSnaskMessageOperationSuccess();
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };

  UpdateprogressVal = operation => {
    let val = parseFloat(this.state.device_setpoint);

    let step = 0.5;
    if (val >= 100) {
      step = 10;
    } else if (val >= 10 && val < 100) {
      step = 1;
    } else {
      step = 0.1;
    }

    if (operation === '-') {
      if (val - step > 0) {
        val = val - step;
      } else {
        val = 0;
      }
    } else {
      val = val + step;
    }
    val = val.toFixed(1);
    this.setState({device_setpoint: val});
    // this.UpdateStatusDevices(val);
  };

  closeModal = () => {
    console.log('Close Modal ');
    this.setState({isshow: false});

    if (
      this.state.device_unit_setpoint !=
      parseInt(this.props.device_unit_setpoint)
    ) {
      this.UpdateStatusDevices(this.state.device_unit_setpoint);
    }
    //
  };

  EndUpdateonLongPressprogressVal = op => {
    /**
         * longPressProgressVal:{
                ispressed:false,
                Interval:null,
            }
         */

    if (this.state.longPressProgressVal.ispressed) {
      clearInterval(this.state.longPressProgressVal.Interval);
      this.setState({
        longPressProgressVal: {
          ispressed: false,
          Interval: null,
        },
      });

      // this.UpdateStatusDevices('device_standard', this.state.progressVal);

      // console.log("Send to Update ");
    }
  };
  UpdateonLongPressprogressVal = op => {
    if (!this.state.longPressProgressVal.ispressed) {
      var myVar = setInterval(() => {
        this.UpdateprogressVal(op);
      }, 100);
      this.setState({
        longPressProgressVal: {
          ispressed: true,
          Interval: myVar,
        },
      });
    }
  };
  RenderModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.isshow}
        onRequestClose={() => {}}>
        <View style={styles.Modalcontainer}>
          <View
            style={{
              justifyContent: 'center',
              backgroundColor: '#000000',
              borderRadius: 20,
              padding: 15,
              width: '90%',
            }}>
            <View
              style={{position: 'absolute', right: 10, top: 10, zIndex: 20}}>
              <TouchableOpacity onPress={() => this.closeModal()}>
                <Icon
                  size={20}
                  containerStyle={{marginLeft: 10}}
                  name="check"
                  type="font-awesome"
                  color="#fff"
                />
              </TouchableOpacity>
            </View>
            <View>
              <View style={[styles.ViewRow, {marginTop: 30, marginBottom: 30}]}>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <TouchableOpacity
                    onPress={() => this.UpdateprogressVal('-')}
                    delayLongPress={1000}
                    onPressIn={() => {
                      //console.log('TouchableOpacity onPressIn');
                      // console.log(Date.now())
                    }}
                    onPressOut={() => {
                      // console.log('TouchableOpacity onPressOut');
                      //console.log(Date.now())
                      this.EndUpdateonLongPressprogressVal('-');
                    }}
                    onLongPress={e => {
                      //console.log(' onLongPress');
                      //console.log(Date.now());

                      this.UpdateonLongPressprogressVal('-');
                    }}>
                    <View style={styles.CircleShapeView}>
                      <Icon
                        iconStyle={{marginTop: 0, marginLeft: 0}}
                        name="minus"
                        type="font-awesome"
                        size={20}
                        underlayColor={'#000'}
                        color={'#000'}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <View style={{flexDirection: 'row'}}>
                    <Text
                      style={{
                        fontSize: 30,
                        fontFamily: fontFamilyBold(),
                        color: '#fff',
                      }}>
                      {this.state.device_setpoint}
                    </Text>
                    <Text style={{fontSize: 12, marginTop: 20, color: '#fff'}}>
                      {this.props.device_unit_setpoint}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <TouchableOpacity
                    delayLongPress={1000}
                    onPress={() => {
                      this.UpdateprogressVal('+');
                    }}
                    onPressIn={() => {
                      //console.log('TouchableOpacity onPressIn');
                      // console.log(Date.now())
                    }}
                    onPressOut={() => {
                      // console.log('TouchableOpacity onPressOut');
                      //console.log(Date.now())
                      this.EndUpdateonLongPressprogressVal('+');
                    }}
                    onLongPress={e => {
                      //console.log(' onLongPress');
                      //console.log(Date.now());

                      this.UpdateonLongPressprogressVal('+');
                    }}>
                    <View style={styles.CircleShapeView}>
                      <Icon
                        iconStyle={{marginTop: 0, marginLeft: 0}}
                        name="plus"
                        type="font-awesome"
                        size={20}
                        underlayColor={'#000'}
                        color={'#000'}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    );
  };
  render() {
    return (
      <View style={styles.container}>
        <Text
          style={{
            color: '#fff',
            fontSize: 18,
            fontFamily: fontFamilyBold(),
            marginBottom: 10,
          }}>
          {translate('consigne')} : {this.state.device_setpoint}{' '}
          {this.state.device_unit_setpoint}
        </Text>

        <View>
          <View style={[styles.ViewRow, {marginTop: 20}]}>
            <Input
              containerStyle={styles.FormInputcontainerStyle}
              inputContainerStyle={styles.FormInputContainerStyle}
              inputStyle={[styles.FormTextInput]}
              editable={false}
              value={
                this.state.device_setpoint.toString() +
                ' ' +
                this.state.device_unit_setpoint
              }
              rightIcon={
                this.state.isWait ? (
                  <ThreeDotsLoader />
                ) : (
                  <View>
                    <Icon
                      reverse
                      color="#242424"
                      activeOpacity={1}
                      containerStyle={{width: 60, height: 60}}
                      iconStyle={{marginTop: 0, marginLeft: 0}}
                      name={'pencil'}
                      type="font-awesome"
                      size={20}
                      underlayColor={'#fff'}
                      onPress={() => {
                        this.setState({isshow: true});
                      }}
                    />
                  </View>
                )
              }
            />
          </View>
        </View>

        {this.RenderModal()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#121212',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
  },
  ViewRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  CircleShapeView: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    borderRadius: 80 / 2,
    borderWidth: 1,
    borderColor: '#fff',
    backgroundColor: '#fff',
  },
  FormInputcontainerStyle: {
    borderBottomWidth: 0,
    marginBottom: 0,
  },
  ValStyle: {
    color: '#fff',
    textAlign: 'center',
  },
  FormInputContainerStyle: {
    borderBottomWidth: 0,
    marginBottom: 5,
    borderWidth: 1,
    borderColor: '#242424',
    backgroundColor: '#242424',
    borderRadius: 50,
  },
  FormTextInput: {
    height: 43,
    fontSize: 18,
    color: '#fff',
    paddingLeft: 10,
    marginTop: 5,
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
    textAlign: 'center',
  },
  Modalcontainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(154, 152, 152, 0.84)',
    flexDirection: 'column',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Consigne);
