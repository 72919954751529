import React from 'react';
import styles from './styles.js';
import {
  Keyboard,
  KeyboardAvoidingView,
  Picker,
  SafeAreaView,
  TouchableWithoutFeedback,
  View,
  TouchableOpacity,
} from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

import translate, { translateString } from '../../translations/translate';
import {Button, Input, Text, ButtonGroup, Header, Icon} from 'react-native-elements';

import {connect} from 'react-redux';
import * as actions from '../../Store/Registration/RegistrationActions';
import {msg_code_to_text, ShowSnaskMessage, appHeaderHeight, appHeaderTop} from '../../Config/Config';

class HeaderRightComponent extends React.Component {
  render() {
    return (
      <TouchableOpacity
        hitSlop={{top: 10, left: 30, right: 30, bottom: 30}}
        onPress={() => this.props.goToBack()}
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}>
        <Icon size={30} name="times" type="font-awesome" color="#fff" />
      </TouchableOpacity>
    );
  }
}

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iswait: false,
      formData: {
        user_unit: 0,
        user_type: 0,
        user_lang: 'fr',
        user_firstname: null,
        user_name: null,
      },
      user_unit: 0,
      user_type: 1,
      user_lang: 'fr',
      guest: '',
      master: '',
      validateAndContinue: '',
      errors: {
        user_unit: null,
        user_type: null,
        user_lang: null,
        user_firstname: null,
        user_name: null,
      },
    };
  }

  componentDidMount = async () => {
    const guest = await translateString('invitee');
    const master = await translateString('principal');
    const validateAndContinue = await translateString('Valider et continuer');

    this.setState({guest, validateAndContinue, master});
  };

  onChangeField = (fieldName, val) => {
    let _formData = this.state.formData;
    let _errors = this.state.errors;

    _formData[fieldName] = val;

    this.setState({formData: _formData, errors: _errors});
  };
  ValidateFormData = () => {
    let isError = false;
    return isError;
  };
  handleChangeOption = (fieldName, val) => {
    let _formData = this.state.formData;
    if (fieldName === 'user_type') {
      this.setState({user_type: val});
    }

    if (fieldName === 'user_lang') {
      this.setState({user_lang: val});
    }

    _formData[fieldName] = val;
    this.setState({formData: _formData});
  };
  SubmitBtn = () => {
    if (!this.ValidateFormData()) {
      this.setState({iswait: true});
      this.props
        .NextStep(this.state.formData, this.props.userAppId)
        .then(userInfo => {
          this.setState({iswait: false});

          if (this.props.EndStep) {
            this.props.EndStep();
          }
          if (this.state.user_type) {
            this.props.nextStep(3);
          } else {
            this.props.nextStep(4);
          }
        })
        .catch(error => {
          let errormsg = msg_code_to_text(error);
          ShowSnaskMessage({
            message: errormsg,
            textColor: '#fff',
            backgroundColor: 'red',
            buttonColor: 'red',
            duration: 2000,
          });
          this.setState({iswait: false});
        });
    }
  };
  SelectedChoicTypee = selectedIndex => {
    this.onChangeField('user_unit', selectedIndex);
    this.setState({user_unit: selectedIndex});
  };
  componentDidMount() {
    this.handleChangeOption('user_type', this.props.usertype);
  }
  renderUserTypePickerAndroid = () => {
    if (Platform.OS !== 'ios') {
      return (
        <View style={styles.ViewPicker}>
          <Picker
            style={styles.Picker}
            selectedValue={this.state.user_type}
            onValueChange={(itemValue, itemIndex) => {
              this.handleChangeOption('user_type', itemValue);
            }}>
              <Picker.Item label={this.state.master || ''} value={0} />
              <Picker.Item label={this.state.guest || ''} value={1} />
          </Picker>
        </View>
      );
    }
  };
  renderUserLangPickerAndroid = () => {
    if (Platform.OS !== 'ios') {
      return ( <View style={styles.ViewPicker}>
        <Picker
          selectedValue={this.state.user_lang}
          style={styles.Picker}
          onValueChange={(itemValue, itemIndex) => {
            this.handleChangeOption('user_lang', itemValue);
          }}>
          <Picker.Item label="English" value={'en'} />
          <Picker.Item label="Français" value={'fr'} />
          <Picker.Item label="Espanol" value={'es'} />
          <Picker.Item label="Italiano" value={'it'} />
          <Picker.Item label="Dutch" value={'nl'} />
          <Picker.Item label="German" value={'de'} />
          <Picker.Item label="Portugese" value={'pt'} />
        </Picker>
      </View>);
    }
  };
  renderUserTypePickerIOS = () => {
    if (Platform.OS === 'ios') {
      return (
        <View style={[styles.ViewPicker, {padding: 10}]}>
          <RNPickerSelect
            placeholder={{}}
            onValueChange={itemValue => {
              this.handleChangeOption('user_type', itemValue);
            }}
            value={this.state.user_type}
            style={{
              ...styles.Picker,
              iconContainer: {
                top: 20,
                right: 10,
              },
              inputIOS: {
                color: '#fff'
              },
            }}
            items={[{value: 1, label: this.state.guest}, {value: 0, label: this.state.master}]}
          />
        </View>
      );
    }
  };
  renderUserLangPickerIOS = () => {
    if (Platform.OS === 'ios') {
      return (
        <View style={[styles.ViewPicker, {padding: 10}]}>
          <RNPickerSelect
            placeholder={{}}
            onValueChange={itemValue => {
              this.handleChangeOption('user_lang', itemValue);
            }}
            value={this.state.user_lang}
            style={{
              iconContainer: {
                top: 20,
                right: 10,
              },
              inputIOS: {
                color: '#fff'
              },
            }}
            items={[{value: 'en', label: 'English'}, {value: 'fr', label: 'Français'}, {value: 'es', label: 'Español'}, {value: 'it', label: 'Italiano'}, {value: 'nl', label: 'Dutch'}, {value: 'de', label: 'German'}, {value: 'pt', label: 'Portugese'}]}
          />
        </View>
      );
    }
  };
  goToBack = () => {
    this.props.goBack();
  };
  render() {
    return (
      <SafeAreaView contentContainerStyle={{flexGrow: 1}}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          rightComponent={<HeaderRightComponent goToBack={this.goToBack} />}
        />
        <KeyboardAwareScrollView contentContainerStyle={{flexGrow: 1}}>
          <KeyboardAvoidingView style={styles.containerView} behavior="padding">
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
              <View style={styles.RegisterFormView}>
                <Text style={styles.subenteteText}>
                  {this.props.EndStep ? translate('2/2') : translate('2/3')}{' '}
                </Text>
                <Text style={styles.enteteStepText}>
                  {translate('Mes Information')}{' '}
                </Text>
                <View>
                  <Text style={styles.inputTextLabel}>
                    {translate('Type de bassin :')}{' '}
                  </Text>
                  <ButtonGroup
                    onPress={this.SelectedChoicTypee}
                    buttonStyle={styles.BtnChoise}
                    innerBorderStyle={{width: 0}}
                    selectedButtonStyle={[
                      styles.BtnChoise,
                      styles.BtnChoiseSelected,
                    ]}
                    textStyle={[styles.BtnChoisetextStyle]}
                    selectedTextStyle={[styles.BtnChoiseSelected]}
                    containerStyle={{
                      backgroundColor: 'transparent',
                      borderWidth: 0,
                    }}
                    selectedIndex={this.state.user_unit}
                    buttons={['°C', 'F°']}
                  />
                </View>
                <View style={styles.zoneView}>
                  <Text style={styles.TextPicker}>
                    {translate('Type de compte')}
                  </Text>
                  {this.renderUserTypePickerAndroid()}
                  {this.renderUserTypePickerIOS()}
                </View>
                <View style={styles.zoneView}>
                  <Text style={styles.TextPicker}>{translate('Langue')}</Text>
                  {this.renderUserLangPickerAndroid()}
                  {this.renderUserLangPickerIOS()}
                </View>

                {this.state.user_type === 0 ? (
                  <View>
                    <Input
                      selectionColor={'white'}
                      onChangeText={val =>
                        this.handleChangeOption('user_name', val)
                      }
                      
                      label={translate(
                      'Nom',
                      styles.FormInputlabelStyle,
                      )}
                      labelStyle={styles.FormInputlabelStyle}
                      containerStyle={styles.FormInputcontainerStyle}
                      inputContainerStyle={styles.FormInputContainerStyle}
                      inputStyle={[
                        styles.FormTextInput,
                        this.state.errors.user_name
                          ? styles.FormTextInputError
                          : {},
                      ]}
                      placeholder=""
                      errorStyle={styles.FormInputErrorStyle}
                      errorMessage={this.state.errors.user_name}
                    />

                    <Input
                      selectionColor={'white'}
                      onChangeText={val =>
                        this.handleChangeOption('user_firstname', val)
                      }
                      label={translate(
                      'Prenom',
                      styles.FormInputlabelStyle,
                      )}
                      labelStyle={styles.FormInputlabelStyle}
                      containerStyle={styles.FormInputcontainerStyle}
                      inputContainerStyle={styles.FormInputContainerStyle}
                      inputStyle={[
                        styles.FormTextInput,
                        this.state.errors.user_firstname
                          ? styles.FormTextInputError
                          : {},
                      ]}
                      placeholder=""
                      errorStyle={styles.FormInputErrorStyle}
                      errorMessage={this.state.errors.user_firstname}
                    />
                  </View>
                ) : (
                  <Text />
                )}

                <View style={{marginTop: 30, marginBottom: 30}}>
                  <View style={{alignSelf: 'center'}}>
                    <Button
                      loading={this.state.iswait}
                      onPress={this.SubmitBtn}
                      buttonStyle={styles.BtnButton}
                      title={translate('Valider et continuer', { color: 'white'})}
                    />
                  </View>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let appid = state.RegistrationRedux.step1
    ? state.RegistrationRedux.step1.token[0].appid
    : null;
  let user_type = state.RegistrationRedux.step1
    ? parseInt(state.RegistrationRedux.step1.user[0].user_type)
    : 0;

  return {
    userInfo: state.RegistrationRedux.step1,
    usertype: user_type,
    userAppId: appid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    NextStep: (data, appid) => {
      return dispatch(actions.RegisterStep2(data, appid));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Step2);
