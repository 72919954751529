import * as SystemsActions from './SystemsActions';
import {setCurrentSystemAppId} from '../../Models/Systems';

const initialState = {
  systems: [],
  system: {},
  clientInfo: {},
};

export default function SystemsReducer(state = initialState, action) {
  switch (action.type) {
    case SystemsActions.ACTION_SYSTEMS_GET_ALL_LOADING:
      return {
        ...state,
        getAllLoading: true,
        last_update_data_redux: Date.now(),
      };
    case SystemsActions.ACTION_SYSTEMS_GET_ALL:
      return {
        ...state,
        systems: action.systems,
        clientInfo: action.clientInfo,
        getAllLoading: false,
        last_update_data_redux: Date.now(),
      };
    case SystemsActions.ACTION_SYSTEMS_CURRENT:
      if (
        action.updateDate &&
        action.updateDate < state.last_update_data_redux
      ) {
        return state;
      }
      setCurrentSystemAppId(action.system.system[0].sys_appid);
      return {
        ...state,
        system: action.system,
        last_update_data_redux: Date.now(),
      };
    case SystemsActions.ACTION_SYSTEM_RESET:
      setCurrentSystemAppId(false);
      return {
        ...state,
        system: undefined,
        last_update_data_redux: Date.now(),
      };
    case SystemsActions.ACTION_SYSTEMS_RESET:
      return initialState;
    default:
      return state;
  }
}
