import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Alert,
  RefreshControl,
  SafeAreaView,
} from 'react-native';
import {connect} from 'react-redux';
import {Header, Icon, Input, Button} from 'react-native-elements';
import Mode from '../components/widget/Mode';
import Programmation from '../components/widget/Programmation';
import Consigne from '../components/widget/Consigne';
import Volume from '../components/widget/Volume';
import Consommation from '../components/widget/Consommation';
import Puissance from '../components/widget/Puissance';
import Capteur from '../components/widget/Capteur';
import DeviceEtalon from '../components/widget/DeviceEtalon';
import Vitesse from '../components/widget/Vitesse';
import {fontFamilyRegular} from '../Config/fonts';
import {
  appHeaderHeight,
  msg_code_to_text,
  ShowSnaskMessage,
  appHeaderTop,
} from '../Config/Config';
import ThreeDotsLoader from '../components/ThreeDotsLoader';
import * as actions from '../Store/Systems/SystemsActions';
import HeaderLeftComponent from '../components/HeaderLeftComponent';
import ButtonCustom from '../components/Button';
import translate, {translateString} from '../translations/translate';

class DevicesDetail extends React.Component {
  constructor(props) {
    super(props);

    let params = props.route.params;

    let slots = params.current_system.slots
      .filter(item => {
        return item.action_number === params.device_number;
      })
      .map(item => {
        let selectedDay = item.slot_days.split('').map(d => {
          return parseInt(d) - 1;
        });
        item.selectedDay = selectedDay;

        return item;
      });

    this.state = {
      system: null,
      slots,
      isLoading: false,
      devices: {},
      device_consumption: params.device_consumption,
      device_date: params.device_date,
      device_hash: params.device_hash,
      device_index: params.device_index,
      device_level: params.device_level,
      device_level_ref: params.device_level_ref,
      device_level_unit: params.device_level_unit,
      device_mode_available: params.device_mode_available,
      device_mode_display: params.device_mode_display,
      device_name: params.device_name,
      device_number: params.device_number,
      device_power: params.device_power,
      device_security: params.device_security,
      device_sensor: params.device_sensor,
      device_sequence: params.device_sequence,
      device_sequence_available: params.device_sequence_available,
      device_sequence_display: params.device_sequence_display,
      device_setpoint: params.device_setpoint,
      device_speed: params.device_speed,
      device_speed_min: params.device_speed_min,
      device_speed_max: params.device_speed_max,
      device_speed_unit: params.device_speed_unit,
      device_speed_gap: params.device_speed_gap,
      device_standard: params.device_standard,
      device_status: params.device_status,
      device_type: params.device_type,
      device_unit_power: params.device_unit_power,
      device_unit_setpoint: params.device_unit_setpoint,
      device_widget: params.device_widget,
      sys_appid: params.current_system.system[0].sys_appid,
      sensors: params.current_system.sensors,
      assetsWidget: params.assetsWidget,
      isshowMore: false,
      isWaitToRemove: false,
      modeEditName: false,
    };
  }

  refeshUpdateDevice = params => {
    this.setState({
      device_consumption: params.device_consumption,
      device_date: params.device_date,
      device_hash: params.device_hash,
      device_index: params.device_index,
      device_level: params.device_level,
      device_level_ref: params.device_level_ref,
      device_level_unit: params.device_level_unit,
      device_mode_available: params.device_mode_available,
      device_mode_display: params.device_mode_display,
      device_name: params.device_name,
      device_number: params.device_number,
      device_power: params.device_power,
      device_security: params.device_security,
      device_sensor: params.device_sensor,
      device_sequence: params.device_sequence,
      device_sequence_available: params.device_sequence_available,
      device_sequence_display: params.device_sequence_display,
      device_setpoint: params.device_setpoint,
      device_speed: params.device_speed,
      device_speed_min: params.device_speed_min,
      device_speed_max: params.device_speed_max,
      device_speed_unit: params.device_speed_unit,
      device_speed_gap: params.device_speed_gap,
      device_standard: params.device_standard,
      device_status: params.device_status,
      device_type: params.device_type,
      device_unit_power: params.device_unit_power,
      device_unit_setpoint: params.device_unit_setpoint,
      device_widget: params.device_widget,
      // sys_appid: params.current_system.system[0].sys_appid,
      //  sensors: params.current_system.sensors,
      // assetsWidget:params.assetsWidget,
    });
  };

  componentDidMount() {
    this.refeshDevice();
  }

  componentWillUnmount() {}

  onBlurField = fieldName => {
    this.UpdateDevices(fieldName, this.state.device_name);
  };

  ConfirmToRemoveDevice = async () => {
    Alert.alert(
      await translateString('suppression'),
      await translateString('confirmation suppression'),
      [
        {
          text: await translateString('annuler'),
          onPress: () => {},
          style: 'cancel',
        },
        {
          text: await translateString('confirmer'),
          onPress: () => this.RemoveDevice(),
        },
      ],
      {cancelable: false},
    );

    /* */
  };

  RemoveDevice = () => {
    this.setState({isWaitToRemove: true});
    this.props
      .RemoveDevice(this.state.sys_appid, {
        type: 'device',
        number: this.state.device_number,
      })
      .then(async response => {
        // console.log("RemoveDevice response ==> ",response);

        ShowSnaskMessage({
          message: 'operation success',
          textColor: '#000',
          backgroundColor: '#7ED321',
          buttonColor: '#7ED321',
          duration: 2000,
        });
        this.props.SystemGetAll(this.state.sys_appid);
        this.props.navigation.navigate('AcceuilTabs');
      })
      .catch(error => {
        this.setState({isWaitToRemove: false});
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };
  render() {
    console.log(
      'device_security ',
      this.state.device_security,
      this.state.sys_appid,
    );
    return (
      <SafeAreaView style={styles.containerfull}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
            marginTop: appHeaderTop(),
          }}
          leftComponent={
            <HeaderLeftComponent
              navigation={this.props.navigation}
              page="AcceuilTabs"
            />
          }
        />
        <ScrollView
          contentContainerStyle={{flexGrow: 1}}
          refreshControl={
            <RefreshControl
              refreshing={this.state.isLoading}
              onRefresh={this.onRefresh}
              tintColor="white"
            />
          }>
          <View>
            {this.state.isWaitToRemove ? <ThreeDotsLoader /> : <View />}
          </View>
          {this.state.modeEditName && (
            <View>
              <Input
                onBlur={() => this.onBlurField('device_name')}
                onChangeText={val => this.setState({device_name: val})}
                containerStyle={styles.FormInputcontainerStyle}
                inputContainerStyle={styles.FormInputContainerStyle}
                inputStyle={[styles.FormTextInput]}
                value={this.state.device_name}
                rightIcon={
                  this.state.isWait ? (
                    <ThreeDotsLoader />
                  ) : (
                    <TouchableOpacity
                      onPress={() => this.onBlurField('device_name')}
                      hitSlop={{top: 10, left: 30, right: 30, bottom: 30}}>
                      <Icon
                        reverse
                        color="#289EFF"
                        activeOpacity={1}
                        containerStyle={{
                          width: 60,
                          height: 60,
                          borderRadius: 30,
                        }}
                        iconStyle={{marginTop: 0, marginLeft: 0}}
                        name={'floppy-o'}
                        type="font-awesome"
                        size={20}
                        underlayColor={'#fff'}
                      />
                    </TouchableOpacity>
                  )
                }
              />
            </View>
          )}
          {!this.state.modeEditName && (
            <View>
              <Text style={styles.enteteText}>{this.state.device_name}</Text>
              <View style={{position: 'absolute', right: 10, top: 0}}>
                <TouchableOpacity
                  onPress={() => this.setState({modeEditName: true})}
                  hitSlop={{top: 10, left: 30, right: 30, bottom: 30}}>
                  <Icon
                    reverse
                    color="#289EFF"
                    activeOpacity={1}
                    containerStyle={{width: 60, height: 60, borderRadius: 30}}
                    iconStyle={{marginTop: 0, marginLeft: 0}}
                    name={'pencil'}
                    type="font-awesome"
                    size={20}
                    underlayColor={'#fff'}
                  />
                </TouchableOpacity>
              </View>
            </View>
          )}

          <View style={styles.container}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 10,
              }}>
              <Text style={{flex: 4, color: '#fff', padding: 5}}>
                {translate('SIGNAL')} °{this.state.device_number}
              </Text>
              {!this.state.isshowMore && (
                <Button
                  buttonStyle={styles.btninfo}
                  title={translate('Configuration avancee', {color: 'white'})}
                  onPress={() =>
                    this.setState({isshowMore: !this.state.isshowMore})
                  }
                />
              )}
            </View>
            {this.state.isshowMore &&
              this.state.device_level !== undefined &&
              parseInt(this.state.device_level) >= 0 && (
                <View style={{paddingBottom: 20}}>
                  <Volume
                    device_level_ref={this.state.device_level_ref}
                    device_level={this.state.device_level}
                    device_level_unit={this.state.device_level_unit}
                    device_number={this.state.device_number}
                    sys_appid={this.state.sys_appid}
                    refeshDevice={this.refeshDevice}
                  />
                </View>
              )}
            {this.state.isshowMore && this.state.device_setpoint ? (
              <View style={{paddingBottom: 20}}>
                <Consigne
                  device_number={this.state.device_number}
                  device_setpoint={this.state.device_setpoint}
                  device_unit_setpoint={this.state.device_unit_setpoint}
                  sys_appid={this.state.sys_appid}
                  refeshDevice={this.refeshDevice}
                />
              </View>
            ) : null}

            <View style={{paddingBottom: 20}}>
              <Consommation
                device_number={this.state.device_number}
                device_consumption={this.state.device_consumption}
                sys_appid={this.state.sys_appid}
                refeshDevice={this.refeshDevice}
              />
            </View>

            <View style={{paddingBottom: 20}}>
              <Mode
                device_number={this.state.device_number}
                device_index={this.state.device_index}
                device_mode_available={this.state.device_mode_available}
                device_mode_display={this.state.device_mode_display}
                sys_appid={this.state.sys_appid}
                refeshDevice={this.refeshDevice}
              />
            </View>

            {this.state.isshowMore && (
              <View style={{paddingBottom: 20}}>
                <Puissance
                  device_number={this.state.device_number}
                  device_power={this.state.device_power}
                  device_unit_power={this.state.device_unit_power}
                  sys_appid={this.state.sys_appid}
                  refeshDevice={this.refeshDevice}
                />
              </View>
            )}

            {this.state.isshowMore && this.state.device_speed_min ? (
              <View style={{paddingBottom: 20}}>
                <Vitesse
                  device_number={this.state.device_number}
                  device_speed={this.state.device_speed}
                  device_text={this.props.route.params.device_text || ''}
                  device_speed_min={this.state.device_speed_min}
                  device_speed_max={this.state.device_speed_max}
                  device_speed_unit={this.state.device_speed_unit}
                  device_speed_gap={this.state.device_speed_gap}
                  sys_appid={this.state.sys_appid}
                  refeshDevice={this.refeshDevice}
                />
              </View>
            ) : null}
            {this.state.isshowMore && (
              <View style={{paddingBottom: 20}}>
                <Capteur
                  device_number={this.state.device_number}
                  device_sensor={this.state.device_sensor}
                  sensors={this.state.sensors}
                  sys_appid={this.state.sys_appid}
                  refeshDevice={this.refeshDevice}
                />
              </View>
            )}
            {this.state.isshowMore && this.state.device_standard ? (
              <View style={{paddingBottom: 20}}>
                <DeviceEtalon
                  device_number={this.state.device_number}
                  device_standard={this.state.device_standard}
                  device_security={this.state.device_security}
                  sys_appid={this.state.sys_appid}
                  refeshDevice={this.refeshDevice}
                />
              </View>
            ) : null}
            {this.state.device_sequence_available ? (
              <View style={{paddingBottom: 20}}>
                <Programmation
                  device_number={this.state.device_number}
                  device_name={this.state.device_name}
                  device_sequence={this.state.device_sequence}
                  device_sequence_available={
                    this.state.device_sequence_available
                  }
                  device_sequence_display={this.state.device_sequence_display}
                  slots={this.state.slots}
                  sys_appid={this.state.sys_appid}
                  device_speed={this.state.device_speed}
                  device_speed_min={this.state.device_speed_min}
                  device_speed_max={this.state.device_speed_max}
                  device_speed_unit={this.state.device_speed_unit}
                  device_speed_gap={this.state.device_speed_gap}
                  refeshDevice={this.refeshDevice}
                />
              </View>
            ) : null}

            {this.state.isshowMore && (
              <View style={{paddingBottom: 20}}>
                <ButtonCustom
                  text="Supprimer cet équipement"
                  style={styles.deleteBtn}
                  onPress={() => this.ConfirmToRemoveDevice()}
                  icon={
                    <Icon
                      name="times"
                      type="font-awesome"
                      color="#fff"
                      size={15}
                    />
                  }
                />
              </View>
            )}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
  refeshDevice = () => {
    this.setState({isLoading: true});

    this.props
      .getDevice(this.state.sys_appid, this.state.device_number)
      .then(device => {
        // console.log("DevicesDetail refeshDevice",device);

        if (device) {
          this.refeshUpdateDevice(device);
        }

        this.setState({isLoading: false});
      });
  };

  UpdateDevices = (field, val) => {
    this.setState({isWait: true});
    this.props
      .UpdateDevice(this.state.sys_appid, {
        key: field,
        value: val,
        number: this.state.device_number,
      })
      .then(data => {
        this.setState({isWait: false});
        this.setState({modeEditName: false});
        this.props.SystemGetAll(this.state.sys_appid);
        this.refeshDevice();
        ShowSnaskMessage({
          message: 'operation success',
          textColor: '#000',
          backgroundColor: '#7ED321',
          buttonColor: '#7ED321',
          duration: 2000,
        });
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };
}

const styles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
  },
  enteteText: {
    fontSize: 30,
    fontWeight: '300',
    padding: 10,
    textAlign: 'center',
    color: '#ffffff',
    fontFamily: fontFamilyRegular(),
  },
  container: {
    flex: 1,
    backgroundColor: '#000004',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  FormInputcontainerStyle: {
    borderBottomWidth: 0,
    marginBottom: 0,
  },
  FormInputContainerStyle: {
    marginBottom: 10,
    height: 50,
    borderRadius: 50,
    borderBottomWidth: 1,
    borderBottomColor: '#eaeaea',
    color: '#fff',
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
  FormTextInput: {
    height: 50,
    fontSize: 30,
    color: '#fff',
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
    textAlign: 'center',
  },
  btninfo: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    borderWidth: 0.5,
    borderColor: '#000',
    color: 'white',
    borderRadius: 50,
  },
  deviceText: {
    color: 'white',
  },
  deleteBtn: {
    backgroundColor: 'red',
    borderRadius: 20,
    marginLeft: 31,
    marginRight: 31,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    last_update_data_redux_system: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },

    RemoveDevice: (appId, params) => {
      return dispatch(actions.RemoveDevice(appId, params));
    },

    getDevice: (appId, device_number) => {
      return dispatch(actions.getDevice(appId, device_number));
    },

    SystemGetAll: (appId, params) => {
      return dispatch(actions.SystemGetAll(appId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DevicesDetail);
