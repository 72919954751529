import React, {useState, useEffect} from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  Picker,
  ScrollView,
  Platform,
  Dimensions,
  TouchableOpacity,
  Alert,
} from 'react-native';
import {connect} from 'react-redux';
import {Header, Icon, Input} from 'react-native-elements';
import RNPickerSelect from 'react-native-picker-select';
import {isEqual} from 'lodash';

import ProgressCircle from '../components/ProgressCircle';
import translate, {translateString} from '../translations/translate';
import AppBarChart from '../ChartJs/AppBarChart';
import {fontFamilyBold, fontFamilyRegular} from '../Config/fonts';
import {isColor} from '../Config/Config';
import ButtonCustom from '../components/Button';
import {
  appHeaderHeight,
  msg_code_to_text,
  ShowSnaskMessage,
  appHeaderTop,
} from '../Config/Config';
import * as actions from '../Store/Systems/SystemsActions';
import ThreeDotsLoader from '../components/ThreeDotsLoader';
import HeaderLeftComponent from '../components/HeaderLeftComponent';

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 12,
    paddingVertical: 5,
    paddingHorizontal: 4,
    color: '#fff',
    textAlign: 'center',
    backgroundColor: '#242424',
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
});

class stripDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      system: null,
      historyPeriod: 1,
      strip: {},
      strip_name: '',
      history: [],
      isWaitToRemove: false,
      sys_appid: '',
      modeEditName: false,
      isWaitName: false,
      isWait: {},
      daily: '',
      monthly: '',
      weekly: '',
      yearly: '',
    };
  }
  async componentDidMount() {
    let params = this.props.route.params;
    //console.log("_strip ==> ",params);
    let _strip = params.stripDetail.strip[0];

    let ArrayHistorique = [];
    if (
      Array.isArray(params.stripDetail.history) &&
      params.stripDetail.history.length > 0
    ) {
      let _history = Array.isArray(params.stripDetail.history)
        ? params.stripDetail.history
        : [];
      ArrayHistorique = _history.map(item => {
        return item.strip_record;
      });
    } else {
      try {
        ArrayHistorique = JSON.parse(_strip.strip_values);
      } catch (e) {
        console.log(e, _strip);
      }
    }

    const daily = await translateString('Daily');
    const monthly = await translateString('monthly');
    const weekly = await translateString('weekly');
    const yearly = await translateString('half-yearly');

    this.setState({
      strip: _strip,
      strip_name: _strip.strip_name,
      strip_number: _strip.strip_number,
      strip_status: _strip.strip_status,
      history: ArrayHistorique,
      daily,
      monthly,
      weekly,
      yearly,
    });

    let IswaitObj = {};
    Object.keys(_strip).map(key => {
      IswaitObj[key] = false;
    });
    this.setState({isWait: IswaitObj});
    this.getSystem();
  }

  async componentDidUpdate(prevProps, prevState) {
    let _strip = this.state.strip[0];

    if(!_strip) {
      return;
    }

    const strip = this.props.current_system.strips.find(
      s => s?.strip_number === _strip?.strip_number,
    );

    if(!strip) {
      return;
    }
      
    // Fix
    strip.strip_last_value = strip.strip_last_value.toString();

    if (!isEqual(_strip, strip)) {
      this.setState({
        strip,
        strip_name: strip.strip_name,
      });
    }
  }

  loadMoreHistory = nbDay => {
    this.setState({history: [], historyPeriod: nbDay});
    if (nbDay === 1) {
      try {
        let ArrayHistorique = JSON.parse(this.state.strip[0].strip_values);
        this.setState({history: ArrayHistorique});
      } catch (e) {}
    } else {
      this.props
        .getStripHistorique(
          this.state.system.sys_appid,
          this.state.strip_number,
          nbDay,
        )
        .then(data => {
          // console.log(data,this.state.system.sys_appid);
          let _strip = data.strip[0];
          if (Array.isArray(data.history) && data.history.length > 0) {
            let ArrayHistorique = data.history.map(item => {
              return item.strip_record;
            });
            this.setState({history: ArrayHistorique});
          }
        });
    }
  };

  componentWillUnmount() {}

  getSystem = () => {
    if (
      this.props.current_system.hasOwnProperty('system') &&
      this.props.current_system.system.length > 0
    ) {
      this.setState({
        system: this.props.current_system.system[0],
        sys_appid: this.props.current_system.system[0].sys_appid,
      });
    }
  };

  goToBack = () => {
    this.props.navigation.goBack();
  };

  onBlurField = fieldName => {
    let _strip = this.state.strip;
    if (fieldName === 'strip_name') {
      this.UpdateStatusstrip(fieldName, this.state.strip_name);
    } else {
      this.UpdateStatusstrip(fieldName, _strip[fieldName]);
    }
  };

  onChangeField = (fieldName, val) => {
    let _strip = this.state.strip;
    _strip[fieldName] = val;
    this.setState({strip: _strip});
  };

  refeshDevice = device_number => {
    this.props.getDevice(this.state.system.sys_appid, device_number);

    this.props.SystemDetail(this.state.system.sys_appid);
  };

  UpdateStatusstrip = (param, val) => {
    if (param === 'strip_name') {
      this.setState({isWaitName: true});
    }
    let objWait = this.state.isWait;
    objWait[param] = true;
    this.setState({isWait: objWait});
    this.props
      .UpdateDevice(this.state.system.sys_appid, {
        key: param,
        value: val,
        number: this.state.strip_number,
      })
      .then(async data => {
        if (param === 'strip_name') {
          this.setState({isWaitName: false, modeEditName: false});
        }
        objWait[param] = false;
        this.setState({isWait: objWait});
        this.refeshDevice(this.state.strip_number);
        ShowSnaskMessage({
          message: 'operation success',
          textColor: '#fff',
          backgroundColor: '#7ED321',
          buttonColor: '#7ED321',
          duration: 2000,
        });
      })
      .catch(error => {
        // console.log(error);
        objWait[param] = false;
        this.setState({isWait: objWait});
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });
      });
  };
  ConfirmToRemoveDevice = async () => {
    const deleteAlertTitle = await translateString('Suppression');
    const deleteAlertText = await translateString('deletionText');
    const cancel = await translateString('Cancel');
    const confirmationText = await translateString('Confirmer');

    Alert.alert(
      deleteAlertTitle,
      deleteAlertText,
      [
        {
          text: cancel,
          onPress: () => {},
          style: 'cancel',
        },
        {text: confirmationText, onPress: () => this.RemoveStrip()},
      ],
      {cancelable: false},
    );

    /* */
  };

  renderPicker = () => {
    if (Platform.OS === 'ios') {
      return (
        <View style={[styles.ViewPicker, {paddingBottom: 4}]}>
          <RNPickerSelect
            placeholder={{
              label: 'Choisir un element',
              value: null,
              color: '#fff',
            }}
            onValueChange={value => this.loadMoreHistory(value)}
            value={this.state.historyPeriod}
            style={{
              ...pickerSelectStyles,
              iconContainer: {
                top: 20,
                right: 10,
              },
              placeholder: {
                color: '#fff',
                fontSize: 12,
                fontWeight: 'bold',
              },
            }}
            items={[
              {label: this.state.daily, value: 1},
              {label: this.state.weekly, value: 7},
              {label: this.state.monthly, value: 30},
              {label: this.state.yearly, value: 180},
            ]}
          />
        </View>
      );
    }

    return (
      <Picker
        selectedValue={this.state.historyPeriod}
        style={styles.Picker}
        onValueChange={(itemValue, itemIndex) => {
          this.loadMoreHistory(itemValue);
        }}>
        <Picker.Item label={this.state.daily} value={1} />
        <Picker.Item label={this.state.weekly} value={7} />
        <Picker.Item label={this.state.monthly} value={30} />
        <Picker.Item label={this.state.yearly} value={180} />
      </Picker>
    );
  };

  RemoveStrip = () => {
    this.setState({isWaitToRemove: true});
    this.props
      .RemoveStrip(this.state.sys_appid, {
        type: 'strip',
        number: this.state.strip_number,
      })
      .then(async response => {
        // console.log("Removestrip response ==> ",response);

        /*ShowSnaskMessage({
          message: 'operation success',
          textColor: '#000',
          backgroundColor: '#7ED321',
          buttonColor: '#7ED321',
          duration: 2000,
        });*/
        this.props.navigation.navigate('AcceuilTabs');
      })
      .catch(error => {
        this.setState({isWaitToRemove: false});
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };

  render() {
    const userType = this.props.user?.user_type;

    return (
      <SafeAreaView style={styles.containerfull}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            marginTop: appHeaderTop(),
            borderBottomColor: '#289EFF',
          }}
          leftComponent={<HeaderLeftComponent goToBack={this.goToBack} />}
          rightComponent={
            <TouchableOpacity
              onPress={() => this.setState({modeEditName: true})}
              hitSlop={{top: 10, left: 30, right: 30, bottom: 30}}>
              <Icon
                reverse
                color="#289EFF"
                activeOpacity={1}
                containerStyle={{width: 60, height: 60, borderRadius: 30}}
                iconStyle={{marginTop: 0, marginLeft: 0}}
                name={'pencil'}
                type="font-awesome"
                size={20}
                underlayColor={'#fff'}
              />
            </TouchableOpacity>
          }
        />
        <ScrollView contentContainerStyle={{flexGrow: 1}}>
          {this.state.modeEditName && (
            <View>
              <Input
                onBlur={() => this.onBlurField('strip_name')}
                onChangeText={val => this.setState({strip_name: val})}
                containerStyle={styles.FormInputcontainerStyleName}
                inputContainerStyle={styles.FormInputContainerStyleName}
                inputStyle={[styles.FormTextInputName]}
                value={this.state.strip_name}
                rightIcon={
                  this.state.isWaitName ? (
                    <ThreeDotsLoader />
                  ) : (
                    <TouchableOpacity
                      onPress={() => this.onBlurField('strip_name')}
                      hitSlop={{top: 10, left: 30, right: 30, bottom: 30}}>
                      <Icon
                        reverse
                        color="#289EFF"
                        activeOpacity={1}
                        containerStyle={{
                          width: 60,
                          height: 60,
                          borderRadius: 30,
                        }}
                        iconStyle={{marginTop: 0, marginLeft: 0}}
                        name={'floppy-o'}
                        type="font-awesome"
                        size={20}
                        underlayColor={'#fff'}
                      />
                    </TouchableOpacity>
                  )
                }
              />
            </View>
          )}

          <View style={styles.container}>
            <View>
              {this.state.isWaitToRemove ? <ThreeDotsLoader /> : <View />}
            </View>

            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <ProgressCircle
                percent={70}
                radius={50}
                borderWidth={3}
                color={this.state.strip.strip_low_color}
                shadowColor="#000000"
                bgColor="#000000">
                <View style={{flexDirection: 'row'}}>
                  <Text
                    style={{
                      fontSize: 30,
                      fontFamily: fontFamilyRegular(),
                      fontWeight: '500',
                      color: '#fff',
                    }}>
                    {this.state.strip?.strip_last_value}
                  </Text>
                  <Text style={{fontSize: 12, marginTop: 20, color: '#fff'}}>
                    {this.state.strip.strip_unit}
                  </Text>
                </View>
              </ProgressCircle>
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: fontFamilyRegular(),
                  fontWeight: '500',
                  color: '#fff',
                  marginLeft: 140,
                  bottom: 9,
                }}>
                {this.state.strip_status}
              </Text>
            </View>
            {userType === '0' && (
              <View style={styles.widgetView}>
                <View style={{display: 'flex', flexDirection: 'column'}}>
                  <View style={{paddingLeft: 10}}>
                    <Text
                      style={{
                        fontSize: 18,
                        color: '#fff',
                        fontFamily: fontFamilyBold(),
                      }}>
                      {translate('Offset')}
                    </Text>
                  </View>
                  <View style={{ width: '100%', paddingBottom: 15}}>
                    <Input
                      keyboardType={
                        Platform.OS === 'ios'
                          ? 'numbers-and-punctuation'
                          : 'numeric'
                      }
                      onBlur={() => this.onBlurField('strip_offset')}
                      onChangeText={val =>
                        this.onChangeField('strip_offset', val)
                      }
                      value={this.state.strip.strip_offset}
                      containerStyle={styles.FormInputcontainerStyle}
                      inputContainerStyle={styles.FormInputContainerStyle}
                      inputStyle={[styles.FormTextInput]}
                      placeholder=""
                      rightIcon={
                        this.state.isWait.strip_offset ? (
                          <ThreeDotsLoader />
                        ) : null
                      }
                    />
                  </View>
                </View>
              </View>
            )}
            {userType === '0' && (
              <View style={styles.widgetView}>
                <View style={{display: 'flex', flexDirection: 'column'}}>
                  <View style={{paddingLeft: 10}}>
                    <Text
                      style={{
                        fontSize: 18,
                        color: '#fff',
                        fontFamily: fontFamilyBold(),
                      }}>
                      {translate('Min Alarme')}
                    </Text>
                  </View>
                  <View style={{width: '100%', paddingBottom: 15}}>
                    <Input
                      keyboardType={'numeric'}
                      onBlur={() => this.onBlurField('strip_alarm_min')}
                      onChangeText={val =>
                        this.onChangeField('strip_alarm_min', val)
                      }
                      value={this.state.strip.strip_alarm_min}
                      containerStyle={styles.FormInputcontainerStyle}
                      inputContainerStyle={styles.FormInputContainerStyle}
                      inputStyle={[styles.FormTextInput]}
                      placeholder=""
                      rightIcon={
                        this.state.isWait.strip_alarm_min ? (
                          <ThreeDotsLoader />
                        ) : null
                      }
                    />
                  </View>
                </View>
                <View>
                  <View style={{paddingLeft: 10}}>
                    <Text
                      style={{
                        fontSize: 18,
                        color: '#fff',
                        fontFamily: fontFamilyBold(),
                      }}>
                      {translate('Max Alarme')}
                    </Text>
                  </View>
                  <View style={{width: '100%', paddingBottom: 15}}>
                    <Input
                      keyboardType={'numeric'}
                      onBlur={() => this.onBlurField('strip_alarm_max')}
                      onChangeText={val =>
                        this.onChangeField('strip_alarm_max', val)
                      }
                      value={this.state.strip.strip_alarm_max}
                      containerStyle={styles.FormInputcontainerStyle}
                      inputContainerStyle={styles.FormInputContainerStyle}
                      inputStyle={[styles.FormTextInput]}
                      placeholder=""
                      rightIcon={
                        this.state.isWait.strip_alarm_max ? (
                          <ThreeDotsLoader />
                        ) : null
                      }
                    />
                  </View>
                </View>
              </View>
            )}
            {userType === '0' && (
              <View style={styles.widgetView}>
                <View style={{display: 'flex', flexDirection: 'column'}}>
                  <View style={{paddingLeft: 10}}>
                    <Text
                      style={{
                        fontSize: 18,
                        color: '#fff',
                        fontFamily: fontFamilyBold(),
                      }}>
                      {translate('Absolute min')}
                    </Text>
                  </View>
                  <View style={{width: '100%', paddingBottom: 15}}>
                    <Input
                      keyboardType={'numeric'}
                      onBlur={() => this.onBlurField('strip_absolute_min')}
                      onChangeText={val =>
                        this.onChangeField('strip_absolute_min', val)
                      }
                      value={this.state.strip.strip_absolute_min}
                      containerStyle={styles.FormInputcontainerStyle}
                      inputContainerStyle={styles.FormInputContainerStyle}
                      inputStyle={[styles.FormTextInput]}
                      placeholder=""
                      rightIcon={
                        this.state.isWait.strip_absolute_min ? (
                          <ThreeDotsLoader />
                        ) : null
                      }
                    />
                  </View>
                </View>
                <View>
                  <View style={{paddingLeft: 10}}>
                    <Text
                      style={{
                        fontSize: 18,
                        color: '#fff',
                        fontFamily: fontFamilyBold(),
                      }}>
                      {translate('Absolute max')}
                    </Text>
                  </View>
                  <View style={{width: '100%', paddingBottom: 15}}>
                    <Input
                      keyboardType={'numeric'}
                      onBlur={() => this.onBlurField('strip_absolute_max')}
                      onChangeText={val =>
                        this.onChangeField('strip_absolute_max', val)
                      }
                      value={this.state.strip.strip_absolute_max}
                      containerStyle={styles.FormInputcontainerStyle}
                      inputContainerStyle={styles.FormInputContainerStyle}
                      inputStyle={[styles.FormTextInput]}
                      placeholder=""
                      rightIcon={
                        this.state.isWait.strip_absolute_max ? (
                          <ThreeDotsLoader />
                        ) : null
                      }
                    />
                  </View>
                </View>
              </View>
            )}
            {userType === '0' && (
              <ButtonCustom
                text="Supprimer cet équipement"
                style={styles.deleteBtn}
                onPress={() => this.ConfirmToRemoveDevice()}
                icon={
                  <Icon
                    name="times"
                    type="font-awesome"
                    color="#fff"
                    size={15}
                  />
                }
              />
            )}
            <View style={styles.widgetView}>
              <View style={{flex: 1, flexDirection: 'row'}}>
                <View style={{flex: 1}}>
                  <Text
                    style={{
                      fontSize: 20,
                      color: '#fff',
                      fontFamily: fontFamilyBold(),
                    }}>
                    {translate('HISTORIQUE', {
                      fontSize: 20,
                      color: '#fff',
                      fontFamily: fontFamilyBold(),
                    })}{' '}
                    :{' '}
                  </Text>
                </View>
                <View style={{flex: 1}}>
                  <View
                    style={{
                      borderWidth: 1,
                      backgroundColor: '#242424',
                      borderColor: '#242424',
                      borderRadius: 50,
                    }}>
                    {this.renderPicker()}
                  </View>
                </View>
              </View>
              <View style={{marginTop: 20}}>
                <AppBarChart
                  data={{
                    datasets: [
                      {
                        data: this.state.history.reverse(),
                      },
                    ],
                  }}
                  width={Dimensions.get('window').width - 40} // from react-native
                  height={180}
                  fromZero={true}
                  yAxisLabel={'$'}
                  withHorizontalLabels={false}
                  getDotColor={(val = 1) => {
                    if (val <= parseFloat(this.props.strip_alarm_min)) {
                      return isColor(this.props.strip_low_color);
                    }
                    if (val >= parseFloat(this.props.strip_alarm_max)) {
                      return isColor(this.props.strip_high_color);
                    }

                    return 'white';
                  }}
                  getDotProps={(val = 1) => {
                    let color = 'white';

                    if (val <= parseFloat(this.state.strip.strip_alarm_min)) {
                      color = isColor(this.state.strip.strip_low_color);
                    }
                    if (val >= parseFloat(this.state.strip.strip_alarm_max)) {
                      color = isColor(this.state.strip.strip_high_color);
                    }

                    return {
                      r: 1,
                      color: color,
                      stroke: color,
                      strokeWidth: 1,
                    };
                  }}
                  chartConfig={{
                    backgroundColor: '#121212',
                    backgroundGradientFrom: '#121212',
                    backgroundGradientTo: '#121212',
                    decimalPlaces: 2,
                    color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                    //color: 'rgba(255,255,255, 1)',
                    style: {
                      borderRadius: 2,
                    },
                    propsForLabels: {
                      fontSize: 10,
                    },
                    barPercentage: 0.3,
                    barRadius: 5,
                  }}
                  style={{
                    marginVertical: 0,
                    borderRadius: 0,
                  }}
                />
              </View>
            </View>
            <View
              style={{padding: 15, backgroundColor: '#242424', marginTop: 10}}>
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  fontFamily: fontFamilyRegular(),
                  color: '#A4A4A4',
                }}>
                {translate('Dernier_read')} {this.state.strip.strip_date}
              </Text>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
  },
  FormInputcontainerStyleName: {
    borderBottomWidth: 0,
    marginBottom: 0,
  },
  FormInputContainerStyleName: {
    marginBottom: 10,
    height: 50,
    borderRadius: 50,
    borderBottomWidth: 1,
    borderBottomColor: '#eaeaea',
    color: '#fff',
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
  enteteText: {
    fontSize: 30,
    fontWeight: '300',
    padding: 10,
    textAlign: 'center',
    color: '#ffffff',
    fontFamily: fontFamilyRegular(),
  },
  container: {
    flex: 1,
    backgroundColor: '#000004',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  widgetView: {
    backgroundColor: '#121212',
    borderRadius: 10,
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  FormTextInputName: {
    height: 50,
    fontSize: 30,
    color: '#fff',
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
    textAlign: 'center',
  },
  FormTextInput: {
    height: 43,
    fontSize: 14,
    width: '100%',
    textAlign: 'center',
    color: '#fff',
    fontFamily: fontFamilyBold(),
    fontWeight: 'normal',
  },
  FormInputcontainerStyle: {
    borderBottomWidth: 0,
    width: '100%',
  },
  FormInputContainerStyle: {
    height: 43,
    fontSize: 14,
    borderRadius: 50,
    borderWidth: 1,
    backgroundColor: '#242424',
    borderColor: '#242424',
    textAlign: 'center',
    color: '#fff',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    fontFamily: fontFamilyBold(),
    fontWeight: 'normal',
  },
  Picker: {
    fontSize: 12,
    color: '#fff',
    height: 40,
    fontFamily: 'arial',
    color: 'black',
  },
  ViewPicker: {
    fontSize: 14,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#242424',
    backgroundColor: '#242424',
    color: '#fff',
    paddingLeft: 10,
    marginTop: 5,
    fontFamily: 'arial',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    current_system: state.SystemsRedux.system,
    user: state.SystemsRedux.clientInfo?.user,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
    getStripHistorique: (appId, strip_number, history = 1) => {
      return dispatch(actions.getStripHistorique(appId, strip_number, history));
    },
    RemoveStrip: (appId, params) => {
      return dispatch(actions.RemoveStrip(appId, params));
    },

    getDevice: (appId, device_number) => {
      return dispatch(actions.getDevice(appId, device_number));
    },

    SystemDetail: (appId, params) => {
      return dispatch(actions.SystemDetail(appId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(stripDetail);
