import Systems from '../Models/Systems';

export const CALIBRATION_START = 'calibration/start';
export const CALIBRATION_RESULT = 'calibration/result';
export const CALIBRATION_CANCEL = 'calibration/cancel';
export const CALIBRATION_RESET = 'calibration/reset';

export function startCalibration(appid, sensorNumber, point, value, time) {
  return function(dispatch, getState) {
    let MSystems = new Systems();

    return MSystems.startCalibration(
      appid,
      sensorNumber,
      point,
      value,
      time,
    ).then(r => {
      console.log(r);
      if (r.ok) {
        dispatch({type: CALIBRATION_START, sensorNumber, point, value, time});
      }
      return r;
    });
  };
}

export function cancelCalibration(appid, sensorNumber) {
  return function(dispatch, getState) {
    let MSystems = new Systems();

    return MSystems.cancelCalibration(appid, sensorNumber).then(r => {
      dispatch({type: CALIBRATION_CANCEL, sensorNumber});
    });
  };
}

export function resetCalibration(sensorNumber) {
  return function(dispatch, getState) {
    dispatch({type: CALIBRATION_RESET, sensorNumber});
  };
}

const initialState = {
  result: false,
};

export default function CalibrationReducer(state = initialState, action) {
  switch (action.type) {
    case CALIBRATION_START:
      return {
        ...state,
        [action.sensorNumber]: {
          point: action.point,
          status: 'started',
          result: false,
        },
      };
    case CALIBRATION_CANCEL:
      return {
        ...state,
        [action.sensorNumber]: {
          result: false,
          point: false,
          status: 'canceled',
        },
      };
    case CALIBRATION_RESET:
      return {
        ...state,
        [action.sensorNumber]: {
          result: false,
          point: false,
          status: 'reset',
          rawValue: false,
        },
      };
    case CALIBRATION_RESULT:
      return {
        ...state,
        [action.sensorNumber]: {
          rawValue: action.rawValue,
          point: action.point,
          result: action.result,
          status: 'finished',
        },
      };
    default:
      return state;
  }
}
