import Model from './Model';
import {get_api_host} from '../Config/Config';
export default class Registration extends Model {
  constructor() {
    let data = {};

    super(data, '');
  }

  SetProfile(_appid, _user, _code, params) {
    let headers = {};
    if (_appid) {
      headers.appid = _appid;
    }
    if (_user) {
      headers.user = _user;
    }
    if (_code) {
      headers.code = _code;
    }
    this.setUrl(get_api_host() + '/set_profile');
    this.setHeaders(headers);

    return this.fetch(params).then(response => {
      //console.log("Response Api => ",response.status,response.data);
      return new Promise((resolve, reject) => {
        if (response.status === 200 && response.data.hasOwnProperty('token')) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      });
    });
  }

  SetController(_appid, _user, _code, params) {
    let headers = {};
    if (_appid) {
      headers.appid = _appid;
    }
    if (_user) {
      headers.user = _user;
    }
    if (_code) {
      headers.code = _code;
    }

    this.setUrl(get_api_host() + '/set_controller');
    this.setHeaders(headers);

    return this.fetch(params)
      .then(response => {
        //console.log("Response Api => ",response.status,response.data);
        return new Promise((resolve, reject) => {
          if (
            response.status === 200 &&
            response.data.hasOwnProperty('token')
          ) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
}
