import React from 'react';
import {StyleSheet, View, TouchableOpacity} from 'react-native';

import translate from '../translations/translate';

const Button = ({icon, text, onPress, style}) => {
  return (
    <TouchableOpacity onPress={onPress} style={style}>
      <View style={styles.btn}>
        {translate(text, {color: '#D8D8D8', textTransform: 'uppercase'})}
        {icon}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  btn: {
    display: 'flex',
    flexDirection: 'row',
    borderColor: '#2B89E7',
    borderWidth: 2,
    backgroundColor: '#121212',
    height: 60,
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 18,
    paddingRight: 18,
  },
});

export default Button;
