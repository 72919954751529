import React from 'react';
import {StyleSheet, Text, View, SafeAreaView, ScrollView} from 'react-native';
import {connect} from 'react-redux';
import {Header, Icon} from 'react-native-elements';
import RemoteControlle from '../components/widget/RemoteControlle';
import {fontFamilyRegular} from '../Config/fonts';
import HeaderLeftComponent from '../components/HeaderLeftComponent';
import translate from '../translations/translate';

class HeaderrightComponent extends React.Component {
  render() {
    return (
      <>
        <View
          style={{flexDirection: 'row', paddingBottom: 0, paddingRight: 10}}>
          <Icon
            containerStyle={{paddingRight: 30}}
            name="lock"
            type="font-awesome"
            color="#fff"
            onPress={() => console.log('hello lock')}
          />
          <Icon
            name="user"
            type="font-awesome"
            color="#fff"
            onPress={() => this.props.navigation.navigate('Profil')}
          />
        </View>
      </>
    );
  }
}

class AppareilVerouillee extends React.Component {
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <SafeAreaView style={styles.containerfull}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: 50,
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          leftComponent={
            <HeaderLeftComponent navigation={this.props.navigation} page="Alertes" />
          }
          rightComponent={
            <HeaderrightComponent navigation={this.props.navigation} />
          }
        />
        <ScrollView contentContainerStyle={{flexGrow: 1}}>
          <View style={styles.container}>
            <View style={{flexDirection: 'row', paddingBottom: 0}}>
              <Text
                style={{
                  flex: 1,
                  borderColor: '#fff',
                  borderTopWidth: 4,
                  marginTop: 10,
                }}
              />
              <Text style={styles.enteteText}>{translate('equipements')}</Text>
              <Text
                style={{
                  flex: 5,
                  borderColor: '#fff',
                  borderBottomWidth: 4,
                  marginBottom: 10,
                }}
              />
            </View>
            <View style={{paddingBottom: 20}}>
              <RemoteControlle
                typeComposent={'progress'}
                progress={{
                  val: '75',
                  label: 7.3,
                  unite: 'pt',
                  color: '#46BC69',
                }}
                borderTitle={'#46BC69'}
                title={'Chlore'}
                placeholder={'Chlore'}
                btnOfflabel={'Off'}
                btnOnlabel={'On'}
                btnAutolabel={'Auto'}
                isAuto={true}
              />
            </View>
            <View style={{paddingBottom: 20}}>
              <RemoteControlle
                typeComposent={'progress'}
                progress={{
                  val: '55',
                  label: 1.2,
                  unite: 'mg/l',
                  color: '#BC9846',
                }}
                borderTitle={'#BC9846'}
                title={'Chlore'}
                placeholder={'Chlore'}
                btnOfflabel={'Off'}
                btnOnlabel={'On'}
                btnAutolabel={'Auto'}
                isOff={true}
              />
            </View>
            <View style={{paddingBottom: 20}}>
              <RemoteControlle
                typeComposent={'progress'}
                progress={{
                  val: '80',
                  label: 30,
                  unite: '°C',
                  color: '#46BC69',
                }}
                borderTitle={'#46BC69'}
                title={'Chauffage'}
                placeholder={'Chauffage'}
                btnOfflabel={'Off'}
                btnOnlabel={'On'}
                btnAutolabel={'Auto'}
                isOff={true}
                timePeriod={'18H00 - 22H00'}
              />
            </View>
            <View style={{paddingBottom: 20}}>
              <RemoteControlle
                typeComposent={'progress'}
                progress={{
                  val: '35',
                  label: 1.2,
                  unite: 'mg/l',
                  color: '#C93B21',
                }}
                borderTitle={'#C93B21'}
                title={'Pompe pH'}
                placeholder={'Pompe pH'}
                btnOfflabel={'Off'}
                btnOnlabel={'On'}
                btnAutolabel={'Auto'}
                isOn={true}
              />
            </View>
            <View style={{paddingBottom: 20}}>
              <RemoteControlle
                typeComposent={'image'}
                image={{
                  on: require('../assets/moteur.webp'),
                  off: require('../assets/moteur.webp'),
                  auto: require('../assets/moteur.webp'),
                }}
                borderTitle={'#3A57FB'}
                title={'Pompe Filtration'}
                placeholder={'Pompe Filtration'}
                btnOfflabel={'Off'}
                btnOnlabel={'On'}
                btnAutolabel={'Auto'}
                isOff={true}
                timePeriod={'18H00 - 22H00'}
              />
            </View>
            <View style={{paddingBottom: 20}}>
              <RemoteControlle
                typeComposent={'image'}
                image={{
                  on: require('../assets/lamp-on.webp'),
                  off: require('../assets/lamp-off.webp'),
                  auto: require('../assets/lamp-on.webp'),
                }}
                borderTitle={'#3A57FB'}
                title={'Eclairage LED'}
                placeholder={'Eclairage LED'}
                btnOfflabel={'Off'}
                btnOnlabel={'On'}
                btnAutolabel={'Auto'}
                isOn={true}
                timePeriod={'18H00 - 22H00'}
              />
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
  },
  container: {
    flex: 1,
    backgroundColor: '#000004',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  enteteText: {
    fontSize: 45,
    fontWeight: '300',
    padding: 10,
    textAlign: 'left',
    color: '#ffffff',
    fontFamily: fontFamilyRegular(),
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux: state.AppRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppareilVerouillee);
