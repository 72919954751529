import {ACTION_USER_LOGIN} from './Login/LoginActions';
import {
  ACTION_APP_LOADDED,
  ACTION_APP_FAV,
  ACTION_APP_RACC,
  get_base_list,
  ACTION_APP_BASE_LIST,
} from './App/AppActions';
import {
  ACTION_SYSTEMS_CURRENT,
  default as actions,
} from './Systems/SystemsActions';
import {
  getStoreDataPromise,
  getStoreMultiDataPromise,
  removeStoreData,
} from '../Config/Config';

const initDataApp = () => {
  return async dispatch => {
    dispatch({
      type: ACTION_APP_FAV,
      fav: [
        {
          id: 0,
          widget_type: 'defaul',
          isfavory: true,
          title: 'Baignade',
          widget_hash: '__Baignade__',
          hasmoreOption: [],
        },
        {
          id: 0,
          widget_type: 'defaul',
          isfavory: true,
          title: 'Faq',
          widget_hash: '__faq__',
          hasmoreOption: [],
        },
        {
          id: 1,
          widget_type: 'defaul',
          isfavory: true,
          title: 'Meteo',
          widget_hash: '__meteo__',
          hasmoreOption: [],
        },
        {
          id: 1,
          widget_type: 'defaul',
          isfavory: true,
          title: 'Bidons',
          widget_hash: '__bidons__',
          hasmoreOption: [],
        },
        {
          id: 1,
          widget_type: 'defaul',
          isfavory: true,
          title: 'Raccourcis',
          widget_hash: '__raccourcis__',
          hasmoreOption: [],
        },
      ],
    });

    getStoreMultiDataPromise(['system_current', 'user_logged']).then(values => {
      values.map(item => {
        try {
          if (item[0] === '@system_current' && item[1]) {
            // let json = JSON.parse(item[1]);
            // console.log("json system_current=>",json)

            // dispatch({type: ACTION_SYSTEMS_CURRENT, system: json});
          }

          if (item[0] === '@user_logged' && item[1]) {
            let json = JSON.parse(item[1]);
            if (json.hasOwnProperty('appid')) {
              //  console.log("call get_base_list");
              //dispatch(get_base_list(json.appid));
              getStoreDataPromise('app_base_list').then(data => {
                // console.log(data)
                if (!data) {
                  dispatch(get_base_list(json.appid));
                } else {
                  dispatch({
                    type: ACTION_APP_BASE_LIST,
                    base_list: JSON.parse(data),
                  });
                }
              });

              /* getStoreDataPromise("app_fav").then((data)=>{

                                if(data){
                                    let json = JSON.parse(data);

                                    dispatch({ type: ACTION_APP_FAV, fav:json });
                                }else{

                                }

                            })*/

              /* getStoreDataPromise("app_racc").then((data)=>{
                                if(data){
                                    let json = JSON.parse(data);
                                    dispatch({ type: ACTION_APP_RACC, racc:json });
                                }else{
                                    dispatch({ type: ACTION_APP_RACC, racc:[] });
                                }
                            })*/
              //removeStoreData("app_base_list");
            }

            dispatch({type: ACTION_USER_LOGIN, user: json});
          }
        } catch (e) {}
      });
      dispatch({type: ACTION_APP_LOADDED});
    });
  };
};

const onAppStart = async store => {
  store.dispatch(initDataApp());
};

export {onAppStart};
