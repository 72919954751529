import React from 'react';
import {
  ImageBackground,
  View,
  StyleSheet,
  KeyboardAvoidingView,
  Keyboard,
  TouchableWithoutFeedback,
} from 'react-native';
import {connect} from 'react-redux';

import * as actions from '../../Store/Systems/SystemsActions';

import Step1 from './Step1';
import Step2 from './Step2';
class NewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
    };
  }
  SetNextStep = stepNb => {
    this.setState({step: 2});
  };
  EndStep = () => {
    this.props.SystemDetail(this.props.current_system.system[0].sys_appid);
    this.props.navigation.navigate('Profil');
  };
  ToProfile = () => {
    this.props.navigation.navigate('Profil');
  };
  SwitchStep = () => {
    switch (this.state.step) {
      case 1:
        return <Step1 nextStep={this.SetNextStep} goBack={this.ToProfile} />;
      case 2:
        return <Step2 nextStep={this.SetNextStep} EndStep={this.EndStep} />;
    }
  };
  render() {
    return (
      <ImageBackground
        style={styles.imgBackground}
        resizeMode="cover"
        source={require('../../assets/bg.png')}>
        {this.SwitchStep()}
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  imgBackground: {
    width: '100%',
    height: '100%',
  },
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  FormView: {
    flex: 1,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.LoginReducer.user,
    current_system: state.SystemsRedux.system,
    clientInfo: state.SystemsRedux.clientInfo,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    last_update_data_redux_system: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    SystemDetail: appId => {
      return dispatch(actions.SystemDetail(appId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewUser);
