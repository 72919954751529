import React from 'react';
import {
  ImageBackground,
  View,
  StyleSheet,
  KeyboardAvoidingView,
  Keyboard,
  TouchableWithoutFeedback,
} from 'react-native';

import Step4 from '../Registration/Step4';
import Step5 from '../Registration/Step5';
export default class NouveauBassin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
    };
  }
  SetNextStep = stepNb => {
    this.setState({step: 2});
  };
  EndStep = () => {
    this.props.navigation.navigate('Systemes');
  };
  ToProfile = () => {
    this.props.navigation.navigate('Profil');
  };
  goBack = () => {
    this.props.navigation.navigate('Systemes');
  };
  SwitchStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <Step4
            nextStep={this.SetNextStep}
            ToProfile={this.ToProfile}
            goBack={this.goBack}
          />
        );
      case 2:
        return (
          <Step5
            nextStep={this.SetNextStep}
            ToProfile={this.ToProfile}
            EndStep={this.EndStep}
            goBack={this.goBack}
          />
        );
    }
  };
  render() {
    return (
      <ImageBackground
        style={styles.imgBackground}
        resizeMode="cover"
        source={require('../../assets/bg.png')}>
        {this.SwitchStep()}
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  imgBackground: {
    width: '100%',
    height: '100%',
  },
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  FormView: {
    flex: 1,
  },
});
