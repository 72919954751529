import {setStoreData, ShowSnaskMessage} from '../../Config/Config';
import {ACTION_APP_REFRESH} from '../../Store/App/AppActions';
import {
  ACTION_SYSTEMS_CURRENT,
  SystemDetail,
} from '../../Store/Systems/SystemsActions';
import System from '../../Models/Systems';
import { CALIBRATION_RESULT } from '../../Store/Calibration';

export const wsConnect = (host, sys_appid) => ({
  type: 'WS_CONNECT',
  host,
  sys_appid,
});
export const wsConnecting = host => ({type: 'WS_CONNECTING', host});
export const wsConnected = host => ({type: 'WS_CONNECTED', host});
export const wsRefresh = (host, sys_appid) => ({
  type: 'WS_REFRESH',
  host,
  sys_appid,
});
export const wsSwitch = (host, sys_appid) => ({
  type: 'WS_SWITCH',
  host,
  sys_appid,
});
export const wsDisconnect = host => ({type: 'WS_DISCONNECT', host});
export const wsDisconnected = host => ({type: 'WS_DISCONNECTED', host});

export function wsDevicesStatusData(data) {
  return async function(dispatch, getState) {
    /*ShowSnaskMessage({
      message: 'En cours de mise à jour',
      textColor: '#000',
      backgroundColor: '#7ED321',
      buttonColor: '#7ED321',
      duration: 2000,
    });*/

    console.log('Call wsDevicesStatusData', data);

    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }

    const systemAPI = new System();
    let actions = _system.actions;
    let updateDate = getState().SystemsRedux.last_update_data_redux;
    let IsUpdate = false;
    if (data.hasOwnProperty('actions')) {
      if (data.actions && data.actions.length > 0) {
        for (let i = 0; i < data.actions.length; i++) {
          const socketDevice = data.actions[i];

          const d = actions.find(
            item =>
              parseInt(item.device_number) === parseInt(socketDevice.actionNum),
          );

          if (!d) {
            continue;
          }

          IsUpdate = true;

          const deviceFromAPI = await systemAPI.getDevice(
            _system.system[0].sys_appid,
            d.device_number,
          );

          if (deviceFromAPI) {
            //d.device_index = deviceFromAPI.device_index;
            //d.device_status = deviceFromAPI.device_status;
            d.device_text = deviceFromAPI.device_text;
            d.device_status = socketDevice.status.toString();
            d.device_index = socketDevice.mode.toString();
            d.device_speed = socketDevice.speed.toString();
            d.device_sequence = socketDevice.sequence.toString();
          }
        }
      }
    }
    /*// IsUpdate = true;
      data.actions.map(dev => {
        actions = actions.map(item => {
          if (parseInt(item.device_number) === parseInt(dev.actionNum)) {
            // console.log("After actions-status-data",item);

            if (item.device_status !== dev.status.toString()) {
              IsUpdate = true;
            }

            if (item.device_index !== dev.mode.toString()) {
              IsUpdate = true;
            }

            if (item.device_speed !== dev.speed.toString()) {
              IsUpdate = true;
            }

            if (item.device_sequence !== dev.sequence.toString()) {
              IsUpdate = true;
            }

            item.device_status = dev.status.toString();
            item.device_index = dev.mode.toString();
            item.device_speed = dev.speed.toString();
            item.device_sequence = dev.sequence.toString();
          }
          return item;
        });
      });

      _system.actions = actions;
    }*/

    if (IsUpdate) {
      console.log('End Call wsDevicesStatusData ');
      setStoreData('system_current', _system);
      dispatch({
        type: ACTION_SYSTEMS_CURRENT,
        system: _system,
        updateDate,
      });
      dispatch({type: ACTION_APP_REFRESH});
    }
  };
}
export function wsDevicesData(data) {
  return async function(dispatch, getState) {
    /*ShowSnaskMessage({
      message: 'En cours de mise à jour',
      textColor: '#000',
      backgroundColor: '#7ED321',
      buttonColor: '#7ED321',
      duration: 2000,
    });*/

    console.log('Call wsDevicesData ', data);
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }
    let actions = _system.actions;
    let updateDate = getState().SystemsRedux.last_update_data_redux;
    let IsUpdate = false;
    const systemAPI = new System();

    if (data.actions && data.actions.length > 0) {
      for (let i = 0; i < data.actions.length; i++) {
        const socketDevice = data.actions[i];

        const d = actions.find(
          item =>
            parseInt(item.device_number) === parseInt(socketDevice.actionNum),
        );

        if (!d) {
          continue;
        }

        IsUpdate = true;

        const deviceFromAPI = await systemAPI.getDevice(
          _system.system[0].sys_appid,
          d.device_number,
        );

        if (deviceFromAPI) {
          d.device_index = deviceFromAPI.device_index;
          d.device_status = deviceFromAPI.device_status;
          d.device_text = deviceFromAPI.device_text;
        }
      }
      // IsUpdate = true;
      /*data.actions.map(dev => {
        actions = actions.map(item => {
          if (parseInt(item.device_number) === parseInt(dev.actionNum)) {
            if (item.device_status !== dev.status.toString()) {
              IsUpdate = true;
            }

            if (item.device_index !== dev.mode.toString()) {
              IsUpdate = true;
            }

            item.device_index = dev.mode.toString();
            item.device_status = dev.status.toString();
          }
          return item;
        });
      });

      _system.actions = actions;*/
    }

    if (IsUpdate) {
      console.log('End Call wsDevicesData ');
      setStoreData('system_current', _system);

      dispatch({
        type: ACTION_SYSTEMS_CURRENT,
        system: _system,
        updateDate,
      });
      dispatch({type: ACTION_APP_REFRESH});
    }
  };
}
export function wsSensorsData(data) {
  return async function(dispatch, getState) {
    /*ShowSnaskMessage({
      message: 'En cours de mise à jour',
      textColor: '#000',
      backgroundColor: '#7ED321',
      buttonColor: '#7ED321',
      duration: 2000,
    });*/

    console.log(' Call wsSensorsData ', data);
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }
    const systemAPI = new System();
    let sensors = _system.sensors;
    let updateDate = getState().SystemsRedux.last_update_data_redux;

    let IsUpdate = false;
    if (data.hasOwnProperty('sensors') && data.sensors.length > 0) {
      for (let i = 0; i < data.sensors.length; i++) {
        const socketSensor = data.sensors[i];

        const s = sensors.find(
          item =>
            parseInt(item.sensor_number) === parseInt(socketSensor.sensorNum),
        );
        if (!s) {
          continue;
        }

        if (s.sensor_last_value != socketSensor.value.toString()) {
          IsUpdate = true;
          const sensorFromAPI = await systemAPI.getSensor(
            _system.system[0].sys_appid,
            s.sensor_number,
          );

          s.sensor_last_value = socketSensor.value.toString();

          if (sensorFromAPI) {
            s.sensor_high_color = sensorFromAPI.sensor_high_color;
            s.sensor_low_color = sensorFromAPI.sensor_low_color;
            s.sensor_text = sensorFromAPI.sensor_text;
          }
        }
      }

      /*data.sensors.map(dev => {
        sensors = sensors.map(item => {
          if (parseInt(item.sensor_number) === parseInt(dev.sensorNum)) {
            if (item.sensor_last_value != dev.value) {
              const sensor = await getSensor(_system.system[0].sys_appid, item.sensorNum);
              IsUpdate = true;
            }
            item.sensor_last_value = dev.value;
          }
          return item;
        });
      });
      _system.sensors = sensors;*/
    }

    if (IsUpdate) {
      console.log('End Call wsSensorsData ');
      setStoreData('system_current', _system);
      dispatch({
        type: ACTION_SYSTEMS_CURRENT,
        system: _system,
        updateDate,
      });
      //dispatch(SystemDetail(_system.system[0].sys_appid));
      dispatch({type: ACTION_APP_REFRESH});
    }
  };
}
export function wsRealtimeData(data) {
  return function(dispatch, getState) {
    console.log('Call wsRealtimeData', data);
    /*ShowSnaskMessage({
      message: 'En cours de mise à jour',
      textColor: '#000',
      backgroundColor: '#7ED321',
      buttonColor: '#7ED321',
      duration: 2000,
    });*/

    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }
    let actions = _system.actions;
    let sensors = _system.sensors;
    let updateDate = getState().SystemsRedux.last_update_data_redux;

    let IsUpdate = false;
    if (data.hasOwnProperty('actions') && data.actions.length > 0) {
      // IsUpdate = true;
      actions = actions.map(item => {
        // Find the device in the data
        const incomingDeviceData = data.actions.find(
          a => a.actionNum.toString() === item.device_number,
        );

        if (!incomingDeviceData) {
          return item;
        }

        if (item.device_status !== incomingDeviceData.status.toString()) {
          IsUpdate = true;
        }

        if (item.device_index !== incomingDeviceData.mode.toString()) {
          IsUpdate = true;
        }

        item.device_status = incomingDeviceData.status.toString();
        item.device_index = incomingDeviceData.mode.toString();
        return item;
      });

      _system.actions = actions;
    }

    if (data.hasOwnProperty('sensors') && data.sensors.length > 0) {
      data.sensors.map(dev => {
        sensors = sensors.map(item => {
          if (parseInt(item.sensor_number) === parseInt(dev.sensorNum)) {
            if (item.sensor_last_value !== dev.value) {
              IsUpdate = true;
            }
            item.sensor_last_value = dev.value;
          }
          return item;
        });
      });
      _system.sensors = sensors;
    }
    if (IsUpdate) {
      console.log('End Call wsRealtimeData ');
      setStoreData('system_current', _system);
      dispatch({type: ACTION_APP_REFRESH});
      dispatch({
        type: ACTION_SYSTEMS_CURRENT,
        system: _system,
        updateDate,
      });
    }
  };
}
export function wsNewData(data) {
  return function(dispatch, getState) {
    /*ShowSnaskMessage({
      message: 'En cours de mise à jour',
      textColor: '#000',
      backgroundColor: '#7ED321',
      buttonColor: '#7ED321',
      duration: 2000,
    });*/
    console.log('Call wsNewData', data);
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }
    let sensors = _system.sensors;
    let actions = _system.actions;
    let updateDate = getState().SystemsRedux.last_update_data_redux;
    let IsUpdate = false;
    if (data.hasOwnProperty('sensors') && data.sensors.length > 0) {
      data.sensors.map(dev => {
        sensors = sensors.map(item => {
          if (parseInt(item.sensor_number) === parseInt(dev.sensorNum)) {
            if (item.sensor_last_value != dev.value.toString()) {
              IsUpdate = true;
            }

            item.sensor_last_value = dev.value.toString();
          }
          return item;
        });
      });
      _system.sensors = sensors;
    }

    if (data.hasOwnProperty('actions') && data.actions.length > 0) {
      data.actions.map(dev => {
        actions = actions.map(item => {
          if (parseInt(item.device_number) === parseInt(dev.actionNum)) {
            if (item.device_consumption != dev.runtime.toString()) {
              IsUpdate = true;
            }

            item.device_consumption = dev.runtime.toString();
          }
          return item;
        });
      });
      _system.actions = actions;
    }
    if (IsUpdate) {
      console.log('End Call wsNewData');
      setStoreData('system_current', _system);
      dispatch({type: ACTION_APP_REFRESH});
      dispatch({
        type: ACTION_SYSTEMS_CURRENT,
        system: _system,
        updateDate,
      });
    }
  };
}

export function wsCalibrationData(data) {
  return function(dispatch, getState) {
    dispatch({
      type: CALIBRATION_RESULT,
      point: data.calibration.point,
      rawValue: data.calibration.value,
      sensorNumber: data.calibration.sensorNum,
      result: data.calibration.result,
    });
  };
}

function RefreshSystemDevices(dataDevice) {
  return function(dispatch, getState) {
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }
    let actions = _system.actions.map(item => {
      if (parseInt(item.device_number) === parseInt(dataDevice.actionNum)) {
        item.sensor_last_value = dataDevice.value;
      }
      return item;
    });
    _system.actions = actions;
    setStoreData('system_current', _system);
    dispatch({type: ACTION_APP_REFRESH});
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: _system});
  };
}
