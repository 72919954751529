import React from 'react';
import {StyleSheet, Text, View, Modal, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import {Icon} from 'react-native-elements';
import ProgressCircle from '../ProgressCircle';
import Svg, {
  Use,
  Image,
} from 'react-native-svg';
import {
  fontFamilyArialBlack,
  fontFamilyArialBold,
  fontFamilyBold,
  fontFamilyRegular,
} from '../../Config/fonts';
import * as actions from '../../Store/Systems/SystemsActions';
import PropTypes from 'prop-types';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  showSnaskMessageOperationSuccess,
} from '../../Config/Config';
import {Button} from 'react-native-elements';
import ThreeDotsLoader from '../ThreeDotsLoader';
import LinearGradient from 'react-native-linear-gradient';
import iconDetailDevice from '../../assets/detail-devices.svg';
import DevicesVitesse from './DevicesVitesse';
import DevicesVitesseSmile from './DevicesVitesseSmile';
import translate, {translateString} from '../../translations/translate';
import {t} from 'i18n-js';
import BottomExtraValues from './BottomExtraValues';

class RemoteControlle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWait: false,
      system: null,
      device_index: null,
      device_consumption: null,
      device_status: null,
      bggradialcolor: ['#1E1E1E', '#3A57FB'],
      btns: {
        on: null,
        off: null,
        auto: null,
      },
      listBtns: [],
      modalVisible: false,
      progressVal: 0,
      modalprogress: false,
      longPressProgressVal: {
        ispressed: false,
        Interval: null,
      },
    };
  }

  componentDidMount() {
    this.initData();
    this.initDays();
  }

  componentDidUpdate(prevProps) {
    const {
      device_status,
      device_index,
      device_consumption,
      device_speed,
      device_standard,
      last_update_data_redux_system,
      last_update_data_redux,
    } = this.props;

    if (
      prevProps.device_status !== device_status ||
      prevProps.device_index !== device_index ||
      prevProps.device_speed !== device_speed ||
      prevProps.device_standard !== device_standard ||
      prevProps.device_consumption !== device_consumption ||
      prevProps.last_update_data_redux_system !==
        last_update_data_redux_system ||
      prevProps.last_update_data_redux !== last_update_data_redux
    ) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      // TODO: fix that
      this.setState({
        device_index: parseInt(device_index),
        device_speed: device_speed,
        device_consumption: device_consumption,
        device_status: parseInt(device_status),
        isWait: false,
      });
    }
  }

  UpdateStatusDevices = (field, val) => {
    if (this.state.isWait) {
      return true;
    }
    this.setState({isWait: true});

    this.closeModal();
    this.props
      .UpdateDevice(this.state.system.sys_appid, {
        key: field,
        value: val,
        number: this.props.device_number,
      })
      .then(data => {
        if (field === 'device_index') {
          val = parseInt(val);
          // this.setState({device_index: val});
        }

        this.timeout = setTimeout(() => {
          this.setState({isWait: false});
          this.props.SystemDetail(this.state.system.sys_appid);
        }, 5000);

        showSnaskMessageOperationSuccess();
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };

  initDays = async () => {
    let items = [
      {
        day: await translateString('Monday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Thuesday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Wednesday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Thursday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Friday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Saturday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Sunday'),
        isselected: false,
        defaultbg: '#242424',
      },
    ];

    this.setState({itemsDays: items, isSlotWait: false});
  };

  RenderTimeInformationDays = () => {
    if (this.props.slot.slot_days) {
      let Week = this.state.itemsDays
        ? this.state.itemsDays.map(id => (id.day ? id.day.substring(0, 1) : ''))
        : [];
      //   console.log("this.props.slot.slot_id ",this.props.slot)
      return this.props.slot.slot_days
        .split('')
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        })
        .map(c => {
          let index = parseInt(c);
          if (Week[index - 1]) {
            return (
              <Text
                style={{
                  marginLeft: 2,
                  backgroundColor: '#404040',
                  paddingLeft: 4,
                  textTransform: 'uppercase',
                  paddingRight: 4,
                  borderRadius: 100,
                  fontSize: 10,
                }}>
                {Week[index - 1]}
              </Text>
            );
          }
        });
    }
    return null;
  };
  RenderTimeInformation = () => {
    if (this.props.slot.slot_days) {
      let slot_start = this.props.slot.slot_start.replace(':', 'H');
      let slot_stop = this.props.slot.slot_stop.replace(':', 'H');
      return (
        <View style={{flexDirection: 'row', marginTop: 10}}>
          <Text style={{color: '#404040', fontSize: 10}}>
            {slot_start} - {slot_stop}
          </Text>
          {this.RenderTimeInformationDays()}
        </View>
      );
    }

    return false;
  };

  closeModalProgress = () => {
    this.setState({modalprogress: false});

    this.UpdateStatusDevices('device_setpoint', this.state.progressVal);
  };
  OpenModalProgress = () => {
    this.setState({modalprogress: true});
  };

  RenderCercleProgress = () => {
    if (
      this.props.typeComposent === 'progress' &&
      this.props.device_max_setpoint
    ) {
      let percent =
        (this.props.device_setpoint /
          parseInt(this.props.device_max_setpoint)) *
        100;

      return (
        <ProgressCircle
          percent={percent}
          radius={50}
          borderWidth={3}
          color={this.props.device_color_setpoint}
          shadowColor={'#000000'}
          bgColor="#000000">
          <View style={{flexDirection: 'row'}}>
            <Text
              style={{
                fontSize: 20,
                fontFamily: fontFamilyRegular(),
                fontWeight: '500',
                color: '#fff',
              }}
              onPress={() => {
                this.OpenModalProgress();
              }}>
              {this.props.device_setpoint}
            </Text>
            <Text style={{fontSize: 12, marginTop: 10, color: '#fff'}}>
              {this.props.device_unit_setpoint}
            </Text>
          </View>
        </ProgressCircle>
      );
    }

    return null;
  };
  RenderImage = () => {
    //  console.log("this.props.typeComposent ",this.props.typeComposent,this.props.device_max_setpoint)
    if (
      this.props.typeComposent === 'image' ||
      this.props.typeComposent === 'speed'
    ) {
      //console.log("this.props.assetsWidget ",this.props.assetsWidget);
      if (
        !this.props.assetsWidget.hasOwnProperty('device_icon_status_off') ||
        !this.props.assetsWidget.hasOwnProperty('device_icon_status_on')
      ) {
        return null;
      }
      /* if(this.state.device_status === 0){
                return (
                    <View>
                        <Image source={off} style={styles.backgroundImage}/>
                    </View>
                )
            }*/

      // console.log(this.props.assetsWidget);
      let image = '';

      switch (this.state.device_status) {
        case 0:
          image = 'https://socket.swimo.io/cgi-bin/icons/PUMP.svg?img=_nocach_2';
          if (this.props.assetsWidget.device_icon_status_off) {
            image =
              this.props.assetsWidget.device_icon_status_off + '?img=_nocach_2';
          }
          break;
        case 1:
          image = 'https://socket.swimo.io/cgi-bin/icons/PUMP_ON.svg?img=_nocach_2';
          if (this.props.assetsWidget.device_icon_status_on) {
            image =
              this.props.assetsWidget.device_icon_status_on + '?img=_nocach_2';
          }
          break;
      }

      return (
        <View style={{width: 90, height: 90}}>
          <TouchableOpacity
            hitSlop={{top: 30, left: 30, right: 30, bottom: 30}}
            onPress={() =>
              this.props.navigation.navigate({
                name: 'DevicesDetail',
                params: this.props,
                key:
                  this.state.system.sys_appid +
                  '_' +
                  this.props.device_number +
                  '_30',
              })
            }>
            {image && 
              <Svg width="80" height="80" style={styles.backgroundImage}>
                <Image href={image} style={styles.backgroundImage} />
              </Svg>
            }
          </TouchableOpacity>
        </View>
      );
    }
  };
  RenderStatus = () => {
    if (this.state.device_status != null) {
      //console.log("RenderStatus ==> ",this.props.device_number,this.props.device_status,this.state.device_status);
      let colorIcon = '';

      switch (this.state.device_status) {
        case 0:
          colorIcon = '#FF0C00';
          break;
        case 1:
          colorIcon = '#7ED321';
          break;
        case 2:
          colorIcon = '#ccc';
          break;
      }
      return (
        <Icon
          iconStyle={{marginTop: 10, marginLeft: 10}}
          name="circle"
          type="font-awesome"
          size={10}
          underlayColor={colorIcon}
          color={colorIcon}
        />
      );
    }
    return null;
  };

  closeModal = () => {
    this.setState({modalVisible: false});
  };
  OpenModal = () => {
    this.setState({modalVisible: true});
  };

  renderSpeed = () => {
    if (this.props.typeComposent === 'speed') {
      if (
        this.props.device_speed === '-1' ||
        this.props.device_speed_max === '-1' ||
        parseInt(this.props.device_speed_max) <= 0 ||
        this.props.device_speed_min === '-1' ||
        this.props.device_speed_gap === '-1'
      ) {
        return null;
      }

      //console.log("this.props.device_speed app",this.props.device_speed,this.props.device_speed_gap)
      if (parseInt(this.props.device_speed_max) > 8) {
        return (
          <DevicesVitesse
            selectedVal={this.props.device_speed}
            max={this.props.device_speed_max}
            min={this.props.device_speed_min}
            gap={this.props.device_speed_gap}
            unit={this.props.device_speed_unit}
            onChange={val => {
              this.UpdateStatusDevices('device_speed', val);
            }}
          />
        );
      } else {
        return (
          <DevicesVitesseSmile
            selectedVal={this.props.device_speed}
            max={this.props.device_speed_max}
            min={this.props.device_speed_min}
            gap={this.props.device_speed_gap}
            unit={this.props.device_speed_unit}
            onChange={val => {
              this.UpdateStatusDevices('device_speed', val);
            }}
          />
        );
      }
    }
  };
  renderBtnStatu = () => {
    if (this.props.typeComposent === 'speed') {
      return null;
    }
    let btnTitle = '';
    let btnstyle = {};

    // console.log("this.state.device_index ",this.state.device_index,this.state.listBtns,this.props.device_number)
    return this.state.listBtns.map((btn, index) => {
      if (this.state.device_index === index) {
        let btnstyle = {};

        switch (parseInt(this.state.device_index)) {
          case 0:
            btnstyle = styles.btnOn;
            break;
          case 1:
            btnstyle = styles.btnOff;
            break;
          case 2:
            btnstyle = styles.btnauto;
            break;
        }
        btnTitle = btn;

        return (
          <Button
            buttonStyle={[styles.btnDefault, btnstyle]}
            onPress={() => {
              this.OpenModal();
            }}
            title={btnTitle}
          />
        );
      }
    });
  };

  initData = () => {
    let _listBtn = this.props.device_mode_display.split('/');

    let _btns = {
      on: _listBtn.length > 0 ? _listBtn[0] : null,
      off: _listBtn.length > 1 ? _listBtn[1] : null,
      auto: _listBtn.length > 2 ? _listBtn[2] : null,
    };

    let gradialcolor = ['#1E1E1E', '#3A57FB'];
    if (
      this.props.device_color_setpoint &&
      this.props.device_color_setpoint != '-1' &&
      this.props.device_color_setpoint.length > 6
    ) {
      //  console.log("this.props.device_color_setpoint ",this.props.device_color_setpoint)
      gradialcolor = ['#1E1E1E', this.props.device_color_setpoint];
    }

    this.setState({
      device_index: parseInt(this.props.device_index),
      device_consumption: this.props.device_consumption,
      progressVal: parseFloat(this.props.device_setpoint),
      unit: this.props.device_unit_setpoint,
      device_status: parseInt(this.props.device_status),
      btns: _btns,
      listBtns: _listBtn,
      bggradialcolor: gradialcolor,
    });

    this.getSystem();
  };

  UpdateStatus = (statu, val) => {
    val = parseInt(val);
    this.UpdateStatusDevices('device_index', val);
  };

  renderBtnModel = () => {
    return this.state.listBtns.map((btn, index) => {
      return (
        <Button
          buttonStyle={[
            styles.btnDefault,
            this.state.device_index === index
              ? styles.ModalbtnEnable
              : styles.Modalbtndisable,
          ]}
          onPress={() => {
            this.UpdateStatus(btn, index);
          }}
          title={btn}
        />
      );
    });
  };
  UpdateprogressVal = operation => {
    let val = parseFloat(this.state.progressVal);
    let step = 0.5;
    if (val / 100 >= 1) {
      step = 10;
    } else if (val / 10 >= 1) {
      step = 1;
    } else {
      step = 0.1;
    }
    if (step >= 1) {
      val = parseInt(this.state.progressVal);
    }
    if (operation === '-') {
      if (val - step > 0) {
        val = val - step;
      } else {
        val = 0;
      }
    } else {
      val = val + step;
    }
    val = val.toFixed(1);

    if (val >= 10) {
      val = parseInt(val);
    }

    this.setState({progressVal: val});
  };
  RenderModalCercleProgress = () => {
    if (this.props.typeComposent === 'progress') {
      return (
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.modalprogress}
          onRequestClose={this.closeModal}>
          <View style={styles.Modalcontainer}>
            <View
              style={{
                backgroundColor: '#000000',
                borderRadius: 20,
                width: '90%',
                paddingBottom: 20,
              }}>
              <View style={styles.topViewtitle}>
                <Text
                  style={{
                    color: this.props.device_color_setpoint,
                    borderTopColor: this.props.device_color_setpoint,
                    borderTopWidth: 3,
                    fontSize: 16,
                    marginTop: -2,
                    fontFamily: fontFamilyBold(),
                    textTransform: 'uppercase',
                    paddingTop: 10,
                  }}>
                  {this.props.device_name}
                </Text>
              </View>
              <View style={{position: 'absolute', right: 10, top: 10}}>
                <TouchableOpacity onPress={() => this.closeModalProgress()}>
                  <Icon
                    size={20}
                    containerStyle={{marginLeft: 10}}
                    name="check"
                    type="font-awesome"
                    color="#fff"
                  />
                </TouchableOpacity>
              </View>
              <View
                style={[
                  styles.ModalViewRow,
                  {marginTop: 50, marginBottom: 50},
                ]}>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <TouchableOpacity
                    hitSlop={{top: 30, left: 30, right: 30, bottom: 30}}
                    onPress={() => this.UpdateprogressVal('-')}
                    delayLongPress={1000}
                    onPressIn={() => {
                      //console.log('TouchableOpacity onPressIn');
                      // console.log(Date.now())
                    }}
                    onPressOut={() => {
                      // console.log('TouchableOpacity onPressOut');
                      //console.log(Date.now())
                      this.EndUpdateonLongPressprogressVal('-');
                    }}
                    onLongPress={e => {
                      //console.log(' onLongPress');
                      //console.log(Date.now());

                      this.UpdateonLongPressprogressVal('-');
                    }}>
                    <View style={styles.CircleShapeView}>
                      <Icon
                        iconStyle={{marginTop: 0, marginLeft: 0}}
                        name="minus"
                        type="font-awesome"
                        size={20}
                        underlayColor={'#000'}
                        color={'#000'}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <View style={{flexDirection: 'row'}}>
                    <Text
                      style={{
                        fontSize: 30,
                        fontFamily: fontFamilyRegular(),
                        fontWeight: '500',
                        color: '#fff',
                      }}>
                      {this.state.progressVal}
                    </Text>
                    <Text style={{fontSize: 12, marginTop: 20, color: '#fff'}}>
                      {this.props.device_unit_setpoint}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <TouchableOpacity
                    hitSlop={{top: 30, left: 30, right: 30, bottom: 30}}
                    delayLongPress={1000}
                    onPress={() => {
                      this.UpdateprogressVal('+');
                    }}
                    onPressIn={() => {
                      //console.log('TouchableOpacity onPressIn');
                      // console.log(Date.now())
                    }}
                    onPressOut={() => {
                      // console.log('TouchableOpacity onPressOut');
                      //console.log(Date.now())
                      this.EndUpdateonLongPressprogressVal('+');
                    }}
                    onLongPress={e => {
                      //console.log(' onLongPress');
                      //console.log(Date.now());

                      this.UpdateonLongPressprogressVal('+');
                    }}>
                    <View style={styles.CircleShapeView}>
                      <Icon
                        iconStyle={{marginTop: 0, marginLeft: 0}}
                        name="plus"
                        type="font-awesome"
                        size={20}
                        underlayColor={'#000'}
                        color={'#000'}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </Modal>
      );
    }
  };
  render() {
    const {borderTitle} = this.props;

    const userType = this.props.user?.user_type;

    if (
      this.props.typeComposent === 'progress' &&
      this.props.device_setpoint === ''
    ) {
      return null;
    }

    if (
      this.props.typeComposent === 'speed' &&
      parseInt(this.props.device_speed_min) < 0
    ) {
      return null;
    }
    let bgtitlecolor =
      this.props.device_color_setpoint &&
      this.props.device_color_setpoint != '-1'
        ? this.props.device_color_setpoint
        : '#3A57FB';
    // console.log("this.state.bggradialcolor ",this.state.bggradialcolor)

    return (
      <LinearGradient
        colors={this.state.bggradialcolor}
        style={styles.LinearGradientcontainer}>
        <View style={styles.container}>
          <View style={styles.containerFixed}>
            <View
              style={{width: 50, backgroundColor: bgtitlecolor, height: 2}}
            />
            <Text style={styles.titrewidget}>{this.props.device_hash}</Text>
          </View>

          <View style={styles.topViewtitle}>
            <Text
              style={{
                color: this.props.device_color_setpoint,
                borderTopColor: this.props.device_color_setpoint,

                fontSize: 16,
                marginTop: -2,
                fontFamily: fontFamilyArialBold(),

                paddingTop: 10,
              }}>
              {this.props.device_name}
            </Text>

            {this.RenderStatus()}
          </View>
          {userType === '0' && (
            <TouchableOpacity
              hitSlop={{top: 30, left: 30, right: 30, bottom: 30}}
              style={{flex: 1, paddingLeft: 10, marginTop: 0}}
              onPress={() =>
                this.props.navigation.navigate({
                  name: 'DevicesDetail',
                  params: this.props,
                  key:
                    this.state.system.sys_appid +
                    '_' +
                    this.props.device_number +
                    '_30',
                })
              }>
                <Svg width="20" height="20">
                  <Image href={iconDetailDevice} />
                </Svg>
            </TouchableOpacity>
          )}

          <View>{this.state.isWait ? <ThreeDotsLoader /> : <View />}</View>
          {this.props.device_text ? (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
              }}>
              {this.props.device_text
                ? translate(this.props.device_text, styles.deviceText)
                : false}
            </View>
          ) : (
            false
          )}
          {this.props.typeComposent === 'speed' ? (
            <View
              style={{
                marginTop: 10,
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                minHeight: 100,
              }}>
              <View style={{flexDirection: 'row'}}>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onPress={() => {
                    /*this.props.navigation.navigate({
                                          name: 'DevicesDetail',
                                          params: this.props,
                                          key:this.state.system.sys_appid+"_"+this.props.device_number+"_30"
                                      })*/
                  }}>
                  {this.RenderCercleProgress()}
                </View>
                <View
                  style={{
                    flex: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              </View>
              <View
                style={{
                  position: 'absolute',
                  width: '80%',
                  right: '9%',
                  top: 10,
                  paddingRight: 5,
                  paddingLeft: 5,
                  zIndex: 9,
                }}>
                {this.renderSpeed()}
              </View>
            </View>
          ) : (
            <View
              style={{
                flexDirection: 'row',
                marginTop: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => {
                  /*this.props.navigation.navigate({
                                  name: 'DevicesDetail',
                                  params: this.props,
                                  key:this.strenderBtnStatuate.system.sys_appid+"_"+this.props.device_number+"_30"
                              })*/
                }}>
                {this.RenderImage()}
                {this.RenderCercleProgress()}
              </View>
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {this.renderBtnStatu()}
                {this.renderSpeed()}
              </View>
            </View>
          )}

          <View style={{paddingLeft: 10}}>{this.RenderTimeInformation()}</View>

          <Modal
            animationType="fade"
            transparent={true}
            visible={this.state.modalVisible}
            onRequestClose={this.closeModal}>
            <View style={styles.Modalcontainer}>
              <View
                style={{
                  backgroundColor: '#000000',
                  borderRadius: 20,
                  width: '90%',
                }}>
                <View style={styles.topViewtitle}>
                  <Text
                    style={{
                      color: borderTitle,
                      borderTopColor: borderTitle,
                      borderTopWidth: 3,
                      fontSize: 16,
                      marginTop: -2,
                      fontFamily: fontFamilyBold(),
                      textTransform: 'uppercase',
                      paddingTop: 10,
                    }}>
                    {this.props.title}
                  </Text>
                </View>
                <View style={{position: 'absolute', right: 20, top: 20}}>
                  <TouchableOpacity onPress={() => this.closeModal()}>
                    <Icon
                      size={20}
                      hitSlop={{top: 8, left: 30, right: 30, bottom: 30}}
                      containerStyle={{marginLeft: 10}}
                      name="check"
                      type="font-awesome"
                      color="#fff"
                    />
                  </TouchableOpacity>
                </View>

                <View style={[styles.ModalViewCol, {marginTop: 20}]}>
                  {this.renderBtnModel()}
                </View>
              </View>
            </View>
          </Modal>
          <BottomExtraValues
            name2={this.props.sensor_name_2}
            value2={this.props.sensor_live_2}
            unit2={this.props.sensor_unit_2}
            name3={this.props.sensor_name_3}
            value3={this.props.sensor_live_3}
            unit3={this.props.sensor_unit_3}
          />
        </View>
        {this.RenderModalCercleProgress()}
      </LinearGradient>
    );
  }

  getSystem = () => {
    if (
      this.props.current_system.hasOwnProperty('system') &&
      this.props.current_system.system.length > 0
    ) {
      this.setState({
        system: this.props.current_system.system[0],
      });
    }
  };

  EndUpdateonLongPressprogressVal = op => {
    /**
         * longPressProgressVal:{
                ispressed:false,
                Interval:null,
            }
         */

    if (this.state.longPressProgressVal.ispressed) {
      clearInterval(this.state.longPressProgressVal.Interval);
      this.setState({
        longPressProgressVal: {
          ispressed: false,
          Interval: null,
        },
      });
    }
  };

  UpdateonLongPressprogressVal = op => {
    if (!this.state.longPressProgressVal.ispressed) {
      var myVar = setInterval(() => {
        this.UpdateprogressVal(op);
      }, 100);
      this.setState({
        longPressProgressVal: {
          ispressed: true,
          Interval: myVar,
        },
      });
    }
  };
}

const styles = StyleSheet.create({
  LinearGradientcontainer: {
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
    padding: 1,
    marginBottom: 20,
  },
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,
    paddingBottom: 10,
  },
  containerFixed: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titrewidget: {
    textAlign: 'right',
    width: '100%',
    fontSize: 40,
    color: '#1E1E1E',
    fontFamily: fontFamilyArialBlack(),
    textTransform: 'uppercase',
    marginTop: 10,
  },
  topViewtitle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnauto: {
    backgroundColor: '#2B8FED',
  },
  btnOff: {
    backgroundColor: '#242424',
  },
  btnOn: {
    backgroundColor: '#39C100',
  },
  btnDefault: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 20,
    color: '#ffffff',
    fontSize: 16,
    fontFamily: fontFamilyBold(),
  },
  backgroundImage: {
    height: 70,
    resizeMode: 'stretch', // or 'stretch'
  },
  Modalcontainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    flexDirection: 'column',
  },
  ModalViewCol: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  ModalViewRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Modalbtndisable: {
    backgroundColor: '#242424',
    marginBottom: 20,
  },
  ModalbtnEnable: {
    backgroundColor: '#2B8FED',
    marginBottom: 20,
  },
  CircleShapeView: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    borderRadius: 80 / 2,
    borderWidth: 1,
    borderColor: '#fff',
    backgroundColor: '#fff',
  },
  deviceText: {
    color: 'white',
  },
});

RemoteControlle.propTypes = {
  typeComposent: PropTypes.string,
  ddevice_consumption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  device_date: PropTypes.string,
  device_hash: PropTypes.string,
  device_index: PropTypes.string,
  device_level: PropTypes.string,
  device_level_ref: PropTypes.string,
  device_level_unit: PropTypes.string,
  device_mode_available: PropTypes.string,
  device_mode_display: PropTypes.string,
  device_name: PropTypes.string,
  device_number: PropTypes.string,
  device_power: PropTypes.string,
  device_security: PropTypes.string,
  device_sensor: PropTypes.string,
  device_sequence: PropTypes.string,
  device_sequence_available: PropTypes.string,
  device_sequence_display: PropTypes.string,
  device_setpoint: PropTypes.string,
  device_speed: PropTypes.number,
  device_speed_unit: PropTypes.string,
  device_speed_min: PropTypes.number,
  device_speed_max: PropTypes.number,
  device_speed_gap: PropTypes.number,
  device_standard: PropTypes.string,
  device_status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  device_type: PropTypes.string,
  device_unit_power: PropTypes.string,
  device_unit_setpoint: PropTypes.string,
  device_color_setpoint: PropTypes.string,
  device_min_setpoint: PropTypes.string,
  device_max_setpoint: PropTypes.string,
  device_widget: PropTypes.string,
  sys_appid: PropTypes.string,
  borderTitle: PropTypes.string,
  assetsWidget: PropTypes.object,
  slot: PropTypes.object,
  sensor_unit_3: PropTypes.string,
  sensor_live_3: PropTypes.string,
  sensor_name_3: PropTypes.string,
  sensor_unit_2: PropTypes.string,
  sensor_live_2: PropTypes.string,
  sensor_name_2: PropTypes.string,
};

RemoteControlle.defaultProps = {
  typeComposent: 'image',
  device_consumption: '',
  device_date: '',
  device_hash: '',
  device_index: '',
  device_level: '',
  device_level_ref: '',
  device_level_unit: '',
  device_mode_available: '',
  device_mode_display: '',
  device_name: '',
  device_number: '',
  device_power: '',
  device_security: '',
  device_sensor: '',
  device_sequence: '',
  device_sequence_available: '',
  device_sequence_display: '',
  device_setpoint: '',
  device_speed: '',
  device_speed_unit: '',
  device_speed_min: '',
  device_speed_max: '',
  device_speed_gap: '',
  device_standard: '',
  device_status: '',
  device_type: '',
  device_unit_power: '',
  device_unit_setpoint: '',
  device_widget: '',
  device_color_setpoint: '',
  device_min_setpoint: '',
  device_max_setpoint: '0',
  sys_appid: '',
  borderTitle: '#3A57FB',
  assetsWidget: {},
  slot: {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    sys_appid: state.SystemsRedux.system && state.SystemsRedux.system.sys_appid,
    current_system: state.SystemsRedux.system,
    user: state.SystemsRedux.clientInfo?.user,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    last_update_data_redux_system: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
    SystemDetail: (appId, params) => {
      return dispatch(actions.SystemDetail(appId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoteControlle);
