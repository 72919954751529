import React from 'react';
import {StyleSheet, View, Text, ImageBackground} from 'react-native';
import {connect} from 'react-redux';
import SmoothPicker from 'react-native-smooth-picker';
import PropTypes from 'prop-types';
const BubbleText = props => {
  const {children, selected, sizeText, customeTextstyle} = props;
  return (
    <View style={[styles.itemTextStyleHorizontal, customeTextstyle]}>
      <Text
        style={{
          textAlign: 'center',
          fontSize: sizeText,
          color: selected ? 'black' : 'black',
          fontWeight: selected ? 'bold' : 'normal',
        }}>
        {children}
      </Text>
    </View>
  );
};

class DevicesVitesse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 20,
      selectedToShow: 1,
      defaultmax: 40,
      defaultmin: 0,
      defaultgap: 1,
    };
  }
  componentDidMount() {
    let nbelements = parseInt(this.props.max) - parseInt(this.props.min);
    let valapp = parseInt(this.props.defaultVal) - parseInt(this.props.min);
    let pourcent = (valapp / nbelements) * 100;

    let newVal = parseInt((pourcent * 30) / 100) + 5;

    // console.log("Vitesse componentDidMount => ",pourcent,newVal,nbelements,)
    this.setState({selected: newVal, selectedToShow: this.props.selectedVal});
  }

  componentDidUpdate(prevProps) {
    const {selectedVal} = this.props;

    if (prevProps.selectedVal !== selectedVal) {
      let nbelements = parseInt(this.props.max) - parseInt(this.props.min);
      let valapp = parseInt(selectedVal) - parseInt(this.props.min);
      let pourcent = (valapp / nbelements) * 100;

      let newVal = parseInt((pourcent * 30) / 100) + 5;

      // console.log("Vitesse componentDidMount => ",pourcent,newVal,nbelements,)
      this.setState({selected: newVal, selectedToShow: selectedVal});
    }
  }

  handleChange(index) {
    //let val = parseInt(index)+parseInt(this.props.gap);
    // this.props.onChange(val)
    if (index < 5) {
      index = 5;
    }
    if (index > 35) {
      index = 35;
    }

    let nbelements = 30;
    let valapp = index - 5;
    let pourcent = (valapp / nbelements) * 100;
    let maxVal = parseInt(this.props.max) - parseInt(this.props.gap);
    let newVal = (pourcent / 100) * maxVal + parseInt(this.props.gap);
    let _selectedToShow = parseInt(this.state.selectedToShow);
    if (newVal >= _selectedToShow + parseInt(this.props.gap)) {
      _selectedToShow = _selectedToShow + parseInt(this.props.gap);
      this.setState({selectedToShow: _selectedToShow});
      this.props.onChange(_selectedToShow);
    }

    if (newVal <= _selectedToShow - parseInt(this.props.gap)) {
      _selectedToShow = _selectedToShow - parseInt(this.props.gap);
      this.setState({selectedToShow: _selectedToShow});
      this.props.onChange(_selectedToShow);
    }

    if (newVal === parseInt(this.props.max)) {
      this.setState({selectedToShow: parseInt(this.props.max)});
      this.props.onChange(parseInt(this.props.max));
    }

    if (newVal === parseInt(this.props.min)) {
      this.setState({selectedToShow: parseInt(this.props.min)});
      this.props.onChange(parseInt(this.props.min));
    }
    console.log('speed => ', pourcent, newVal);
    this.setState(
      {
        selected: index,
        //  selectedToShow: _selectedToShow,
      },
      () => {
        this.refList.refs.smoothPicker.scrollToIndex({
          animated: false,
          index: index,
          viewOffset: -30,
        });
      },
    );
  }

  render() {
    const {selected} = this.state;
    return (
      <View style={styles.container}>
        <View>
          <ImageBackground
            source={require('../../assets/vitesse.png')}
            style={[styles.wrapperHorizontal, this.props.customestyle]}>
            <SmoothPicker
              initialScrollToIndex={selected}
              onScrollToIndexFailed={() => {}}
              ref={ref => (this.refList = ref)}
              keyExtractor={(_, index) => index.toString()}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              horizontal={true}
              scrollAnimation
              data={Array.from(
                {length: this.state.defaultmax},
                (_, i) => 0 + i,
              )}
              onSelected={({item, index}) => this.handleChange(index)}
              renderItem={({item, index}) => {
                let max = this.state.defaultmax;
                let midV = max / 2;
                let bigsize = this.props.fontsize;
                let sizeT = 1;
                if (midV - 0.5 < index && index < midV + 0.5) {
                  sizeT = bigsize * 1.5;
                } else if (midV - 0.5 > index) {
                  sizeT = (index / midV) * bigsize;
                  sizeT = sizeT * 1.5;
                } else {
                  sizeT = ((max - index) / midV) * bigsize;
                  sizeT = sizeT * 1.5;
                }

                if (index === 0) {
                  sizeT = 2;
                }

                return (
                  <BubbleText
                    horizontal
                    selected={index === selected}
                    sizeText={sizeT}
                    customeTextstyle={this.props.customeTextstyle}>
                    |
                  </BubbleText>
                );
              }}
            />
          </ImageBackground>
        </View>
        {this.props.showFooter && (
          <View style={{flex: 1, flexDirection: 'row', marginTop: 10}}>
            <View style={{flex: 1}}>
              <Text style={{color: '#fff', fontSize: 10}}>
                {this.props.min}
              </Text>
            </View>
            <View style={{flex: 1}}>
              <Text style={{color: '#fff', textAlign: 'center', fontSize: 10}}>
                {this.state.selectedToShow} {this.props.unit}
              </Text>
            </View>
            <View style={{flex: 1}}>
              <Text style={{color: '#fff', textAlign: 'right', fontSize: 10}}>
                {this.props.max}
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  wrapperHorizontal: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    color: 'black',
  },

  itemTextStyleHorizontal: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: -4,
    width: 20,
    height: 50,
    paddingTop: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

DevicesVitesse.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  gap: PropTypes.number,
  fontsize: PropTypes.number,
  onChange: PropTypes.number,
  defaultVal: PropTypes.number,
  customestyle: PropTypes.any,
  minVal: PropTypes.number,
  showFooter: PropTypes.bool,
};
DevicesVitesse.defaultProps = {
  defaultmax: 40,
  defaultmin: 0,
  defaultgap: 1,
  max: 30,
  min: 0,
  gap: 1,
  selectedVal: 1,
  fontsize: 33,
  defaultVal: 15,
  showFooter: true,
  customestyle: {
    width: '98%',
    height: 55,
  },
  customeTextstyle: {
    width: 20,
    height: 55,
  },
  onChange: index => {},
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DevicesVitesse);
