import React from 'react';
import {StyleSheet, Text, View, Button, Dimensions} from 'react-native';
import {connect} from 'react-redux';
import LinearGradient from 'react-native-linear-gradient';
import AppBarChart from '../../ChartJs/AppBarChart';
import PropTypes from 'prop-types';
import {
  fontFamilyArialBlack,
  fontFamilyArialBold,
  fontFamilyBold,
} from '../../Config/fonts';
import {isColor, getColumnNumber} from '../../Config/Config';
import {getAlarmColor} from '../../Utils/colors';

class GraphSensor extends React.Component {
  constructor(props) {
    super(props);

    let dataGraph = [];
    try {
      dataGraph = JSON.parse(this.props.sensor_values);
    } catch (e) {
      console.log(e, this.props.sensor_values);
    }

    this.state = {
      dataGraph,
      statusLabel: '7,2',
    };
  }

  resize = () => {
    this.setState({
      innerWidth: window.innerWidth
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  render() {
    let cerclepos = -5;

    if (
      parseFloat(this.props.sensor_min) <
        parseFloat(this.props.sensor_last_value) &&
      parseFloat(this.props.sensor_last_value) <
        parseFloat(this.props.sensor_max)
    ) {
      cerclepos =
        (parseFloat(this.props.sensor_last_value) /
          parseFloat(this.props.sensor_max)) *
          (Dimensions.get('window').width / (getColumnNumber() * 2) - 25);
    } else if (this.props.sensor_min >= this.props.sensor_last_value) {
      cerclepos = -5;
    } else {
      cerclepos = Dimensions.get('window').width / (getColumnNumber() * 2) - 25;
    }
    let bgtitlecolor = '#3A57FB';
    return (
      <LinearGradient
        colors={['#121212', '#3A57FB']}
        style={styles.containerLinearGradient}>
        <View style={styles.container}>
          <View style={styles.containerFixed}>
            <View
              style={{width: 50, backgroundColor: bgtitlecolor, height: 2}}
            />
            <Text style={styles.titrewidget}>{this.props.sensor_hash}</Text>
          </View>
          <View style={styles.topViewtitle}>
            <Text
              style={{
                color: getAlarmColor(
                  this.props.sensor_high_color,
                  this.props.sensor_low_color,
                  this.props.sensor_alarm_min,
                  this.props.sensor_alarm_max,
                  this.props.sensor_last_value,
                ),
                borderTopColor: getAlarmColor(
                  this.props.sensor_high_color,
                  this.props.sensor_low_color,
                  this.props.sensor_alarm_min,
                  this.props.sensor_alarm_max,
                  this.props.sensor_last_value,
                ),
                fontFamily: fontFamilyArialBold(),
                borderTopWidth: 3,
                fontSize: 16,
                paddingTop: 10,
              }}>
              {this.props.sensor_name}
            </Text>
          </View>
          <View style={{flexDirection: 'row', marginTop: 25}}>
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 10,
                paddingRight: 10,
              }}>
              <Text
                style={{
                  color: '#fff',
                  fontSize: 20,
                  fontFamily: fontFamilyBold(),
                }}>
                {this.props.sensor_last_value}
              </Text>
              <View style={{flex: 1, flexDirection: 'row', width:  Dimensions.get('window').width / (getColumnNumber() * 2) - 25, paddingLeft: 5, paddingRight: 5}}>
                <View style={{flex: 1}}>
                  <Text
                    style={{color: '#fff', fontSize: 10, textAlign: 'left'}}>
                    {this.props.sensor_alarm_min}
                  </Text>
                </View>
                <View style={{flex: 1}}>
                  <Text
                    style={{color: '#fff', fontSize: 10, textAlign: 'right'}}>
                    {this.props.sensor_alarm_max}
                  </Text>
                </View>
              </View>
              <LinearGradient
                start={{x: 0.0, y: 0.0}}
                end={{x: 1, y: 1.0}}
                colors={[
                  isColor(this.props.sensor_low_color),
                  isColor(this.props.sensor_high_color),
                ]}
                style={{
                  height: 2,
                  width:  Dimensions.get('window').width / (getColumnNumber() * 2) - 25,
                }}
              />

              <View style={{flex: 1}}>
                <Text style={{color: '#9C9C9C', fontSize: 14, marginBottom: 3}}>
                  {this.props.sensor_unit}
                </Text>
              </View>
            </View>
            <View
              style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
              <AppBarChart
                data={{
                  datasets: [
                    {
                      data: this.state.dataGraph,
                    },
                  ],
                }}
                width={Dimensions.get('window').width / (getColumnNumber() * 2) - 25} // from react-native
                height={80}
                yAxisLabel={'$'}
                withHorizontalLabels={false}
                chartConfig={{
                  backgroundColor: '#121212',
                  backgroundGradientFrom: '#121212',
                  backgroundGradientTo: '#121212',
                  decimalPlaces: 2,
                  color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                  colorBar: (val = 1) => {
                    if (val <= parseFloat(this.props.sensor_alarm_min)) {
                      return [isColor(this.props.sensor_low_color)];
                    }
                    if (val >= parseFloat(this.props.sensor_alarm_max)) {
                      return [isColor(this.props.sensor_high_color)];
                    }

                    return [
                      isColor(this.props.sensor_low_color),
                      isColor(this.props.sensor_high_color),
                    ];
                  },
                  style: {
                    borderRadius: 2,
                  },
                  barPercentage: 0.3,
                  barRadius: 5,
                }}
                style={{
                  marginVertical: 0,
                  borderRadius: 0,
                }}
              />
            </View>
          </View>
        </View>
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  topViewtitle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerFixed: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titrewidget: {
    textAlign: 'center',
    fontSize: 40,
    color: '#1E1E1E',
    fontFamily: fontFamilyArialBlack(),
    textTransform: 'uppercase',
    marginTop: 10,
  },
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,
    height: 145,
    paddingRight: 5,
  },
  containerLinearGradient: {
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
    padding: 1,
  },
  CircleShapeView: {
    width: 10,
    height: 10,
    borderRadius: 10 / 2,
    borderWidth: 1,
    borderColor: '#BD10E0',
    backgroundColor: '#BD10E0',
  },
});

GraphSensor.propTypes = {
  sensor_alarm_max: PropTypes.string,
  sensor_alarm_min: PropTypes.string,
  sensor_calibration_date: PropTypes.string,
  sensor_calibration_point: PropTypes.string,
  sensor_date: PropTypes.string,
  sensor_high_color: PropTypes.string,
  sensor_last_value: PropTypes.string,
  sensor_low_color: PropTypes.string,
  sensor_max: PropTypes.number,
  sensor_min: PropTypes.number,
  sensor_name: PropTypes.string,
  sensor_number: PropTypes.string,
  sensor_offset: PropTypes.string,
  sensor_security: PropTypes.string,
  sensor_status: PropTypes.string,
  sensor_type: PropTypes.string,
  sensor_unit: PropTypes.string,
  sensor_values: PropTypes.string,
  sensor_widget: PropTypes.string,
};
GraphSensor.defaultProps = {
  sensor_alarm_max: '',
  sensor_alarm_min: '',
  sensor_calibration_date: '',
  sensor_calibration_point: '',
  sensor_date: '',
  sensor_high_color: '#BD10E0',
  sensor_last_value: '0',
  sensor_low_color: '#BD10E0',
  sensor_max: '0',
  sensor_min: '0',
  sensor_name: 'Sonned',
  sensor_number: '',
  sensor_offset: '',
  sensor_security: '',
  sensor_status: '',
  sensor_type: '',
  sensor_unit: 'L/h',
  sensor_values: '["1","0","3","2","5","1","4","6"]',
  sensor_widget: '',
};
const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GraphSensor);
