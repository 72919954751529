import React, {useState, useEffect} from 'react';
import * as RNLocalize from 'react-native-localize';
const Html5Entities = require('html-entities').Html5Entities;
import AsyncStorage from '@react-native-async-storage/async-storage';
import md5 from 'md5';
import {Platform} from 'react-native';

import {Text} from 'react-native';

import DeviceInfo from 'react-native-device-info';

const entities = new Html5Entities();
const local = RNLocalize.getLocales()[0].languageCode;
let localTranslation = {};

switch (local) {
  case 'fr':
    localTranslation = require('./fr.json');
    break;
  case 'it':
    localTranslation = require('./it.json');
    break;
  case 'en':
    localTranslation = require('./en.json');
    break;
}

let API_KEY = 'AIzaSyAw3rUj3BnoAPPBifAf5dJ27-ZpLj3A818';
let headers = {
  'X-Ios-Bundle-Identifier': DeviceInfo.getBundleId(),
};

if (Platform.OS === 'ios') {
  headers = {
    'X-Ios-Bundle-Identifier': DeviceInfo.getBundleId(),
  };
  API_KEY = 'AIzaSyAw3rUj3BnoAPPBifAf5dJ27-ZpLj3A818';
}

const translateText = async (value, fromLang, toLang = 'en') => {
  if (!value) {
    return value;
  }

  if (localTranslation[value]) {
    return localTranslation[value];
  }

  const storedTranslation = await AsyncStorage.getItem(
    `translation-cache-JIT-${md5(value)}-${toLang}`,
  );

  if (storedTranslation) {
    return storedTranslation;
  }

  return value;

  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}&q=${encodeURI(
    value,
  )}&source=${fromLang}&target=${toLang || 'en'}`;

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headers,
    },
  })
    .then(res => res.json())
    .then(async response => {
      console.log('response from google: ', response);
      console.log(
        'response from google again: ',
        response.data.translations[0].translatedText,
      );

      const respTranslation = entities.decode(
        response.data.translations[0].translatedText,
      );

      await AsyncStorage.setItem(
        `translation-cache-JIT-${md5(value)}-${toLang}`,
        respTranslation,
      );

      return respTranslation;
    })
    .catch(error => {
      console.log('There was an error with the translation request: ', error);

      return value;
    });
};

// Load en.json tranlation
const en = require('./en.json');

const translationsCache = en;

const googleTranslate = async () => {
  if (local !== 'en' && local !== 'fr' && local !== 'it') {
    const hash = md5(JSON.stringify(en));

    const storedTranslations = await AsyncStorage.getItem(
      `translation-cache-${local}-${hash}`,
    );

    if (storedTranslations) {
      return JSON.parse(storedTranslations);
    }

    try {
      const k = Object.keys(en);

      for (let i = 0; i < k.length; i++) {
        const translation = await translateText(en[k[i]], 'en', local, false);
        if (!translation) {
          translationsCache[k[i]] = en[k[i]];
        } else {
          translationsCache[k[i]] = entities.decode(translation);
        }
      }

      await AsyncStorage.setItem(
        `translation-cache-${local}-${hash}`,
        JSON.stringify(translationsCache),
      );
    } catch (err) {
      console.log(err);
    }
  }

  return translationsCache;
};

const googleTranslatePromise = googleTranslate();

const TranslateComponent = ({trKey, style}) => {
  const [translation, setTranslation] = useState(translationsCache[trKey]);

  useEffect(() => {
    googleTranslatePromise.then(t => {
      const runtimeTranslate = async tx => {
        const tr = await translateText(tx, 'en', local);
        translationsCache[trKey] = tr;
        setTranslation(tr);
      };

      if (localTranslation[trKey]) {
        setTranslation(localTranslation[trKey]);
        return;
      }

      if (local === 'en') {
        setTranslation(t[trKey] || trKey);
        return;
      }

      if (t[trKey]) {
        setTranslation(t[trKey]);
        return;
      }

      // Translate JIT
      runtimeTranslate(en[trKey] || trKey);
    });
  }, [trKey]);

  return <Text style={style}>{translation}</Text>;
};

const translate = (key, style = {}) => {
  return <TranslateComponent trKey={key} style={style} />;
};

export const translateString = async key => {
  if (localTranslation[key]) {
    return localTranslation[key];
  }

  const translations = await googleTranslatePromise;

  if (local === 'en') {
    return translations[key] || key;
  }

  if (!translations[key]) {
    // Translate JIT
    const t = await translateText(key, 'en', local);
    translationsCache[key] = t;

    return t;
  }

  return translations[key];
};

export default translate;
