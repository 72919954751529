import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Button,
  Dimensions,
  TouchableOpacity,
} from 'react-native';
import {Header, Icon, Image} from 'react-native-elements';
import {connect} from 'react-redux';
import LinearGradient from 'react-native-linear-gradient';
import AppBarChart from '../../ChartJs/AppBarChart';
import PropTypes from 'prop-types';
import {
  fontFamilyArialBlack,
  fontFamilyArialBold,
  fontFamilyBold,
} from '../../Config/fonts';
import {isColor, APP_BAR_ICON_SIZE, LEFT_HIT_SLOP} from '../../Config/Config';
import {getAlarmColor} from '../../Utils/colors';
import GaugeChart from '../../components/GaugeChart';
import BottomExtraValues from './BottomExtraValues';
import translate, {translateString} from '../../translations/translate';

class GaugeSensor extends React.Component {
  constructor(props) {
    super(props);

    let dataGraph = [];
    try {
      dataGraph = JSON.parse(this.props.sensor_values);
    } catch (e) {
      console.log(e, this.props.sensor_values);
    }

    this.state = {
      minText: '',
      maxText: '',
      dataGraph,
      statusLabel: '7,2',
    };
  }

  onWidthChange = width => {
    this.setState({
      width: Dimensions.get('window').width,
    });
  };

  async componentDidMount() {
    Dimensions.addEventListener('change', this.onWidthChange);
    this.setState({
      minText: await translateString(this.props.sensor_absolute_min_text),
      maxText: await translateString(this.props.sensor_absolute_max_text),
    });
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change', this.onWidthChange);
  }

  render() {
    const gaugeSize = Dimensions.get('window').width > 350 ? 170 : 150;

    const currentValue = parseFloat(this.props.sensor_last_value);

    const isAlert =
      currentValue < parseFloat(this.props.sensor_absolute_min) ||
      currentValue > parseFloat(this.props.sensor_absolute_max);

    let bgtitlecolor = '#3A57FB';
    return (
      <LinearGradient
        colors={['#121212', '#3A57FB']}
        style={styles.containerLinearGradient}>
        <View style={styles.container}>
          <View style={{flexDirection: 'row'}}>
            <View
              style={{
                flex: 1,
                marginTop: 10,
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 5,
              }}>
              <GaugeChart
                dialWidth={0.9}
                size={gaugeSize}
                progressWidth={2}
                minimumValue={parseFloat(this.props.sensor_absolute_min)}
                maximumValue={parseFloat(this.props.sensor_absolute_max)}
                currentValue={
                  currentValue % 1 === 0
                    ? currentValue
                    : currentValue.toFixed(1)
                }
                unit={this.props.sensor_unit}
                tickInterval={8}
                minText={this.state.minText}
                maxText={this.state.maxText}
                progressRanges={[
                  {
                    start: parseFloat(this.props.sensor_absolute_min),
                    color: this.props.sensor_low_color,
                  },
                  {
                    start: parseFloat(this.props.sensor_alarm_min),
                    color: '#09d9bc',
                  },
                  {
                    start: parseFloat(this.props.sensor_alarm_max),
                    color: this.props.sensor_high_color,
                  },
                ]}
              />
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
              }}>
              <View style={styles.containerFixed}>
                <View
                  style={{
                    width: 50,
                    backgroundColor: bgtitlecolor,
                  }}
                />
                <Text style={styles.titrewidget}>{this.props.sensor_hash}</Text>
              </View>
              <View style={styles.topViewtitle}>
                <Text
                  style={{
                    color: bgtitlecolor,
                    borderTopColor: getAlarmColor(
                      this.props.sensor_high_color,
                      this.props.sensor_low_color,
                      this.props.sensor_alarm_min,
                      this.props.sensor_alarm_max,
                      this.props.sensor_last_value,
                    ),
                    fontFamily: fontFamilyArialBold(),
                    borderTopWidth: 3,
                    fontSize: 16,
                    paddingTop: 10,
                  }}>
                  {this.props.sensor_name}
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <View
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    marginTop: 10,
                    marginBottom: 30,
                  }}>
                  {translate('State', {color: bgtitlecolor})}
                  <Text style={{marginTop: 10, color: bgtitlecolor}}>:</Text>
                  <Text style={{...styles.percentText, color: '#14e6c6'}}>
                    {this.props.sensor_status}
                  </Text>
                </View>

                <TouchableOpacity
                  onPress={() => this.props.navigation.navigate('Alertes')}
                  hitSlop={LEFT_HIT_SLOP}>
                  <Icon
                    name="exclamation-triangle"
                    type="font-awesome"
                    reverseColor={'#121212'}
                    color={isAlert ? 'red' : 'black'}
                    size={30}
                  />
                </TouchableOpacity>
                {translate(this.props.sensor_text, {
                  ...styles.secondText,
                  marginTop: 30,
                  color: isAlert ? 'red' : 'white',
                })}
              </View>
            </View>
          </View>
          <BottomExtraValues
            name2={this.props.sensor_name_2}
            value2={this.props.sensor_live_2}
            unit2={this.props.sensor_unit_2}
            name3={this.props.sensor_name_3}
            value3={this.props.sensor_live_3}
            unit3={this.props.sensor_unit_3}
          />
        </View>
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  topViewtitle: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  containerFixed: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titrewidget: {
    textAlign: 'center',
    fontSize: 40,
    color: '#1E1E1E',
    fontFamily: fontFamilyArialBlack(),
    textTransform: 'uppercase',
    marginTop: 10,
  },
  percentText: {
    textAlign: 'center',
    fontSize: 15,
    color: 'white',
    fontFamily: fontFamilyArialBlack(),
    textTransform: 'uppercase',
    marginTop: 10,
  },
  secondText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#1E1E1E',
    fontFamily: fontFamilyArialBlack(),
    textTransform: 'uppercase',
    marginTop: 15,
  },
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,
    paddingRight: 5,
    paddingBottom: 5,
  },
  containerLinearGradient: {
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
    padding: 1,
  },
  CircleShapeView: {
    width: 10,
    height: 10,
    borderRadius: 10 / 2,
    borderWidth: 1,
    borderColor: '#BD10E0',
    backgroundColor: '#BD10E0',
  },
});

GaugeSensor.propTypes = {
  sensor_alarm_max: PropTypes.string,
  sensor_alarm_min: PropTypes.string,
  sensor_calibration_date: PropTypes.string,
  sensor_calibration_point: PropTypes.string,
  sensor_date: PropTypes.string,
  sensor_high_color: PropTypes.string,
  sensor_last_value: PropTypes.string,
  sensor_low_color: PropTypes.string,
  sensor_max: PropTypes.number,
  sensor_min: PropTypes.number,
  sensor_name: PropTypes.string,
  sensor_number: PropTypes.string,
  sensor_offset: PropTypes.string,
  sensor_security: PropTypes.string,
  sensor_status: PropTypes.string,
  sensor_type: PropTypes.string,
  sensor_unit: PropTypes.string,
  sensor_values: PropTypes.string,
  sensor_widget: PropTypes.string,
  sensor_unit_3: PropTypes.string,
  sensor_live_3: PropTypes.string,
  sensor_name_3: PropTypes.string,
  sensor_unit_2: PropTypes.string,
  sensor_live_2: PropTypes.string,
  sensor_name_2: PropTypes.string,
};
GaugeSensor.defaultProps = {
  sensor_alarm_max: '',
  sensor_alarm_min: '',
  sensor_calibration_date: '',
  sensor_calibration_point: '',
  sensor_date: '',
  sensor_high_color: '#BD10E0',
  sensor_last_value: '0',
  sensor_low_color: '#BD10E0',
  sensor_max: '0',
  sensor_min: '0',
  sensor_name: 'Sonned',
  sensor_number: '',
  sensor_offset: '',
  sensor_security: '',
  sensor_status: '',
  sensor_type: '',
  sensor_unit: 'L/h',
  sensor_values: '["1","0","3","2","5","1","4","6"]',
  sensor_widget: '',
};
const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GaugeSensor);
