import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import {Header, Icon, Button} from 'react-native-elements';
import moment from 'moment';

import translate, {translateString} from '../translations/translate';
import {connect} from 'react-redux';
import {
  appHeaderHeight,
  msg_code_to_text,
  ShowSnaskMessage,
  APP_BAR_ICON_SIZE,
} from '../Config/Config';
import {FlatList, RectButton} from 'react-native-gesture-handler';
import * as RNLocalize from 'react-native-localize';
import {Animated, I18nManager} from 'react-native';

import Swipeable from 'react-native-gesture-handler/Swipeable';
import * as actions from '../Store/Systems/SystemsActions';
import * as actionsApp from '../Store/App/AppActions';
import ThreeDotsLoader from '../components/ThreeDotsLoader';
const window = Dimensions.get('window');
import HeaderLeftComponent from '../components/HeaderLeftComponent';

const local = RNLocalize.getLocales()[0].languageCode;

class Alertes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWait: false,
      system: null,
      statusFilter: '0',
      alerts: [],
      archiveTr: '',
    };
  }

  componentDidMount() {
    this.initScreens();
  }

  initScreens = () => {
    this.getSystem();
  };

  getSystem = () => {
    if (
      this.props.current_system.hasOwnProperty('system') &&
      this.props.current_system.system.length > 0
    ) {
      console.log(
        'this.props.current_system ',
        this.props.current_system.alarm,
      );

      this.setState({
        system: this.props.current_system.system[0],
        alarmm: this.props.current_system.alarm,
      });
    }
  };

  ListEmpty = () => {
    return (
      <View style={styles.MainContainer}>
        <Text style={{textAlign: 'center', color: '#fff'}}>
          {translate('Aucune alerte disponible')}
        </Text>
      </View>
    );
  };

  renderFlatList = () => {
    return (
      <FlatList
        data={this.state.alarmm.filter(
          i => i.alarm_status === this.state.statusFilter,
        )}
        style={{marginLeft: 10, marginRight: 10}}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        renderItem={({item, index}) => (
          <SwipeableRow
            item={item}
            index={index}
            handler={this.handler}
            isWait={this.state.isWait}
          />
        )}
        keyExtractor={(item, index) => `alert-${index}`}
        ListEmptyComponent={this.ListEmpty}
      />
    );
  };

  renderList = () => {
    if (this.state.alarmm && this.state.alarmm.length * 200 > window.height) {
      return this.renderFlatList();
    } else if (
      this.state.alarmm &&
      this.state.alarmm.length > 0 &&
      this.state.alarmm.length * 200 < window.height
    ) {
      return this.state.alarmm
        .filter(i => i.alarm_status === this.state.statusFilter)
        .map((item, index) => {
          return (
            <SwipeableRow
              item={item}
              index={index}
              handler={this.handler}
              isWait={this.state.isWait}
            />
          );
        });
    } else {
      return this.ListEmpty();
    }
  };

  toggleStatus = () => {
    this.setState({
      statusFilter: this.state.statusFilter === '0' ? '1' : '0',
    });
  };

  render() {
    return (
      <SafeAreaView style={styles.containerfull}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          leftComponent={
            <HeaderLeftComponent
              navigation={this.props.navigation}
              page="AcceuilTabs"
            />
          }
        />
        <ScrollView contentContainerStyle={{flexGrow: 1}}>
          <View style={styles.container}>
            <View style={{flexDirection: 'row', paddingBottom: 0}}>
              <View
                style={{
                  flex: 1,
                  borderColor: '#fff',
                  borderTopWidth: 4,
                  marginTop: 10,
                }}
              />
              <Text style={styles.enteteText}>{translate('Alertes')} </Text>
              <View
                style={{
                  flex: 5,
                  borderBottomColor: '#fff',
                  borderBottomWidth: 4,
                  marginBottom: 10,
                }}
              />
            </View>
            {this.renderList()}
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 30,
                marginLeft: 10,
                marginRight: 10,
              }}>
              <Button
                buttonStyle={styles.BtnButton}
                title={translate(
                  this.state.statusFilter === '0'
                    ? 'see_resolved_alarms'
                    : 'see_not_resolved_alarms',
                { color: "white" })}
                onPress={this.toggleStatus}
              />
              <Text style={{flex: 1}} />
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }

  handler = (item, text) => {
    console.log('Text ', text);

    this.UpdateByField('alarm_status', '1', item.alarm_id);

    this.props.getSystem(this.props.current_system.system[0].sys_appid);

    this.setState(state => {
      const index = state.alarmm.findIndex(a => a.alarm_id === item.alarm_id);

      if (index !== -1) {
        state.alarmm[index] = {
          ...state.alarmm[index],
          alarm_status: '1',
        };
      }

      this.props.refreshApp();

      return state;
    });
  };

  UpdateByField = (_field, _val, _number) => {
    this.setState({isWait: true});
    // console.log(this.props.sys_appid)

    this.props
      .UpdateDevice(this.state.system.sys_appid, {
        key: _field,
        value: _val,
        number: _number,
      })
      .then(data => {
        this.setState({isWait: false});

        ShowSnaskMessage({
          message: 'operation success',
          textColor: '#fff',
          backgroundColor: '#7ED321',
          buttonColor: '#7ED321',
          duration: 2000,
        });
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);

        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };
}

const SwipeableRow = ({item, index, handler, isWait}) => {
  return (
    <AppleStyleSwipeableRow handler={handler} item={item}>
      <Row item={item} isWait={isWait} />
    </AppleStyleSwipeableRow>
  );
};

const Row = ({item, isWait}) => (
  <RectButton style={styles.rectButton}>
    <View style={[styles.itemContainer]}>
      <View style={{flexDirection: 'row', paddingBottom: 0, marginBottom: 10}}>
        <Text
          style={{
            flex: 0.5,
            fontSize: 14,
            color: 'red',
            fontFamily: 'arial-black',
          }}>
          .
        </Text>
        <Text
          style={{
            flex: 10,
            fontSize: 14,
            color: '#fff',
            fontFamily: 'arial-black',
          }}>
          {translate(item.alarm_name || item.alarm_title)}
        </Text>
        <Text
          style={{flex: 10, fontSize: 12, color: '#fff', fontFamily: 'arial'}}>
          #{item.alarm_code}{' '}
          {moment(item.alarm_date)
            .locale(local)
            .format('L')}{' '}
          {moment(item.alarm_date)
            .locale(local)
            .format('LTS')}
        </Text>
      </View>
      <Text style={{fontSize: 10, color: '#fff', fontFamily: 'arial'}}>
        {translate(item.alarm_message)}{' '}
      </Text>
      <Text style={{fontSize: 10, color: '#fff', fontFamily: 'arial', fontWeight: 'bold'}}>
        {translate(item.alarm_solution)}
      </Text>
      <View>{isWait ? <ThreeDotsLoader /> : <View />}</View>
    </View>
  </RectButton>
);

class AppleStyleSwipeableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archiveTr: 'Archiver',
    };
  }
  async componentDidMount() {
    const archiveTr = await translateString('archiver');
    this.setState({
      archiveTr,
    });
  }
  renderLeftActions = (progress, dragX) => {
    const trans = dragX.interpolate({
      inputRange: [0, 50, 100, 101],
      outputRange: [-20, 0, 0, 1],
    });
    const pressHandler = () => {
      this.close();
      this.props.handler(this.props.item, 'archive');
    };
    return (
      <RectButton style={styles.leftAction} onPress={pressHandler}>
        <Animated.Text
          style={[
            styles.actionText,
            {
              transform: [{translateX: trans}],
            },
          ]}>
          {this.state.archiveTr}
        </Animated.Text>
      </RectButton>
    );
  };
  renderRightAction = (text, color, x, progress) => {
    const trans = progress.interpolate({
      inputRange: [0, 1],
      outputRange: [x, 0],
    });
    const pressHandler = () => {
      this.close();
      this.props.handler(this.props.item, text);
    };
    return (
      <Animated.View
        style={{marginBottom: 25, flex: 1, transform: [{translateX: trans}]}}>
        <RectButton
          style={[styles.rightAction, {backgroundColor: color}]}
          onPress={pressHandler}>
          <Animated.Text
            style={[
              styles.actionText,
              {
                transform: [{translateX: trans}],
              },
            ]}>
            {text}
          </Animated.Text>
        </RectButton>
      </Animated.View>
    );
  };
  renderRightActions = text => progress => (
    <View
      style={{
        width: 192,
        flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
      }}>
      {this.renderRightAction(text, '#6BF96D', 200, progress)}
    </View>
  );
  updateRef = ref => {
    this._swipeableRow = ref;
  };
  close = () => {
    this._swipeableRow.close();
  };
  render() {
    const {children} = this.props;
    return (
      <Swipeable
        ref={this.updateRef}
        friction={2}
        leftThreshold={30}
        rightThreshold={40}
        renderLeftActionqs={this.renderLeftActions}
        renderRightActions={this.renderRightActions(this.state.archiveTr)}>
        {children}
      </Swipeable>
    );
  }
}
const styles = StyleSheet.create({
  MainContainer: {
    margin: 10,
  },
  rectButton: {
    marginBottom: 25,
  },
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
  },
  container: {
    flex: 1,
    backgroundColor: '#000004',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  enteteText: {
    fontSize: 18,
    fontFamily: 'arial-black',
    fontWeight: '200',
    paddingBottom: 10,
    paddingTop: 10,
    textAlign: 'left',
    color: '#ffffff',
  },
  itemContainer: {
    borderColor: '#FF0000',
    borderWidth: 1.5,
    borderRadius: 10,
    justifyContent: 'flex-end',
    padding: 10,
    paddingTop: 20,
    paddingBottom: 20,
    // marginBottom: 25
  },
  BtnButton: {
    // backgroundColor: 'transparent',
    borderWidth: 1,
    fontFamily: 'arial',
    fontSize: 12,
    borderColor: '#ffffff',
    borderRadius: 50,
    paddingTop: 5,
    paddingLeft: 15,
    paddingRight: 15,
    flex: 1,
  },
  leftAction: {
    flex: 1,
    // backgroundColor: '#497AFC',
    justifyContent: 'center',
  },
  actionText: {
    color: 'white',
    fontSize: 16,
    // backgroundColor: 'transparent',
    padding: 10,
  },
  rightAction: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    borderRadius: 10,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.LoginReducer.user,
    current_system: state.SystemsRedux.system,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
    getSystem: appid => {
      return dispatch(actions.SystemDetail(appid));
    },
    refreshApp: newFav => {
      return dispatch(actionsApp.refreshApp());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Alertes);
