import React, {useEffect} from 'react';
import {SafeAreaView, View, Text, StyleSheet} from 'react-native';

import {Header, Icon} from 'react-native-elements';

import {fontFamilyBold} from '../../Config/fonts';
import {appHeaderHeight} from '../../Config/Config';
import HeaderLeftComponent from '../../components/HeaderLeftComponent';
import translate from '../../translations/translate';

const Step3 = ({navigation}) => {
  useEffect(() => {
    const goToLogin = () => {
      navigation.navigate('Login');
    };
    setTimeout(() => {
      goToLogin();
    }, 5000);
  }, [navigation]);

  return (
    <SafeAreaView style={styles.containerfull}>
      <Header
        containerStyle={{
          backgroundColor: '#289EFF',
          height: appHeaderHeight(),
          paddingTop: 0,
          margin: 0,
          borderBottomColor: '#289EFF',
        }}
        leftComponent={
          <HeaderLeftComponent
            navigation={navigation}
            goToBack={navigation.goBack}
          />
        }
      />
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 100,
          height: '50%',
          padding: 10,
        }}>
        <Icon name="check" type="font-awesome" color={'green'} size={50} />
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 50,
          }}>
          <Text
            style={{
              color: 'white',
              fontFamily: fontFamilyBold(),
              fontSize: 30,
            }}>
            {translate('Reboot of your controller')}
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 50,
            marginTop: 20,
            marginBottom: 20,
          }}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
    alignItems: 'center',
    color: 'white',
  },
  BtnButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    fontFamily: 'arial',
    fontSize: 12,
    borderColor: '#ffffff',
    borderRadius: 50,
    paddingTop: 5,
    paddingLeft: 15,
    paddingRight: 15,
    flex: 1,
    marginRight: 20,
  },
});

export default Step3;
