import React from 'react';
import {SafeAreaView, ScrollView, View, Text, StyleSheet} from 'react-native';

import {Header, Input, Button, Icon} from 'react-native-elements';

import {fontFamilyBold, fontFamilyRegular} from '../Config/fonts';
import {
  appHeaderHeight,
  appHeaderTop,
  setStoreData,
  getStoreData,
} from '../Config/Config';
import HeaderLeftComponent from '../components/HeaderLeftComponent';
import translate from '../translations/translate';

const NeedLogin = ({navigation}) => {
  const goToLogin = () => {
    navigation.navigate('Login');
  };

  return (
    <View style={styles.containerfull}>
      <Header
        containerStyle={{
          backgroundColor: '#289EFF',
          height: appHeaderHeight(),
          paddingTop: 0,
          margin: 0,
          marginTop: appHeaderTop(),
          borderBottomColor: '#289EFF',
        }}
        leftComponent={
          <HeaderLeftComponent
            navigation={navigation}
            goToBack={navigation.goBack}
          />
        }
      />
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 100,
          height: '50%',
          padding: 10,
        }}>
        <Icon name="check" type="font-awesome" color={'green'} size={50} />
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text
            style={{
              color: 'white',
              fontFamily: fontFamilyBold(),
              fontSize: 30,
            }}>
            {translate('Oops')}!
          </Text>
          <Text>
            {translate('You are not connected to register your scan', {
              color: 'white',
              fontFamily: fontFamilyBold(),
              fontSize: 20,
            })}
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 50,
            marginTop: 20,
            marginBottom: 20,
          }}>
          <Button
            buttonStyle={styles.BtnButton}
            title={translate('Login')}
            onPress={goToLogin}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
    alignItems: 'center',
    color: 'white',
  },
  BtnButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    fontFamily: 'arial',
    fontSize: 12,
    borderColor: '#ffffff',
    borderRadius: 50,
    paddingTop: 5,
    paddingLeft: 15,
    paddingRight: 15,
    flex: 1,
    marginRight: 20,
  },
});

export default NeedLogin;
