import React from 'react';
import {
  View,
  ScrollView,
  ImageBackground,
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
} from 'react-native';
import {connect} from 'react-redux';
import {
  Button,
  CheckBox,
  Header,
  Icon,
  Input,
  Text,
} from 'react-native-elements';
import translate from '../../translations/translate';
import * as actions from '../../Store/Login/LoginActions';
import {
  appHeaderHeight,
  appHeaderTop,
  msg_code_to_text,
  ShowSnaskMessage,
} from '../../Config/Config';
import {fontFamilyBold, fontFamilyRegular} from '../../Config/fonts';
import HeaderLeftComponent from '../../components/HeaderLeftComponent';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iswait: false,
      formData: {
        user: null,
        cgu: false,
      },
      errors: {
        user: null,
        cgu: null,
      },
    };
  }

  ValidateFormData = () => {
    let isError = false;
    let _formData = this.state.formData;
    let _errors = this.state.errors;
    if (!_formData.cgu) {
      isError = true;
      _errors.cgu = translate('Ce champ est obligatoire');
    }
    if (!_formData.user) {
      isError = true;
      _errors.user = translate('Ce champ est obligatoire');
    }
    this.setState({errors: _errors});
    return isError;
  };
  onChangeUserText = (fieldName, val) => {
    let _formData = this.state.formData;
    let _errors = this.state.errors;

    if (val) {
      _errors[fieldName] = '';
    } else {
      _errors[fieldName] = 'Error';
    }
    _formData[fieldName] = val;

    this.setState({formData: _formData, errors: _errors});
  };
  SubmitBtn = () => {
    if (!this.ValidateFormData()) {
      this.setState({iswait: true});
      this.props
        .ResetPassword(this.state.formData.user)
        .then(response => {
          this.setState({iswait: false});
          this.props.navigation.navigate('NewPassword', {appidfrommail: null});
        })
        .catch(error => {
          this.setState({iswait: false});
          let _errors = this.state.errors;
          this.setState({iswait: false});
          let errormsg = msg_code_to_text(error);
          ShowSnaskMessage({
            message: errormsg,
            textColor: '#fff',
            backgroundColor: 'red',
            buttonColor: 'red',
            duration: 2000,
          });
          switch (error) {
            case '_USER_TYPE_ANONYMOUS_INVALID_OPERATION':
              _errors.user = translate(errormsg);
              break;
          }
          this.setState({errors: _errors});
        });
    }
    //this.props.navigation.navigate('NewPassword');
  };

  render() {
    return (
      <ImageBackground
        style={styles.imgBackground}
        resizeMode="cover"
        source={require('../../assets/bg.png')}>
        <Header
          containerStyle={{
            backgroundColor: 'transparent',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            marginTop: appHeaderTop(),
            borderBottomColor: '#289EFF',
          }}
          leftComponent={
            <HeaderLeftComponent navigation={this.props.navigation} page="Login" />
          }
        />
        <ScrollView contentContainerStyle={{flexGrow: 1}}>
          <View style={styles.container}>
            <KeyboardAvoidingView style={styles.containerView}>
              <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                <View style={styles.ResetFormView}>
                  <Text style={styles.logoText}>
                    {translate('Mot de passe oublie')}{' '}
                  </Text>
                  <Input
                    label={translate('Email')}
                    onChangeText={val => this.onChangeUserText('user', val)}
                    labelStyle={styles.FormLabelInput}
                    containerStyle={{marginBottom: 20}}
                    inputContainerStyle={{
                      borderBottomWidth: 0,
                      marginBottom: 5,
                    }}
                    inputStyle={[
                      styles.FormTextInput,
                      this.state.errors.user ? styles.FormTextInputError : {},
                    ]}
                    placeholder=""
                    errorStyle={{color: 'red'}}
                    errorMessage=""
                  />
                  <CheckBox
                    onPress={() =>
                      this.onChangeUserText('cgu', !this.state.formData.cgu)
                    }
                    checked={this.state.formData.cgu}
                    title={translate(
                      'Un email va vous parvenir',
                    )}
                    containerStyle={{
                      backgroundColor: 'transparent',
                      borderWidth: 0,
                      margin: 0,
                    }}
                    textStyle={{
                      color: '#ffffff',
                      fontSize: 10,
                      fontFamily: fontFamilyRegular(),
                      fontWeight: 'normal',
                    }}
                    errorMessage=""
                  />
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'flex-end',
                      marginTop: 50,
                    }}>
                    <View style={{alignSelf: 'center'}}>
                      <Button
                        onPress={this.SubmitBtn}
                        buttonStyle={styles.SubmitButton}
                        title={translate('envoyer la demande')}
                        loading={this.state.iswait}
                      />
                    </View>
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </KeyboardAvoidingView>
          </View>
        </ScrollView>
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  imgBackground: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
  },
  FormLabelInput: {
    fontSize: 12,
    color: 'white',
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    width: '90%',
  },
  ResetFormView: {
    marginLeft: 10,
    marginRight: 10,
  },
  logoText: {
    marginBottom: 50,
    textAlign: 'center',
    color: '#ffffff',
    fontSize: 18,

    fontFamily: fontFamilyBold(),
  },
  FormTextInput: {
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
    height: 43,
    fontSize: 14,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#eaeaea',
    color: '#fff',
    paddingLeft: 10,
    marginTop: 5,
  },
  FormTextInputError: {
    borderColor: '#D80000',
    backgroundColor: 'rgba(255, 0, 0, 0.22)',
  },
  SubmitButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#ffffff',
    borderRadius: 50,
    paddingTop: 5,
    paddingBottom: 10,
    width: '100%',
    fontWeight: 'bold',
    fontFamily: fontFamilyRegular(),
  },
});

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    ResetPassword: user => {
      return dispatch(actions.ResetPassword(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPassword);
