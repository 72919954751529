import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {connect} from 'react-redux';
import {ButtonGroup} from 'react-native-elements';
import {fontFamilyBold} from '../../Config/fonts';
import PropTypes from 'prop-types';
import * as actions from '../../Store/Systems/SystemsActions';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  showSnaskMessageOperationSuccess,
} from '../../Config/Config';
import ThreeDotsLoader from '../ThreeDotsLoader';
import translate, {translateString} from '../../translations/translate';

class Mode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWait: false,
      btnsDisplay: [],
      SelectedBtn: 0,
      AvailableVal: [],
      device_number: '',
    };
  }
  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(nextProps) {
    const {device_mode_display} = this.props;
    if (nextProps.device_mode_display !== device_mode_display) {
      this.initData();
    }
  }

  initData = async () => {
    let _listBtn = this.props.device_mode_display.split('/');
    let _listBtnAva = this.props.device_mode_available.split('/');
    let defaultIndex = parseInt(this.props.device_index);

    for (let i = 0; i < _listBtn.length; i++) {
      // _listBtn[i] = await translateString(_listBtn[i]);
    }

    this.setState({
      btnsDisplay: _listBtn,
      SelectedBtn: defaultIndex,
      AvailableVal: _listBtnAva,
      device_number: this.props.device_number,
    });
  };

  Onchange = selectedIndex => {
    // this.setState({SelectedBtn:selectedIndex})
    //  console.log("Mode app ",selectedIndex)
    this.UpdateStatusDevices(selectedIndex);
  };

  UpdateStatusDevices = val => {
    if (this.state.isWait) {
      return true;
    }
    this.setState({isWait: true});
    //  console.log(this.props.sys_appid)
    this.props
      .UpdateDevice(this.props.sys_appid, {
        key: 'device_index',
        //value:this.state.AvailableVal[val],
        value: val,
        number: this.state.device_number,
      })
      .then(data => {
        this.setState({SelectedBtn: val});
        this.setState({isWait: false});
        this.props.refeshDevice();
        showSnaskMessageOperationSuccess();
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };
  render() {
    return (
      <View style={styles.container}>
        <Text
          style={{
            color: '#fff',
            fontSize: 18,
            fontFamily: fontFamilyBold(),
            marginBottom: 10,
          }}>
          {translate('mode')} :
        </Text>
        <View>{this.state.isWait ? <ThreeDotsLoader /> : <View />}</View>
        <ButtonGroup
          onPress={this.Onchange}
          buttonStyle={styles.BtnChoise}
          innerBorderStyle={{width: 0}}
          selectedButtonStyle={[styles.BtnChoise, styles.BtnChoiseSelected]}
          textStyle={[styles.BtnChoisetextStyle]}
          selectedTextStyle={[styles.BtnChoiseSelected]}
          containerStyle={{backgroundColor: 'transparent', borderWidth: 0}}
          selectedIndex={this.state.SelectedBtn}
          buttons={this.state.btnsDisplay}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#121212',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
  },
  BtnChoise: {
    borderWidth: 1,
    fontFamily: 'arial',
    fontSize: 12,
    borderColor: '#242424',
    backgroundColor: '#242424',
    borderRadius: 50,
    paddingTop: 10,
    paddingBottom: 10,

    marginRight: 10,
  },
  BtnChoiseSelected: {
    backgroundColor: '#289EFF',
    color: '#fff',
  },
  BtnChoisetextStyle: {
    fontFamily: 'arial',
    fontSize: 12,
    backgroundColor: 'transparent',
    color: '#fff',
  },
});

Mode.propTypes = {
  device_number: PropTypes.string,
  device_index: PropTypes.string,
  device_mode_available: PropTypes.string,
  device_mode_display: PropTypes.string,
  sys_appid: PropTypes.string,
};

Mode.defaultProps = {
  device_number: '',
  device_index: '',
  device_mode_available: '',
  device_mode_display: '',
  sys_appid: '',
};
const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Mode);
