import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Button,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity,
  RefreshControl,
} from 'react-native';
import {Header, Icon, Input} from 'react-native-elements';
import translate from '../translations/translate';
// import {FlatGrid} from 'react-native-super-grid';
import * as actions from '../Store/Systems/SystemsActions';
import Loader from '../components/Loader';
import {connect} from 'react-redux';
import * as actionsApp from '../Store/App/AppActions';
import {appHeaderHeight, appHeaderTop, setStoreData} from '../Config/Config';
import {fontFamilyBold, fontFamilyRegular} from '../Config/fonts';
import {setCurrentSystemAppId} from '../Models/Systems';
import * as actionsLogin from '../Store/Login/LoginActions';
import { getColumnNumber } from '../Config/Config';

class HeaderrightComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  logout = () => {
    try {
      this.props.Logout().then(() => {
        setTimeout(() => {
          this.props.navigation.replace('Login');
        }, 400);
      });
    } catch (err) {}
  };

  render() {
    return (
      <>
        <TouchableOpacity
          onPress={this.logout}
          hitSlop={{top: 10, left: 30, right: 30, bottom: 30}}>
          <Icon
            containerStyle={{paddingRight: 30}}
            name={'sign-out'}
            type="font-awesome"
            color="#fff"
          />
        </TouchableOpacity>
      </>
    );
  }
}

class Systemes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iswait: false,
      isRefresh: false,
      search: '',
    };
  }
  
  componentDidMount() {
    window.addEventListener("resize", this.resize);
    if (this.props.appId && this.props.systems.length === 0) {
      this.props.getAllSystems(this.props.appId);
    }
  }

  resize = () => {
    this.setState({
      innerWidth: window.innerWidth
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  OpenElement = item => {
    this.setState({iswait: true});
    const {navigate} = this.props.navigation;
    //navigate('AcceuilTabs', {  element: item});
    setStoreData('system_current_is_admin', 'false');
    setCurrentSystemAppId(item.sys_appid);

    this.props
      .getDetailSystem(item.sys_appid)
      .then(system => {
        // console.log(system);
        this.props.refreshApp();
        navigate('AcceuilTabs', {element: this.props.current_system});
        this.setState({iswait: false});
      })
      .catch(error => {
        this.setState({iswait: false});
      });
  };

  onSearch = val => {
    this.setState({search: val});
  };

  searchFilter = system => {
    const s = this.state.search.toUpperCase();

    return (
      (system.sys_ref && system.sys_ref.toUpperCase().indexOf(s) !== -1) ||
      (system.sys_address &&
        system.sys_address.toUpperCase().indexOf(s) !== -1) ||
      (system.sys_city && system.sys_city.toUpperCase().indexOf(s) !== -1) ||
      (system.sys_apikey &&
        system.sys_apikey.toUpperCase().indexOf(s) !== -1) ||
      (system.sys_zip && system.sys_zip.toUpperCase().indexOf(s) !== -1) ||
      (system.sys_version &&
        system.sys_version.toUpperCase().indexOf(s) !== -1) ||
      (system.user_pseudo && system.user_pseudo.toUpperCase().indexOf(s) !== -1)
    );
  };

  onRefresh = async () => {
    this.setState({isRefresh: true});
    await this.props.getAllSystems(this.props.appId);
    this.setState({isRefresh: false});
  };

  render() {
    const systemNumber = this.props.systems && this.props.systems.length;

    return (
      <>
        <Header
          containerStyle={{
            backgroundColor: '#000004',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            marginTop: appHeaderTop(),
            borderBottomColor: '#000004',
          }}
          rightComponent={
            <HeaderrightComponent
              navigation={this.props.navigation}
              Logout={this.props.Logout}
            />
          }
        />
        <Loader isloader={this.state.iswait} />
        <View style={styles.container}>
          <View style={styles.title}>
            <Text style={styles.enteteStepText}>
              {translate('Mes Systemes')}{' '}
            </Text>
            <TouchableOpacity
              onPress={() => this.props.navigation.navigate('NouveauBassin')}>
              <Icon
                raised
                name="plus"
                type="font-awesome"
                color="#000000"
                size={10}
                activeOpacity={1}
                disabledStyle={{backgroundColor: '#D8D8D8'}}
              />
            </TouchableOpacity>
          </View>
          {systemNumber > 0 && (
            <Input
              containerStyle={styles.FormInputcontainerStyle}
              inputContainerStyle={styles.FormInputContainerStyle}
              inputStyle={[styles.FormTextInput]}
              onChangeText={this.onSearch}
            />
          )}
          <ScrollView
            style={{padding: 0}}
            refreshControl={
              <RefreshControl
                refreshing={this.state.isRefresh || this.props.loading}
                onRefresh={this.onRefresh}
                tintColor="white"
              />
            }>
            {systemNumber === 0 && !this.state.iswait && (
              <Text
                style={{
                  ...styles.enteteStepText,
                  fontSize: 15,
                  fontWeight: '100',
                }}>
                {translate('No system yet')}
              </Text>
            )}
            <View style={{ height: "100%", width: '100%', display: 'grid',
            gridTemplateColumns: `repeat(${getColumnNumber()}, minmax(100px, 1fr))`,
            gridGap: '10px',
            gridAutoRows: 'minmax(100px, auto)'}} >
            {(this.state.search
                  ? this.props.systems.filter(this.searchFilter)
                  : this.props.systems).map((item, index) => (
                    <View style={[styles.itemContainer]}>
                      <View style={{flexDirection: 'row'}}>
                        <Text style={styles.itemEmail}>{item.user_pseudo}</Text>
                        <TouchableOpacity
                          hitSlop={{top: 10, left: 30, right: 30, bottom: 30}}
                          onPress={() => {
                            this.OpenElement(item);
                          }}>
                          <Icon
                            iconStyle={{flex: 0.5, marginTop: -1, zIndex: 100}}
                            name="circle"
                            type="font-awesome"
                            size={30}
                            underlayColor={'#10CC10'}
                            color={item.sys_status > 0 ? '#10CC10' : '#c2c2c2'}
                          />
                        </TouchableOpacity>
                      </View>

                      <Text style={[styles.itemName, styles.itemNameColorBleu]}>
                        {item.sys_ref} - {item.sys_address} - {item.sys_zip} -{' '}
                        {item.sys_city}
                      </Text>
                      <View style={{flexDirection: 'row'}}>
                        <Icon name="map-marker" type="font-awesome" color="#fff" />
                        <Text style={styles.itemTextIcon}>{item.sys_id}</Text>
                      </View>
                      </View>
                ))}
                </View>
          </ScrollView>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#000004',
  },
  enteteStepText: {
    fontSize: 18,
    fontFamily: 'arial-black',
    fontWeight: '200',
    paddingBottom: 10,
    paddingTop: 10,
    textAlign: 'center',
    color: '#ffffff',
  },
  itemContainer: {
    borderColor: '#fff',
    borderWidth: 0.5,
    borderRadius: 10,
    justifyContent: 'flex-end',
    padding: 10,
    paddingTop: 15,
    paddingBottom: 20,
    margin: 5,
    minHeight: 100,
  },
  itemName: {
    fontSize: 16,
    color: '#fff',
    fontWeight: '600',
  },
  itemEmail: {
    flex: 5,
    fontSize: 16,
    color: '#fff',
    fontWeight: '600',
  },
  itemNameColorBleu: {
    color: '#289EFF',
  },
  itemTextIcon: {
    fontSize: 12,
    color: '#fff',
    marginTop: 5,
    marginLeft: 10,
  },
  FormInputcontainerStyle: {
    borderBottomWidth: 0,
    marginBottom: 0,
    backgroundColor: '#000004',
  },
  FormInputContainerStyle: {
    borderBottomWidth: 0,
    marginBottom: 5,
    borderWidth: 1,
    borderColor: '#242424',
    backgroundColor: '#242424',
    borderRadius: 50,
  },
  FormTextInput: {
    height: 43,
    fontSize: 14,
    color: '#fff',
    paddingLeft: 10,
    marginTop: 0,
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});

const mapStateToProps = (state, ownProps) => {
  //console.log( state.SystemsRedux.systems);
  return {
    systems: state.SystemsRedux.systems,
    loading: state.SystemsRedux.getAllLoading,
    current_system: state.SystemsRedux.system,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    last_update_data_redux_system: state.SystemsRedux.last_update_data_redux,
    appId: state.LoginReducer.appId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllSystems: appId => {
      return dispatch(actions.SystemGetAll(appId));
    },
    getDetailSystem: appId => {
      return dispatch(actions.SystemDetail(appId));
    },
    refreshApp: () => {
      return dispatch(actionsApp.refreshApp());
    },
    Logout: () => {
      return dispatch(actionsLogin.UserLogout());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Systemes);
