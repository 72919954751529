import {toPairs} from 'lodash';
import React, {Component} from 'react';
import Svg, {G, Circle, Line, Polygon, Use, Defs, Text} from 'react-native-svg';

import Arc from './Arc';

const TICK_ID = 'tick';
const TICK_ID_LONG = 'tick-long';

export default class Gauge extends Component {
  renderDial = opts => {
    return (
      <Arc
        cx={opts.cX}
        cy={opts.cY}
        r={opts.radius - 20}
        fill="none"
        stroke={opts.dialColor}
        strokeWidth={opts.dialWidth}
      />
    );
  };

  defineTick = opts => {
    let tX1 =
      opts.cX +
      (opts.radius - 15) -
      Math.max(opts.dialWidth, opts.progressWidth) / 2;
    let tX2 = tX1 - opts.tickLength;
    return (
      <Line
        id={TICK_ID}
        x1={tX1}
        y1={opts.cY}
        x2={tX2}
        y2={opts.cY}
        stroke={opts.tickColor}
        strokeWidth={opts.tickWidth}
      />
    );
  };

  defineTickLong = opts => {
    let tX1 =
      opts.cX +
      (opts.radius - 10) -
      Math.max(opts.dialWidth, opts.progressWidth) / 2;
    let tX2 = tX1 - (opts.tickLength + 5);
    return (
      <Line
        id={TICK_ID_LONG}
        x1={tX1}
        y1={opts.cY}
        x2={tX2}
        y2={opts.cY}
        stroke={opts.tickColor}
        strokeWidth={opts.tickWidth}
      />
    );
  };

  renderTicks = opts => {
    let tickAngles = [];
    for (let i = 145; i <= 399; i += opts.tickInterval) {
      tickAngles.push(i);
    }
    return (
      <G className="ticks">
        {tickAngles.map((tickAngle, idx) => {
          if (idx !== 0 && idx % 5 === 0) {
            return (
              <Use
                href={`#${TICK_ID_LONG}`}
                key={`tick-${idx}`}
                transform={`rotate(${tickAngle} ${opts.cX} ${opts.cY})`}
              />
            );
          }

          return (
            <Use
              href={`#${TICK_ID}`}
              key={`tick-${idx}`}
              transform={`rotate(${tickAngle} ${opts.cX} ${opts.cY})`}
            />
          );
        })}
      </G>
    );
  };

  renderProgress = opts => {
    let offset =
      (opts.circumference + 20) * (1 - opts.currentValue / opts.maximumValue);

    return (
      <Arc
        cx={opts.cX}
        cy={opts.cY}
        r={opts.radius - 4}
        fill="none"
        stroke={opts.progressColor}
        strokeWidth={opts.progressWidth || opts.tickWidth}
        strokeDasharray={opts.circumference + 20}
        strokeDashoffset={-offset}
      />
    );
  };

  renderProgressRange = (opts, offset = 0, color) => {
    return (
      <Arc
        cx={opts.cX}
        cy={opts.cY}
        r={opts.radius - 8}
        fill="none"
        stroke={color || opts.progressColor}
        strokeWidth={opts.progressWidth || opts.tickWidth}
        strokeDasharray={opts.circumference + 12}
        strokeDashoffset={offset}
      />
    );
  };

  renderProgresses = opts => {
    return opts.progressRanges.map(pr => {
      let offset =
        (opts.circumference + 20) *
        (0.97 -
          (opts.maximumValue - pr.start) /
            (opts.maximumValue - opts.minimumValue));
      return this.renderProgressRange(opts, offset, pr.color);
    });
  };

  renderNeedle = opts => {
    if (!opts.needle) {
      return false;
    }
    let x1 = opts.cX + 10,
      y1 = opts.cY - opts.needleWidth / 2,
      x2 = opts.cX + 10,
      y2 = opts.cY + opts.needleWidth / 2,
      x3 = opts.diameter + 12,
      y3 = opts.cY,
      needleAngle =
        140 +
        255 *
          ((opts.currentValue - opts.minimumValue) /
            (opts.maximumValue - opts.minimumValue));
    let needleElm = null;
    if (opts.needleSharp) {
      needleElm = (
        <Polygon
          points={`${x1},${y1} ${x2},${y2} ${x3},${y3}`}
          fill={'black'}
          stroke={opts.needleColor}
        />
      );
    } else {
      needleElm = (
        <Line
          x1={opts.cX}
          y1={opts.cY}
          x2={opts.diameter}
          y2={opts.cY}
          fill="black"
          strokeWidth={opts.needleWidth}
          stroke={opts.needleColor}
        />
      );
    }

    return (
      <G className="needle">
        <G transform={`rotate(${needleAngle} ${opts.cX} ${opts.cY})`}>
          {needleElm}
        </G>
        <Circle
          cx={opts.cX}
          cy={opts.cY}
          r={opts.needleBaseSize}
          stroke={opts.needleColor}
          fill="black"
        />
      </G>
    );
  };

  renderText = opts => {
    let offsetUnit = 0;
    let offsetY = 0;

    switch (opts.currentValue.toString().length) {
      case 1:
        offsetUnit = 0;
        break;
      case 2:
        offsetUnit = 18;
        break;
      case 3:
        offsetUnit = 18;
        break;
      case 4:
        offsetUnit = 21;
        break;
      case 5:
        offsetUnit = 26;
        offsetY = 10;
        break;
    }

    return (
      <G>
        <Text
          x={opts.cX}
          y={opts.cY + (opts.needle ? (opts.size > 150 ? 57 : 52) : 0) + 5}
          fontSize={opts.progressFontSize + 5}
          transform={`rotate(0 ${opts.cX} ${opts.cY})`}
          textAnchor="middle"
          fill="white">
          {opts.currentValue}
        </Text>
        <Text
          x={opts.cX}
          y={opts.cY + (opts.needle ? (opts.size > 150 ? 75 : 67) : 0) + 2}
          fontSize={opts.progressFontSize - 5}
          transform={`rotate(0 ${opts.cX} ${opts.cY})`}
          textAnchor="middle"
          fill="white">
          {opts.unit}
        </Text>
      </G>
    );
  };

  renderProgressMetric = (opts, value) => {
    let tX = opts.cX - (opts.radius + 1.6);
    let tY = opts.cY;
    //const offset = (0.3 * tX * value + -1 * value) / opts.maximumValue;
    const offset = value > 99 ? 1 : 0;

    return (
      <Text
        x={value > opts.maximumValue / 2 ? tX + offset : tX - offset}
        y={tY}
        transform={`rotate(${((value - opts.minimumValue) /
          (opts.maximumValue - opts.minimumValue)) *
          250 -
          36} ${opts.cX} ${opts.cY}) rotate(${-(
          ((value - opts.minimumValue) /
            (opts.maximumValue - opts.minimumValue)) *
            250 -
          36
        )} ${tX} ${tY})`}
        fontSize={opts.metricsFontSize}
        textAnchor="middle"
        fill="white">
        {value}
      </Text>
    );
  };

  renderMetric = (opts, value, text) => {
    let tX = opts.cX - (opts.radius - (opts.size === 170 ? 30 : 25));
    let tY = opts.cY - (opts.size === 170 ? 47 : 40);
    return (
      <Text
        x={tX}
        y={tY}
        transform={`rotate(${(value / opts.maximumValue) * 180} ${opts.cX} ${
          opts.cY
        }) rotate(${-((value / opts.maximumValue) * 180)} ${tX} ${tY})`}
        fontSize={opts.metricsFontSize}
        textAnchor="middle"
        fill="white">
        {text || value}
      </Text>
    );
  };

  renderStartMetric = (opts, value) => {
    let tX = opts.cX - (opts.radius - (opts.size === 170 ? 30 : 25));
    let tY = opts.cY + (opts.size === 170 ? 47 : 40);
    return (
      <Text
        x={tX}
        y={tY}
        transform={`rotate(${((value - opts.minimumValue) / opts.maximumValue) *
          180} ${opts.cX} ${opts.cY}) rotate(${-(
          ((value - opts.minimumValue) / opts.maximumValue) *
          180
        )} ${tX} ${tY})`}
        fontSize={opts.metricsFontSize}
        textAnchor="middle"
        fill="white">
        {value}
      </Text>
    );
  };

  renderCircle(opts) {
    return (
      <G>
        <Circle
          stroke="white"
          cx={opts.cX}
          cy={opts.cY}
          r={opts.radius + 12}
          strokeWidth={1}
          fill="none"
        />
        <Circle
          stroke="#143BB9"
          cx={opts.cX}
          cy={opts.cY}
          r={opts.radius + 11}
          strokeWidth={2}
          fill="none"
        />
      </G>
    );
  }

  render() {
    let opts = Object.assign({}, this.props);
    let {size, dialWidth} = opts;

    let cX = size / 2;
    let cY = size / 2;
    let radius = (size - 30 * dialWidth) / 2;
    let diameter = 2 * radius;
    let circumference = (Math.PI + Math.PI * 0.2) * radius;
    opts = Object.assign(opts, {
      cX,
      cY,
      radius,
      diameter,
      circumference,
    });

    if (opts.currentValue > opts.maximumValue) {
      opts.currentValue = opts.maximumValue;
    }

    if (opts.currentValue < opts.minimumValue) {
      opts.currentValue = opts.minimumValue;
    }

    return (
      <Svg
        className={opts.className}
        height={size}
        width={size + 20}
        viewBox={`0 0 ${size} ${size}`}>
        <Defs>{this.defineTick(opts)}</Defs>
        <Defs>{this.defineTickLong(opts)}</Defs>
        <G>
          {this.renderCircle(opts)}
          {this.renderDial(opts)}
          {this.renderTicks(opts)}
          {this.renderProgresses(opts)}
          {this.renderNeedle(opts)}
          {this.renderText(opts)}
          {this.renderStartMetric(opts, opts.minText || opts.minimumValue)}
          {opts.progressRanges.map(({start}) => {
            return (
              start !== opts.minimumValue &&
              start !== opts.maximumValue &&
              this.renderProgressMetric(opts, start)
            );
          })}
          {this.renderMetric(opts, opts.maximumValue, opts.maxText)}
        </G>
      </Svg>
    );
  }
}

Gauge.defaultProps = {
  size: 120,

  dialWidth: 10,
  dialColor: '#eee',

  tickLength: 3,
  tickWidth: 1,
  tickColor: '#cacaca',
  tickInterval: 4,

  maximumValue: 100,
  minimumValue: 0,
  currentValue: 50,
  unit: '',
  progressWidth: 2,
  progressColor: '#3d3d3d',
  downProgressColor: 'red',
  progressFontSize: 15,
  metricsFontSize: 7,

  needle: true,
  needleBaseSize: 10,
  needleBaseColor: '#9d9d9d',
  needleWidth: 5,
  needleSharp: true,
  needleColor: '#8a8a8a',

  minText: '',
  maxText: '',
  progressRanges: [
    {start: 0, color: 'blue'},
    {start: 50, color: 'green'},
    {start: 75, color: 'red'},
    {start: 99, color: 'green'},
  ],
};
