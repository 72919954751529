import * as AppActions from './AppActions';

const initialState = {
  app_is_loadded: false,
  base_list: [],
  app_fav_widget: [],
  app_racc: [],
  last_update_data_redux: 0,
};

export default function AppReducer(state = initialState, action) {
  switch (action.type) {
    case AppActions.ACTION_APP_LOADDED:
      return {...state, app_is_loadded: true};
      break;
    case AppActions.ACTION_APP_BASE_LIST:
      return {
        ...state,
        base_list: action.base_list,
        last_update_data_redux: Date.now(),
      };
      break;
    case AppActions.ACTION_APP_FAV:
      return {
        ...state,
        app_fav_widget: action.fav,
        last_update_data_redux: Date.now(),
      };
      break;
    case AppActions.ACTION_APP_REFRESH:
      return {...state, last_update_data_redux: Date.now()};
      break;
    case AppActions.ACTION_APP_RACC:
      return {
        ...state,
        app_racc: action.racc,
        last_update_data_redux: Date.now(),
      };
      break;
    default:
      return state;
  }
}
