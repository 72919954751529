import React, {Component} from 'react';
import {connect} from 'react-redux';
import {wsConnect, wsRefresh, wsSwitch} from './websocket';
import * as actions from '../../Store/Systems/SystemsActions';

class WebSocketConnection extends Component {
  componentDidMount() {
    if (
      this.props.current_system &&
      this.props.current_system.hasOwnProperty('system') &&
      this.props.current_system.system.length > 0
    ) {
      const {host} = this.props;
      this.props.wsConnect(host, this.props.current_system.system[0].sys_appid);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.current_system) {
      return false;
    }

    if (
      this.props.current_system &&
      this.props.current_system.hasOwnProperty('system') &&
      this.props.current_system.system.length > 0 &&
      (!prevProps.current_system ||
        !prevProps.current_system.system ||
        prevProps.current_system.system[0].sys_appid !==
          this.props.current_system.system[0].sys_appid)
    ) {
      const {host} = this.props;

      this.props.wsRefresh(host, this.props.current_system.system[0].sys_appid);
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    current_system: state.SystemsRedux.system,
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    wsConnect: (host, sys_appid) => {
      return dispatch(wsConnect(host, sys_appid));
    },
    wsRefresh: (host, sys_appid) => {
      return dispatch(wsRefresh(host, sys_appid));
    },
    wsSwitch: (host, sys_appid) => {
      return dispatch(wsSwitch(host, sys_appid));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebSocketConnection);
