import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ImageBackground,
  Keyboard,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  Image,
  PermissionsAndroid,
  ScrollView,
  SafeAreaView,
} from 'react-native';
// import WifiManager from 'react-native-wifi-reborn';
import {Dimensions} from 'react-native';

const isARSupportedOnDevice = false;

import translate from '../translations/translate';
import {Input, Button, Text, Icon} from 'react-native-elements';
import * as actions from '../Store/Login/LoginActions';
import * as actionsSystems from '../Store/Systems/SystemsActions';
import {msg_code_to_text, ShowSnaskMessage} from '../Config/Config';
import {connect} from 'react-redux';
import {fontFamilyBold} from '../Config/fonts';
import {Platform} from 'react-native';

class Login extends Component {
  constructor(props) {
    super(props);

    this._handleARSupported = this._handleARSupported.bind(this);
    this._handleARNotSupported = this._handleARNotSupported.bind(this);

    this.state = {
      iswait: false,
      showWifiLogo: false,
      isARSupportedOnDevice: false,

      // user: 'frederick868@gmail.com',
      // code: 'demo',
      // user: "",
      // code: "",
      errors: {
        user: null,
        code: null,
      },
    };
  }

  async componentDidMount() {
    if (Platform.OS === 'android') {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
        {
          title: 'Location permission is required for WiFi connections',
          message:
            'This app needs location permission as this is required  ' +
            'to scan for wifi networks.',
          buttonNegative: 'DENY',
          buttonPositive: 'ALLOW',
        },
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        if (await WifiManager.isEnabled()) {
          const wifiList = await WifiManager.reScanAndLoadWifiList();
          const ssids = wifiList.filter(w => w.SSID.startsWith('swimo-'));

          if (ssids.length > 0) {
            this.setState({
              showWifiLogo: true,
            });
          }
        }
      }
    } else {
      this.setState({
        showWifiLogo: true,
      });
    }
  }

  componentWillMount() {
    // isARSupportedOnDevice(this._handleARNotSupported, this._handleARSupported);
  }
  _handleARSupported() {
    console.log('AR supported');
    this.setState({
      isARSupportedOnDevice: true,
    });
  }
  _handleARNotSupported(reason) {
    console.log('AR not supported, with reason: ' + reason);
    this.setState({
      isARSupportedOnDevice: false,
    });
  }

  onChangeUserText = val => {
    let _errors = this.state.errors;
    if (val) {
      _errors.user = '';
    } else {
      _errors.user = translate('Ce champ est obligatoire');
    }
    this.setState({user: val, errors: _errors});
  };
  onChangeCodeText = val => {
    let _errors = this.state.errors;
    if (val) {
      _errors.code = '';
    } else {
      _errors.code = translate('Ce champ est obligatoire');
    }
    this.setState({code: val, errors: _errors});
  };
  OpenRegister = () => {
    this.props.navigation.navigate('Registration');
  };
  OpenResetPassword = () => {
    this.props.navigation.navigate('ResetPassword');
  };
  SubmitBtn = () => {
    let isError = false;
    let _errors = this.state.errors;
    if (!this.state.user) {
      isError = true;
      _errors.user = translate('Ce champ est obligatoire');
    }
    if (!this.state.code) {
      isError = true;
      _errors.code = translate('Ce champ est obligatoire');
    }
    if (!isError) {
      this.setState({iswait: true});

      this.props
        .Login(this.state.user, this.state.code)
        .then(user => {
          //this.props.navigation.navigate('Systemes');
          this.loadSystems();
        })
        .catch(error => {
          console.log('error', error);
          this.setState({iswait: false});

          _errors = this.state.errors;

          let errormsg = msg_code_to_text(error.error);
          ShowSnaskMessage({
            message: errormsg,
            textColor: '#fff',
            backgroundColor: 'red',
            buttonColor: 'red',
            duration: 2000,
          });
          switch (error.error) {
            case '_INVALID_USER':
              _errors.user = translate(errormsg);
              break;
          }

          // _errors.code="Eoor Login";
          // _errors.user="Error Login";
          this.setState({errors: _errors});
        });
    } else {
      this.setState({errors: _errors});
    }
  };

  loadSystems = () => {
    this.props
      .getAllSystems(this.props.appId)
      .then(response => {
        //  console.log("response.hasOwnProperty('system') ",response.hasOwnProperty('system'));
        if (response.hasOwnProperty('system')) {
          this.props.setSystemCurrent(response);
          this.props.navigation.navigate('AcceuilTabs', {element: response});
          this.setState({iswait: false});
        } else {
          if (response.systems && response.systems.length > 0) {
            this.props.navigation.navigate('Systemes');
            this.setState({iswait: false});
          } else {
            this.props.navigation.navigate('Systemes');
            this.setState({iswait: false});
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  OpenSystem = item => {
    // this.setState({iswait:true});
    const {navigate} = this.props.navigation;
    //navigate('AcceuilTabs', {  element: item});

    this.props
      .getDetailSystem(item.sys_appid)
      .then(system => {
        navigate('AcceuilTabs', {element: this.props.current_system});
        this.setState({iswait: false});
      })
      .catch(error => {
        this.setState({iswait: false});
      });
  };
  goToWifiSetup = () => {
    this.props.navigation.navigate('WifiSetup');
  };
  render() {
    const windowHeight = Dimensions.get('window').height;
    return (
      <ImageBackground
        style={styles.imgBackground}
        resizeMode="cover"
        source={require('../assets/bg.png')}>
        <SafeAreaView contentContainerStyle={styles.container}>
          <KeyboardAvoidingView style={styles.containerView}>
            <ScrollView>
                <View style={styles.loginFormView}>
                  <Text style={styles.logoText}>{translate('Connexion')} </Text>
                  <Input
                    selectionColor={'white'}
                    label={translate('Pseudo/email', styles.FormLabelInput)}
                    onChangeText={val => this.onChangeUserText(val)}
                    labelStyle={styles.FormLabelInput}
                    autoCapitalize="none"
                    autoCorrect={false}
                    containerStyle={{marginBottom: 20}}
                    inputContainerStyle={{
                      borderBottomWidth: 0,
                      marginBottom: 5,
                    }}
                    inputStyle={[
                      styles.loginFormTextInput,
                      this.state.errors.user ? styles.FormTextInputError : {},
                    ]}
                    placeholder=""
                    errorStyle={{color: 'red'}}
                    errorMessage={this.state.errors.user}
                  />
                  <Input
                    selectionColor={'white'}
                    label={translate('Mot de passe', styles.FormLabelInput)}
                    onChangeText={val => this.onChangeCodeText(val)}
                    labelStyle={styles.FormLabelInput}
                    autoCapitalize="none"
                    autoCorrect={false}
                    inputStyle={[
                      styles.loginFormTextInput,
                      this.state.errors.code ? styles.FormTextInputError : {},
                    ]}
                    containerStyle={{marginBottom: 10}}
                    inputContainerStyle={{
                      borderBottomWidth: 0,
                      marginBottom: 5,
                    }}
                    placeholder=""
                    secureTextEntry={true}
                    errorStyle={{color: 'red'}}
                    errorMessage={this.state.errors.code}
                  />
                  <Text
                    style={styles.resetpasswordtext}
                    onPress={this.OpenResetPassword}>
                    {translate('mot de passe oublie ? c’est part ici')}
                  </Text>
                  <View
                    style={{
                      marginTop: 50,
                      marginBottom: windowHeight > 800 ? 50 : 10,
                    }}>
                    <View style={{alignSelf: 'center'}}>
                      <Button
                        onPress={this.SubmitBtn}
                        buttonStyle={styles.loginButton}
                        title={translate('se connecter', { color: 'white', marginTop: 10 })}
                        loading={this.state.iswait}
                      />
                    </View>
                  </View>
                  <Text
                    style={styles.inscriptiontext}
                    onPress={this.OpenRegister}>
                    {translate('Je n est pas de compte je m inscris')}
                  </Text>
                </View>
            </ScrollView>
          </KeyboardAvoidingView>
        </SafeAreaView>
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  imgBackground: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    width: '90%',
    height: '100%',
  },
  containerView: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginScreenContainer: {},
  logoText: {
    fontSize: 18,
    marginBottom: 50,
    textAlign: 'center',
    color: '#ffffff',
    fontFamily: fontFamilyBold(),
  },
  loginFormView: {
    paddingTop: 20,
    justifyContent: 'center',
  },
  resetpasswordtext: {
    paddingLeft: 10,
    color: '#ffffff',

    textDecorationLine: 'underline',
    fontSize: 13,
  },
  inscriptiontext: {
    paddingLeft: 10,
    color: '#ffffff',
    textAlign: 'center',
    textDecorationLine: 'underline',
    fontSize: 12,
    marginTop: 20,
  },
  loginFormTextInput: {
    fontFamily: fontFamilyBold(),
    fontWeight: 'normal',
    height: 43,
    fontSize: 14,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#eaeaea',
    color: '#fff',
    paddingLeft: 10,
    marginTop: 5,
  },
  FormLabelInput: {
    fontSize: 12,
    color: 'white',
    fontFamily: fontFamilyBold(),
    fontWeight: 'normal',
  },
  FormTextInputError: {
    borderColor: '#D80000',
    backgroundColor: 'rgba(255, 0, 0, 0.22)',
  },
  loginButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    color: '#ffffff',
    borderColor: '#ffffff',
    borderRadius: 50,
    paddingTop: 20,
    paddingBottom: 20,
    width: '100%',
    fontFamily: fontFamilyBold(),
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.LoginReducer.user,
    appId: state.LoginReducer.appId,
    systems: state.SystemsRedux.systems,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    Login: (user, code) => {
      return dispatch(actions.UserLogin(user, code));
    },
    getAllSystems: appId => {
      return dispatch(actionsSystems.SystemGetAll(appId));
    },
    getDetailSystem: appId => {
      return dispatch(actionsSystems.SystemDetail(appId));
    },
    setSystemCurrent: system => {
      return dispatch(actionsSystems.setSystemCurrent(system));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
