const initialState = {
  connected: false,
};

export default function SocketReducer(state = initialState, action) {
  switch (action.type) {
    case 'WS_CONNECTED':
      return {...state, connected: true};
    default:
      return state;
  }
}
