import {combineReducers} from 'redux';
import LoginReducer from './Login/LoginRedux';
import RegistrationRedux from './Registration/RegistrationRedux';
import SystemsRedux from './Systems/SystemsRedux';
import AppRedux from './App/AppRedux';
import SocketReducer from '../middleware/socket/SocketRedux';
import Calibration from './Calibration';

export default combineReducers({
  LoginReducer,
  RegistrationRedux,
  SystemsRedux,
  AppRedux,
  SocketReducer,
  Calibration,
});
