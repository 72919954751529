import React, {useEffect, useState} from 'react';
import {
  StyleSheet,
  View,
  ScrollView,
  ImageBackground,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  Keyboard,
  SafeAreaView,
} from 'react-native';
import {Header, Input} from 'react-native-elements';
import {useSelector, useDispatch} from 'react-redux';

import ThreeDotsLoader from '../../components/ThreeDotsLoader';
import HeaderLeftComponent from '../../components/HeaderLeftComponent';

import translate from '../../translations/translate';
import {
  appHeaderHeight,
  appHeaderTop,
  msg_code_to_text,
  ShowSnaskMessage,
} from '../../Config/Config';
import {fontFamilyRegular} from '../../Config/fonts';
import {UpdateUser} from '../../Store/Systems/SystemsActions';

const ProfilInfoEdit = ({navigation}) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [firstName, setFirstName] = useState('');
  const [phone, setPhone] = useState('');
  const [zip, setZip] = useState('');
  const [pseudo, setPseudo] = useState('');
  const [loading, setLoading] = useState({});
  const [errors, setErrors] = useState({});

  const currentUser = useSelector(state => {
    return state.SystemsRedux.system.user[0];
  });
  const currentUserCredentials = useSelector(state => {
    return (
      state.SystemsRedux.system.system[0] &&
      state.SystemsRedux.system.system[0].sys_appid
    );
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setPseudo(currentUser.user_pseudo);
    setName(currentUser.user_name);
    setAddress(currentUser.user_address);
    setCity(currentUser.user_city);
    setFirstName(currentUser.user_firstname);
    setPhone(currentUser.user_phone);
    setZip(currentUser.user_zip);
  }, [currentUser]);

  const onBlur = (fieldName, val) => {
    setLoading({
      [fieldName]: true,
    });
    setErrors({
      [fieldName]: false,
      ...errors,
    });

    dispatch(
      UpdateUser(currentUserCredentials, {
        key: fieldName,
        value: val,
      }),
    )
      .then(() => {
        ShowSnaskMessage({
          message: 'operation success',
          textColor: '#000',
          backgroundColor: '#7ED321',
          buttonColor: '#7ED321',
          duration: 2000,
        });
        setLoading({
          [fieldName]: false,
        });
      })
      .catch(err => {
        setLoading({
          [fieldName]: false,
        });
        let errormsg = msg_code_to_text(err);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        setErrors({
          [fieldName]: err,
          ...errors,
        });
      });
  };

  return (
    <SafeAreaView style={styles.containerfull}>
      <KeyboardAvoidingView style={styles.containerView}>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <ImageBackground
            style={styles.imgBackground}
            resizeMode="cover"
            source={require('../../assets/bg.png')}>
            <Header
              containerStyle={{
                backgroundColor: '#289EFF',
                height: appHeaderHeight(),
                paddingTop: 0,
                margin: 0,
                borderBottomColor: '#289EFF',
              }}
              leftComponent={
                <HeaderLeftComponent goToBack={navigation.goBack} />
              }
            />
            <ScrollView
              contentContainerStyle={{display: 'flex', alignItems: 'center'}}>
              <View style={{marginTop: 20, width: '90%'}}>
                <Input
                  selectionColor={'white'}
                  label={translate('Name', styles.Label)}
                  onBlur={() => onBlur('user_name', name)}
                  onChangeText={val => setName(val)}
                  containerStyle={styles.FormInputcontainerStyle}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[styles.FormTextInput]}
                  value={name}
                  rightIcon={loading.user_name ? <ThreeDotsLoader /> : false}
                  errorStyle={styles.Error}
                  errorMessage={errors.user_name}
                />
              </View>
              <View style={{width: '90%'}}>
                <Input
                  selectionColor={'white'}
                  label={translate('First name', styles.Label)}
                  onBlur={() => onBlur('user_firstname', firstName)}
                  onChangeText={val => setFirstName(val)}
                  containerStyle={styles.FormInputcontainerStyle}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[styles.FormTextInput]}
                  value={firstName}
                  rightIcon={
                    loading.user_firstname ? <ThreeDotsLoader /> : false
                  }
                  errorStyle={styles.Error}
                  errorMessage={errors.user_firstname}
                />
              </View>
              <View style={{width: '90%'}}>
                <Input
                  selectionColor={'white'}
                  label={translate('Email', styles.Label)}
                  onBlur={() => onBlur('user_pseudo', pseudo)}
                  onChangeText={val => setPseudo(val)}
                  containerStyle={styles.FormInputcontainerStyle}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[styles.FormTextInput]}
                  value={pseudo}
                  rightIcon={loading.user_pseudo ? <ThreeDotsLoader /> : false}
                  errorStyle={styles.Error}
                  errorMessage={errors.user_pseudo}
                />
              </View>
              <View style={{width: '90%'}}>
                <Input
                  selectionColor={'white'}
                  label={translate('Address', styles.Label)}
                  onBlur={() => onBlur('user_address', address)}
                  onChangeText={val => setAddress(val)}
                  containerStyle={styles.FormInputcontainerStyle}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[styles.FormTextInput]}
                  value={address}
                  rightIcon={loading.user_address ? <ThreeDotsLoader /> : false}
                  errorStyle={styles.Error}
                  errorMessage={errors.user_address}
                />
              </View>
              <View style={{width: '90%'}}>
                <Input
                  selectionColor={'white'}
                  label={translate('City', styles.Label)}
                  onBlur={() => onBlur('user_city', city)}
                  onChangeText={val => setCity(val)}
                  containerStyle={styles.FormInputcontainerStyle}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[styles.FormTextInput]}
                  value={city}
                  rightIcon={loading.user_city ? <ThreeDotsLoader /> : false}
                  errorStyle={styles.Error}
                  errorMessage={errors.user_city}
                />
              </View>
              <View style={{width: '90%'}}>
                <Input
                  selectionColor={'white'}
                  label={translate('Zip code', styles.Label)}
                  onBlur={() => onBlur('user_zip', zip)}
                  onChangeText={val => setZip(val)}
                  containerStyle={styles.FormInputcontainerStyle}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[styles.FormTextInput]}
                  value={zip}
                  rightIcon={loading.user_zip ? <ThreeDotsLoader /> : false}
                  errorStyle={styles.Error}
                  errorMessage={errors.user_zip}
                />
              </View>
              <View style={{marginBottom: 20, width: '90%'}}>
                <Input
                  selectionColor={'white'}
                  label={translate('Phone', styles.Label)}
                  onBlur={() => onBlur('user_phone', phone)}
                  onChangeText={val => setPhone(val)}
                  containerStyle={styles.FormInputcontainerStyle}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[styles.FormTextInput]}
                  value={phone}
                  rightIcon={loading.user_phone ? <ThreeDotsLoader /> : false}
                  errorStyle={styles.Error}
                  errorMessage={errors.user_phone}
                />
              </View>
            </ScrollView>
          </ImageBackground>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  containerfull: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
  },
  containerView: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  FormInputContainerStyle: {
    borderBottomWidth: 0,
    marginBottom: 5,
  },
  FormTextInput: {
    height: 43,
    fontSize: 14,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#eaeaea',
    color: '#fff',
    paddingLeft: 10,
    marginTop: 5,
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
  Label: {
    color: '#fff',
    paddingLeft: 10,
    marginTop: 5,
    marginBottom: 5,
    fontFamily: 'arial',
  },
  Error: {
    color: 'red',
  },
  imgBackground: {
    width: '100%',
    height: '100%',
  },
});

export default ProfilInfoEdit;
