import React from 'react';
import styles from './styles.js';
import {
  Keyboard,
  KeyboardAvoidingView,
  SafeAreaView,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

import translate, {translateString} from '../../translations/translate';
import {Button, Input, Text, CheckBox, Header} from 'react-native-elements';
import HeaderLeftComponent from '../../components/HeaderLeftComponent';

import {connect} from 'react-redux';
import * as actions from '../../Store/Registration/RegistrationActions';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  appHeaderHeight,
} from '../../Config/Config';
import {fontFamilyRegular} from '../../Config/fonts';

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iswait: false,
      formData: {
        user: null,
        pwd: null,
        re_pwd: null,
        cgu: false,
        user_type: 0,
      },
      errors: {
        user: null,
        pwd: null,
        re_pwd: null,
        cgu: false,
      },
    };
  }

  onChangeField = (fieldName, val) => {
    let _formData = this.state.formData;
    let _errors = this.state.errors;

    if (val) {
      _errors[fieldName] = '';
    } else {
      _errors[fieldName] = 'Error';
    }
    _formData[fieldName] = val;

    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (fieldName === 'user' && reg.test(val) === true) {
      _formData.user_type = 0;
    } else {
      _formData.user_type = 1;
    }
    this.setState({formData: _formData, errors: _errors});
  };
  ValidateFormData = async () => {
    let isError = false;
    let _formData = this.state.formData;
    let _errors = this.state.errors;
    if (!_formData.cgu) {
      isError = true;
      _errors.cgu = await translateString('Ce champ est obligatoire');
    }
    if (!_formData.user) {
      isError = true;
      _errors.user = await translateString('Ce champ est obligatoire');
    }
    if (!_formData.pwd) {
      isError = true;
      _errors.pwd = await translateString('Ce champ est obligatoire');
    }

    if (_formData.pwd && _formData.pwd != _formData.re_pwd) {
      isError = true;
      _errors.re_pwd = await translateString(
        'Merci de verifier votre mots de passe',
      );
    }

    this.setState({errors: _errors});
    return isError;
  };
  SubmitBtn = async () => {
    if (!(await this.ValidateFormData())) {
      this.setState({iswait: true});

      this.props
        .NextStep(this.state.formData)
        .then(userInfo => {
          this.setState({iswait: false});
          this.props.nextStep(2);
        })
        .catch(async error => {
          let _errors = this.state.errors;
          this.setState({iswait: false});
          let errormsg = msg_code_to_text(error);
          ShowSnaskMessage({
            message: errormsg,
            textColor: '#fff',
            backgroundColor: 'red',
            buttonColor: 'red',
            duration: 2000,
          });
          switch (error) {
            case '_USERNAME_ALREADY_REGISTERED':
              _errors.user = await translateString(errormsg);
              break;
          }
          this.setState({errors: _errors});
        });
    }
  };
  goToBack = () => {
    this.props.goBack();
  };
  render() {
    return (
      <SafeAreaView contentContainerStyle={{flexGrow: 1}}>
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#289EFF',
          }}
          leftComponent={<HeaderLeftComponent goToBack={this.goToBack} />}
        />
        <KeyboardAwareScrollView contentContainerStyle={{flexGrow: 1}}>
          <KeyboardAvoidingView style={styles.containerView}>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
              <View style={styles.RegisterFormView}>
                <Text style={styles.enteteText}>
                  {translate('Inscription')}{' '}
                </Text>
                <Input
                  selectionColor={'white'}
                  label={translate('Pseudo/email', styles.FormInputlabelStyle)}
                  onChangeText={val => this.onChangeField('user', val)}
                  labelStyle={styles.FormInputlabelStyle}
                  containerStyle={styles.FormInputcontainerStyle}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[
                    styles.FormTextInput,
                    this.state.errors.user ? styles.FormTextInputError : {},
                  ]}
                  autoCapitalize="none"
                  autoCorrect={false}
                  placeholder=""
                  errorStyle={styles.FormInputErrorStyle}
                  errorMessage={this.state.errors.user}
                />

                <Input
                  selectionColor={'white'}
                  secureTextEntry={true}
                  label={translate('Mot de passe', styles.FormInputlabelStyle)}
                  autoCapitalize="none"
                  autoCorrect={false}
                  onChangeText={val => this.onChangeField('pwd', val)}
                  labelStyle={styles.FormInputlabelStyle}
                  containerStyle={styles.FormInputcontainerStyle}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[
                    styles.FormTextInput,
                    this.state.errors.pwd ? styles.FormTextInputError : {},
                  ]}
                  placeholder=""
                  errorStyle={styles.FormInputErrorStyle}
                  errorMessage={this.state.errors.pwd}
                />

                <Input
                  selectionColor={'white'}
                  secureTextEntry={true}
                  label={translate(
                    'Retaper votre mot de passe',
                    styles.FormInputlabelStyle,
                  )}
                  onChangeText={val => this.onChangeField('re_pwd', val)}
                  autoCapitalize="none"
                  autoCorrect={false}
                  labelStyle={styles.FormInputlabelStyle}
                  containerStyle={[
                    styles.FormInputcontainerStyle,
                    styles.FormInputcontainerStyleLast,
                  ]}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[
                    styles.FormTextInput,
                    this.state.errors.re_pwd ? styles.FormTextInputError : {},
                  ]}
                  placeholder=""
                  errorStyle={styles.FormInputErrorStyle}
                  errorMessage={this.state.errors.re_pwd}
                />

                <CheckBox
                  onPress={() =>
                    this.onChangeField('cgu', !this.state.formData.cgu)
                  }
                  checked={this.state.formData.cgu}
                  title={translate(
                    'Condition generale',
                    styles.FormInputlabelStyle,
                  )}
                  containerStyle={{
                    backgroundColor: 'transparent',
                    borderWidth: 0,
                    margin: 0,
                    padding: 0,
                  }}
                  textStyle={{
                    color: '#ffffff',
                    fontSize: 10,
                    fontFamily: fontFamilyRegular(),
                    fontWeight: 'normal',
                  }}
                  errorMessage={this.state.errors.cgu}
                />
                <View
                  style={{
                    marginTop: 30,
                    marginBottom: 30,
                  }}>
                  <View style={{alignSelf: 'center'}}>
                    <Button
                      loading={this.state.iswait}
                      onPress={this.SubmitBtn}
                      buttonStyle={styles.BtnButton}
                      title={translate('Valider et continuer', { color: 'white'})}
                    />
                  </View>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    NextStep: data => {
      return dispatch(actions.RegisterStep1(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Step1);
