import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Alert,
  SafeAreaView,
} from 'react-native';
import {Icon} from 'react-native-elements';
import {connect} from 'react-redux';

import translate, {translateString} from '../../translations/translate';
import Systems from '../../Models/Systems';
import * as actions from '../../Store/Systems/SystemsActions';

class UserList extends React.Component {
  deleteUser = async userId => {
    const system = new Systems();

    Alert.alert(
      await translateString('delete_user_alert_title'),
      await translateString('delete_user_alert_message'),
      [
        {
          text: await translateString('yes'),
          onPress: () => {
            system
              .RemoveUser(this.props.current_system.system[0].sys_appid, {
                number: userId,
              })
              .then(() => {
                this.props.SystemDetail(
                  this.props.current_system.system[0].sys_appid,
                );
              })
              .catch(() => {
                this.props.SystemDetail(
                  this.props.current_system.system[0].sys_appid,
                );
              });
          },
        },
        {
          text: await translateString('no'),
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
      ],
      {cancelable: true},
    );
  };

  render() {
    const users = this.props.current_system.list;
    const currentUser =
      this.props.current_system.user && this.props.current_system.user[0];

    if (!currentUser) {
      return null;
    }

    return (
      <SafeAreaView style={styles.SectionRow}>
        <View
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: 10,
          }}>
          <Text style={styles.SectionTitle}>{translate('my_users')} :</Text>
        </View>
        <View>
          {users.map(u => (
            <View style={styles.UserLine}>
              <Text style={styles.UserText}>{u.user_name}</Text>
              <Text style={styles.UserText}>{u.user_firstname}</Text>
              <TouchableOpacity onPress={() => this.deleteUser(u.user_id)}>
                <Icon
                  raised
                  name="times"
                  type="font-awesome"
                  color="#000000"
                  size={10}
                  activeOpacity={1}
                  disabledStyle={{backgroundColor: '#D8D8D8'}}
                />
              </TouchableOpacity>
            </View>
          ))}
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  SectionRow: {
    flexDirection: 'column',
    paddingBottom: 10,
    paddingTop: 10,
    color: 'white',
    backgroundColor: '#121212',
    borderRadius: 10,
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  SectionTitle: {
    paddingBottom: 10,
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
  UserText: {
    width: 100,
    color: '#fff',
  },
  UserLine: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#fff',
    paddingLeft: 10,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.LoginReducer.user || [],
    current_system: state.SystemsRedux.system || [],
    clientInfo: state.SystemsRedux.clientInfo || {},
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    last_update_data_redux_system: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    SystemDetail: appId => {
      return dispatch(actions.SystemDetail(appId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserList);
