import React from 'react';
import {connect} from 'react-redux';
import {
  StyleSheet,
  Modal,
  View,
  Text,
  Picker,
  TouchableHighlight,
  Platform,
  SafeAreaView,
} from 'react-native';
import {Button, Icon} from 'react-native-elements';
import translate, {translateString} from '../../translations/translate';
import {fontFamilyBold} from '../../Config/fonts';
import RNPickerSelect from 'react-native-picker-select';
import Iconchecked from '../../assets/checked.svg';
import * as actions from '../../Store/Systems/SystemsActions';
import {msg_code_to_text, ShowSnaskMessage} from '../../Config/Config';
import PropTypes from 'prop-types';
import Loader from '../../components/Loader';

class NouveauDevices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base_list: {},
      numberTranlation: '',
      loading: true,
      chooseElementTranslation: '',
      iswait: false,
      issucced: false,
      isOpen: false,
      isvalide: false,
      widgetlist: [],
      devices_number_available: [],
      equipementlist: [],
      formData: {
        widget_type: 0,
        widget_ref: null,
      },
    };
  }

  async componentDidMount() {
    this.LoadDefaultDevices();
    let _equipementlist = [];

    const chooseElementTranslation = await translateString(
      'Choisir un element',
    );
    const numberTranlation = await translateString('Number');
    for (let i = 0; i < this.props.base_list.device_list.length; i++) {
      const el = this.props.base_list.device_list[i];
      let labelPicker = '';
      let ValPicker = '';

      labelPicker = await translateString(el['device-default_name']);
      ValPicker = el.device_type;

      _equipementlist.push({
        label: labelPicker || el['device-default_name'],
        value: ValPicker,
      });
    }

    this.setState({
      numberTranlation: numberTranlation,
      chooseElementTranslation: chooseElementTranslation,
      loading: false,
      equipementlist: _equipementlist,
      isOpen: this.props.isOpen,
      iswait: false,
      issucced: false,
    });
  }
  componentDidUpdate(nextProps) {
    const {isOpen} = this.props;

    if (nextProps.isOpen !== isOpen) {
      this.LoadDefaultDevices();
      this.setState({isOpen: isOpen, isWait: false, issucced: false}, () => {});
    }
  }
  LoadDefaultDevices = async () => {
    // console.log("this.props.base_list => ",this.props.base_list)
    let listdevicesavailable = [];
    if (
      this.props.current_system.hasOwnProperty('actions') &&
      this.props.current_system.actions.length > 0
    ) {
      //console.log("this.props.current_system ",this.props.current_system)
      this.props.current_system.actions.map(device => {
        listdevicesavailable.push(parseInt(device.device_number));
      });
    }
    let _devices_number_available = [];
    if (this.props.base_list.hasOwnProperty('device_available')) {
      let device_available = this.props.base_list.device_available[0];

      for (
        let i = parseInt(device_available.min_action_number);
        i <= parseInt(device_available.max_action_number);
        i++
      ) {
        if (!listdevicesavailable.includes(i)) {
          _devices_number_available.push({
            label: await translateString('Devices number ' + i),
            value: i,
          });
        }
      }
    }
    let _formData = {
      widget_type: 0,
      widget_ref: null,
    };
    this.setState({
      base_list: this.props.base_list,
      devices_number_available: _devices_number_available,
      formData: _formData,
      isvalide: false,
    });

    //devices_number_available
  };

  closeModal = () => {
    this.setState({isOpen: false});
    this.props.closeModal();
  };

  SelectWidgetType = (itemValue, itemIndex) => {
    let form = this.state.formData;
    form.widget_type = itemValue;
    let _widgetlist = [];
    _widgetlist = this.state.base_list.device_list;

    this.setState({formData: form, widgetlist: _widgetlist}, () => {
      this.IsValideData();
    });
  };
  SelectWidgetRef = (itemValue, itemIndex) => {
    let form = this.state.formData;
    form.widget_ref = itemValue;

    this.setState({formData: form}, () => {
      this.IsValideData();
    });
  };

  IsValideData = () => {
    let _isvalide = true;

    if (!this.state.formData.widget_type || !this.state.formData.widget_ref) {
      _isvalide = false;
    }

    this.setState({isvalide: _isvalide, iswait: false});
  };
  SubmitBtn = () => {
    this.setState({iswait: true});
    //alert("Send Data")
    let params = {};
    params = {
      type: 'device',
      number: this.state.formData.widget_type,
      action: this.state.formData.widget_ref,
    };

    this.addNewEquipment(params);
  };
  renderPickerAndroid() {
    if (Platform.OS !== 'ios') {
      return (
        <View>
          <View style={styles.ViewPicker}>
            <Picker
              style={styles.Picker}
              selectedValue={this.state.formData.widget_type}
              placeholderIconColor={'#fff'}
              onValueChange={(itemValue, itemIndex) => {
                this.SelectWidgetType(itemValue, itemIndex);
              }}>
              <Picker.Item label={this.state.numberTranlation} value={0} />
              {this.state.devices_number_available.map(el => {
                return <Picker.Item label={el.label} value={el.value} />;
              })}
            </Picker>
          </View>
          <View style={styles.ViewPicker}>
            <Picker
              style={styles.Picker}
              selectedValue={this.state.formData.widget_ref}
              placeholderIconColor={'#fff'}
              onValueChange={(itemValue, itemIndex) => {
                this.SelectWidgetRef(itemValue, itemIndex);
              }}>
              {this.state.equipementlist.map(el => {
                return <Picker.Item label={el.label} value={el.value} />;
              })}
            </Picker>
          </View>
        </View>
      );
    }
  }

  renderPickerIos() {
    if (Platform.OS === 'ios') {
      return (
        <View>
          <View style={[styles.ViewPicker, {padding: 10}]}>
            <RNPickerSelect
              placeholder={{
                label: this.state.numberTranlation,
                value: null,
                color: '#fff',
              }}
              onValueChange={value => this.SelectWidgetType(value, value)}
              value={this.state.formData.widget_type}
              style={{
                ...pickerSelectStyles,
                iconContainer: {
                  top: 20,
                  right: 10,
                },
                placeholder: {
                  color: '#fff',
                  fontSize: 12,
                  fontWeight: 'bold',
                },
              }}
              items={this.state.devices_number_available}
            />
          </View>
          <View style={[styles.ViewPicker, {padding: 10}]}>
            <RNPickerSelect
              placeholder={{
                label: this.state.chooseElementTranslation,
                value: null,
                color: '#fff',
              }}
              value={this.state.formData.widget_ref}
              style={{
                ...pickerSelectStyles,
                iconContainer: {
                  top: 20,
                  right: 10,
                },
                placeholder: {
                  color: '#fff',
                  fontSize: 12,
                  fontWeight: 'bold',
                },
              }}
              onValueChange={value => this.SelectWidgetRef(value, value)}
              items={this.state.equipementlist}
            />
          </View>
        </View>
      );
    }
  }
  render() {
    return (
      <SafeAreaView>
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.isOpen}>
          <View style={styles.Modalcontainer}>
            <View
              style={{
                justifyContent: 'center',
                backgroundColor: '#000000',
                borderRadius: 20,
                padding: 15,
                width: '90%',
              }}>
              <View style={{right: 10, top: 10, alignSelf: 'flex-end'}}>
                <TouchableHighlight
                  style={{padding: 5}}
                  onPress={() => this.closeModal()}>
                  <Icon
                    size={20}
                    containerStyle={{marginLeft: 10}}
                    name="check"
                    type="font-awesome"
                    color="#fff"
                  />
                </TouchableHighlight>
              </View>
              {!this.state.issucced && (
                <View>
                  <View style={styles.topViewtitle}>
                    <Text
                      style={{
                        color: '#fff',
                        borderTopWidth: 3,
                        fontSize: 16,
                        marginTop: -2,
                        fontFamily: fontFamilyBold(),
                        textTransform: 'uppercase',
                        paddingTop: 10,
                      }}>
                      {translate('equipements')}
                    </Text>
                  </View>
                  <Loader isloader={this.state.iswait || this.state.loading} />
                  {!this.state.loading && this.renderPickerAndroid()}
                  {!this.state.loading && this.renderPickerIos()}
                  <View
                    style={{
                      alignSelf: 'center',
                      marginTop: 20,
                      marginBottom: 20,
                    }}>
                    <Button
                      loading={this.state.iswait}
                      disabled={!this.state.isvalide}
                      onPress={this.SubmitBtn}
                      buttonStyle={styles.BtnButton}
                      title={translate('Valider et Terminer')}
                    />
                  </View>
                </View>
              )}

              {this.state.issucced && (
                <View style={{alignSelf: 'center'}}>
                  <View
                    style={{
                      justifyContent: 'center',
                      alignSelf: 'center',
                      borderWidth: 1,
                      width: 100,
                      height: 100,
                      borderColor: '#fff',
                      borderRadius: 100,
                    }}>
                    <Iconchecked width={80} height={80} />
                  </View>
                  <Text style={{color: '#fff', marginTop: 10, fontSize: 17}}>
                    {translate('equipement_ajout')}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </Modal>
      </SafeAreaView>
    );
  }

  addNewEquipment = params => {
    console.log('addNewEquipment params ', params);
    this.props
      .addNewEquipment(this.props.sys_id, params)
      .then(response => {
        this.setState({iswait: false, issucced: true});
      })
      .catch(error => {
        this.setState({iswait: false, isvalide: false});
        let errormsg = msg_code_to_text(error);
        console.log('addNewEquipment errormsg', errormsg);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });
      });
  };
}

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 5,
    paddingHorizontal: 4,
    color: '#fff',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
});
const styles = StyleSheet.create({
  Modalcontainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    flexDirection: 'column',
  },
  topViewtitle: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  ViewPicker: {
    fontSize: 14,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#fff',
    color: '#fff',
    paddingLeft: 10,
    marginTop: 10,
    fontFamily: 'arial',
  },
  Picker: {
    fontSize: 12,
    color: '#fff',
    height: 40,
    fontFamily: 'arial',
  },
  BtnButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    fontFamily: 'arial',
    fontSize: 12,
    borderColor: '#ffffff',
    borderRadius: 50,
    paddingTop: 5,
    paddingBottom: 10,
    width: '100%',
  },
});

NouveauDevices.propTypes = {
  current_system: PropTypes.object,
  base_list: PropTypes.object,
};

NouveauDevices.defaultProps = {
  current_system: {},
  base_list: {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    base_list: state.AppRedux.base_list,
    current_system: state.SystemsRedux.system,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addNewEquipment: (appId, params) => {
      return dispatch(actions.addNewEquipment(appId, params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NouveauDevices);
