import React from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import Accueil from './Accueil';
import Devices from './Devices';
import Sensors from './Sensors';
import Strips from './Strips';
import Profil from '../Profil';
import Settings from './Settings';
import AppareilVerouillee from '../AppareilVerouillee';
import Iconmaison from '../../assets/maison.svg';
import Iconsenssor from '../../assets/senssor.svg';
import Icondevices from '../../assets/devices.svg';
import IconStrip from '../../assets/scan.svg';
import Iconsettings from '../../assets/settings.svg';
import Svg, {
  Use,
  Image,
} from 'react-native-svg';

const Tab = createBottomTabNavigator();
import {StyleSheet, View, AppState} from 'react-native';
import {connect} from 'react-redux';

// import messaging from '@react-native-firebase/messaging';

import {TouchableOpacity} from 'react-native';
import Bidon from '../../assets/bi.svg';
import {getStoreDataPromise} from '../../Config/Config';
import Systems from '../../Models/Systems';
import * as actions from '../../Store/Systems/SystemsActions';

function TabBar({state, descriptors, navigation}) {
  return (
    <View style={{flexDirection: 'row', backgroundColor: '#2A92F1'}}>
      {state.routes.map((route, index) => {
        const {options} = descriptors[route.key];
        const isFocused = state.index === index;

        const iconTab = options.tabBarIconC(isFocused);
        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };

        const onLongPress = () => {
          navigation.emit({
            type: 'tabLongPress',
            target: route.key,
          });
        };

        return (
          <TouchableOpacity
            accessibilityRole="button"
            accessibilityStates={isFocused ? ['selected'] : []}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            onLongPress={onLongPress}
            style={[
              {
                flex: 1,
                paddingTop: 10,
                paddingBottom: 10,
                alignSelf: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                borderTopWidth: 5,
                borderTopColor: isFocused ? '#fff' : '#2A92F1',
              },
            ]}>
            {iconTab}
          </TouchableOpacity>
        );
      })}
    </View>
  );
}
class AcceuilTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
    };
  }

  handleAppStateChange = nextAppState => {
    if (nextAppState === 'active') {
      const appid =
        this.props.current_system.system &&
        this.props.current_system.system[0].sys_appid;
      this.props.getSystem(appid);
    }
  };

  componentDidMount() {
    this.IsAdminSystem();
    // The user is authenticated at this point
    // const appid = this.props.current_system.system[0].sys_appid;
    const appid = this.props.appId;
    AppState.addEventListener('change', this.handleAppStateChange);

    const s = new Systems();

    let params = this.props.route.params;

    if (params.goToTab) {
      this.props.navigation.navigate(params.goToTab);
    }
  }

  componentWillUnmount() {
    AppState.removeEventListener('change', this.handleAppStateChange);
  }

  componentDidUpdate(nextProps) {
    const {app_racc, last_update_data_redux} = this.props;

    if (nextProps.last_update_data_redux !== last_update_data_redux) {
      //  console.log("last_update_data_redux ",last_update_data_redux)
      this.IsAdminSystem();
    }

    //console.log("componentDidUpdate AcceuilTabs")
  }

  IsAdminSystem = () => {
    getStoreDataPromise('system_current_is_admin').then(_isAdmin => {
      // console.log("IsAdminSystem acceuilTabs IsAdminSystem",_isAdmin);
      if (_isAdmin === 'true') {
        this.setState({isAdmin: true});
      } else {
        this.setState({isAdmin: false});
      }
    });
  };

  render() {
    // console.log("IsAdminSystem acceuilTabs ",this.state.isAdmin);
    if (this.state.isAdmin) {
      return this.renderIsAdmin();
    }

    return <Accueil navigation={this.props.navigation} />;
  }
  renderIsAdmin() {
    return (
      <Tab.Navigator
        initialRouteName="TabHome"
        tabBar={props => <TabBar {...props} />}>
        <Tab.Screen
          name="TabHome"
          component={Accueil}
          options={{
            headerShown: false,
            tabBarLabel: '',
            tabBarIconC: isFocused =>  <Svg width="30" height="30" style={styles.backgroundImage}>
            <Image width="30" height="30" href={Iconmaison} style={styles.backgroundImage} />
          </Svg>,
          }}
        />
        <Tab.Screen
          name="tab1"
          component={Sensors}
          options={{
            headerShown: false,
            tabBarLabel: '',
            tabBarIconC: isFocused =>  <Svg width="30" height="30" style={styles.backgroundImage}>
            <Image width="30" height="30" href={Iconsenssor} style={styles.backgroundImage} />
          </Svg>,
          }}
        />
        <Tab.Screen
          name="tab2"
          component={Devices}
          options={{
            headerShown: false,
            tabBarLabel: '',
            tabBarIconC: isFocused => <Svg width="30" height="30" style={styles.backgroundImage}>
            <Image width="30" height="30" href={Icondevices} style={styles.backgroundImage} />
          </Svg>,
          }}
        />
        <Tab.Screen
          name="TabSetting"
          component={Settings}
          options={{
            headerShown: false,
            tabBarLabel: '',
            tabBarIconC: isFocused => <Svg width="30" height="30" style={styles.backgroundImage}>
            <Image width="30" height="30" href={Iconsettings} style={styles.backgroundImage} />
          </Svg>,
          }}
        />
      </Tab.Navigator>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.LoginReducer.user,
    current_system: state.SystemsRedux.system,
    app_fav_widget: state.AppRedux.app_fav_widget,
    app_racc: state.AppRedux.app_racc,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    appId: state.LoginReducer.appId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSystem: appid => {
      return dispatch(actions.SystemDetail(appid));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AcceuilTabs);
