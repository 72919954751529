import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  TouchableHighlight,
  SafeAreaView,
} from 'react-native';
import {connect} from 'react-redux';
import {
  appHeaderHeight,
  appHeaderTop,
  getStoreDataPromise,
  setStoreData,
  APP_BAR_ICON_SIZE,
  LEFT_HIT_SLOP,
} from '../../Config/Config';
import PropTypes from 'prop-types';
import Loader from '../../components/Loader';
import {Header, Icon} from 'react-native-elements';
import MachineUnlock from '../../components/widget/MachineUnlock';
import FlowSwitch from '../../components/widget/FlowSwitch';
import GraphSensor from '../../components/widget/GraphSensor';
import Temperature from '../../components/widget/Temperature';
import * as actions from '../../Store/Systems/SystemsActions';

import {fontFamilyBold, fontFamilyRegular} from '../../Config/fonts';
import WidgetStrip from '../../components/WidgetStrip';
// import NouveauStrips from './NouveauStrips';
import * as actionsApp from '../../Store/App/AppActions';
import translate from '../../translations/translate';

class HeaderLeftComponent extends React.Component {
  render() {
    return (
      <>
        <TouchableOpacity
          onPress={() => this.props.navigation.navigate('Alertes')}
          hitSlop={LEFT_HIT_SLOP}>
          <Icon
            name="exclamation-triangle"
            type="font-awesome"
            color={
              this.props.current_system.alarm &&
              this.props.current_system.alarm.filter(
                i => i.alarm_status === '0',
              ).length > 0
                ? 'red'
                : '#fff'
            }
            size={APP_BAR_ICON_SIZE}
          />
        </TouchableOpacity>
      </>
    );
  }
}

class HeaderrightComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalUnlock: false,
    };
  }
  unlock = () => {
    if (!this.props.isAdmin) {
      this.setState({modalUnlock: true});
    } else {
      this.props.CloseApp();
    }
  };
  closeModal = () => {
    this.setState({modalUnlock: false});
  };
  callBackModal = () => {
    this.setState({modalUnlock: false});
    this.props.ModalUnlock();
  };
  render() {
    return (
      <View
        style={{flexDirection: 'row', paddingBottom: 0, paddingRight: 10}}>
        <TouchableOpacity onPress={this.unlock}>
          <Icon
            containerStyle={{paddingRight: 30}}
            name={this.props.isAdmin ? 'unlock-alt' : 'lock'}
            type="font-awesome"
            color="#fff"
            size={30}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => this.props.navigation.navigate('Profil')}>
          <Icon name="user" type="font-awesome" color="#fff" size={30} />
        </TouchableOpacity>

        <MachineUnlock
          isOpen={this.state.modalUnlock}
          closeModal={this.closeModal}
          callback={this.callBackModal}
        />
      </View>
    );
  }
}
class Strips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      system: null,
      system_name: '',
      strips: [],
      isAdmin: false,
      isLoader: false,
      OpenModal: false,
    };
  }
  componentDidMount() {
    this.initScreens();
  }

  componentWillUnmount() {}
  componentDidUpdate(nextProps) {
    const {last_update_data_redux, last_update_data_redux_system} = this.props;
    if (
      nextProps.last_update_data_redux !== last_update_data_redux ||
      nextProps.last_update_data_redux_system !== last_update_data_redux_system
    ) {
      this.initScreens();
    }
  }
  initScreens = () => {
    this.getSystem();
    this.IsAdminSystem();
  };

  IsAdminSystem = () => {
    getStoreDataPromise('system_current_is_admin').then(_isAdmin => {
      if (_isAdmin === 'true') {
        this.setState({isAdmin: true});
      } else {
        this.setState({isAdmin: false});
      }
    });
  };
  getSystem = () => {
    if (
      this.props.current_system.hasOwnProperty('system') &&
      this.props.current_system.system.length > 0
    ) {
      this.setState({
        system: this.props.current_system.system[0],
        system_name: this.props.current_system.system[0].sys_ref,
        strips: this.props.current_system.strips,
      });
    }
  };

  ModalUnlock = () => {
    this.setState({isAdmin: true});
    setStoreData('system_current_is_admin', 'true');
  };

  renderFlowSwitch = strip => {
    return (
      <TouchableOpacity
        style={{paddingBottom: 20}}
        onPress={() => {
          this.OpenDetailStrip(strip);
        }}>
        <FlowSwitch {...strip} />
      </TouchableOpacity>
    );
  };

  renderGraphSensor = strip => {
    return (
      <TouchableOpacity
        style={{paddingBottom: 20}}
        onPress={() => {
          this.OpenDetailStrip(strip);
        }}>
        <GraphSensor {...strip} />
      </TouchableOpacity>
    );
  };

  OpenDetailStrip = strip => {
    this.setState({isLoader: true});
    this.props
      .getStripHistorique(this.state.system.sys_appid, strip.strip_number, 30)
      .then(response => {
        this.setState({isLoader: false});
        this.props.navigation.navigate({
          name: 'StripDetail',
          params: {
            stripDetail: {strip, ...response},
          },
          key: this.state.system.sys_appid + '_' + strip.strip_number + '_30',
        });
      })
      .catch(error => {
        this.setState({isLoader: false});
      });
  };

  renderTemperature = strip => {
    return (
      <TouchableOpacity
        style={{paddingBottom: 20}}
        onPress={() => {
          this.OpenDetailStrip(strip);
        }}>
        <Temperature {...strip} />
      </TouchableOpacity>
    );
  };

  RenderWidget = strip => {
    return (
      <WidgetStrip
        OpenDetailStrip={this.OpenDetailStrip}
        strip={strip}
        navigation={this.props.navigation}
      />
    );
  };

  /* RenderWidget = (strip) =>{
        switch (strip.strip_hash) {
            case "_FLOW_SWITCH":return this.renderFlowSwitch(strip);break;
            case "_FLOW_PRES":return this.renderFlowSwitch(strip);break;
            case "_PH_SIGNAL_1":return this.renderGraphSensor(strip);break;
            case "_ORP_SIGNAL_2":return this.renderGraphSensor(strip);break;
            case "_TEMP_PT100":return this.renderTemperature(strip);break;
        }
    }*/

  renderElement = strip => {
    return this.RenderWidget(strip);
  };
  renderElements = () => {
    if (this.state.strips.length > 0) {
      return this.state.strips.map(strip => {
        return this.renderElement(strip);
      });
    } else {
      return this.renderEmpty();
    }
  };

  renderEmpty = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Text style={{color: '#fff', textAlign: 'center'}}>
          {translate('Empty')}
        </Text>
      </View>
    );
  };

  closeModal = () => {
    this.setState({OpenModal: false});
  };
  OpenModal = () => {
    this.setState({OpenModal: true});
  };

  CloseApp = () => {
    this.setState({isAdmin: false});
    setStoreData('system_current_is_admin', 'false');
    this.props.refreshApp();
  };

  render() {
    return (
      <SafeAreaView style={styles.containerfull}>
        <Loader isloader={this.state.isLoader} />
        <Header
          containerStyle={{
            backgroundColor: '#289EFF',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            marginTop: appHeaderTop(),
            borderBottomColor: '#289EFF',
          }}
          leftComponent={
            <HeaderLeftComponent
              navigation={this.props.navigation}
              page="Alertes"
              current_system={this.props.current_system}
            />
          }
          rightComponent={
            <HeaderrightComponent
              navigation={this.props.navigation}
              isAdmin={this.state.isAdmin}
              ModalUnlock={this.ModalUnlock}
              CloseApp={this.CloseApp}
            />
          }
        />
        <ScrollView contentContainerStyle={{flexGrow: 1}}>
          <View style={styles.container}>
            <View style={{flexDirection: 'row', paddingBottom: 0}}>
              <View
                style={{
                  flex: 1,
                  borderColor: '#fff',
                  borderTopWidth: 4,
                  marginTop: 10,
                }}
              />
              <Text style={styles.enteteText}>{translate('Strips')}</Text>
              <View
                style={{
                  flex: 5,
                  borderColor: '#fff',
                  borderBottomWidth: 4,
                  marginBottom: 10,
                }}
              />
            </View>
            <View style={styles.containerFixed}>
              <TouchableHighlight
                style={{padding: 3}}
                onPress={() => this.props.navigation.navigate('Scan')}>
                <Text style={styles.btnadd}>+</Text>
              </TouchableHighlight>
            </View>
            {/*}
                        <View style={{alignSelf: 'flex-end',paddingBottom:20,paddingRight:20}}>
                            <View style={styles.CircleShapeView}>
                                <TouchableHighlight
                                    onPress={() => this.OpenModal()}
                                >
                                    <Image
                                        source={require('../../assets/plus.png')}
                                        style={{ width:50, height: 50}}

                                    />
                                </TouchableHighlight>
                            </View>
                        </View>
                        {*/}
            {this.renderElements()}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#289EFF',
  },
  container: {
    flex: 1,
    backgroundColor: '#000004',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  enteteText: {
    fontSize: 45,
    fontWeight: '300',
    padding: 10,
    textAlign: 'left',
    color: '#ffffff',
    fontFamily: fontFamilyRegular(),
  },
  CircleShapeView: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    borderRadius: 50 / 2,
    borderWidth: 1,
    borderColor: '#1E1E1E',
    backgroundColor: '#289EFF',
  },
  containerFixed: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    zIndex: 200,
  },
  btnadd: {
    right: 0,
    fontSize: 30,
    marginRight: 15,
    marginTop: 10,
    color: '#289EFF',
    fontFamily: fontFamilyBold(),
    textAlign: 'right',
  },
});

Strips.propTypes = {
  current_system: PropTypes.object,
};

Strips.defaultProps = {
  current_system: {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    current_system: state.SystemsRedux.system,
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    last_update_data_redux_system: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStripHistorique: (appId, strip_number, history) => {
      return dispatch(actions.getStripHistorique(appId, strip_number, history));
    },
    refreshApp: () => {
      return dispatch(actionsApp.refreshApp());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Strips);
