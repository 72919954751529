import {StyleSheet} from 'react-native';
import {fontFamilyBold, fontFamilyRegular} from '../../Config/fonts';
export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
  },
  RegisterFormView: {
    marginBottom: 100,
  },
  containerView: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    width: '90%',
  },
  subenteteText: {
    marginTop: 50,
    color: '#ffffff',
    textAlign: 'center',
  },
  enteteText: {
    fontSize: 18,

    marginBottom: 50,
    textAlign: 'center',
    color: '#ffffff',
    fontFamily: fontFamilyBold(),
  },
  enteteStepText: {
    fontSize: 18,
    fontFamily: 'arial-black',
    fontWeight: '200',
    marginTop: 5,
    marginBottom: 50,
    textAlign: 'center',
    color: '#ffffff',
  },
  inputTextLabel: {
    color: '#fff',
    paddingLeft: 10,
    marginTop: 5,
    marginBottom: 5,
    fontFamily: 'arial',
  },

  ViewPicker: {
    fontSize: 14,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#eaeaea',
    color: '#fff',
    paddingLeft: 10,
    marginTop: 5,
    fontFamily: 'arial',
  },
  TextPicker: {
    fontSize: 12,
    color: '#fff',
    fontFamily: 'arial',
  },
  Picker: {
    fontSize: 12,
    color: '#fff',
    height: 40,
    fontFamily: 'arial',
  },
  FormTextInput: {
    height: 43,
    fontSize: 14,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#eaeaea',
    color: '#fff',
    paddingLeft: 10,
    marginTop: 5,
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
  FormInputContainerStyle: {
    borderBottomWidth: 0,
    marginBottom: 5,
  },
  FormInputcontainerStyle: {
    borderBottomWidth: 0,
    marginBottom: 20,
  },
  FormTextInputError: {
    borderColor: '#D80000',
    backgroundColor: 'rgba(255, 0, 0, 0.22)',
  },
  FormInputcontainerStyleLast: {
    borderBottomWidth: 0,
    marginBottom: 10,
  },
  FormInputcontainerNoLabelStyleLast: {
    marginBottom: 0,
    borderBottomWidth: 0,
    marginTop: 0,
  },
  FormInputlabelStyle: {
    color: '#fff',
    fontSize: 12,
    fontFamily: fontFamilyRegular(),
    fontWeight: 'normal',
  },
  FormInputErrorStyle: {
    color: 'red',
  },

  BtnButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    fontFamily: 'arial',
    fontSize: 12,
    borderColor: '#ffffff',
    borderRadius: 50,
    paddingTop: 5,
    paddingBottom: 10,
    width: '100%',
  },
  zoneView: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 20,
  },
  zoneViewSwitch: {
    flexDirection: 'row',
    marginTop: 10,
  },
  ViewCol: {
    width: '100%',
    flexDirection: 'row',
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
  },
  BtnChoisetextStyle: {
    fontFamily: 'arial',
    fontSize: 12,
    backgroundColor: 'transparent',
    color: '#fff',
  },

  BtnChoise: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    fontFamily: 'arial',
    fontSize: 12,
    borderColor: '#ffffff',
    borderRadius: 50,
    paddingTop: 10,
    paddingBottom: 10,

    marginRight: 10,
  },
  BtnChoiseSelected: {
    backgroundColor: '#ffffff',
    color: '#289EFF',
  },
  FormInputcontainerStyleStep2: {
    borderBottomWidth: 0,
    marginBottom: 0,
  },
});
