import Systems from '../../Models/Systems';
import {setStoreData} from '../../Config/Config';
import {ACTION_APP_REFRESH} from '../App/AppActions';
import {UserLogout} from '../Login/LoginActions';
import {wsDisconnect} from '../../middleware/socket/websocket';
import {get_base_list as get_base_list_app} from '../App/AppActions';

export const ACTION_SYSTEMS_GET_ALL = 'ACTION_SYSTEMS_GET_ALL';
export const ACTION_SYSTEMS_CURRENT = 'ACTION_SYSTEMS_CURRENT';
export const ACTION_SYSTEMS_RESET = 'ACTION_SYSTEMS_RESET';
export const ACTION_SYSTEM_RESET = 'ACTION_SYSTEM_RESET';
export const ACTION_SYSTEMS_GET_ALL_LOADING = 'ACTION_SYSTEMS_GET_ALL_LOADING';

export function ResetSystem() {
  return async function(dispatch, getState) {
    dispatch(wsDisconnect());
    await setStoreData('system_current', {});
    await setStoreData('app_fav', []);
    await setStoreData('app_racc', []);
    await ('system_current_is_admin', 'false');
    dispatch({type: ACTION_SYSTEM_RESET});
  };
}

export function SystemGetAll(appId) {
  return function(dispatch, getState) {
    if (!appId) {
      return;
    }
    dispatch({
      type: ACTION_SYSTEMS_GET_ALL_LOADING,
    });

    let MSystems = new Systems();

    return MSystems.getAll(appId).then(data => {
      let systems = data.systems ? data.systems : [];

      let _clientInfo = {
        user: data.user ? data.user[0] : {},
        system: data.system ? data.system[0] : {},
        actions: data.actions ? data.actions[0] : {},
        sensors: data.sensors ? data.sensors[0] : {},
        strips: data.strips ? data.strips[0] : {},
        alarm: data.alarm ? data.alarm[0] : {},
        slots: data.slots ? data.slots[0] : {},
        version: data.version ? data.version[0] : {},
        weather: data.weather ? data.weather[0] : {},
        pro: data.pro ? data.pro[0] : {},
      };

      if (data.hasOwnProperty('system')) {
        systems = data.system;
      }

      dispatch({
        type: ACTION_SYSTEMS_GET_ALL,
        systems: systems,
        clientInfo: _clientInfo,
      });
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    });
  };
}

export function getSenssorHistorique(appId, sensor_number, history = 30) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    return MSystems.getSenssorHistorique(appId, sensor_number, history);
  };
}

export function getStripHistorique(appId, strip_number, history = 30) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    return MSystems.getStripHistorique(appId, strip_number, history);
  };
}

export function SystemDetail(appId) {
  return function(dispatch, getState) {
    const globalAppId = getState().LoginReducer.appId;
    dispatch(get_base_list_app(globalAppId));

    let MSystems = new Systems();

    return MSystems.getSystem(appId)
      .then(data => {
        setStoreData('system_current', data);
        // setStoreData('system_current_is_admin', 'false');
        dispatch({type: ACTION_SYSTEMS_CURRENT, system: data});
      })
      .catch(error => {
        console.log(error);
        if (error === '_MISSING_TOKEN') {
          dispatch(UserLogout());
        }
      });
  };
}

export function setSystemCurrent(system) {
  return function(dispatch, getState) {
    setStoreData('system_current', system);
    setStoreData('system_current_is_admin', 'false');
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: system});
  };
}

export function get_base_list(appId) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    return MSystems.get_base_list(appId).then(data => {
      dispatch({type: ACTION_SYSTEMS_CURRENT, system: data});
    });
  };
}

export function removeSlot(appId, params) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    return MSystems.RemoveSlot(appId, params).then(data => {
      MSystems.getSystem(appId).then(data => {
        //   console.log("Reload SystemDetail ",data);
        setStoreData('system_current', data);

        dispatch({type: ACTION_SYSTEMS_CURRENT, system: data});
      });
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    });
  };
}

export function RemoveDevice(appId, params) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    return MSystems.RemoveDevice(appId, params).then(data => {
      dispatch(RemoveSystemDevices(params.number));
      /* MSystems.getSystem(appId).then((data)=>{

                console.log("Reload SystemDetail ",data);
                setStoreData("system_current",data);

                dispatch({ type: ACTION_SYSTEMS_CURRENT, system:data });
            });*/
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    });
  };
}

function RemoveSystemDevices(number) {
  return function(dispatch, getState) {
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }
    let actions = _system.actions;
    actions = actions.filter(item => {
      if (parseInt(item.device_number) != parseInt(number)) {
        return true;
      }

      return false;
    });
    _system.actions = actions;
    setStoreData('system_current', _system);
    dispatch({type: ACTION_APP_REFRESH});
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: _system});
  };
}
export function getDevice(appId, device_number) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    return MSystems.getDevice(appId, device_number).then(data => {
      if (data) {
        MSystems.getSystem(appId).then(systemData => {
          //console.log("Reload SystemDetail ",data);
          setStoreData('system_current', systemData);

          dispatch({type: ACTION_SYSTEMS_CURRENT, system: systemData});
        });
      }
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    });
  };
}
export function RemoveSensor(appId, params) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    // console.log("RemoveSensor ",appId,params);
    return MSystems.RemoveSensor(appId, params).then(data => {
      /*MSystems.getSystem(appId).then((data)=>{
                //console.log("Reload SystemDetail ",data);
                setStoreData("system_current",data);

                dispatch({ type: ACTION_SYSTEMS_CURRENT, system:data });
            });*/
      dispatch(RemoveSystemSensors(params.number));
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    });
  };
}

export function RemoveStrip(appId, params) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    // console.log("RemoveSensor ",appId,params);
    return MSystems.RemoveSensor(appId, params).then(data => {
      /*MSystems.getSystem(appId).then((data)=>{
                //console.log("Reload SystemDetail ",data);
                setStoreData("system_current",data);

                dispatch({ type: ACTION_SYSTEMS_CURRENT, system:data });
            });*/
      dispatch(RemoveSystemStrips(params.number));
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    });
  };
}

function RemoveSystemStrips(number) {
  return function(dispatch, getState) {
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }
    let strips = _system.strips;
    strips = strips.filter(item => {
      if (parseInt(item.strip_number) !== parseInt(number)) {
        return true;
      }

      return false;
    });
    _system.strips = strips;
    setStoreData('system_current', _system);
    dispatch({type: ACTION_APP_REFRESH});
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: _system});
  };
}

function RemoveSystemSensors(number) {
  return function(dispatch, getState) {
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }
    let sensors = _system.sensors;
    sensors = sensors.filter(item => {
      if (parseInt(item.sensor_number) != parseInt(number)) {
        return true;
      }

      return false;
    });
    _system.sensors = sensors;
    setStoreData('system_current', _system);
    dispatch({type: ACTION_APP_REFRESH});
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: _system});
  };
}

export function addSlot(appId, params) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    console.log('addSlot params ', appId, params);
    return MSystems.addSlot(appId, params).then(data => {
      MSystems.getSystem(appId).then(data => {
        // console.log("Reload SystemDetail ",data);
        setStoreData('system_current', data);

        dispatch({type: ACTION_SYSTEMS_CURRENT, system: data});
      });
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    });
  };
}

export function UpdateDevice(appId, params) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    console.log('params UpdateDevice ', appId, params);
    return MSystems.UpdateDevice(appId, params).then(data => {
      //console.log("UpdateDevice ==> ",data)
      /*  MSystems.getSystem(appId).then((data)=>{
               // console.log("Reload SystemDetail ",data);
                setStoreData("system_current",data);

                dispatch({ type: ACTION_SYSTEMS_CURRENT, system:data });
            });*/
      // console.log("data.actions ==> ",data)

      if (data.hasOwnProperty('actions')) {
        dispatch(RefreshSystemDevices(data.actions));
      }

      if (data.hasOwnProperty('system')) {
        dispatch(RefreshSystemSystem(data.system));
      }

      if (data.hasOwnProperty('sensors')) {
        dispatch(RefreshSystemSensors(data.sensors));
      }
      if (data.hasOwnProperty('strips')) {
        dispatch(RefreshSystemStrips(data.strips));
      }
      if (data.hasOwnProperty('favorites')) {
        //  console.log("RefreshSystemFavorites data ",data)
        console.log(data);
        dispatch(RefreshSystemFavorites(data.favorites));
      }

      // dispatch({type: ACTION_APP_REFRESH});
      return new Promise((resolve, reject) => {
        resolve(data);
      });
      // dispatch({ type: ACTION_SYSTEMS_CURRENT, system:data });
    });
  };
}

export function UpdateUser(appId, params) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    console.log('params UpdateDevice ', appId, params);
    return MSystems.UpdateUser(appId, params).then(data => {
      dispatch(RefreshSystemUser(data.user));
      dispatch({type: ACTION_APP_REFRESH});
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    });
  };
}

function RefreshSystemUser(user) {
  return function(dispatch, getState) {
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }

    _system.user[0] = user;

    setStoreData('system_current', _system);
    dispatch({type: ACTION_APP_REFRESH});
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: _system});
  };
}

function RefreshSystemFavorites(dataFavorites) {
  return function(dispatch, getState) {
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }
    let _favorites = _system.favorites;
    //console.log("dataFavorites ",dataFavorites)
    dataFavorites.map(Fav => {
      _favorites = _favorites.map(favorite => {
        if (favorite.fav_id === Fav.fav_id) {
          //  console.log("(favorite.fav_id === Fav.fav_id) ",(favorite.fav_id === Fav.fav_id))
          return Fav;
        }
        return favorite;
      });
    });
    // console.log("_favorites ==> ",_favorites)
    _system.favorites = _favorites;
    setStoreData('system_current', _system);
    dispatch({type: ACTION_APP_REFRESH});
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: _system});
  };
}
function RefreshSystemSensors(dataSensor) {
  return function(dispatch, getState) {
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }
    let sensors = _system.sensors.map(item => {
      if (item.sensor_number === dataSensor.sensor_number) {
        return dataSensor;
      }
      return item;
    });
    _system.sensors = sensors;
    setStoreData('system_current', _system);
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: _system});
  };
}

function RefreshSystemStrips(dataStrips) {
  return function(dispatch, getState) {
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }

    _system.strips = dataStrips;

    setStoreData('system_current', _system);
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: _system});
  };
}

function RefreshSystemSystem(dataSystem) {
  return function(dispatch, getState) {
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }
    _system.system = [dataSystem];
    setStoreData('system_current', _system);
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: _system});
  };
}
function RefreshSystemDevices(dataDevice) {
  return function(dispatch, getState) {
    let _system = getState().SystemsRedux.system;
    let updateDate = getState().SystemsRedux.last_update_data_redux;
    if (!_system) {
      return false;
    }
    let actions = _system.actions.map(item => {
      if (item.device_number === dataDevice.device_number) {
        return dataDevice;
      }
      return item;
    });
    _system.actions = actions;
    setStoreData('system_current', _system);
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: _system, updateDate});
  };
}

export function addNewSensor(appId, params) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    //console.log("params addNewSensor",appId,params);

    return MSystems.addNewEquipment(appId, params).then(data => {
      //console.log("addNewSensor response => ",data)

      dispatch(AddSystemSensor(data.sensor[0]));
      return new Promise((resolve, reject) => {
        resolve(data);
      });
      // dispatch({ type: ACTION_SYSTEMS_CURRENT, system:data });
      // dispatch(AddSystemDevices());
    });
  };
}

export function addNewBand(appId, params) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    //console.log("params addBand",appId,params);
    return MSystems.addBand(appId, params).then(data => {
      //console.log("addNewSensor response => ",data)
      if (data.hasOwnProperty('strips')) {
        dispatch(RefreshSystemStrips(data.strips));
      }
      // dispatch(AddBand(data.strips[0]));
      return new Promise((resolve, reject) => {
        resolve(data);
      });
      // dispatch({ type: ACTION_SYSTEMS_CURRENT, system:data });
      // dispatch(AddSystemDevices());
    });
  };
}

function AddBand(dataStrip) {
  return function(dispatch, getState) {
    //console.log("AddSystemDevices dataDevice ",dataSensor);
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }

    let strips = _system.strips;
    strips.push(dataStrip);
    _system.strips = strips;
    setStoreData('system_current', _system);
    dispatch({type: ACTION_APP_REFRESH});
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: _system});
  };
}

function AddSystemSensor(dataSensor) {
  return function(dispatch, getState) {
    //console.log("AddSystemDevices dataDevice ",dataSensor);
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }
    let sensors = _system.sensors;
    sensors.push(dataSensor);
    _system.sensors = sensors;
    setStoreData('system_current', _system);
    dispatch({type: ACTION_APP_REFRESH});
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: _system});
  };
}
export function addNewEquipment(appId, params) {
  return function(dispatch, getState) {
    let MSystems = new Systems();
    //console.log("params addNewEquipment",appId,params);

    return MSystems.addNewEquipment(appId, params).then(data => {
      //  console.log("addNewEquipment response => ",data)

      dispatch(AddSystemDevices(data.actions[0]));
      return new Promise((resolve, reject) => {
        resolve(data);
      });
      // dispatch({ type: ACTION_SYSTEMS_CURRENT, system:data });
      // dispatch(AddSystemDevices());
    });
  };
}

function AddSystemDevices(dataDevice) {
  return function(dispatch, getState) {
    //console.log("AddSystemDevices dataDevice ",dataDevice);
    let _system = getState().SystemsRedux.system;
    if (!_system) {
      return false;
    }
    let actions = _system.actions;
    actions.push(dataDevice);
    _system.actions = actions;
    setStoreData('system_current', _system);
    dispatch({type: ACTION_APP_REFRESH});
    dispatch({type: ACTION_SYSTEMS_CURRENT, system: _system});
  };
}
