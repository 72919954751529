import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Platform,
  SafeAreaView,
} from 'react-native';

import {connect} from 'react-redux';
import {appHeaderHeight, appHeaderTop} from '../Config/Config';
import {Header, Icon} from 'react-native-elements';
import {fontFamilyBold, fontFamilyRegular} from '../Config/fonts';
import DateTimePicker from '@react-native-community/datetimepicker';
// import {FlatGrid} from 'react-native-super-grid';
import {Button} from 'react-native-elements';
import PropTypes from 'prop-types';
import ThreeDotsLoader from './ThreeDotsLoader';
import DevicesVitesse from './widget/DevicesVitesse';
import HeaderLeftComponent from '../components/HeaderLeftComponent';
import translate, {translateString} from '../translations/translate';
import DateTimePickerModal from "react-native-modal-datetime-picker";

class NewPlage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWait: false,
      isSlotWait: false,
      defaultStartTime: new Date(1598051730000),
      LabelStartTime: '00:00',
      defaultEndTime: new Date(1598051730000),
      LabelEndTime: '00:00',
      speed_val: '0',
      isShowPickerStartTime: false,
      isShowPickerEndTime: false,
      device_number: '',
      itemsDays: [],
    };
  }

  initDays = async () => {
    let items = [
      {
        day: await translateString('Monday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Thuesday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Wednesday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Thursday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Friday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Saturday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Sunday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('SAUVER'),
        isselected: false,
        defaultbg: '#B164C5',
      },
    ];
    this.setState({itemsDays: items, isSlotWait: false});
  };
  componentDidMount() {
    this.initDays();
  }

  componentWillUnmount() {}

  formatTime = date => {
    if (Platform.OS === 'ios') {
      var hours = date.getUTCHours();
      var minutes = date.getUTCMinutes();
    } else {
      var hours = date.getHours();

      var minutes = date.getMinutes();
    }

    minutes = minutes < 10 ? '0' + minutes : minutes;
    hours = hours < 10 ? '0' + hours : hours;
    var strTime = hours + ':' + minutes;
    return strTime;
  };

  setTime = (event, date, field) => {
    if (!date) {
      return false;
    }

    if (field === 'start_time') {
      this.setState({
        defaultStartTime: date,
        isShowPickerStartTime: false,
        LabelStartTime: this.formatTime(date),
      });
    } else {
      this.setState({
        defaultEndTime: date,
        isShowPickerEndTime: false,
        LabelEndTime: this.formatTime(date),
      });
    }
    // console.log("field ",field,"date ",typeof date)
  };

  selectDay = index => {
    let _selectDay = this.state.itemsDays;
    if (index === 7) {
      let obj = {
        days: _selectDay,
        defaultStartTime: this.state.defaultStartTime,
        LabelStartTime: this.state.LabelStartTime,
        defaultEndTime: this.state.defaultEndTime,
        LabelEndTime: this.state.LabelEndTime,
        speed_val: this.state.speed_val,
      };

      this.setState({isSlotWait: true});

      this.props.AddNewPlage(obj);
      this.props.back();
    } else {
      // console.log("index ",index);

      _selectDay[index].isselected = !_selectDay[index].isselected;
      _selectDay[index].defaultbg = _selectDay[index].isselected
        ? '#2B8FED'
        : '#242424';
      this.setState({itemsDays: _selectDay});
    }
  };

  renderTimePickerStartTime = () => {
    // console.log("this.state.isShowPickerStartTime ",this.state.isShowPickerStartTime)
    if (this.state.isShowPickerStartTime) {
      if (Platform.OS === 'ios') {
        return (
              <DateTimePickerModal
                testID="dateTimePicker"
                isVisible={true}
                timeZoneOffsetInMinutes={0}
                value={this.state.defaultStartTime}
                mode={'time'}
                is24Hour={true}
                display="default"
                textColor="#000"
                neutralButtonLabel="clear"
                onCancel={() => this.setState({isShowPickerStartTime: false})}
                onConfirm={date => {
                  this.setTime(undefined, date, 'start_time');
                }}
              />
        );
      } else {
        return (
          <DateTimePicker
            testID="dateTimePicker"
            timeZoneOffsetInMinutes={0}
            value={this.state.defaultStartTime}
            mode={'time'}
            is24Hour={true}
            display="default"
            textColor="#fff"
            neutralButtonLabel="clear"
            onChange={(event, date) => {
              this.setState({isShowPickerStartTime: false});
              this.setTime(event, date, 'start_time');
            }}
          />
        );
      }
    }
  };
  renderTimePickerEndTime = () => {
    if (this.state.isShowPickerEndTime) {
      if (Platform.OS === 'ios') {
        return (
              <DateTimePickerModal
                isVisible={true}
                testID="dateTimePicker"
                timeZoneOffsetInMinutes={0}
                value={this.state.defaultEndTime}
                mode={'time'}
                is24Hour={true}
                display="default"
                textColor="#000"
                onCancel={() => this.setState({isShowPickerEndTime: false})}
                onConfirm={date => {
                  this.setTime(undefined, date, 'end_time');
                }}
              />
        );
      } else {
        return (
          <DateTimePicker
            testID="dateTimePicker"
            timeZoneOffsetInMinutes={0}
            value={this.state.defaultEndTime}
            mode={'time'}
            is24Hour={true}
            display="default"
            textColor="#fff"
            onChange={(event, date) => {
              this.setState({isShowPickerEndTime: false});
              this.setTime(event, date, 'end_time');
            }}
          />
        );
      }
    }
  };

  renderSpeed = () => {
    if (
      this.props.device_speed === '-1' ||
      this.props.device_speed_max === '-1' ||
      parseInt(this.props.device_speed_max) <= 0 ||
      this.props.device_speed_min === '-1' ||
      this.props.device_speed_gap === '-1'
    ) {
      return null;
    }

    return (
      <View style={styles.containerGrid}>
        <View>
          <Text style={styles.texttitre}>{translate('Sequence')} :</Text>
        </View>
        <View style={{width: '100%', paddingTop: 15}}>
          <DevicesVitesse
            fontsize={15}
            defaultVal={this.props.device_speed}
            max={this.props.device_speed_max}
            min={this.props.device_speed_min}
            gap={this.props.device_speed_gap}
            onChange={val => {
              this.setState({speed_val: val});
            }}
            showFooter={true}
          />
        </View>
      </View>
    );
  };
  render() {
    return (
      <SafeAreaView style={styles.containerfull}>
        <Header
          containerStyle={{
            backgroundColor: '#000',
            height: appHeaderHeight(),
            paddingTop: 0,
            margin: 0,
            borderBottomColor: '#000',
          }}
          leftComponent={
            <HeaderLeftComponent
              navigation={this.props.navigation}
              goToBack={this.props.back}
            />
          }
        />

        <ScrollView contentContainerStyle={{flexGrow: 1}}>
          <View>
            <Text style={styles.enteteText}>{this.props.device_name}</Text>
          </View>

          <View>{this.state.isSlotWait ? <ThreeDotsLoader /> : <View />}</View>
          <View style={styles.container}>
            <View style={styles.containerGrid}>
              <View
                style={{
                  flexDirection: 'row',
                  padding: 20,
                  borderBottomWidth: 1,
                  borderBottomColor: '#ccc',
                }}>
                <View style={{flex: 1}}>
                  <Text style={styles.textTime}>
                    {translate('from')}
                  </Text>
                </View>
                <View style={{flex: 1}}>
                  <Text
                    style={styles.textTime}
                    onPress={() => {
                      this.setState({
                        isShowPickerStartTime: !this.state
                          .isShowPickerStartTime,
                      });
                    }}>
                    {this.state.LabelStartTime}
                  </Text>
                </View>
              </View>
              {this.renderTimePickerStartTime()}
              <View style={{flexDirection: 'row', padding: 20}}>
                <View style={{flex: 1}}>
                  <Text style={styles.textTime}>{translate('to')}</Text>
                </View>
                <View style={{flex: 1}}>
                  <Text
                    style={styles.textTime}
                    onPress={() => {
                      this.setState({
                        isShowPickerEndTime: !this.state.isShowPickerEndTime,
                      });
                    }}>
                    {this.state.LabelEndTime}
                  </Text>
                </View>
              </View>
              {this.renderTimePickerEndTime()}
            </View>
            {this.renderSpeed()}
            <View style={styles.containerGrid30}>
              <View>
                <Text style={styles.texttitre}>
                  {translate('recurrence')} :
                </Text>
              </View>
              <View>
                {/*<FlatGrid
                  items={this.state.itemsDays}
                  renderItem={({item, index}) => (
                    <View style={[styles.itemContainer]}>
                      <Button
                        onPress={() => {
                          this.selectDay(index);
                        }}
                        buttonStyle={[
                          styles.BtnButton,
                          {backgroundColor: item.defaultbg},
                        ]}
                        title={item.day}
                      />
                    </View>
                  )}
                />*/}
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  containerfull: {
    flex: 1,
    backgroundColor: '#000',
  },
  enteteText: {
    fontSize: 30,
    fontWeight: '300',
    padding: 0,
    textAlign: 'center',
    color: '#ffffff',
    fontFamily: fontFamilyRegular(),
  },
  container: {},
  containerGrid: {
    flex: 1,
    backgroundColor: '#121212',
    padding: 10,
    margin: 10,
    borderRadius: 10,
  },
  containerGrid30: {
    flex: 1,
    backgroundColor: '#121212',
    padding: 30,
    margin: 10,
    borderRadius: 10,
  },
  textTime: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 18,
    fontFamily: fontFamilyRegular(),
  },
  texttitre: {
    color: '#fff',
    fontSize: 18,
    fontFamily: fontFamilyBold(),
  },
  itemContainer: {
    padding: 5,
  },
  BtnButton: {
    backgroundColor: 'transparent',
    fontFamily: fontFamilyRegular(),
    fontSize: 12,
    borderRadius: 50,
    width: '100%',
  },
});

NewPlage.propTypes = {
  device_speed: PropTypes.number,
  device_speed_min: PropTypes.number,
  device_speed_max: PropTypes.number,
  device_speed_unit: PropTypes.number,
  device_speed_gap: PropTypes.number,
  back: PropTypes.func,
  AddNewPlage: PropTypes.func,
  device_name: PropTypes.string,
};

NewPlage.defaultProps = {
  device_speed: '',
  device_speed_min: '',
  device_speed_max: '',
  device_speed_unit: '',
  device_speed_gap: '',
  back: () => {},
  AddNewPlage: () => {},
  device_name: '',
};
const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewPlage);
