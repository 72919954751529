// Imports
var ___CSS_LOADER_API_IMPORT___ = require("./node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("./node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./App/assets/fonts/arial.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./App/assets/fonts/helvetica-neue-bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./App/assets/fonts/helvetica-neue-light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./App/assets/fonts/helvetica-neue.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./App/assets/fonts/arial-bold.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./App/assets/fonts/arial-black.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face {\n    font-family: Arial;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n    font-family: Helvetica-neue-bold;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n@font-face {\n    font-family: Helvetica-neue-light;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\n@font-face {\n    font-family: Helvetica-neue;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n\n@font-face {\n    font-family: Arial-bold;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n\n@font-face {\n    font-family: Arial-black;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n}", ""]);
// Exports
module.exports = exports;
