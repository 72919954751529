import React from 'react';
import {StyleSheet, Text, View, Modal, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import {Icon, Input, Button} from 'react-native-elements';
import translate from '../../translations/translate';
import PropTypes from 'prop-types';
import {fontFamilyBold} from '../../Config/fonts';
import Systems from '../../Models/Systems';
import {ShowSnaskMessage, msg_code_to_text} from '../../Config/Config';

class MachineUnlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      system: null,
      modalVisible: props.isOpen,
      iswait: false,
      code: '',
      formData: {
        code: null,
      },
      errors: {
        code: null,
      },
    };
  }

  getSystem = () => {
    if (
      this.props.current_system.hasOwnProperty('system') &&
      this.props.current_system.system.length > 0
    ) {
      this.setState({system: this.props.current_system.system[0]});
    }
  };

  componentDidMount() {
    this.getSystem();
  }

  componentWillUnmount() {}

  SubmitBtn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      this.state.system.sys_code &&
      this.state.formData.code === this.state.system.sys_code
    ) {
      this.setModalVisible(false);
      this.props.callback();
    } else {
      this.setState({errors: {code: true}});
    }
  };

  setModalVisible = stateModal => {
    this.props.closeModal();

    this.OpenInfo(stateModal);
    // this.setState({modalVisible:stateModal});
  };
  OpenInfo = stateModal => {
    this.props.closeModal();
    this.setState({modalVisible: stateModal});
  };
  onChangeField = (fieldName, val) => {
    let _formData = this.state.formData;
    _formData[fieldName] = val;
    this.setState({formData: _formData});
  };
  requestAccess = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({isWait: true});
    const s = new Systems();

    s.requestAccess(this.state.system.sys_appid, this.state.system.sys_appid)
      .then(data => {
        this.setState({isWait: false, code: data});

        ShowSnaskMessage({
          message: 'Opération effectuée avec succès',
          textColor: '#fff',
          backgroundColor: '#7ED321',
          buttonColor: '#7ED321',
          duration: 2000,
        });
      })
      .catch(error => {
        this.setState({isWait: false});
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });
      });
  };

  render() {
    return (
      <View>
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.props.isOpen}
          dismiss={this.setModalVisible}>
          <TouchableOpacity
            style={styles.Modalcontainer}
            onPress={() => {
              // this.setModalVisible(!this.state.modalVisible);
            }}>
            <View
              style={{
                justifyContent: 'center',
                backgroundColor: '#fff',
                borderRadius: 20,
                padding: 15,
                width: '90%',
              }}>
              <View style={{flexDirection: 'row'}}>
                <Text style={{flex: 4}}>
                  {translate('to_unlock_expert_mode')}
                </Text>
                <View style={{flex: 0.5}}>
                  <Icon
                    reverse
                    size={13}
                    color="#289EFF"
                    activeOpacity={1}
                    containerStyle={{}}
                    name="ios-american-football"
                    type="font-awesome"
                    onPress={() => this.OpenInfo(true)}
                  />
                </View>
              </View>
              <Input
                label={translate('Entre le code')}
                labelStyle={styles.FormInputlabelStyle}
                keyboardType={'numeric'}
                inputStyle={[
                  styles.FormInputContainerStyle,
                  this.state.errors.code ? styles.FormTextInputError : {},
                ]}
                placeholder="Code"
                onChangeText={val => this.onChangeField('code', val)}
              />
              <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <Button
                  loading={this.state.iswait}
                  onPress={this.SubmitBtn}
                  buttonStyle={styles.BtnButton}
                  title={translate('Valider et continuer', { color: 'white'})}
                />
              </View>
              <Text>{translate('access_to_admin_space_detail')}</Text>
            </View>
          </TouchableOpacity>
        </Modal>

        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.modalVisible}
          dismiss={this.setModalVisible}>
          <TouchableOpacity
            style={styles.Modalcontainer}
            onPress={() => {
              // this.setModalVisible(!this.state.modalVisible);
            }}>
            <View
              style={{
                justifyContent: 'center',
                backgroundColor: '#fff',
                borderRadius: 20,
                padding: 15,
                width: '90%',
              }}>
              <Text>{translate('demand_explanations')}</Text>
              <Text
                style={{
                  textAlign: 'center',
                  fontFamily: fontFamilyBold(),
                  fontSize: 18,
                  marginTop: 20,
                }}>
                {translate('do_the_demand')}
              </Text>
              {!!this.state.code && (
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: fontFamilyBold(),
                    fontSize: 18,
                    marginTop: 20,
                  }}>
                  {translate('code')}: {this.state.code}
                </Text>
              )}
              <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <Button
                  loading={this.state.iswait}
                  onPress={this.requestAccess}
                  buttonStyle={styles.BtnButton}
                  title={translate('send_demand')}
                />
              </View>
              <Text>{translate('demand_instructions')}</Text>
            </View>
          </TouchableOpacity>
        </Modal>
      </View>
    );
  }
}

MachineUnlock.propTypes = {
  system: PropTypes.object,
  isOpen: PropTypes.bool,
  callback: PropTypes.func,
  closeModal: PropTypes.func,
};
MachineUnlock.defaultProps = {
  system: {
    sys_code: null,
  },
  isOpen: false,
  callback: () => {},
  closeModal: () => {},
};
const styles = StyleSheet.create({
  Modalcontainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(154, 152, 152, 0.84)',
    flexDirection: 'column',
  },
  FormInputlabelStyle: {
    color: '#000',
    fontSize: 16,
    marginTop: 20,
    marginBottom: 20,
    fontFamily: fontFamilyBold(),
    fontWeight: 'normal',
    textAlign: 'center',
  },
  FormInputContainerStyle: {
    textAlign: 'center',
  },
  BtnButton: {
    backgroundColor: '#2B89E7',
    fontSize: 12,
    textAlign: 'center',
    borderRadius: 50,
    marginTop: 20,
    marginBottom: 20,
  },
  FormTextInputError: {
    borderColor: '#D80000',
    backgroundColor: 'rgba(255, 0, 0, 0.22)',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    current_system: state.SystemsRedux.system,
    user: state.LoginReducer.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MachineUnlock);
