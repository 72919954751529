import React from 'react';
import {StyleSheet, Text, View, Button, ActivityIndicator} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

class Loader extends React.Component {
  render() {
    if (this.props.isloader) {
      return (
        <View style={[styles.containerspinner, styles.horizontalspinner]}>
          <ActivityIndicator size={this.props.size} color="#0000ff" />
        </View>
      );
    }
    return null;
  }
}

Loader.propTypes = {
  isloader: PropTypes.bool,
  size: PropTypes.any,
};
Loader.defaultProps = {
  isloader: false,
  size: 100,
};
const styles = StyleSheet.create({
  containerspinner: {
    zIndex: 999,
    flex: 1,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
  horizontalspinner: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Loader);
