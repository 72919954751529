import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {fontFamilyBold} from '../../Config/fonts';
import DevicesVitesse from './DevicesVitesse';
import * as actions from '../../Store/Systems/SystemsActions';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  showSnaskMessageOperationSuccess,
} from '../../Config/Config';
import translate from '../../translations/translate';

class Vitesse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWait: false,
      device_speed: props.device_speed,
    };
  }

  componentDidUpdate(prevProps) {
    const {last_update_data_redux_system, last_update_data_redux} = this.props;

    if (prevProps.last_update_data_redux_system !== last_update_data_redux_system || prevProps.last_update_data_redux !== last_update_data_redux) {
      this.initData();
    }
  }

  componentWillUnmount() {}

  initData = () => {
    this.setState({device_speed: this.props.device_speed});
  };

  render() {
    //console.log("this.props.device_speed ",this.props.device_speed);
    if (
      this.props.device_speed === '-1' ||
      this.props.device_speed_max === '-1' ||
      parseInt(this.props.device_speed_max) <= 0 ||
      this.props.device_speed_min === '-1' ||
      this.props.device_speed_gap === '-1'
    ) {
      return null;
    }

    // console.log("this.props.device_speed ",this.props.device_speed)
    return (
      <View style={styles.container}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <Text
            style={{
              color: '#fff',
              fontSize: 18,
              fontFamily: fontFamilyBold(),
              marginBottom: 10,
            }}>
            {translate('sequence')} : {this.state.device_speed} {this.props.device_speed_unit}
          </Text>
          {this.props.device_text ? translate(this.props.device_text, styles.deviceText) : false}
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
          }}>
          <View style={{width: '70%'}}>
            <DevicesVitesse
              fontsize={15}
              defaultVal={this.props.device_speed}
              max={this.props.device_speed_max}
              min={this.props.device_speed_min}
              gap={this.props.device_speed_gap}
              onChange={val => {
                this.UpdateDevices('device_speed', val);
              }}
              showFooter={false}
            />
          </View>
        </View>
        <View style={{flexDirection: 'row', padding: 10}}>
          <View style={{flex: 1}}>
            <Text style={{color: '#fff'}}>
              {this.props.device_speed_min} {this.props.device_speed_unit}
            </Text>
          </View>
          <View style={{flex: 1}}>
            <Text style={{color: '#fff', textAlign: 'right'}}>
              {this.props.device_speed_max} {this.props.device_speed_unit}
            </Text>
          </View>
        </View>
      </View>
    );
  }

  UpdateDevices = (field, val) => {
    this.setState({[field]: val});

    this.props
      .UpdateDevice(this.props.sys_appid, {
        key: 'device_speed',
        value: val,
        number: this.props.device_number,
      })
      .then(() => {
        this.setState({isWait: false});
        this.props.SystemDetail(this.props.sys_appid);
        this.props.refeshDevice();
        showSnaskMessageOperationSuccess();
      })
      .catch(error => {
        this.setState({default_sensor: this.props.default_sensor});
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };
}

Vitesse.propTypes = {
  device_number: PropTypes.string,
  device_speed: PropTypes.string,
  device_speed_min: PropTypes.string,
  device_speed_max: PropTypes.string,
  device_speed_unit: PropTypes.string,
  device_speed_gap: PropTypes.string,
  sys_appid: PropTypes.array,
};

Vitesse.defaultProps = {
  device_number: '',
  device_speed: '',
  device_speed_min: '',
  device_speed_max: '',
  device_speed_unit: '',
  device_speed_gap: '',
  sys_appid: '',
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#121212',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
  },
  deviceText: {
    color: 'white',
    marginBottom: 10,
    marginLeft: '50%',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux: state.AppRedux.last_update_data_redux,
    last_update_data_redux_system: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },

    SystemDetail: (appId, params) => {
      return dispatch(actions.SystemDetail(appId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Vitesse);
