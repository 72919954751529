import * as RNLocalize from 'react-native-localize';

import Registration from '../../Models/Registration';
import {ACTION_USER_SET_APPID} from '../Login/LoginActions';

export const ACTION_REGISTER_USER_STEP1 = 'REGISTER_USER_STEP1';
export const ACTION_REGISTER_USER_STEP2 = 'REGISTER_USER_STEP2';
export const ACTION_REGISTER_USER_STEP3 = 'REGISTER_USER_STEP3';
export const ACTION_REGISTER_USER_STEP4 = 'REGISTER_USER_STEP4';

import {get_base_list} from '../App/AppActions';

export function RegisterStep1(formData) {
  return function(dispatch, getState) {
    let MRegistration = new Registration();
    return MRegistration.SetProfile(null, formData.user, formData.pwd, {
      user_type: formData.user_type,
    }).then(data => {
      dispatch({type: ACTION_USER_SET_APPID, appId: data.token[0].appid});
      dispatch({type: ACTION_REGISTER_USER_STEP1, step1: data});
    });
  };
}

export function RegisterSubUserStep1(appid, formData) {
  return function(dispatch, getState) {
    let MRegistration = new Registration();
    return MRegistration.SetProfile(appid, formData.user, formData.pwd, {
      user_type: formData.user_type,
      sys_id: formData.sysId,
    }).then(data => {
      // dispatch({type: ACTION_USER_SET_APPID, appId: data.token[0].appid});
      dispatch({type: ACTION_REGISTER_USER_STEP1, step1: data});
    });
  };
}

export function RegisterStep2(formData, appid) {
  return function(dispatch, getState) {
    const local = RNLocalize.getLocales()[0].languageCode;
    let MRegistration = new Registration();
    return MRegistration.SetProfile(appid, null, null, {
      user_lang: local,
      ...formData,
    })
      .then(data => {
        dispatch(get_base_list(data.token[0].appid));
        dispatch({type: ACTION_REGISTER_USER_STEP2, step2: data});
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export function RegisterStep3(formData, appid) {
  return function(dispatch, getState) {
    let MRegistration = new Registration();
    return MRegistration.SetController(appid, null, null, formData).then(
      data => {
        dispatch(get_base_list(data.token));
        dispatch({type: ACTION_USER_SET_APPID, appId: data.token});
        dispatch({type: ACTION_REGISTER_USER_STEP3, step3: data});
      },
    );
  };
}

export function RegisterStep4(formData, appid) {
  return function(dispatch, getState) {
    let MRegistration = new Registration();
    return MRegistration.SetController(appid, null, null, formData).then(
      data => {
        dispatch({type: ACTION_REGISTER_USER_STEP4, step4: data});
      },
    );
  };
}
