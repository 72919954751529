import React from 'react';
import {StyleSheet, Text, View, Dimensions} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {fontFamilyArialBlack, fontFamilyBold} from '../../Config/fonts';

const WidgetCard = ({children}) => {
  return (
    <LinearGradient
      colors={['#121212', '#3A57FB']}
      style={styles.containerLinearGradient}>
      <View style={styles.container}>
        <View style={styles.containerFixed}>
          <View style={{width: 50, backgroundColor: '#3A57FB', height: 2}} />
          {children}
        </View>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  topViewtitle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerFixed: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titrewidget: {
    textAlign: 'center',
    fontSize: 40,
    color: '#1E1E1E',
    fontFamily: fontFamilyArialBlack(),
    textTransform: 'uppercase',
    marginTop: 10,
  },
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,
    height: 120,
  },
  containerLinearGradient: {
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
    padding: 1,
    marginTop: 20,
  },
  CircleShapeView: {
    width: 10,
    height: 10,
    borderRadius: 10 / 2,
    borderWidth: 1,
    borderColor: '#BD10E0',
    backgroundColor: '#BD10E0',
  },
});

export default WidgetCard;
