import React from 'react';
import {StyleSheet, Text, View, Button, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import GraphSensor from './widget/GraphSensor';
import Temperature from './widget/Temperature';
import FlowSwitch from './widget/FlowSwitch';
import SimpleSensor from './widget/SimpleSensor';
import GaugeSensor from './widget/GaugeSensor';

class WidgetSensor extends React.Component {
  componentDidMount() {}

  componentWillUnmount() {}

  renderByType = () => {
    //console.log("renderByType ");
    const {sensor_widget, sensor_hash, sensor_number} = this.props.sensor;

    switch (parseInt(sensor_widget)) {
      case 0:
        //curve
        return <FlowSwitch {...this.props.sensor} />;
      case 1:
        //graph
        return <GraphSensor {...this.props.sensor} />;
      case 2:
        //round
        return <Temperature {...this.props.sensor} />;
      case 3:
        //Simple
        return <SimpleSensor {...this.props.sensor} />;
      case 4:
        //Gauge
        return (
          <GaugeSensor
            navigation={this.props.navigation}
            {...this.props.sensor}
          />
        );
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <TouchableOpacity
          style={{paddingBottom: 20}}
          onPress={() => {
            this.props.OpenDetailSenssor(this.props.sensor);
          }}>
          {this.renderByType()}
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

WidgetSensor.propTypes = {
  sensor: PropTypes.object,
  current_system: PropTypes.object,
  base_list: PropTypes.array,
  device_list: PropTypes.array,
  slots: PropTypes.array,
  navigation: PropTypes.any,
  OpenDetailSenssor: PropTypes.func,
};

WidgetSensor.defaultProps = {
  sensor: {},
  navigation: {},
  current_system: {},
  device_list: [],
  slots: [],
  OpenDetailSenssor: () => {},
};
const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux_app: state.AppRedux.last_update_data_redux,
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetSensor);
