import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
} from 'react-native';
import {Header, Icon, Image, Button} from 'react-native-elements';
import translate, {translateString} from '../translations/translate';
import ImageResizer from 'react-native-image-resizer';
import {
  appHeaderHeight,
  APP_BAR_ICON_SIZE,
  LEFT_HIT_SLOP,
} from '../Config/Config';
import ImagePicker from 'react-native-image-picker';
import * as Progress from 'react-native-progress';

// import RNFS from 'react-native-fs';

import HeaderLeftComponent from '../components/HeaderLeftComponent';
import {removeStoreData} from '../Config/Config';

class Scan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colors: [],
      loading: false,
      error: '',
      loadingValue: 0,
      loadingText: '',
    };
  }

  componentDidMount() {
    // this.SelectImage();
  }

  SelectImage = async () => {
    const options = {
      title: await translateString('Strip scan'),
      takePhotoButtonTitle: await translateString('Take photo'),
      chooseFromLibraryButtonTitle: await translateString(
        'Choose from library',
      ),
      storageOptions: {
        skipBackup: true,
        rotation: 360,
        originalRotation: 360,
        path: 'images',
        image: '',
      },
    };

    this.setState({
      loadingValue: 0,
      loading: true,
      image: '',
      colors: [],
      errors: '',
    });

    ImagePicker.showImagePicker(options, async response => {
      // console.log('Response = ', response);

      if (response.didCancel) {
        console.log('User cancelled image picker');
      } else if (response.error) {
        console.log('ImagePicker Error: ', response.error);
      } else {
        this.setState({
          loadingText: await translateString('Image resizing...'),
        });
        let rotation = 0;

        if (response.originalRotation === 90) {
          rotation = 90;
        } else if (response.originalRotation === 270) {
          rotation = -90;
        }

        const r = await ImageResizer.createResizedImage(
          response.uri,
          2000,
          2000,
          'JPEG',
          100,
          rotation,
        );
        this.setState({
          loadingText: await translateString('Converting image...'),
          loadingValue: 0.1,
        });
        // const base64 = await RNFS.readFile(r.uri, 'base64');

        this.setState({
          loadingText: await translateString('Sending image...'),
          loadingValue: 0.2,
        });
        try {
          await removeStoreData('BandResults');
          this.setState({
            loadingText: await translateString('AI connection in progress...'),
            loadingValue: 0.5,
          });
          const to = setInterval(() => {
            this.setState({
              loadingValue:
                this.state.loadingValue < 0.8
                  ? this.state.loadingValue + 0.1
                  : this.state.loadingValue,
            });
          }, 500);
          const resp = await fetch('https://strip.swimo.io', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              image: undefined,
            }),
          }).then(b => {
            clearInterval(to);
            this.setState({
              loadingValue: 0.9,
            });
            return b.json();
          });

          if (!resp.error) {
            this.setState({
              loadingValue: 1,
            });
            this.props.navigation.navigate('BandResults', {
              results:
                resp.values.length === 6 ? resp.values : [resp.values[0]],
              id: resp.id,
            });
          } else {
            clearInterval(to);
            this.setState({
              loadingText: '',
            });
          }

          this.setState({
            loading: false,
            error: resp.error,
            colors: resp.colors,
            image: resp.image,
          });
        } catch (err) {
          console.log(err);
          this.setState({loading: false, error: err.toString()});
        }
      }

      this.setState({loading: false});
    });
  };

  render() {
    const windowWidth = Dimensions.get('window').width;
    const windowHeight = Dimensions.get('window').height;

    const fontSize = windowHeight <= 750 ? 12 : 15;
    return (
      <SafeAreaView>
        <ScrollView style={styles.containerfull}>
          <Header
            containerStyle={{
              backgroundColor: '#289EFF',
              height: appHeaderHeight(),
              paddingTop: 0,
              margin: 0,
              borderBottomColor: '#289EFF',
            }}
            leftComponent={
              <HeaderLeftComponent
                navigation={this.props.navigation}
                goToBack={this.props.navigation.goBack}
              />
            }
            rightComponent={
              <TouchableOpacity
                hitSlop={LEFT_HIT_SLOP}
                onPress={() => this.props.navigation.navigate('BandResults')}
                style={{paddingLeft: 5, paddingRight: 5}}>
                <Icon
                  name="chevron-right"
                  type="font-awesome"
                  color="#fff"
                  size={APP_BAR_ICON_SIZE}
                />
              </TouchableOpacity>
            }
          />
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: windowHeight - 100,
              justifyContent: 'space-between',
            }}>
            <View>
              <Text style={styles.title}>{translate('Strip scan')}</Text>
              {this.state.error ? (
                <Text style={{color: 'red'}}>Error: {this.state.error}</Text>
              ) : (
                false
              )}
              {this.state.loading ? (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                  }}>
                  <Text
                    style={{
                      color: 'white',
                      fontSize: 14,
                      textTransform: 'uppercase',
                    }}>
                    {this.state.loadingText}
                  </Text>
                  <Progress.Bar
                    progress={this.state.loadingValue}
                    width={windowWidth / 2}
                    borderColor="white"
                    unfilledColor="#289EFF"
                    color="white"
                    height={10}
                  />
                </View>
              ) : (
                <View>
                  <Text style={{fontSize, ...styles.text}}>
                    {translate('strip_scan_txt1')}
                  </Text>
                  <Text style={{fontSize, ...styles.text}}>
                    {translate('strip_scan_txt2')}
                  </Text>
                  <Text style={{fontSize, ...styles.text}}>
                    {translate('strip_scan_txt3')}
                  </Text>
                </View>
              )}
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                {false && this.state.colors.length
                  ? this.state.colors.map(c => (
                      <View
                        style={{
                          width: 100,
                          height: 100,
                          marginBottom: 5,
                          backgroundColor: `rgb(${c[2]}, ${c[1]}, ${c[0]})`,
                        }}>
                        <Text>{`rgb(${Math.round(c[2])}, ${Math.round(
                          c[1],
                        )}, ${Math.round(c[0])})`}</Text>
                      </View>
                    ))
                  : false}
                {this.state.image ? (
                  <Image
                    style={{
                      width: 500,
                      height: 500,
                      borderWidth: 1,
                      borderColor: 'black',
                    }}
                    source={{uri: 'data:image/png;base64,' + this.state.image}}
                  />
                ) : (
                  false
                )}
              </View>
            </View>
            {!this.state.loading ? (
              <Button
                loading={this.state.loading}
                containerStyle={{
                  paddingLeft: 30,
                  paddingRight: 30,
                  maxWidth: 400,
                  alignSelf: 'center',
                }}
                buttonStyle={styles.btninfo}
                titleStyle={{color: '#289EFF'}}
                title={translate('Scan')}
                onPress={() => {
                  this.SelectImage();
                }}
              />
            ) : (
              false
            )}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  containerfull: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#289EFF',
    height: '100%',
  },
  btninfo: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    borderWidth: 0.5,
    borderColor: '#000',
    color: 'black',
    backgroundColor: 'white',
    borderRadius: 50,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 50,
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    alignSelf: 'center',
    color: 'white',
    marginTop: 20,
    marginBottom: 20,
  },
  text: {
    color: 'white',
    marginTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 20,
  },
});

Scan.propTypes = {};
Scan.defaultProps = {};

export default Scan;
