import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Modal,
  Platform,
  TouchableOpacity,
} from 'react-native';
import {connect} from 'react-redux';
import {ButtonGroup, Icon} from 'react-native-elements';
import {fontFamilyBold} from '../../Config/fonts';
import PropTypes from 'prop-types';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  showSnaskMessageOperationSuccess,
} from '../../Config/Config';
import * as actions from '../../Store/Systems/SystemsActions';
import ThreeDotsLoader from '../ThreeDotsLoader';
import NewPlage from '../NewPlage';
import ModalDateTimePicker from '../ModalDateTimePicker';
import translate, {translateString} from '../../translations/translate';
class Programmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWait: false,
      isSlotWait: false,
      modaldefaulTime: false,
      modalFieldTime: false,
      isSlotWaitToRemove: false,
      isOpenModelDateTime: false,
      selectedSlotIndex: null,
      defaulTime: new Date(1598051730000),
      NewPlage: false,
      btnsDisplay: [],
      SelectedBtn: 0,
      AvailableVal: [],
    };
  }
  componentDidMount() {
    this.initData();
    this.initDays();
  }

  componentWillUnmount() {}

  componentDidUpdate(nextProps) {
    const {device_sequence} = this.props;

    if (nextProps.device_sequence !== device_sequence) {
      this.initData();
    }
  }

  initDays = async () => {
    let items = [
      {
        day: await translateString('Monday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Thuesday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Wednesday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Thursday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Friday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Saturday'),
        isselected: false,
        defaultbg: '#242424',
      },
      {
        day: await translateString('Sunday'),
        isselected: false,
        defaultbg: '#242424',
      },
    ];

    this.setState({itemsDays: items, isSlotWait: false});
  };

  initData = async () => {
    let _listBtn = this.props.device_sequence_display.split('/');
    let _listBtnAva = this.props.device_sequence_available.split('/');
    let defaultIndex = parseInt(this.props.device_sequence);

    for (let i = 0; i < _listBtn.length; i++) {
      _listBtn[i] = await translateString(_listBtn[i]);
    }
    //console.log(this.props.slots);
    this.setState({
      btnsDisplay: _listBtn,
      SelectedBtn: defaultIndex,
      AvailableVal: _listBtnAva,
      slots: this.props.slots,
      device_number: this.props.device_number,
    });
  };

  Onchange = selectedIndex => {
    // this.setState({SelectedBtn:selectedIndex})

    this.UpdateStatusDevices(selectedIndex);
  };

  UpdateStatusDevices = val => {
    if (this.state.isWait) {
      return true;
    }
    this.setState({isWait: true});
    // console.log(this.props.sys_appid)
    this.props
      .UpdateDevice(this.props.sys_appid, {
        key: 'device_sequence',
        value: val,
        number: this.state.device_number,
      })
      .then(data => {
        this.setState({SelectedBtn: val});
        this.setState({isWait: false});
        this.props.refeshDevice();
        showSnaskMessageOperationSuccess();
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };

  renderPlage = (slot, index) => {
    let slot_start = slot.slot_start.replace(':', 'H');
    let slot_stop = slot.slot_stop.replace(':', 'H');
    let plageindex = index + 1;

    return (
      <View style={styles.itemp}>
        <View style={{position: 'absolute', right: 5, top: 5}}>
          <TouchableOpacity
            onPress={() => this.removeSlot(index)}
            style={{padding: 5}}>
            <Icon
              size={15}
              containerStyle={{marginLeft: 10}}
              name="times"
              type="font-awesome"
              color="#fff"
            />
          </TouchableOpacity>
        </View>
        <View style={{flexDirection: 'row', marginTop: 30, marginBottom: 10}}>
          <Text style={{flex: 4, color: '#fff', padding: 5}}>
            {translate('plage')}
            {plageindex}
          </Text>
          <View
            style={{
              flex: 3,
              color: '#fff',
              padding: 5,
              flexDirection: 'row',
              marginRight: 40,
            }}>
            <Text
              style={{
                color: '#fff',
                fontSize: 18,
                marginRight: 10,
                marginLeft: 10,
              }}
              onPress={() => {
                this.setState({
                  isOpenModelDateTime: true,
                  modalFieldTime: 'slot_start',
                  selectedSlotIndex: index,
                });
              }}>
              {slot_start}
            </Text>
            <Text style={{color: '#fff', fontSize: 18}}> - </Text>
            <Text
              style={{
                color: '#fff',
                fontSize: 18,
                marginRight: 10,
                marginLeft: 10,
              }}
              onPress={() => {
                this.setState({
                  isOpenModelDateTime: true,
                  modalFieldTime: 'slot_stop',
                  selectedSlotIndex: index,
                });
              }}>
              {slot_stop}
            </Text>
          </View>
        </View>

        <ButtonGroup
          onPress={Selectedindex => this.OnchangeSlotDay(index, Selectedindex)}
          buttonStyle={[styles.BtnChoiseDaydefault]}
          innerBorderStyle={{width: 0}}
          selectedButtonStyle={[styles.BtnChoise, styles.BtnChoiseSelected]}
          textStyle={[styles.BtnChoiseDayStyle]}
          selectedTextStyle={[styles.BtnChoiseSelected]}
          containerStyle={{backgroundColor: 'transparent', borderWidth: 0}}
          selectedIndexes={slot.selectedDay}
          selectMultiple={true}
          buttons={
            this.state.itemsDays
              ? this.state.itemsDays.map(id => id.day.substring(0, 3))
              : []
          }
        />
      </View>
    );
  };

  renderPlages = () => {
    if (Array.isArray(this.state.slots)) {
      return this.state.slots.map((slot, index) => {
        // console.log(slot);
        return this.renderPlage(slot, index);
      });
    }
  };

  OnchangeSlotDay = (arrayindex, selectedIndex) => {
    let _slots = this.state.slots;
    _slots[arrayindex].selectedDay = selectedIndex;
    this.setState({slots: _slots});
    this.updateSlotDay(_slots[arrayindex]);
  };

  UpdateDevicesByField = (_field, _val, _number) => {
    this.setState({isWait: true});
    // console.log(this.props.sys_appid)
    this.props
      .UpdateDevice(this.props.sys_appid, {
        key: _field,
        value: _val,
        number: _number,
      })
      .then(data => {
        this.setState({isWait: false});
        this.props.refeshDevice();
        showSnaskMessageOperationSuccess();
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };
  updateSlotDay = slot => {
    let dys = '';
    slot.selectedDay.map(d => {
      dys = dys + '' + (d + 1);
    });
    this.UpdateDevicesByField('slot_days', dys, slot.slot_id);
  };

  renderBtn = () => {
    if (this.state.btnsDisplay.length > 1) {
      return (
        <ButtonGroup
          onPress={this.Onchange}
          buttonStyle={styles.BtnChoise}
          innerBorderStyle={{width: 0}}
          selectedButtonStyle={[styles.BtnChoise, styles.BtnChoiseSelected]}
          textStyle={[styles.BtnChoisetextStyle]}
          selectedTextStyle={[styles.BtnChoiseSelected]}
          containerStyle={{backgroundColor: 'transparent', borderWidth: 0}}
          selectedIndex={this.state.SelectedBtn}
          buttons={this.state.btnsDisplay}
        />
      );
    }
  };
  render() {
    if (this.state.btnsDisplay.length > 1) {
      return (
        <View style={styles.container}>
          <Text
            style={{
              color: '#fff',
              fontSize: 18,
              fontFamily: fontFamilyBold(),
              marginBottom: 10,
            }}>
            {translate('programmation')} :
          </Text>
          <View>{this.state.isWait ? <ThreeDotsLoader /> : <View />}</View>

          {this.renderBtn()}

          <View style={{flexDirection: 'row', marginTop: 20}}>
            <Text style={{flex: 4, color: '#fff', padding: 5}}>
              {translate('Plages horaires')} {this.state.device_number}
            </Text>
            <TouchableOpacity
              onPress={() => this.openModal()}
              style={{padding: 5}}>
              <Icon
                color="#fff"
                activeOpacity={1}
                containerStyle={{width: 60, height: 60, borderRadius: 30}}
                iconStyle={{marginTop: 0, marginLeft: 0}}
                name="plus"
                type="font-awesome"
                size={20}
                underlayColor={'#fff'}
              />
            </TouchableOpacity>
          </View>

          <View>{this.renderPlages()}</View>

          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.NewPlage}>
            <NewPlage
              back={this.closeModal}
              AddNewPlage={this.AddNewPlage}
              isSlotWait={this.state.isSlotWait}
              device_name={this.props.device_name}
              device_speed={this.props.device_speed_min}
              device_speed_min={this.props.device_speed_min}
              device_speed_max={this.props.device_speed_max}
              device_speed_unit={this.props.device_speed_unit}
              device_speed_gap={this.props.device_speed_gap}
            />
          </Modal>

          <ModalDateTimePicker
            isshow={this.state.isOpenModelDateTime}
            close={this.closeModelDateTime}
          />
        </View>
      );
    }

    return <View />;
  }

  formatTime = date => {
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();

    minutes = minutes < 10 ? '0' + minutes : minutes;
    hours = hours < 10 ? '0' + hours : hours;
    var strTime = hours + ':' + minutes;
    return strTime;
  };

  closeModelDateTime = val => {
    this.setState({isOpenModelDateTime: false});

    if (!val) {
      return null;
    }
    //

    let newVal = this.formatTime(val);
    // console.log("Datetimeselected ",val,newVal,this.state.selectedSlotIndex);
    let slot = this.state.slots[this.state.selectedSlotIndex];
    slot[this.state.modalFieldTime] = newVal;
    let _slots = this.state.slots;
    _slots[this.state.selectedSlotIndex] = slot;
    this.setState({slots: _slots});
    this.UpdateDevicesByField(this.state.modalFieldTime, newVal, slot.slot_id);
    this.setState({isOpenModelDateTime: false});
  };

  openModal = () => {
    this.setState({NewPlage: true});
  };
  closeModal = () => {
    this.setState({NewPlage: false});
    setTimeout(() => {
      this.props.refeshDevice();
      this.initData();
    }, 300);
  };

  AddNewPlage = obj => {
    let slot_days = '';
    let selectedDay = [];
    obj.days.map((item, index) => {
      if (item.isselected) {
        slot_days = slot_days + '' + (index + 1);
        selectedDay.push(index);
      }
    });
    let itemSlot = {
      action_number: this.props.device_number,
      selectedDay: selectedDay,
      slot_days: slot_days,
      slot_id: '',

      slot_speed: obj.speed_val,
      slot_start: obj.LabelStartTime,
      slot_stop: obj.LabelEndTime,
    };
    let slot_start = obj.LabelStartTime.replace(':', '');
    let slot_stop = obj.LabelEndTime.replace(':', '');
    this.setState({isSlotWait: true});

    this.props
      .addSlot(this.props.sys_appid, {
        type: 'slot',
        start: slot_start,
        stop: slot_stop,
        days: slot_days,
        speed: obj.speed_val,
        action: 1,
        number: this.state.device_number,
      })
      .then(response => {
        itemSlot.slot_id = response.slots.slot_id;
        // console.log("response addSlot APP RES ",response.slots[0].slot_id);
        this.setState({isSlotWait: false});
        let slot = this.state.slots;
        slot.push(itemSlot);
        this.props.refeshDevice();

        this.setState({slots: slot});

        this.closeModal();
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        //console.log("errormsg  Slot ",errormsg)
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isSlotWait: false});
      });
  };
  removeSlot = index => {
    this.setState({isWait: true});
    let slot = this.state.slots[index];
    //  console.log("this.props.sys_appid removeSlot",this.props.sys_appid,slot.slot_id);
    this.props
      .removeSlot(this.props.sys_appid, {
        type: 'slot',
        number: slot.slot_id,
      })
      .then(response => {
        // console.log("response ",response)

        this.setState({isWait: false});
        let _slots = this.state.slots;
        _slots.splice(index, 1);
        this.setState({slots: _slots});
        this.props.refeshDevice();
        ShowSnaskMessage({
          message: 'Opération effectuée avec succès',
          textColor: '#fff',
          backgroundColor: '#7ED321',
          buttonColor: '#7ED321',
          duration: 2000,
        });
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        // console.log("errormsg  Slot ",errormsg)
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#121212',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
  },
  BtnChoise: {
    borderWidth: 1,
    fontFamily: 'arial',
    fontSize: 12,
    borderColor: '#242424',
    backgroundColor: '#242424',
    borderRadius: 50,
    paddingTop: 10,
    paddingBottom: 10,

    marginRight: 10,
  },
  BtnChoiseDaydefault: {
    borderWidth: 1,
    fontFamily: 'arial',
    fontSize: 12,
    borderColor: '#289EFF',
    backgroundColor: '#000',
    borderRadius: 50,
    paddingTop: 10,
    paddingBottom: 10,

    marginRight: 10,
  },
  BtnChoiseDay: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 80 / 2,
    borderWidth: 1,
    borderColor: '#289EFF',
    backgroundColor: '#000',
  },
  BtnChoiseSelected: {
    backgroundColor: '#289EFF',
    color: '#fff',
  },
  BtnChoiseDayStyle: {
    fontFamily: 'arial',
    fontSize: 10,
    backgroundColor: 'transparent',
    color: '#fff',
  },
  BtnChoisetextStyle: {
    fontFamily: 'arial',
    fontSize: 12,
    backgroundColor: 'transparent',
    color: '#fff',
  },
  itemp: {
    backgroundColor: '#000',
    padding: 5,
    marginTop: 10,
    width: '95%',
    borderRadius: 20,
  },
  Modalcontainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(154, 152, 152, 0.84)',
    flexDirection: 'column',
  },
});

Programmation.propTypes = {
  device_number: PropTypes.string,
  device_mode_display: PropTypes.string,
  device_sequence_available: PropTypes.string,
  device_sequence: PropTypes.string,
  device_name: PropTypes.string,
  device_speed: PropTypes.number,
  device_speed_min: PropTypes.number,
  device_speed_max: PropTypes.number,
  device_speed_unit: PropTypes.number,
  device_speed_gap: PropTypes.number,
  sys_appid: PropTypes.string,
  slots: PropTypes.array,
  refeshDevice: PropTypes.func,
};

Programmation.defaultProps = {
  device_number: '',
  device_mode_display: '',
  device_sequence_available: '',
  device_sequence: '',
  device_name: '',
  device_speed: '',
  device_speed_min: '',
  device_speed_max: '',
  device_speed_unit: '',
  device_speed_gap: '',
  sys_appid: '',
  slots: [],
  refeshDevice: () => {},
};
const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
    addSlot: (appId, params) => {
      return dispatch(actions.addSlot(appId, params));
    },
    removeSlot: (appId, params) => {
      return dispatch(actions.removeSlot(appId, params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Programmation);
