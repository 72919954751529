import AsyncStorage from '@react-native-async-storage/async-storage';
import {showSnackBar} from '../components/snackBar';
import {Platform, Dimensions} from 'react-native';
import DeviceInfo from 'react-native-device-info';
// import Snackbar from 'react-native-snackbar';
import translate, {translateString} from '../translations/translate';
const get_api_host = () => {
  return 'https://socket.swimo.io/cgi-bin';
};

const APP_BAR_ICON_SIZE = 25;
const LEFT_HIT_SLOP = {top: 8, left: 30, right: 30, bottom: 30};
const NB_COLUMNS = Dimensions.get('window').width >= 800 ? 2 : 1;

const getColumnNumber = (width) => {
  if((width || Dimensions.get('window').width) >= 800) {
    return 2;
  }

  return 1;
};

const msg_code_to_text = code => {
  let msgcode = {
    _MISSING_HEADER: 'Le token est manquant',
    _INVALID_NEW_SERIAL: 'Ce nouveau numéro de série n’existe pas',
    _INVALID_SERIAL: 'Ce numéro de série n’existe pas',
    _INVALID_TOKEN: 'Le token n’est plus valide',
    _UNAUTHORIZED_ACCESS: 'Tous les entêtes sont manquants',
    _USERNAME_ALREADY_REGISTERED: 'Ce pseudo ou cet email est déjà pris',
    _MISSING_HEADER_USER: 'Le couple pseudo/pass est manquant',
    _UNVAILABLE_USER: 'Cet utilisateur est inconnu',
    _MISSING_KEY: 'Il faut choisir Reset (key=0) ou Renew (key=1)',
    _USER_TYPE_ANONYMOUS_INVALID_OPERATION:
      'un compte anonyme ne peut pas renouveller son mot de passe',
    _MISSING_HEADER_CODE: 'Le mot de passe est manquant',
    _MISSING_HEADER_APPID: 'Le token est manquant',
    _INVALID_USER: 'Pseudo ou émail est incorrecte',
    _MISSING_APPID: '_MISSING_APPID',
  };

  if (msgcode.hasOwnProperty(code)) {
    return msgcode[code];
  }

  if (typeof code === 'object') {
    return code.message;
    // return JSON.stringify(code);
  }
  return code;
};

const isColor = (cl, _default = '#fff') => {
  //console.log("isColor ",cl)
  return cl || _default;
};
const ShowSnaskMessage = async _default => {
  if (Platform.OS === 'ios') {
    Snackbar.show({
      text: await translateString(_default.message),
      duration: Snackbar.LENGTH_SHORT,
    });
  } else {
    showSnackBar({
      message: _default.hasOwnProperty('message')
        ? translate(_default.message)
        : '',
      textColor: _default.hasOwnProperty('textColor')
        ? _default.textColor
        : '#FFF', // message text color
      position: _default.hasOwnProperty('position') ? _default.position : 'top', // enum(top/bottom).
      confirmText: _default.hasOwnProperty('confirmText')
        ? _default.confirmText
        : '', // button text.
      buttonColor: _default.hasOwnProperty('buttonColor')
        ? _default.buttonColor
        : '#03a9f4', // default button text color
      duration: _default.hasOwnProperty('duration') ? _default.duration : 4000, // (in ms), duartion for which snackbar is visible.
      animationTime: _default.hasOwnProperty('animationTime')
        ? _default.animationTime
        : 250, // time duration in which snackbar will complete its open/close animation.
      backgroundColor: _default.hasOwnProperty('backgroundColor')
        ? _default.backgroundColor
        : '#323232', //background color for snackbar
      onConfirm: _default.hasOwnProperty('onConfirm')
        ? _default.onConfirm
        : () => {}, //  perform some task here on snackbar button press.
    });
  }
};

const showSnaskMessageOperationSuccess = () =>
  ShowSnaskMessage({
    message: 'operation_success',
    textColor: '#000',
    backgroundColor: '#7ED321',
    buttonColor: '#7ED321',
    duration: 2000,
  });

const setStoreData = async (storage_Key, storage_val) => {
  try {
    if (typeof storage_val === 'object') {
      storage_val = JSON.stringify(storage_val);
      await AsyncStorage.setItem('@' + storage_Key, storage_val);
    } else {
      await AsyncStorage.setItem('@' + storage_Key, storage_val);
    }

    return true;
  } catch (e) {
    return null;
  }
};

const removeStoreData = async storage_Key => {
  try {
    await AsyncStorage.removeItem('@' + storage_Key);
  } catch (e) {
    // remove error
  }
};

const getStoreData = async storage_Key => {
  try {
    return await AsyncStorage.getItem('@' + storage_Key);
  } catch (e) {
    return null;
  }
};

const getStoreDataPromise = async storage_Key => {
  return AsyncStorage.getItem('@' + storage_Key);
};
const getStoreMultiDataPromise = async storage_Keys => {
  let arr = storage_Keys.map(key => {
    return '@' + key;
  });
  return AsyncStorage.multiGet(arr);
};

const getStoreDataObj = async storage_Key => {
  const data = await getStoreData(storage_Key);
  if (data && typeof data === 'string') {
    return JSON.parse(data);
  }

  return null;
};

const getStoreDataString = async storage_Key => {
  return await getStoreData(storage_Key);
};
const getStoreDataBool = async storage_Key => {
  const bool = await getStoreData(storage_Key);
  if (bool === 'true') {
    return true;
  }
  return false;
};

const appHeaderHeight = () => {
  return 50;
};
const appHeaderTop = () => {
  if (Platform.OS === 'ios') {
    return DeviceInfo.hasNotch() ? 30 : 15;
  }
  return DeviceInfo.hasNotch() ? 40 : 10;
};
export {
  get_api_host,
  msg_code_to_text,
  ShowSnaskMessage,
  showSnaskMessageOperationSuccess,
  getStoreData,
  setStoreData,
  getStoreDataString,
  getStoreDataBool,
  getStoreDataObj,
  removeStoreData,
  getStoreDataPromise,
  getStoreMultiDataPromise,
  appHeaderHeight,
  appHeaderTop,
  isColor,
  APP_BAR_ICON_SIZE,
  LEFT_HIT_SLOP,
  NB_COLUMNS,
  getColumnNumber
};
