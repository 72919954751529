import {Platform} from 'react-native';

const fontFamilyRegular = () => {
  return Platform.OS === 'ios' ? 'Helvetica-Light' : 'Helvetica-neue';
};

const fontFamilyLight = () => {
  return Platform.OS === 'ios' ? 'Helvetica-Light' : 'Helvetica-neue-light';
};

const fontFamilyBlack = () => {
  return Platform.OS === 'ios' ? 'HelveticaNeue-Bold' : 'Helvetica-neue-bold';
};

const fontFamilyBold = () => {
  return Platform.OS === 'ios' ? 'Helvetica-Bold' : 'Helvetica-neue-bold';
};

const fontFamilyArialBold = () => {
  return Platform.OS === 'ios' ? 'GillSans-Bold' : 'Arial-bold';
};

const fontFamilyArialBlack = () => {
  return Platform.OS === 'ios' ? 'GillSans-Bold' : 'Arial-black';
};

export {
  fontFamilyArialBold,
  fontFamilyArialBlack,
  fontFamilyRegular,
  fontFamilyLight,
  fontFamilyBlack,
  fontFamilyBold,
};
