import React from 'react';
import {StyleSheet, Text, View, TouchableOpacity, Modal} from 'react-native';
import {connect} from 'react-redux';
import {Icon} from 'react-native-elements';
import PropTypes from 'prop-types';

import {getTankName} from '../../Utils/device';
import bidon from '../../assets/bi.svg';
import LinearGradient from 'react-native-linear-gradient';
import BottomExtraValues from './BottomExtraValues';
import {
  fontFamilyArialBlack,
  fontFamilyArialBold,
  fontFamilyBold,
  fontFamilyRegular,
} from '../../Config/fonts';
import {
  msg_code_to_text,
  ShowSnaskMessage,
  showSnaskMessageOperationSuccess,
} from '../../Config/Config';
import * as actions from '../../Store/Systems/SystemsActions';
import ThreeDotsLoader from '../ThreeDotsLoader';
import translate from '../../translations/translate';

class Pompe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalprogress: false,
      system: null,
      progressVal: 0,
      bggradialcolor: ['#1E1E1E', '#3A57FB'],
      longPressProgressVal: {
        ispressed: false,
        Interval: null,
      },
    };
  }

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps[this.props.fav_source_field] !==
      this.props[this.props.fav_source_field]
    ) {
      this.initData();
    }
  }

  componentWillUnmount() {}

  initData = () => {
    let gradialcolor = ['#1E1E1E', '#3A57FB'];
    if (
      this.props.device_color_setpoint &&
      this.props.device_color_setpoint !== '-1'
    ) {
      gradialcolor = ['#1E1E1E', this.props.device_color_setpoint];
    }

    this.setState({
      bggradialcolor: gradialcolor,
      progressVal: this.props[this.props.fav_source_field],
    });

    this.getSystem();
  };
  getSystem = () => {
    if (
      this.props.current_system.hasOwnProperty('system') &&
      this.props.current_system.system.length > 0
    ) {
      this.setState({
        system: this.props.current_system.system[0],
      });
    }
  };

  openModal = () => {
    this.setState({modalprogress: true});
  };
  closeModal = () => {
    this.setState({modalprogress: false});
    this.UpdateStatusDevices(
      this.props.fav_source_field,
      this.state.progressVal,
    );
  };

  UpdateStatusDevices = (field, val) => {
    if (this.state.isWait) {
      return true;
    }
    this.setState({isWait: true});
    this.props
      .UpdateDevice(this.state.system.sys_appid, {
        key: field,
        value: val,
        number: this.props.device_number,
      })
      .then(data => {
        if (field === 'device_index') {
          val = parseInt(val);
          this.setState({device_index: val});
        }

        this.setState({isWait: false});
        this.props.refreshDevice(this.props.device_number);

        showSnaskMessageOperationSuccess();
      })
      .catch(error => {
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };
  render() {
    //  console.log("Pompe ",this.props.fav_source_field)
    let bgH = 0;
    if (
      this.props[this.props.fav_source_field] &&
      parseInt(this.props.device_level_ref) > 0
    ) {
      bgH =
        (90 * parseInt(this.props[this.props.fav_source_field])) /
        parseInt(this.props.device_level_ref);
    }

    let bgtitlecolor =
      this.props.device_color_setpoint &&
      this.props.device_color_setpoint != '-1'
        ? this.props.device_color_setpoint
        : '#3A57FB';

    return (
      <LinearGradient
        colors={this.state.bggradialcolor}
        style={styles.LinearGradientcontainer}>
        <View style={styles.container}>
          <View style={styles.containerFixed}>
            <View
              style={{width: 50, backgroundColor: bgtitlecolor, height: 2}}
            />
            <Text style={styles.titrewidget}>{this.props.device_hash}</Text>
          </View>
          <View style={styles.topViewtitle}>
            <Text
              style={{
                color: this.props.device_color_setpoint,
                borderTopColor: this.props.device_color_setpoint,
                fontFamily: fontFamilyArialBold(),
                borderTopWidth: 3,
                fontSize: 16,
                paddingTop: 20,
              }}>
              {translate(getTankName(this.props.device_hash))}
            </Text>
          </View>
          <View style={styles.btnadd}>
            <TouchableOpacity onPress={() => this.openModal()}>
              <Icon size={20} name="plus" type="font-awesome" color="#fff" />
            </TouchableOpacity>
          </View>
          <View>{this.state.isWait ? <ThreeDotsLoader /> : <View />}</View>
          <View style={{flexDirection: 'row', marginTop: 25}}>
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}>
              <Text
                style={{
                  color: '#858585',
                  fontSize: 16,
                  fontFamily: fontFamilyBold(),
                  textAlign: 'center',
                  marginRight: 5,
                }}>
                {this.props[this.props.fav_source_field]}
              </Text>
              <Text
                style={{
                  color: '#858585',
                  fontSize: 12,
                  fontFamily: fontFamilyBold(),
                  textAlign: 'center',
                }}>
                {this.props.device_level_unit}
              </Text>
            </View>
            <View style={{flex: 1, marginBottom: -8}}>
              <LinearGradient
                colors={['#fff', '#fff']}
                style={{width: 70, height: 90, position: 'absolute', bottom: 5}}
              />
              <LinearGradient
                colors={['#17A7E8', '#3A57FB']}
                style={{
                  width: 70,
                  height: bgH,
                  position: 'absolute',
                  bottom: 5,
                }}
              />
              <img src={bidon} width={71} height={100} style={{ zIndex: 10 }} />
            </View>
          </View>
          <BottomExtraValues
            name2={this.props.sensor_name_2}
            value2={this.props.sensor_live_2}
            unit2={this.props.sensor_unit_2}
            name3={this.props.sensor_name_3}
            value3={this.props.sensor_live_3}
            unit3={this.props.sensor_unit_3}
          />
        </View>

        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.modalprogress}>
          <View style={styles.Modalcontainer}>
            <View
              style={{
                backgroundColor: '#000000',
                borderRadius: 20,
                width: '90%',
                paddingBottom: 20,
              }}>
              <View style={styles.topViewtitleModal}>
                <Text
                  style={{
                    color: this.props.device_color_setpoint,
                    borderTopColor: this.props.device_color_setpoint,
                    borderTopWidth: 3,
                    fontSize: 16,
                    marginTop: -2,
                    fontFamily: fontFamilyArialBold(),

                    paddingTop: 10,
                  }}>
                  {this.props.device_name}
                </Text>
              </View>
              <View style={{position: 'absolute', right: 10, top: 10}}>
                <TouchableOpacity onPress={() => this.closeModal()}>
                  <Icon
                    size={20}
                    containerStyle={{marginLeft: 10}}
                    name="check"
                    type="font-awesome"
                    color="#fff"
                  />
                </TouchableOpacity>
              </View>
              <View
                style={[
                  styles.ModalViewRow,
                  {marginTop: 50, marginBottom: 50},
                ]}>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <TouchableOpacity
                    onPress={() => this.UpdateprogressVal('-')}
                    delayLongPress={1000}
                    onPressIn={() => {
                      //console.log('TouchableOpacity onPressIn');
                      // console.log(Date.now())
                    }}
                    onPressOut={() => {
                      // console.log('TouchableOpacity onPressOut');
                      //console.log(Date.now())
                      this.EndUpdateonLongPressprogressVal('-');
                    }}
                    onLongPress={e => {
                      //console.log(' onLongPress');
                      //console.log(Date.now());

                      this.UpdateonLongPressprogressVal('-');
                    }}>
                    <View style={styles.CircleShapeView}>
                      <Icon
                        iconStyle={{marginTop: 0, marginLeft: 0}}
                        name="minus"
                        type="font-awesome"
                        size={20}
                        underlayColor={'#000'}
                        color={'#000'}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <View style={{flexDirection: 'row'}}>
                    <Text
                      style={{
                        fontSize: 30,
                        fontFamily: fontFamilyRegular(),
                        fontWeight: '500',
                        color: '#fff',
                      }}>
                      {this.state.progressVal}
                    </Text>
                    <Text style={{fontSize: 12, marginTop: 20, color: '#fff'}}>
                      {this.props.device_level_unit}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <TouchableOpacity
                    delayLongPress={1000}
                    onPress={() => {
                      this.UpdateprogressVal('+');
                    }}
                    onPressIn={() => {
                      //console.log('TouchableOpacity onPressIn');
                      // console.log(Date.now())
                    }}
                    onPressOut={() => {
                      // console.log('TouchableOpacity onPressOut');
                      //console.log(Date.now())
                      this.EndUpdateonLongPressprogressVal('+');
                    }}
                    onLongPress={e => {
                      //console.log(' onLongPress');
                      //console.log(Date.now());

                      this.UpdateonLongPressprogressVal('+');
                    }}>
                    <View style={styles.CircleShapeView}>
                      <Icon
                        iconStyle={{marginTop: 0, marginLeft: 0}}
                        name="plus"
                        type="font-awesome"
                        size={20}
                        underlayColor={'#000'}
                        color={'#000'}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </Modal>
      </LinearGradient>
    );
  }

  UpdateprogressVal = operation => {
    let val = parseFloat(this.state.progressVal);
    let step = 0.5;
    if (val / 100 >= 1) {
      step = 10;
    } else if (val / 10 >= 1) {
      step = 1;
    } else {
      step = 0.1;
    }
    if (step >= 1) {
      val = parseInt(this.state.progressVal);
    }
    if (operation === '-') {
      if (val - step > 0) {
        val = val - step;
      } else {
        val = 0;
      }
    } else {
      val = val + step;
    }
    val = val.toFixed(1);

    if (val >= 10) {
      val = parseInt(val);
    }

    if (val > parseInt(this.props.device_level_ref)) {
      val = parseInt(this.props.device_level_ref);
    }

    this.setState({progressVal: val});
  };

  EndUpdateonLongPressprogressVal = op => {
    /**
         * longPressProgressVal:{
                ispressed:false,
                Interval:null,
            }
         */

    if (this.state.longPressProgressVal.ispressed) {
      clearInterval(this.state.longPressProgressVal.Interval);
      this.setState({
        longPressProgressVal: {
          ispressed: false,
          Interval: null,
        },
      });
    }
  };

  UpdateonLongPressprogressVal = op => {
    if (!this.state.longPressProgressVal.ispressed) {
      var myVar = setInterval(() => {
        this.UpdateprogressVal(op);
      }, 100);
      this.setState({
        longPressProgressVal: {
          ispressed: true,
          Interval: myVar,
        },
      });
    }
  };
}

const styles = StyleSheet.create({
  LinearGradientcontainer: {
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
    padding: 1,
    marginBottom: 20,
  },
  container: {
    flex: 1,
    borderRadius: 10,
    backgroundColor: '#121212',
    paddingBottom: 10,
  },
  topViewtitle: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -10,
  },
  containerFixed: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnadd: {
    position: 'absolute',
    right: 0,
    fontSize: 30,
    marginTop: 5,
    marginRight: 5,
    color: '#fff',
    fontFamily: fontFamilyBold(),
    textAlign: 'right',
  },
  titrewidget: {
    textAlign: 'center',
    fontSize: 40,
    color: '#1E1E1E',
    fontFamily: fontFamilyArialBlack(),
    textTransform: 'uppercase',
    marginTop: 10,
  },
  Modalcontainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    flexDirection: 'column',
  },
  topViewtitleModal: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  ModalViewRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  CircleShapeView: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    borderRadius: 80 / 2,
    borderWidth: 1,
    borderColor: '#fff',
    backgroundColor: '#fff',
  },
});

Pompe.propTypes = {
  device_consumption: PropTypes.string,
  device_date: PropTypes.string,
  device_hash: PropTypes.string,
  device_index: PropTypes.string,
  device_level: PropTypes.string,
  device_level_ref: PropTypes.string,
  device_level_unit: PropTypes.string,
  device_mode_available: PropTypes.string,
  device_mode_display: PropTypes.string,
  device_name: PropTypes.string,
  device_number: PropTypes.string,
  device_power: PropTypes.string,
  device_security: PropTypes.string,
  device_sensor: PropTypes.string,
  device_sequence: PropTypes.string,
  device_sequence_available: PropTypes.string,
  device_sequence_display: PropTypes.string,
  device_setpoint: PropTypes.string,
  device_speed: PropTypes.string,
  device_standard: PropTypes.string,
  device_status: PropTypes.string,
  device_type: PropTypes.string,
  device_unit_power: PropTypes.string,
  device_unit_setpoint: PropTypes.string,
  sys_id: PropTypes.string,
  fav_source_field: PropTypes.string,
  sensor_unit_3: PropTypes.string,
  sensor_live_3: PropTypes.string,
  sensor_name_3: PropTypes.string,
  sensor_unit_2: PropTypes.string,
  sensor_live_2: PropTypes.string,
  sensor_name_2: PropTypes.string,
};
Pompe.defaultProps = {
  device_consumption: '',
  device_date: '',
  device_hash: '',
  device_index: '',
  device_level: '',
  device_level_ref: '',
  device_level_unit: '',
  device_mode_available: '',
  device_mode_display: '',
  device_name: '',
  device_number: '',
  device_power: '',
  device_security: '',
  device_sensor: '',
  device_sequence: '',
  device_sequence_available: '',
  device_sequence_display: '',
  device_setpoint: '',
  device_speed: '',
  device_standard: '',
  device_status: '',
  device_type: '',
  device_unit_power: '',
  device_unit_setpoint: '',
  sys_id: '',
  fav_source_field: 'device_level_ref',
};
const mapStateToProps = (state, ownProps) => {
  return {
    sys_appid: state.SystemsRedux.system && state.SystemsRedux.system.sys_appid,
    current_system: state.SystemsRedux.system,
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pompe);
