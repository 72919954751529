import React from 'react';
import {StyleSheet, Text, View, Button, Picker, Platform} from 'react-native';
import {connect} from 'react-redux';
import {fontFamilyBold} from '../../Config/fonts';
import PropTypes from 'prop-types';
import RNPickerSelect from 'react-native-picker-select';
import ThreeDotsLoader from '../ThreeDotsLoader';
import * as actions from '../../Store/Systems/SystemsActions';
import {
  msg_code_to_text,
  showSnaskMessageOperationSuccess,
  ShowSnaskMessage,
} from '../../Config/Config';
import translate, {translateString} from '../../translations/translate';

class Capteur extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWait: false,
      sensors: [],
      default_sensor: null,
    };
  }
  componentDidMount() {
    this.initData();
  }

  initData = async () => {
    let _sensors = this.props.sensors.map(item => {
      return {
        label: `N°${item.sensor_number} ${item.sensor_name}`,
        value: item.sensor_number,
      };
    });

    _sensors.unshift({
      label: await translateString('none'),
      value: 0,
    });

    this.setState({
      default_sensor: this.props.device_sensor,
      sensors: _sensors,
    });
  };

  renderPickerAndroid() {
    if (Platform.OS !== 'ios') {
      return (
        <View style={styles.ViewPicker}>
          <Picker
            style={styles.Picker}
            selectedValue={this.state.default_sensor}
            placeholderIconColor={'black'}
            onValueChange={(itemValue, itemIndex) => {
              this.SelectWidgetType(itemValue);
            }}>
            {this.state.sensors.map(el => {
              return <Picker.Item label={el.label} value={el.value} />;
            })}
          </Picker>
        </View>
      );
    }
  }
  SelectWidgetType = val => {
    this.setState({default_sensor: val});

    this.props
      .UpdateDevice(this.props.sys_appid, {
        key: 'device_sensor',
        value: val,
        number: this.props.device_number,
      })
      .then(res => {
        console.log(res);
        this.setState({isWait: false});
        this.props.refeshDevice();
        showSnaskMessageOperationSuccess();
      })
      .catch(error => {
        this.setState({default_sensor: this.props.default_sensor});
        let errormsg = msg_code_to_text(error);
        ShowSnaskMessage({
          message: errormsg,
          textColor: '#fff',
          backgroundColor: 'red',
          buttonColor: 'red',
          duration: 2000,
        });

        this.setState({isWait: false});
      });
  };
  renderPickerIos() {
    if (Platform.OS === 'ios') {
      return (
        <View style={[styles.ViewPicker, {padding: 10}]}>
          <RNPickerSelect
            placeholder={{
              label: 'Choisir un capteur',
              value: null,
              color: '#fff',
            }}
            onValueChange={value => this.SelectWidgetType(value)}
            value={this.state.default_sensor}
            style={{
              ...pickerSelectStyles,
              iconContainer: {
                top: 20,
                right: 10,
              },
              placeholder: {
                color: '#fff',
                fontSize: 12,
                fontWeight: 'bold',
              },
            }}
            items={this.state.sensors}
          />
        </View>
      );
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <Text
          style={{
            color: '#fff',
            fontSize: 18,
            fontFamily: fontFamilyBold(),
            marginBottom: 10,
          }}>
          {translate('capteur')} :
        </Text>
        <View>{this.state.isWait ? <ThreeDotsLoader /> : <View />}</View>
        {this.renderPickerAndroid()}
        {this.renderPickerIos()}
      </View>
    );
  }
}
const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 5,
    paddingHorizontal: 4,
    color: '#fff',
    paddingRight: 30, // to ensure the text is never behind the icon
    textAlign: 'center',
    backgroundColor: '#242424',
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
});
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#121212',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
  ViewPicker: {
    fontSize: 14,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#242424',
    backgroundColor: '#242424',
    color: 'black',
    paddingLeft: 10,
    marginTop: 5,
    fontFamily: 'arial',
  },
  Picker: {
    fontSize: 12,
    color: 'black',
    height: 40,
    fontFamily: 'arial',
  },
  CircleShapeView: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    borderRadius: 80 / 2,
    borderWidth: 1,
    borderColor: '#fff',
    backgroundColor: '#fff',
  },
  ViewRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
Capteur.propTypes = {
  device_number: PropTypes.string,
  device_sensor: PropTypes.string,
  sys_appid: PropTypes.string,
  sensors: PropTypes.array,
};

Capteur.defaultProps = {
  device_number: '',
  device_sensor: '',
  sensors: [],
  sys_appid: '',
};

const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    UpdateDevice: (appId, params) => {
      return dispatch(actions.UpdateDevice(appId, params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Capteur);
