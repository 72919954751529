import React from 'react';
import {StyleSheet, Text, View, Button, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import GraphSensor from './widget/GraphSensor';
import Temperature from './widget/Temperature';
import FlowSwitch from './widget/FlowSwitch';
import SimpleSensor from './widget/SimpleSensor';
import GaugeSensor from './widget/GaugeSensor';

class WidgetStrip extends React.Component {
  componentDidMount() {}

  componentWillUnmount() {}

  stripToSensor = strip => {
    return {
      sensor_alarm_max: strip.strip_alarm_max,
      sensor_alarm_min: strip.strip_alarm_min,
      sensor_absolute_min: strip.strip_absolute_min,
      sensor_absolute_max: strip.strip_absolute_max,
      sensor_calibration_date: strip.strip_calibration_date,
      sensor_calibration_point: strip.strip_calibration_point,
      sensor_date: strip.strip_date,
      sensor_high_color: strip.strip_high_color,
      sensor_last_value: strip.strip_last_value,
      sensor_low_color: strip.strip_low_color,
      sensor_max: strip.strip_max,
      sensor_min: strip.strip_min,
      sensor_name: strip.strip_name,
      sensor_number: strip.strip_number,
      sensor_offset: strip.strip_offset,
      sensor_security: strip.strip_security,
      sensor_status: strip.strip_status,
      sensor_type: strip.strip_type,
      sensor_unit: strip.strip_unit,
      sensor_values: strip.strip_values,
      sensor_widget: strip.strip_widget,
      sensor_text: strip.strip_text,
      sensor_unit_3: strip.strip_unit_3,
      sensor_live_3: strip.strip_live_3,
      sensor_name_3: strip.strip_name_3,
      sensor_unit_2: strip.strip_unit_2,
      sensor_live_2: strip.strip_live_2,
      sensor_name_2: strip.strip_name_2,
      sensor_hash: strip.strip_hash,
    };
  };

  renderByType = () => {
    //console.log("renderByType ");
    const {strip_widget} = this.props.strip;
    const sensor = this.stripToSensor(this.props.strip);

    switch (parseInt(strip_widget)) {
      case 0:
        //curve
        return <FlowSwitch {...sensor} />;
      case 1:
        //graph
        return <GraphSensor {...sensor} />;
      case 2:
        //round
        return <Temperature {...sensor} />;
      case 3:
        //Simple
        return <SimpleSensor {...sensor} />;
      case 4:
        //Gauge
        return <GaugeSensor navigation={this.props.navigation} {...sensor} />;
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <TouchableOpacity
          style={{paddingBottom: 20}}
          onPress={() => {
            this.props.OpenDetailStrip(this.props.strip);
          }}>
          {this.renderByType()}
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

WidgetStrip.propTypes = {
  strip: PropTypes.object,
  current_system: PropTypes.object,
  base_list: PropTypes.array,
  device_list: PropTypes.array,
  slots: PropTypes.array,
  navigation: PropTypes.any,
  OpenDetailStrip: PropTypes.func,
};

WidgetStrip.defaultProps = {
  strip: {},
  navigation: {},
  current_system: {},
  device_list: [],
  slots: [],
  OpenDetailStrip: () => {},
};
const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux_app: state.AppRedux.last_update_data_redux,
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetStrip);
