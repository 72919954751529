import React from 'react';
import {TouchableOpacity} from 'react-native';
import {Icon} from 'react-native-elements';
import {APP_BAR_ICON_SIZE, LEFT_HIT_SLOP} from '../Config/Config';

class HeaderLeftComponent extends React.Component {
  render() {
    return (
      <>
        <TouchableOpacity
          hitSlop={LEFT_HIT_SLOP}
          onPress={() =>
            this.props.page
              ? this.props.navigation.navigate(this.props.page)
              : this.props.goToBack()
          }
          style={{paddingLeft: 5, paddingRight: 5, paddingTop: 20}}>
          <Icon
            name="chevron-left"
            type="font-awesome"
            color="#fff"
            size={APP_BAR_ICON_SIZE}
          />
        </TouchableOpacity>
      </>
    );
  }
}

export default HeaderLeftComponent;
