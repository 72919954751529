import Auth from '../../Models/Auth';
import {setStoreData} from '../../Config/Config';
import {ACTION_SYSTEMS_RESET} from '../Systems/SystemsActions';
import {get_base_list} from '../App/AppActions';
import {wsDisconnect} from '../../middleware/socket/websocket';
import {setCurrentSystemAppId} from '../../Models/Systems';

export const ACTION_USER_LOGIN = 'USER_LOGIN';
export const ACTION_USER_SET_APPID = 'USER_SET_APPID';
export const ACTION_USER_LOGOUT = 'USER_LOGOUT';
export const ACTION_USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const ACTION_USER_RENEW_PASSWORD = 'USER_RENEW_PASSWORD';

export function UserLogin(user, code) {
  return function(dispatch, getState) {
    let MAuth = new Auth();
    return MAuth.Login(user, code).then(data => {
      //  console.log("data Login ",data)
      setStoreData('user_logged', data);
      dispatch(get_base_list(data.appid));

      dispatch({type: ACTION_USER_LOGIN, user: data});
    });
  };
}

export function ResetPassword(user) {
  return function(dispatch, getState) {
    let MAuth = new Auth();
    return MAuth.ResetPassword(user).then(data => {
      dispatch({type: ACTION_USER_RESET_PASSWORD, restepassword: {}});
    });
  };
}

export function RenewPassword(user, pwd, appid) {
  return function(dispatch, getState) {
    let MAuth = new Auth();
    return MAuth.RenewPassword(user, pwd, appid).then(data => {
      dispatch({type: ACTION_USER_RENEW_PASSWORD, renewpassword: {}});
    });
  };
}
export function UserLogout(data) {
  return async function(dispatch, getState) {
    return new Promise(async resolve => {
      dispatch(wsDisconnect(null));
      setCurrentSystemAppId(false);
      dispatch({type: ACTION_SYSTEMS_RESET, data: []});
      dispatch({type: ACTION_USER_LOGOUT});

      await setStoreData('user_logged', '');
      await setStoreData('system_current', {});
      await setStoreData('app_fav', []);
      await setStoreData('app_racc', []);
      await ('app_base_list', []);
      await ('system_current_is_admin', 'false');
      resolve();
    });
    //console.log("Logout ");
  };
}
