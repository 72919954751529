import axios from 'axios';
import {setupCache} from 'axios-cache-adapter';
export default class Model {
  /**
   * Create a new model
   * @param {object} data model data
   * @param {string} url request url
   * @param {object} params additional url parameters
   */
  constructor(data, url, params = {}) {
    this.url = url;
    this.originalData = data;
    this.setFields(data);
    this.list = [];
    this.item = null;
    this.loading = false;
    this.editMode = false;
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    };
    this.params = {
      // query: null,
      // page: null,
      // per_page: 15,
      // include: null
    };

    this.setParams(params);
  }

  setUrl(url) {
    this.url = url;
  }
  /**
   * Set additional parameters
   * @param {object} params url parameters
   */
  setParams(params = {}) {
    this.params = Object.assign({}, this.params, params);
  }

  /**
   * Set fields as model properties
   * @param {object} data model data
   */
  setFields(data = {}) {
    // this.data = Object.assign({}, this.data, data);
    for (const field in data) {
      this[field] = data[field];
    }
  }

  setHeaders(data = {}) {
    for (const field in data) {
      this.headers[field] = data[field];
    }
  }

  /**
   * Set all model properties to null
   */
  clean() {
    for (const property in this.originalData) {
      this[property] = null;
    }
  }

  /**
   * Fetches a paginated list of a specific resource
   * @param {object} params additional url parameters
   * @param {callback} transformResponseCallback function for transforming the response
   * @param {callback} transformRequestCallback function for transforming the request
   * @return Promise
   */
  fetch(
    params = {},
    transformResponseCallback = null,
    transformRequestCallback = null,
  ) {
    this.setParams(params);
    console.log('fectch');
    return this.submit(
      'get',
      this.url,
      null,
      null,
      transformResponseCallback,
      transformRequestCallback,
    );
  }
  post(
    params = {},
    transformResponseCallback = null,
    transformRequestCallback = null,
  ) {
    this.setParams(params);
    console.log('POST => ', params);
    return this.submit(
      'post',
      this.url,
      params,
      null,
      transformResponseCallback,
      transformRequestCallback,
    );
  }

  put(
    params = {},
    transformResponseCallback = null,
    transformRequestCallback = null,
  ) {
    this.setParams(params);
    console.log('PUT => ', params);
    return this.submit(
      'put',
      this.url,
      params,
      null,
      transformResponseCallback,
      transformRequestCallback,
    );
  }

  /**
   * Fetches the resource matching the provided id
   * @param {integer} id resource id
   * @param {object} params additional url parameters
   * @param {callback} transformResponseCallback function for transforming the response
   * @param {callback} transformRequestCallback function for transforming the request
   * @return Promise
   */
  get(
    id,
    params = {},
    transformResponseCallback = null,
    transformRequestCallback = null,
  ) {
    this.setParams(params);
    return this.submit(
      'get',
      `${this.url}/${id}`,
      null,
      null,
      transformResponseCallback,
      transformRequestCallback,
    );
  }

  /**
   * Creates a resource with the provided data
   * @param {object} data request payload
   * @param {callback} transformResponseCallback function for transforming the response
   * @param {callback} transformRequestCallback function for transforming the request
   * @return Promise
   */
  create(
    data,
    transformResponseCallback = null,
    transformRequestCallback = null,
  ) {
    return this.submit(
      'post',
      this.url,
      data,
      null,
      transformResponseCallback,
      transformRequestCallback,
    );
  }

  /**
   * Updates the resource matching the provided id with the provided data
   * @param {integer} id resource id
   * @param {object} data request payload
   * @param {callback} transformResponseCallback function for transforming the response
   * @param {callback} transformRequestCallback function for transforming the request
   * @return Promise
   */
  update(
    id,
    data,
    transformResponseCallback = null,
    transformRequestCallback = null,
  ) {
    return this.submit(
      'patch',
      `${this.url}/${id}`,
      data,
      null,
      transformResponseCallback,
      transformRequestCallback,
    );
  }

  /**
   * Deletes the resource matching the provided id
   * @param {integer} id resource id
   * @param {callback} transformResponseCallback function for transforming the response
   * @param {callback} transformRequestCallback function for transforming the request
   * @return Promise
   */
  destroy(
    id,
    transformResponseCallback = null,
    transformRequestCallback = null,
  ) {
    return this.submit(
      'delete',
      `${this.url}/${id}`,
      null,
      null,
      transformResponseCallback,
      transformRequestCallback,
    );
  }
  /**
   * Sends an ajax request with the specified configurations
   * @param {string} requestType ajax request method
   * @param {string} url request url
   * @param {object} data request payload
   * @param {object} headers request headers
   * @param {callback} transformResponseCallback function for transforming the response
   * @param {callback} transformRequestCallback function for transforming the request
   * @return Promise
   */
  submit(
    requestType,
    url,
    data = null,
    headers = null,
    transformResponseCallback = null,
    transformRequestCallback = null,
  ) {
    let config = {
      method: requestType,
      url: url,
      data: data,
    };

    if (requestType === 'get') {
      const cache = setupCache({
        maxAge: 15 * 60 * 1000,
      });
      config.params = this.params;
      config.adapter = cache.adapter;
    }

    if (headers != null) {
      config.headers = headers;
      config.headers = {};
    }
    config.headers = {
      //Authorization:"Bearer "+get_api_access_token(),
      //Authorization:"Bearer 1e605nb0le3mk9irop7etocskp1x8av8",
      // Authorization:'Bearer eqkujh7c0h1qb31ict1c55hchivdka7i',
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    };

    config.headers = this.headers;

    if (transformResponseCallback != null) {
      config.transformResponse = [transformResponseCallback];
    }

    if (transformRequestCallback != null) {
      config.transformRequest = [transformRequestCallback];
    }

    // console.log('Request Axios => ',config);
    return axios(config);
  }

  getErrorMessageForResponce(data) {
    const params = data.parameters;
    // console.log('getErrorMessageForResponce => ',data.parameters);
    let message = data.message;
    if (typeof params !== 'undefined') {
      Object.keys(data.parameters).map(function(key) {
        let keyfiled = key;
        if (isNaN(key)) {
          key = key - 1;
        }
        message = message.replace(`%${keyfiled}`, data.parameters[key]);
      });
      console.log('getErrorMessageForResponce New Val=> ', message);
      return message;
    }
    return message;
  }
}
