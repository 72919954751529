import React, {useEffect, useState} from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  ScrollView,
  TouchableWithoutFeedback,
  View,
  Platform,
  Alert,
  PermissionsAndroid,
  Picker,
  StyleSheet,
  SafeAreaView,
} from 'react-native';
// import WifiManager from 'react-native-wifi-reborn';

import RNPickerSelect from 'react-native-picker-select';

import styles from './styles.js';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {Button, Input, Text, Header, CheckBox} from 'react-native-elements';

import HeaderLeftComponent from '../../components/HeaderLeftComponent';
import translate, {translateString} from '../../translations/translate';
import {appHeaderHeight} from '../../Config/Config';
import {fontFamilyRegular} from '../../Config/fonts';

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 5,
    paddingHorizontal: 4,
    color: '#fff',
    paddingRight: 30, // to ensure the text is never behind the icon
    textAlign: 'center',
    backgroundColor: 'transparent',
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
});

const Step2 = ({navigation, nextStep}) => {
  const [ssidList, setSsidList] = useState([]);
  const [selectedSsid, setSelectedSsid] = useState('');
  const [password, setPassword] = useState('');
  const [checkboxText, setCheckboxText] = useState('');
  const [checkboxValue, setCheckbox] = useState(false);
  const [isWait, setIsWait] = useState(false);
  const [errors, setErrors] = useState('');
  const [generalError, setGeneralErrors] = useState('');
  const [previousSsid, setPreviousSsid] = useState('');
  const [status, setStatus] = useState('Loading...');

  const renderPicker = () => {
    if (Platform.OS !== 'ios') {
      return (
        <View style={styles.ViewPicker}>
          <Picker
            style={styles.Picker}
            selectedValue={selectedSsid}
            placeholderIconColor={'#fff'}
            onValueChange={(itemValue, itemIndex) => {
              setSelectedSsid(itemValue);
            }}>
            {ssidList.map(ssid => {
              return <Picker.Item label={ssid} value={ssid} />;
            })}
          </Picker>
        </View>
      );
    } else {
      return (
        <View style={[styles.ViewPicker, {padding: 10}]}>
          <RNPickerSelect
            placeholder={{
              label: 'Choisir un reseau wifi',
              value: null,
              color: '#fff',
            }}
            onValueChange={value => setSelectedSsid(value)}
            value={selectedSsid}
            style={{
              ...pickerSelectStyles,
              iconContainer: {
                top: 20,
                right: 10,
              },
              placeholder: {
                color: '#fff',
                fontSize: 12,
                fontWeight: 'bold',
              },
            }}
            items={ssidList.map(s => ({value: s, label: s}))}
          />
        </View>
      );
    }
  };

  const load = async () => {
    setIsWait(true);
    setGeneralErrors(false);
    setStatus(false);

    const text = await translateString(
      'The controller will reboot with this wifi network as client. The swimo-xxxx wifi network will not be visible anymore',
    );
    setCheckboxText(text);

    if (Platform.OS === 'ios') {
      try {
        // setPreviousSsid(await WifiManager.getCurrentWifiSSID());
        setStatus('Trying to connect to the wifi controller');
        await WifiManager.connectToProtectedSSIDPrefix(
          'swimo-',
          'swimo000',
          false,
        );
        setStatus('Connection successful');
      } catch (err) {
        setGeneralErrors('Impossible to find the controller');
        setIsWait(false);
        setStatus(false);
        console.log(err);
        return;
      }
    } else if (Platform.OS === 'android') {
      try {
        setStatus('Checking permissions');
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
          {
            title: 'Location permission is required for WiFi connections',
            message:
              'This app needs location permission as this is required  ' +
              'to scan for wifi networks.',
            buttonNegative: 'DENY',
            buttonPositive: 'ALLOW',
          },
        );
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          setStatus('Checking permissions');
          if (!(await WifiManager.isEnabled())) {
            WifiManager.setEnabled(true);
          } else {
            setPreviousSsid(await WifiManager.getCurrentWifiSSID());
          }
          if (!previousSsid.startsWith('swimo-')) {
            setStatus('Scanning wifi networks...');
            const wifiList = await WifiManager.reScanAndLoadWifiList();
            const ssids = wifiList.filter(w => w.SSID.startsWith('swimo-'));
            setStatus('Seaching for the controller');
            if (!ssids || !ssids.length) {
              setGeneralErrors('Impossible to find the controller');
              setStatus(false);
              return;
            }

            setStatus('Trying to connect to the wifi controller');
            // TODO: Do something is there is more than one?
            await WifiManager.connectToProtectedSSID(
              ssids[0].SSID,
              'swimo000',
              false,
            );
          }
        } else {
          Alert.alert('Permission Error', 'You need to grant permission');
        }
      } catch (err) {
        setGeneralErrors('Impossible to find the controller');
        setIsWait(false);
        setStatus(false);
        console.log(err);
        return;
      }
    }

    let done = false;
    let retry = 0;
    setStatus('Connection successfull, getting the wifi list');
    do {
      retry++;
      try {
        const resp = await fetch('http://192.168.240.1/cgi-bin/getSSID');

        const body = await resp.text();

        setStatus('Please select a wifi and set the password');

        const ssids = body.split('\n');

        done = true;

        setSsidList(ssids);
        setIsWait(false);
      } catch (err) {
        console.log(err);
      }
    } while (retry < 5 && !done);

    if (!done) {
      setGeneralErrors('Impossible to find the controller');
    }

    setIsWait(false);
  };

  useEffect(() => {
    load();
  }, []);

  const setWifiPassword = async () => {
    try {
      setStatus('Sending the data to the controller');
      await fetch(
        `http://192.168.240.1/cgi-bin/setSSID?SSID=${selectedSsid}&PASS=${password}`,
      );

      setStatus('Done');

      nextStep(3);
    } catch (err) {
      console.log(err);
      setGeneralErrors('Impossible to find the controller');
      setStatus(false);
      // errors.password = err;
      // setErrors(errors);
    }
  };

  return (
    <SafeAreaView contentContainerStyle={{flexGrow: 1}}>
      <Header
        containerStyle={{
          backgroundColor: '#289EFF',
          height: appHeaderHeight(),
          paddingTop: 0,
          margin: 0,
          borderBottomColor: '#289EFF',
        }}
        leftComponent={
          <HeaderLeftComponent
            navigation={navigation}
            goToBack={navigation.goBack}
          />
        }
      />
      <KeyboardAwareScrollView contentContainerStyle={{flexGrow: 1}}>
        <KeyboardAvoidingView style={styles.containerView}>
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <ScrollView
              contentContainerStyle={{height: '100%', paddingBottom: 40}}>
              <Text style={styles.enteteStepText}>
                {translate('Wifi setup')}{' '}
              </Text>
              {translate(status, styles.status)}
              {translate(generalError, styles.error)}
              {generalError ? (
                <View
                  style={{
                    flex: 1,
                    marginTop: 30,
                    marginBottom: 30,
                  }}>
                  <View style={{alignSelf: 'center'}}>
                    <Button
                      loading={isWait}
                      onPress={load}
                      buttonStyle={styles.BtnButton}
                      title={translate('Retry')}
                    />
                  </View>
                </View>
              ) : (
                false
              )}
              {ssidList && ssidList.length ? renderPicker() : false}
              {selectedSsid ? (
                <Input
                  autoCapitalize="none"
                  selectionColor={'white'}
                  autoCorrect={false}
                  onChangeText={val => setPassword(val)}
                  label={translate('Password', styles.FormInputlabelStyle)}
                  labelStyle={styles.FormInputlabelStyle}
                  containerStyle={styles.FormInputcontainerStyleStep2}
                  inputContainerStyle={styles.FormInputContainerStyle}
                  inputStyle={[
                    styles.FormTextInput,
                    errors.password ? styles.FormTextInputError : {},
                  ]}
                  placeholder=""
                  errorStyle={styles.FormInputErrorStyle}
                  errorMessage={errors.password}
                />
              ) : (
                false
              )}
              {ssidList && ssidList.length ? (
                <CheckBox
                  onPress={() => setCheckbox(!checkboxValue)}
                  checked={checkboxValue}
                  containerStyle={{
                    backgroundColor: 'transparent',
                    borderWidth: 0,
                    margin: 0,
                  }}
                  textStyle={{
                    color: '#ffffff',
                    fontSize: 10,
                    fontFamily: fontFamilyRegular(),
                    fontWeight: 'normal',
                  }}
                  title={
                    <Text
                      style={{
                        color: '#ffffff',
                      }}>
                      {checkboxText}
                    </Text>
                  }
                />
              ) : (
                false
              )}
              {password ? (
                <View
                  style={{
                    flex: 1,
                    marginTop: 30,
                    marginBottom: 30,
                  }}>
                  <View style={{alignSelf: 'center'}}>
                    <Button
                      loading={isWait}
                      onPress={setWifiPassword}
                      buttonStyle={styles.BtnButton}
                      title={translate('Validate and reboot')}
                    />
                  </View>
                </View>
              ) : (
                false
              )}
            </ScrollView>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

export default Step2;
