import React from 'react';
import {StyleSheet, Text, View, Button, Dimensions} from 'react-native';
import {connect} from 'react-redux';
import LinearGradient from 'react-native-linear-gradient';
import AppBarChartWithLabel from '../../ChartJs/AppBarChartWithLabel';
import PropTypes from 'prop-types';

import {fontFamilyArialBlack, fontFamilyArialBold} from '../../Config/fonts';
import {isColor, getColumnNumber} from '../../Config/Config';
import {getAlarmColor} from '../../Utils/colors';
import BottomExtraValues from './BottomExtraValues';

class Temperature extends React.Component {
  constructor(props) {
    super(props);
    let dataGraph = [
      Math.round(parseFloat(props.sensor_min)),
      Math.round(parseFloat(props.sensor_max)),
    ];
    this.state = {
      dataGraph,
    };
  }

  resize = () => {
    this.setState({
      innerWidth: window.innerWidth
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  render() {
    let bgtitlecolor = '#3A57FB';
    let sensor_last_value = parseFloat(this.props.sensor_last_value);
    // let sensor_last_value = 10.89;
    if (sensor_last_value >= 10 && sensor_last_value < 100) {
      sensor_last_value = sensor_last_value.toFixed(1);
    } else if (sensor_last_value >= 100) {
      sensor_last_value = parseInt(sensor_last_value);
    }
    return (
      <LinearGradient
        colors={['#121212', '#3A57FB']}
        style={styles.containerLinearGradient}>
        <View style={styles.container}>
          <View style={styles.containerFixed}>
            <View
              style={{width: 50, backgroundColor: bgtitlecolor, height: 2}}
            />
            <Text style={styles.titrewidget}>{this.props.sensor_hash}</Text>
          </View>
          <View style={styles.topViewtitle}>
            <Text
              style={{
                color: getAlarmColor(
                  this.props.sensor_high_color,
                  this.props.sensor_low_color,
                  this.props.sensor_alarm_min,
                  this.props.sensor_alarm_max,
                  this.props.sensor_last_value,
                ),
                borderTopColor: getAlarmColor(
                  this.props.sensor_high_color,
                  this.props.sensor_low_color,
                  this.props.sensor_alarm_min,
                  this.props.sensor_alarm_max,
                  this.props.sensor_last_value,
                ),
                fontFamily: fontFamilyArialBold(),
                borderTopWidth: 3,
                fontSize: 16,
                paddingTop: 10,
              }}>
              {this.props.sensor_name}
            </Text>
          </View>
          <View style={{flexDirection: 'row', marginTop: 20}}>
            <View
              style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
              <LinearGradient
                colors={[
                  isColor(this.props.sensor_high_color),
                  isColor(this.props.sensor_low_color),
                ]}
                style={styles.CircleShapeView}>
                <View style={styles.SubCircleShapeView}>
                  <Text style={{color: '#fff', fontSize: 30}}>
                    {sensor_last_value}
                  </Text>
                  <Text style={{color: '#fff', fontSize: 12, marginTop: 20}}>
                    {this.props.sensor_unit}
                  </Text>
                </View>
              </LinearGradient>
            </View>
            <View style={{flex: 1}}>
              <AppBarChartWithLabel
                data={{
                  labels: ['January', 'February'],
                  datasets: [
                    {
                      data: this.state.dataGraph.reverse(),
                    },
                  ],
                }}
                width={Dimensions.get('window').width / (getColumnNumber() * 2) - 20}
                height={110}
                fromZero={true}
                withHorizontalLabels={false}
                unit={this.props.sensor_unit}
                chartConfig={{
                  backgroundColor: '#121212',
                  backgroundGradientFrom: '#121212',
                  backgroundGradientTo: '#121212',
                  decimalPlaces: 2,

                  color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                  colorBar: (val = 1) => {
                    // console.log(val,this.props.sensor_low_color,this.props.sensor_high_color)
                    if (
                      parseFloat(val) <= parseFloat(this.props.sensor_alarm_min)
                    ) {
                      return [isColor(this.props.sensor_low_color)];
                    }
                    if (
                      parseFloat(val) >= parseFloat(this.props.sensor_alarm_max)
                    ) {
                      return [isColor(this.props.sensor_high_color)];
                    }

                    return [
                      isColor(this.props.sensor_low_color),
                      isColor(this.props.sensor_high_color),
                    ];
                  },
                  style: {
                    borderRadius: 16,
                  },
                  barRadius: 10,
                  barPercentage: 0.5,
                  propsForLabels: {
                    fontSize: 12,
                  },
                }}
                style={{
                  marginVertical: 8,
                  borderRadius: 16,
                }}
              />
            </View>
          </View>
          <BottomExtraValues
            name2={this.props.sensor_name_2}
            value2={this.props.sensor_live_2}
            unit2={this.props.sensor_unit_2}
            name3={this.props.sensor_name_3}
            value3={this.props.sensor_live_3}
            unit3={this.props.sensor_unit_3}
          />
        </View>
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  topViewtitle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerFixed: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titrewidget: {
    textAlign: 'center',
    fontSize: 40,
    color: '#1E1E1E',
    fontFamily: fontFamilyArialBlack(),
    textTransform: 'uppercase',
    marginTop: 10,
  },
  container: {
    flex: 1,
    backgroundColor: '#121212',
    borderRadius: 10,

    paddingRight: 5,
    paddingBottom: 10,
  },
  containerLinearGradient: {
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
    padding: 1,
  },
  CircleShapeView: {
    width: 110,
    height: 110,
    borderRadius: 100 / 2,
    padding: 5,
  },
  SubCircleShapeView: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    borderRadius: 100 / 2,
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#000',
  },
});

Temperature.propTypes = {
  sensor_alarm_max: PropTypes.string,
  sensor_alarm_min: PropTypes.string,
  sensor_calibration_date: PropTypes.string,
  sensor_calibration_point: PropTypes.string,
  sensor_date: PropTypes.string,
  sensor_high_color: PropTypes.string,
  sensor_last_value: PropTypes.string,
  sensor_low_color: PropTypes.string,
  sensor_max: PropTypes.number,
  sensor_min: PropTypes.number,
  sensor_name: PropTypes.string,
  sensor_number: PropTypes.string,
  sensor_offset: PropTypes.string,
  sensor_security: PropTypes.string,
  sensor_status: PropTypes.string,
  sensor_type: PropTypes.string,
  sensor_unit: PropTypes.string,
  sensor_values: PropTypes.string,
  sensor_widget: PropTypes.string,
  sensor_unit_3: PropTypes.string,
  sensor_live_3: PropTypes.string,
  sensor_name_3: PropTypes.string,
  sensor_unit_2: PropTypes.string,
  sensor_live_2: PropTypes.string,
  sensor_name_2: PropTypes.string,
};
Temperature.defaultProps = {
  sensor_alarm_max: '',
  sensor_alarm_min: '',
  sensor_calibration_date: '',
  sensor_calibration_point: '',
  sensor_date: '',
  sensor_high_color: '',
  sensor_last_value: '',
  sensor_low_color: '',
  sensor_max: '',
  sensor_min: '',
  sensor_name: '',
  sensor_number: '',
  sensor_offset: '',
  sensor_security: '',
  sensor_status: '',
  sensor_type: '',
  sensor_unit: '',
  sensor_values: '',
  sensor_widget: '',
};
const mapStateToProps = (state, ownProps) => {
  return {
    last_update_data_redux: state.SystemsRedux.last_update_data_redux,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Temperature);
