import * as actions from './websocket';
import io from 'socket.io-client';
import {ShowSnaskMessage} from '../../Config/Config';
import { debounce } from 'lodash';

const socketMiddleware = () => {
  let socket = null;
  const WsSocketInit = () => {};
  const onOpen = store => () => {
    console.log('websocket open ');
    store.dispatch(actions.wsConnected('url'));
  };

  const onClose = store => () => {
    console.log('websocket close');
    store.dispatch(actions.wsDisconnected());
  };

  const wsSensorsData = debounce((store, payload) => {
    store.dispatch(actions.wsSensorsData(payload));
  }, 5000);

  const onMessage = store => data => {
    console.log('onMessage ', data);
    //  data='{"actions": [{"actionNum": 1, "mode": 1, "status": 0}, {"actionNum": 2, "mode": 0, "status": 1}, {"actionNum": 3, "mode": 1, "status": 0}, {"actionNum": 4, "mode": 1, "status": 0}, {"actionNum": 5, "mode": 1, "status": 0}, {"actionNum": 6, "mode": 1, "status": 0}, {"actionNum": 7, "mode": 1, "status": 0}, {"actionNum": 8, "mode": 0, "status": 0}, {"actionNum": 19, "mode": 1, "status": 0}], "sensors": [{"sensorNum": 1, "value": 8.41}, {"sensorNum": 2, "value": 145.19}, {"sensorNum": 3, "value": 0.27}, {"sensorNum": 4, "value": 12.56}, {"sensorNum": 12, "value": 0}, {"sensorNum": 21, "value": 15.5}], "type": "realtime", "userId": "FR-THIB3BBQT09"}';
    const payload = JSON.parse(data);
    // console.log('receiving server message',payload);
    switch (payload.type) {
      case 'data':
        store.dispatch(actions.wsNewData(payload));
        // store.dispatch(updateGame(payload.game, payload.current_player));
        break;
      case 'actions-status-data':
        store.dispatch(actions.wsDevicesStatusData(payload));
        break;
      case 'actions-data':
        store.dispatch(actions.wsDevicesData(payload));
        break;
      case 'sensors-data':
        // wsSensorsData(store, payload);
        store.dispatch(actions.wsSensorsData(payload));
        break;
      case 'calibration-data':
        store.dispatch(actions.wsCalibrationData(payload));
        break;
      case 'realtime':
        store.dispatch(actions.wsRealtimeData(payload));
        break;
      default:
        break;
    }
  };

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case 'WS_CONNECT':
        if (socket !== null) {
          return;
          //socket.close();
        }

        console.log('socketIOClient init');

        socket = io('wss://now.swimo.io', {
          //  forceNew: true,
          forceNode: true,
        });

        // connect to the remote host
        //socket = new WebSocket(action.host);

        // websocket handlers
        socket.on('connect', () => {
          console.log('websocket connectid ', socket.id);

          socket.emit('authenticate', {
            appid: action.sys_appid,
          });
          /*ShowSnaskMessage({
            message: 'Contrôleur connecté',
            textColor: '#000',
            backgroundColor: '#7ED321',
            buttonColor: '#7ED321',
            duration: 2000,
          });*/
          onOpen(store)();
        });
        socket.on('reconnect', () => {
          //Your Code Here
          socket.emit('authenticate', {
            appid: action.sys_appid,
          });
          console.log('socketIOClient reconnect');
        });
        socket.on('event', onMessage(store));

        socket.on('data', onMessage(store));
        socket.on('authentication', data => {
          if (data.alive) {
            ShowSnaskMessage({
              message: 'Contrôleur connecté',
              textColor: '#000',
              backgroundColor: '#7ED321',
              buttonColor: '#7ED321',
              duration: 2000,
            });
          }
          console.log('ws is authenticated ', data);
        });

        socket.on('disconnect', reason => {
          console.log('socketIOClient disconnect ', reason);
          if (reason === 'io server disconnect') {
            // the disconnection was initiated by the server, you need to reconnect manually
            //  socket.connect();
          }
          // else the socket will automatically try to reconnect
        });

        socket.on('reconnect', attemptNumber => {
          console.log('socketIOClient reconnect ', attemptNumber);
          // ...
        });

        socket.on('reconnect_attempt', attemptNumber => {
          console.log('socketIOClient reconnect_attempt ', attemptNumber);
          // ...
        });

        socket.on('reconnecting', attemptNumber => {
          console.log('socketIOClient reconnecting ', attemptNumber);
          // ...
        });

        socket.on('reconnect_error', error => {
          console.log('socketIOClient reconnect_error ', error);
        });

        socket.on('reconnect_failed', () => {
          // ...
          console.log('socketIOClient reconnect_failed ');
        });

        socket.on('ping', () => {
          console.log('socketIOClient ping ');
          // ...
        });

        socket.on('pong', latency => {
          console.log('socketIOClient pong ', latency);
          // ...
        });

        socket.on('disconnect', function() {
          //  socket.socket.reconnect();
        });

        // socket.onmessage = onMessage(store);
        //socket.onclose = onClose(store);
        // socket.onopen = onOpen(store);

        break;
      case 'WS_SWITCH':
        console.log('MildderWare WS_SWITCH');
        if (socket) {
          socket.close();
        }
        console.log('socketIOClient WS_SWITCH init');

        socket = io('wss://now.swimo.io', {
          //  forceNew: true,
          forceNode: true,
        });

        // connect to the remote host
        //socket = new WebSocket(action.host);

        // websocket handlers
        socket.on('connect', () => {
          console.log('websocket connectid WS_SWITCH', socket.id);
          socket.emit('authenticate', {
            appid: action.sys_appid,
          });
          onOpen(store)();
        });
        socket.on('reconnect', () => {
          //Your Code Here
          socket.emit('authenticate', {
            appid: action.sys_appid,
          });
          console.log('socketIOClient reconnect WS_SWITCH');
        });
        socket.on('event', onMessage(store));

        socket.on('data', onMessage(store));
        socket.on('authentication', data => {
          if (data.alive) {
            ShowSnaskMessage({
              message: 'Contrôleur connecté',
              textColor: '#000',
              backgroundColor: '#7ED321',
              buttonColor: '#7ED321',
              duration: 2000,
            });
          }
          console.log('ws is authenticated ', data);
        });

        socket.on('disconnect', reason => {
          console.log('socketIOClient disconnect WS_SWITCH ', reason);
          if (reason === 'io server disconnect') {
            // the disconnection was initiated by the server, you need to reconnect manually
            //  socket.connect();
          }
          // else the socket will automatically try to reconnect
        });

        socket.on('reconnect', attemptNumber => {
          console.log('socketIOClient reconnect WS_SWITCH', attemptNumber);
          // ...
        });

        socket.on('reconnect_attempt', attemptNumber => {
          console.log(
            'socketIOClient reconnect_attempt WS_SWITCH',
            attemptNumber,
          );
          // ...
        });

        socket.on('reconnecting', attemptNumber => {
          console.log('socketIOClient reconnecting WS_SWITCH', attemptNumber);
          // ...
        });

        socket.on('reconnect_error', error => {
          console.log('socketIOClient reconnect_error WS_SWITCH', error);
        });

        socket.on('reconnect_failed', () => {
          // ...
          console.log('socketIOClient reconnect_failed WS_SWITCH');
        });

        socket.on('ping', () => {
          console.log('socketIOClient ping WS_SWITCH');
          // ...
        });

        socket.on('pong', latency => {
          console.log('socketIOClient pong WS_SWITCH', latency);
          // ...
        });

        socket.on('disconnect', function() {
          //  socket.socket.reconnect();
        });

        break;
      case 'WS_REFRESH':
        if (socket !== null) {
          socket.close();
        }
        console.log('WS_REFRESH ', action);
        socket = io('wss://now.swimo.io', {
          // forceNew: true,
          forceNode: true,
        });

        socket.on('reconnect', () => {
          //Your Code Here
          socket.emit('authenticate', {
            appid: action.sys_appid,
          });

          console.log('socketIOClient refesh reconnect');
        });
        // websocket handlers
        socket.on('connect', () => {
          socket.emit('authenticate', {
            appid: action.sys_appid,
          });
          /*ShowSnaskMessage({
            message: 'Contrôleur connecté',
            textColor: '#000',
            backgroundColor: '#7ED321',
            buttonColor: '#7ED321',
            duration: 2000,
          });*/
          onOpen(store)();
        });
        socket.on('event', onMessage(store));

        socket.on('data', onMessage(store));
        socket.on('authentication', data => {
          if (data.alive) {
            ShowSnaskMessage({
              message: 'Contrôleur connecté',
              textColor: '#000',
              backgroundColor: '#7ED321',
              buttonColor: '#7ED321',
              duration: 2000,
            });
          }
          console.log('ws is authenticated ', data);
        });
        socket.on('disconnect', function() {
          console.log('disconnect  and reconnect');
          // socket.reconnect();
        });

        break;
      case 'WS_DISCONNECT':
        if (socket !== null) {
          socket.close();
        }

        socket = null;
        console.log('websocket closed');
        break;
      case 'NEW_MESSAGE':
        console.log('sending a message', action.msg);
        socket.send(
          JSON.stringify({command: 'NEW_MESSAGE', message: action.msg}),
        );
        break;
      default:
        console.log('the next action:', action.type);
        return next(action);
    }
  };
};

export default socketMiddleware();
